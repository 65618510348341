import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'uxDetail',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'uxDetail.component.html',
    standalone: false
})
export class UxDetailComponent implements OnInit {
  @Input() uxDetail;

  depths = [];

  constructor() {
  }

  ngOnInit() {
    if (this.uxDetail) {
      for (let i = this.uxDetail.minDepth; i <= this.uxDetail.maxDepth; ++i) {
        this.depths.push(i);
      }
    }
  }

  getDetailByDepth(depth) {
    if (this.uxDetail) {
      const records = [];

      if (depth < 0) {
        ['uxc', 'uxl', 'uxcc'].forEach((type) => {
          Object.keys(this.uxDetail[type]).forEach((id) => {
            const record = this.uxDetail[type][id];
            if (record.depths.indexOf(depth) >= 0) {
              records.push({
                type,
                id,
                name: record.name,
              });
            }
          });
        });
      } else if (depth > 0) {
        ['uxcc'].forEach((type) => {
          Object.keys(this.uxDetail[type]).forEach((id) => {
            const record = this.uxDetail[type][id];
            if (record.depths.indexOf(depth) >= 0) {
              records.push({
                type,
                id,
                name: record.name,
                childUxcompIds: record.childUxcompIds,
              });
            }
          });
        });
      }

      return records;
    }
  }
}

import {CommerceOrder} from "../../models/commerce/commerceOrder";
import { LogUtils } from "../../utils/logUtils";
import { idProtectionProductKeys } from "./idProtectionKeys";

export class IdProtectionUtils {
  public static META_KEY = "IdProtection";

  public getMeta(commerceOrder: CommerceOrder) {
    if (commerceOrder?.meta) {
      return commerceOrder.meta[IdProtectionUtils.META_KEY];
    }
  }

  public hasValidProductKey(commerceOrders: CommerceOrder[], productKey) {
    let flag = false;
    if (commerceOrders?.length) {
      commerceOrders.some((commerceOrder) => {
        if (commerceOrder.isActive()) {
          const meta = this.getMeta(commerceOrder);
          let validCommerceProductKeys = meta?.validCommerceProductKeys;
          if (!validCommerceProductKeys && commerceOrder?.tempClientSecured && commerceOrder?.tempClientSecured[IdProtectionUtils.META_KEY]) {
            validCommerceProductKeys = commerceOrder?.tempClientSecured[IdProtectionUtils.META_KEY]?.validCommerceProductKeys;
          }
          if (validCommerceProductKeys?.length) {
            if (validCommerceProductKeys.includes(productKey)) {
              flag = true;
              return true;
            }
          }
        }
      });
    }
    return flag;
  }

  public getProductIdForCustomerEnrollment(commerceOrders: CommerceOrder[]) {
    try {
      const productIds = {
        insurance1M: null,
        insurance: null,
        default: null,
      };

      if (commerceOrders?.length) {
        commerceOrders = [...commerceOrders];

        commerceOrders.sort((a, b) => {
          return a.createdTimestamp - b.createdTimestamp;
        });

        // commerceOrder - subStatus cancel status check remove
        commerceOrders.forEach((commerceOrder) => {
          if (commerceOrder.isActive()) {  //  && (!commerceOrder.isCancelled())) {
            const meta = this.getMeta(commerceOrder);
            if (meta && meta?.externalProductId && meta?.validCommerceProductKeys?.length) {
              if ((!productIds.insurance1M) && meta.validCommerceProductKeys.includes(idProtectionProductKeys.idProtectionFraudInsurance1M)) {
                productIds.insurance1M = meta.externalProductId;
              } else if ((!productIds.insurance) && meta.validCommerceProductKeys.includes(idProtectionProductKeys.idProtectionFraudInsurance)) {
                productIds.insurance = meta.externalProductId;
              } else if ((!productIds.default)) {
                productIds.default = meta.externalProductId;
              }
            }
          }
        });
      }
      if (productIds?.insurance1M) {
        return productIds.insurance1M;
      } else if (productIds?.insurance) {
        return productIds.insurance;
      } else {
        return productIds.default;
      }
    } catch (e) {
      LogUtils.error(e);
    }
  }
}

export const idProtectionUtils = new IdProtectionUtils();

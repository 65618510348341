import {Injectable} from "@angular/core";
import {UxComposite} from "../../common/models/ux/uxComposite";
import {LogUtils} from "../../common/utils/logUtils";
import {serverPaths} from "../../common/helpers/pathHelpers";
import {customPathChanger} from "../utils/customPathChanger";
import { StorageService } from "./storage.service";

declare var $: any;

@Injectable()
export class PixelService {
  STORAGE_NAME = 'pixel';

  storage: string[] = [];
  uxComposite: UxComposite;
  serverPaths = serverPaths;

  constructor(private storageService: StorageService) {
  }

  /**
   * this set's the uxCompostie property of current service to recieved parma
   * @param uxComposite to be assigned to this.uxComposite
   */
  setUxComposite(uxComposite: UxComposite) {
    this.uxComposite = uxComposite;
  }

  /**
   * This FN fire the pixel and add the fixel iframe,
   * @param key a uxComp key of a pageComponent
   * @param extra a pixel extra which set in baseComponent of the invoking component
   */
  fire(key, extra) {
    try {
      if (key) {
        this.load();
        let pixel = this.uxComposite.get(key);
        if (pixel) {
          let once = !!this.uxComposite.get(key + ".once");
          let fired = this.storage.indexOf(key) !== -1;
          if (!once || !fired) {
            this.addPixel(key, extra);
            if (!fired) {
              this.storage.push(key);
              this.store();
            }
          }
        }
      }
    } catch (e) {
      LogUtils.error(e);
    }
  }

  /**
   * This add the iframe for the pixel
   * @param key a uxComp key of a pageComponent
   * @param extra a pixel extra which set in baseComponent of the invoking component
   */
  private addPixel(key, extra) {
    if (key) {
      let id = `pixel-${Math.random()}`;
      let className = "pixelFrame-" + (key.replace(/\./g, "-"));
      let url = customPathChanger.getNewAPIPath(serverPaths.trackingPixelFrame) + "?type=pixel&_frameKey=" + key + "&" + extra;

      $("iframe."+className).remove();
      let iframe = document.createElement("iframe");
      iframe.setAttribute("id", id);
      iframe.setAttribute("class", className);
      iframe.setAttribute("height", "1");
      iframe.setAttribute("width", "1");
      iframe.setAttribute("style", "position:fixed;bottom:0;left:0;");
      iframe.setAttribute("frameborder", "0");
      iframe.setAttribute("scrolling", "NO");
      // iframe.setAttribute("sandbox", "allow-scripts allow-forms");
      iframe.setAttribute("sandbox", "allow-scripts allow-forms allow-same-origin");
      iframe.setAttribute("src", url);

      /*
      iframe.setAttribute("src", "about:blank");
      iframe.contentWindow.document.body.innerHTML = src;
      */


      document.body.appendChild(iframe);
    }
  }

  /**
   *
   * @returns boolean based on if a localstorage is empty or not for pixel info
   */
  isEmpty() {
    return !this.storage || this.storage.length === 0;
  }

  // this loads the data in the localStorage for pixel in the storage property
  load() {
    if (this.isEmpty()) {
      this.storage = JSON.parse(this.storageService.getItem(this.STORAGE_NAME));
      //this.storage = JSON.parse(localStorage.getItem(this.STORAGE_NAME));
      if (this.isEmpty()) {
        this.storage = [];
      }
    }
    return this.storage;
  }

  // this set the pixel info inside the localStorage
  store() {
    this.storageService.set(this.STORAGE_NAME, this.storage);
    //localStorage.setItem(this.STORAGE_NAME, JSON.stringify(this.storage));
  }

}

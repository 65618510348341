import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {TimeSheet} from '../../../common/models/timesheet';

@Component({
    selector: "add-timesheet-dialog",
    templateUrl: "addTimesheetModal.component.html",
    standalone: false
})
export class AddTimesheetDialog {
  timesheets: TimeSheet[];
  editData = {
    date: new Date(),
    timeIn: '',
    timeOut: '',
    note: '',
  }

  constructor(
    public dialogRef: MatDialogRef<AddTimesheetDialog>,
    private snackBar: MatSnackBar
  ) {
    this.timesheets = [];
  }

  isValidEditData() {
    if (!this.editData.date) {
      return false;
    }
    if (this.editData.timeOut?.split(":")?.length !== 2) {
      return false;
    }
    if (this.editData.timeIn?.split(":")?.length !== 2) {
      return false;
    }
    const startHours = +this.editData.timeIn.split(":")[0];
    const startMinutes = +this.editData.timeIn.split(":")[1];
    const endHours = +this.editData.timeOut.split(":")[0];
    const endMinutes = +this.editData.timeOut.split(":")[1];
    if (startHours > endHours) {
      return false;
    } else if (startHours === endHours) {
      if (startMinutes >= endMinutes) {
        return false;
      }
    }
    return true;
  }

  getTimestampFromDateAndHour(date: Date, hour: string) {
    const time = hour.split(":");
    if (time.length !== 2) {
      return -1;
    }
    const h = time[0];
    const m = time[1];
    return date.setHours(+h, +m, 0, 0);
  }

  handleAddTimesheetEntry() {
    if (!this.isValidEditData()) {
      this.snackBar.open('Invaild Timesheet Entry', 'Failed', {duration: 2000});
      return;
    }
    this.timesheets.push(new TimeSheet());
    this.timesheets.push(new TimeSheet());
    this.timesheets[0].draft.note = this.editData.note;
    this.timesheets[1].draft.note = this.editData.note;
    this.timesheets[0].draft.timestamp = this.getTimestampFromDateAndHour(this.editData.date, this.editData.timeIn);
    this.timesheets[1].draft.timestamp = this.getTimestampFromDateAndHour(this.editData.date, this.editData.timeOut);
    this.dialogRef.close(this.timesheets);
  }

  closeModal() {
    this.dialogRef.close(null);
  }
}
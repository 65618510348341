import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {JsonService} from '../../clientCommon/services/json.service';
import {User} from '../../common/models/user/user';
import {ResponseEvent} from '../../common/event/responseEvent';
import {objectUtils} from '../../common/utils/objectUtils';
import {ActivatedRoute, Router} from '@angular/router';
import {SpinnerService} from '../../clientCommon/services/spinner.service';
import {adminPaths, serverPaths} from '../../common/helpers/pathHelpers';
import {inputUtils} from '../../clientCommon/utils/inputUtils';
import {LogUtils} from '../../common/utils/logUtils';
import {CsrAuthGuard} from '../guards';
import {BaseDirective} from '../../clientCommon/directives/BaseDirective';
import {ServiceHelperService} from '../../clientCommon/services/serviceHelper.service';
import {configUtils} from '../utils/ConfigUtils';
import {ModelBase} from '../../common/models/modelBase';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, map,} from 'rxjs/operators';
import {CommerceOrder} from '../../common/models/commerce/commerceOrder';
import {customerSearchUtils, CustomerSearchUtils} from '../utils/CustomerSearchUtils';
import {AddressUtils, AddressField} from '../../common/utils/addressUtils';
import {Location} from "@angular/common"

@Component({
    templateUrl: './customers.component.html',
    standalone: false
})

export class CustomersComponent extends BaseDirective implements OnInit {
  adminPaths = adminPaths;
  addressField: AddressField = AddressUtils.getAddressField("US");
  states = [];

  users: User[] = [];
  filteredUsers: User[] = [];
  input: any = {
    index: {},
    user: {},
  };
  prevInput: any = {};
  more = false;
  brands = [];
  smartSearch = '';
  onSmartSearch = false;
  smartSearchCount = 0;
  returnedSmartSearchCount = 0;
  userColumns = ['recursive', 'brand', 'zipCode', 'email', 'name', 'card', 'phone', 'lastBill', 'status'];

  findCustomerInput: any = {};

  @ViewChild('smartSearchInput', {static: true}) smartSearchInput: ElementRef;

  constructor(public serviceHelperService: ServiceHelperService,
              public activatedRoute: ActivatedRoute,
              private jsonService: JsonService,
              private csrAuthGuard: CsrAuthGuard,
              private route: ActivatedRoute,
              public snackBar: MatSnackBar,
              private spinnerService: SpinnerService,
              private router: Router,
              private location: Location) {
    super(serviceHelperService, activatedRoute);
  }

  ngOnInit() {
    return super.baseInit().then(() => {
      const allStateObj = {"code": "all", "name":"All States"};
      this.states = [allStateObj, ...this.addressField.getAdministrativeAreaOptionsArray()];
      fromEvent(this.smartSearchInput.nativeElement, 'keyup').pipe(
        map((event: any) => {
          return event.target.value;
        }),
        debounceTime(200),
        distinctUntilChanged(),
      ).subscribe((text: string) => {
        this.parseSmartSearchStringToQuery();
        if (this.findCustomerInput.flag) {
          this.searchSmart(this.findCustomerInput, false);
        } else {
          this.users = [];
          this.filteredUsers = this.users;
        }
      });
      return this.init();
    }).then(() => {
      this.initDone = true;
    });
  }

  init() {
    return Promise.resolve().then(() => {
      this.brands.push({
        id: "all",
        name: "All Brands",
        abb: "all",
      });
      this.brands.push(...configUtils.getBrands(this.uxComposite));
      this.populateSearch();
    }).then(() => {
      this.activatedRoute.queryParams.subscribe((params) => {
        if (params.brand) {
          this.input.user.brandId = params.brand;
        }
      });
    });
  }

  populateSearch() {
    const fullPath = this.location.path(true);
    const params = fullPath.split("?smartsearch=");
    if (params.length > 1) {
      const query = params[1];
      this.smartSearch = decodeURIComponent(query);
      this.parseSmartSearchStringToQuery();
      if (this.findCustomerInput.flag) {
        this.searchSmart(this.findCustomerInput, false);
      } else {
        this.users = [];
        this.filteredUsers = this.users;
      }
    }
  }

  isValid() {
    let flag = false;

    if (this.input.index.email ||
      this.input.index.firstName ||
      this.input.index.lastName ||
      (this.input.index.creditCardLast4 && this.input.index.creditCardLast4.match(/^[0-9]{4}$/)) ||
      (this.input.index.orderNumericId && this.input.index.orderNumericId.length === 29) ||
      (this.input.index.reversedOrderNumericId && this.input.index.reversedOrderNumericId.length > 7) ||
      (this.input.index.phone && this.input.index.phone.length === 10)) {
      flag = true;
    }

    return flag;
  }

  search(input, aggregate?: boolean) {
    objectUtils.deleteEmptyProperties(input);
    this.prevInput = objectUtils.clone(input);
    this.spinnerService.spin();
    this.jsonService.json('/' + serverPaths.manageCsrFindCustomers, input).then((responseEvent: ResponseEvent) => {
      const users = responseEvent.getDocs();
      users.sort((a, b) => {
        return b.createdTimestamp - a.createdTimestamp;
      });

      if (aggregate === true) {
        this.users = this.users.concat(users);
      } else {
        this.users = users;
      }

      this.filteredUsers = this.users;
      this.more = !!responseEvent.data.extraResult.total;
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  searchSmart(smartInput, aggregate?: boolean) {
    this.smartSearchCount++;
    const currentSmartSearchCount = this.smartSearchCount;
    this.onSmartSearch = true;
    this.prevInput = objectUtils.clone(smartInput);
    this.jsonService.json('/' + serverPaths.manageCsrFindCustomersSmart, smartInput).then((responseEvent: ResponseEvent) => {
      if (this.returnedSmartSearchCount <= currentSmartSearchCount) {
        this.returnedSmartSearchCount = currentSmartSearchCount;
        const users = responseEvent.getDocs();
        users.sort((a, b) => {
          return b.createdTimestamp - a.createdTimestamp;
        });

        if (aggregate === true) {
          this.users = this.users.concat(users);
        } else {
          this.users = users;
        }
        this.filteredUsers = this.users;
        this.filterUsers();
        this.more = !!responseEvent.data.extraResult.total;
      }
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      if (this.smartSearchCount === currentSmartSearchCount) {
        this.onSmartSearch = false;
      }
    });
  }

  getUserClass(user: User) {
    if (user?.tempClient?.commerceOrder?.status === ModelBase.STATUS.inactive) {
      return 'inactive';
    } else if (user?.tempClient?.commerceOrder?.status === ModelBase.STATUS.failed) {
      return 'failed';
    } else if (user?.tempClient?.commerceOrder?.subStatus === CommerceOrder.SUB_STATUS.cancelled) {
      return 'cancelled';
    } else if (user?.tempClient?.commerceOrder?.status === ModelBase.STATUS.active) {
      return 'active';
    } else {
      return 'else';
    }
  }

  keyDownEvent(event) {
    if (inputUtils.isEnterEvent(event)) {
      return this.search(this.input);
    }
  }

  keyDownSmartSearchEvent(event) {
    if (inputUtils.isEnterEvent(event)) {
      if (this.users.length === 1) {
        this.router.navigate([adminPaths.csrCustomer + '/' + this.users[0]._id]);
      }
    }
  }

  loadMore() {
    if (!this.prevInput.page) {
      this.prevInput.page = 0;
    }

    this.prevInput.page++;
    if (this.prevInput?.flag) {
      return this.searchSmart(this.prevInput, true);
    } else {
      return this.search(this.prevInput, true);
    }
  }

  getCardInfo(commerceToken) {
    let ccNumber = '';
    if (commerceToken) {
      const bin = commerceToken.bin;
      const last4 = commerceToken.last4;
      const length = commerceToken.length - last4?.length;
      if (length) {
        ccNumber = bin;
        ccNumber = ccNumber?.padEnd(length, '*');
        ccNumber += last4;
      }
    }

    return ccNumber;
  }

  getBrandAbbr(brandId) {
    const brand = this.brands.find(brand => brand.id === brandId);
    if (brand) {
      return brand.abb;
    }
    return '';
  }

  getBrandName(brandId) {
    const brand = this.brands.find(brand => brand.id === brandId);
    if (brand) {
      return brand.name;
    }
    return '';
  }

  getBrandId(brandAbb) {
    const brand = this.brands.find(brand => brand.abb.toLowerCase() === brandAbb?.toLowerCase());
    return brand?.id;
  }


  getDate(timestamp) {
    if (timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleDateString();
    }
  }

  createRecursiveSearchQuery(user: User) {
    const findCustomerInput = {
      flag: false,
      user: [{
        index: [],
      }],
      commerceToken: [{
        bin: [],
      }],
    };
    findCustomerInput.user[0].index.push('^fn:' + user.firstName);
    findCustomerInput.user[0].index.push('^ln:' + user.lastName);
    findCustomerInput.user[0].index.push('^em:' + user.email);
    findCustomerInput.user[0].index.push('^ph:' + user.phone);
    (user.tempClient.commerceTokens || []).forEach((token) => {
      findCustomerInput.user[0].index.push('CL4:' + token.last4);
      findCustomerInput.commerceToken[0].bin.push('^' + token.bin);
    });
    this.spinnerService.spin();
    this.jsonService.json('/' + serverPaths.manageCsrFindCustomersSmart, findCustomerInput).then((responseEvent: ResponseEvent) => {
      const responseUsers = responseEvent.getDocs();
      const rUsers = responseUsers.filter((u) => !this.users.find(ou => {
        return ou._id === u._id
      })).sort((a, b) => {
        return b.createdTimestamp - a.createdTimestamp;
      });
      rUsers.forEach((u) => {
        u.tempClient.recursive = true;
      })
      const userIndex = this.users.findIndex((u) => u._id === user._id);
      const modifiedUsers = this.users;
      if (userIndex > -1) {
        modifiedUsers.splice(userIndex + 1, 0, ...rUsers);
      }
      this.users = [...modifiedUsers];
      this.filteredUsers = this.users;
      this.spinnerService.unspin();
    }).catch((e) => {
      LogUtils.debug(e);
      this.spinnerService.unspin();
    })
  }

  parseSmartSearchStringToQuery(search?: string) {
    let text = this.smartSearch;
    if (search) {
      text = search;
    }
    let queries = [];
    if (text) {
      text = text.replace(/  */g, ' ');
      queries = text.split(' ');
    }

    this.findCustomerInput = {
      flag: false,
      user: [],
      commerceToken: [],
    };

    queries.forEach(query => {
      if (query) {
        if (query.indexOf(':') > 0) {
          let split = query.split(':');

          if (split[1].length > 0) {
            if ([CustomerSearchUtils.SEARCH_PREFIX.fname, CustomerSearchUtils.SEARCH_PREFIX.fname_short].includes(split[0].toLowerCase())) {
              this.findCustomerInput.flag = true;
              this.findCustomerInput.user.push({index: ['^fn:' + split[1]]});
            } else if ([CustomerSearchUtils.SEARCH_PREFIX.lname, CustomerSearchUtils.SEARCH_PREFIX.lname_short].includes(split[0].toLowerCase())) {
              this.findCustomerInput.flag = true;
              this.findCustomerInput.user.push({index: ['^ln:' + split[1]]});
            } else if ([CustomerSearchUtils.SEARCH_PREFIX.email, CustomerSearchUtils.SEARCH_PREFIX.email_short].includes(split[0].toLowerCase())) {
              this.findCustomerInput.flag = true;
              this.findCustomerInput.user.push({index: ['^em:' + split[1]]});
            } else if ([CustomerSearchUtils.SEARCH_PREFIX.phone, CustomerSearchUtils.SEARCH_PREFIX.phone_short].includes(split[0].toLowerCase())) {
              this.findCustomerInput.flag = true;
              // removing 1 from for the number searched with preceding country code '1' i.e ph:18054320540
              split[1] = (split[1].charAt(0) === "1")? split[1].substring(1): split[1];
              this.findCustomerInput.user.push({index: ['^ph:' + split[1]]});
            } else if (CustomerSearchUtils.SEARCH_PREFIX.l4 === split[0].toLowerCase()) {
              this.findCustomerInput.flag = true;
              this.findCustomerInput.user.push({index: ['CL4:' + split[1]]});
            } else if ([CustomerSearchUtils.SEARCH_PREFIX.brand, CustomerSearchUtils.SEARCH_PREFIX.brand_short].includes(split[0].toLowerCase())) {
              this.findCustomerInput.flag = true;
              const brandId = this.getBrandId(split[1]);
              if (brandId) {
                this.findCustomerInput.flag = true;
                this.findCustomerInput.user.push({brandId: [brandId]});
              }
            } else if (CustomerSearchUtils.SEARCH_PREFIX.f4 === split[0].toLowerCase()) {
              this.findCustomerInput.flag = true;
              this.findCustomerInput.user.push({index: ['^bin:' + split[1]]});
            }
          }
        } else {
          const userIndex = [];
          const commerceToken = [];
          this.findCustomerInput.user.push({index: userIndex});

          if (query.match(/^[0-9]{1,10}$/)) {
            this.findCustomerInput.flag = true;
            // removing 1 from for the number searched with preceding country code '1' i.e 18054320540
            query = (query.charAt(0) === "1")? query.substring(1): query;
            userIndex.push('^ph:' + query);
            if (query.length === 4) {
              userIndex.push('CL4:' + query);
              commerceToken.push({bin: ['^' + query]});
              this.findCustomerInput.commerceToken = commerceToken;
            }
          } else if (customerSearchUtils.isSmartSearchNameCandidate(query)) {
            this.findCustomerInput.flag = true;
            userIndex.push('^fn:' + query);
            userIndex.push('^ln:' + query);
            userIndex.push('^em:' + query);
          } if (query.match(/^[0-9a-zA-Z]{24}$/)) {
            this.findCustomerInput.flag = true;
            this.findCustomerInput.user[0].index = [];
            this.findCustomerInput.user[0]._id = [query];
          }
        }
      }
    });
  }

  viewDetailPage(user: User) {
    if (!this.csrAuthGuard.canActivatePath(adminPaths.csrCustomer)) return;

    const csrPath = configUtils.getCsrPathFromVersion(this.uxComposite);

    window.open(csrPath + '/' + user._id);
  }

  filterUsers() {
    let results = this.users;
    if (this.input.user.brandId) {
      if (this.input.user.brandId !== 'all') {
        results = results.filter(u => u.brandId === this.input.user.brandId);
      }
    }
    if (this.input.user.state) {
      if (this.input.user.state !== 'all') {
        results = results.filter(u => {
          return !!u.tempClient.commerceTokens.find((token) => token.billingAddress.state === this.input.user.state);
        })
      }
    }
    this.filteredUsers = results;
  }

  getStatus(order: CommerceOrder) {
    if (CommerceOrder.STATUS_MAPPING[`${order.status}|${order.subStatus}`]) {
      return CommerceOrder.STATUS_MAPPING[`${order.status}|${order.subStatus}`];
    } else if (order.subStatus === CommerceOrder.SUB_STATUS.none) {
      return 'active';
    } else {
      return order.subStatus;
    }
  }

  onSmartSearchChange(value) {
    this.smartSearch = value;
  }
}

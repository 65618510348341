import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { NavigationEnd } from "@angular/router";
import { Observable, catchError, filter, firstValueFrom, retry, timer, throwError, concatMap, from, of, switchMap } from "rxjs";
import { CaptchaHelper, MaxRetryCaptchaError, UnsurpportedBrowserError } from "../helper/captchaHelper";
import { CaptchaCommonHelper, CaptchaTokenAndType, CaptchaType } from "../../common/helpers/captchaCommonHelper";
import { LogUtils } from "../../common/utils/logUtils";
import { serverPaths } from "../../common/helpers/pathHelpers";
import { ServiceHelperService } from "./serviceHelper.service";

type CaptchaActionResolveConfig = {
  beforeNext?: () => void,
  svgData?: string,
};

abstract class CaptchaAction {
  static readonly recaptchaV2Wrapper = 'RecaptchaV2ElementWrapper';

  static readonly recaptchaV3Wrapper = 'RecaptchaV3ElementWrapper';

  static readonly turnstileV0Wrapper = 'TurnstileV0ElementWrapper';
  
  static readonly svgCaptchaWrapper = 'SvgCaptchaElementWrapper';
  
  static readonly passwordCaptchaWrapper = 'PasswordCaptchaElementWrapper';

  abstract readonly foreground: boolean;

  abstract resolve(
    resolveConfig?: CaptchaActionResolveConfig,
  ): Promise<CaptchaTokenAndType>;

  static fromCaptchaType(type: CaptchaType, dependencies: {
    service: CaptchaService,
    action?: string,
  }): CaptchaAction {
    switch (type) {
      case CaptchaType.RecaptchaV2:
        return new CaptchaActionRecaptchaV2({
          siteKey: dependencies.service.getSiteKey(CaptchaType.RecaptchaV2) ?? '',
          action: dependencies?.action,
        });
      case CaptchaType.RecaptchaV3:
        return new CaptchaActionRecaptchaV3({
          siteKey: dependencies.service.getSiteKey(CaptchaType.RecaptchaV3) ?? '',
          action: dependencies?.action,
        });
      case CaptchaType.TurnstileV0:
        return new CaptchaActionTurnstile({
          siteKey: dependencies.service.getSiteKey(CaptchaType.TurnstileV0) ?? '',
          action: dependencies?.action,
        });
      case CaptchaType.SvgCaptchaText:
      case CaptchaType.SvgCaptchaMath:
        return new CaptchaActionSvgCaptcha({
          type,
        });
      case CaptchaType.PasswordV1:
        return new CaptchaActionPassword({
          type,
        });
      case CaptchaType.FakeCaptchaSuccessV1:
        return new CaptchaActionFakeCaptchaV1({
          type: CaptchaType.FakeCaptchaSuccessV1,
        });
      case CaptchaType.FakeCaptchaFailV1:
        return new CaptchaActionFakeCaptchaV1({
          type: CaptchaType.FakeCaptchaFailV1,
        });
      default:
        return new CaptchaActionNone();
    }
  }

  static removeElement(type: CaptchaType) {
    let element;

    switch (type) {
      case CaptchaType.RecaptchaV2:
        element = document.getElementById(CaptchaAction.recaptchaV2Wrapper);
        
        break;
      case CaptchaType.TurnstileV0:
        element = document.getElementById(CaptchaAction.turnstileV0Wrapper);
        
        break;
    }

    if (element) {
      document.body.removeChild(element);
    }
  }

  static removeAllElements() {
    CaptchaAction.removeElement(CaptchaType.RecaptchaV2);

    CaptchaAction.removeElement(CaptchaType.TurnstileV0);
  }
}

class CaptchaActionRecaptchaV2 extends CaptchaAction {
  constructor(params: {
    siteKey: string,
    action: string,
  }) {
    super();

    this.siteKey = params?.siteKey;

    this.action = params?.action;
  }

  private readonly siteKey: string;

  private readonly action: string;

  static readonly renderId = 'recaptcha-v2-rendering';

  readonly foreground: boolean = true;

  resolve(
    resolveConfig?: CaptchaActionResolveConfig,
  ): Promise<CaptchaTokenAndType> {
    return new Promise((resolve, reject) => {
      const captchaHelper = CaptchaHelper.getInstance();

      const target = this.showModalAndGet();

      captchaHelper.loadRecaptchaV2(target.captchaElement, {
        sitekey: this.siteKey,
        action: this.action,
        callback: (token) => {
          target.close();

          resolveConfig?.beforeNext?.();

          resolve({
            token,
            type: CaptchaType.RecaptchaV2,
          });
        },
        failCallback: (err) => {
          target.close();

          reject(err);
        },
      });
    });
  }

  private showModalAndGet(): {
    captchaElement: HTMLElement,
    close: () => void,
  } {
    const wrapper = document.createElement('div');

    wrapper.id = CaptchaAction.recaptchaV2Wrapper;

    wrapper.innerHTML = `
      <div style="position: fixed; top: 0; left: 0; right: 0; bottom: 0;background-color: #0000007d; overflow-x: hidden; overflow-y: auto; z-index: 1072;">
        <div id="${CaptchaActionRecaptchaV2.renderId}" style="position: fixed; top: 33%; margin-top: -39px; left: 50%; margin-left: -152px; box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; -moz-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; -webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;"></div>
      </div>
    `;

    document.body.appendChild(wrapper);

    return {
      captchaElement: document.getElementById(CaptchaActionRecaptchaV2.renderId)!,
      close: () => document.body.removeChild(wrapper),
    };
  }
}

class CaptchaActionRecaptchaV3 extends CaptchaAction {
  constructor(params: {
    siteKey: string,
    action: string,
  }) {
    super();

    this.siteKey = params?.siteKey;

    this.action = params?.action;
  }

  private readonly siteKey: string;

  private readonly action: string;

  static readonly duringClass = 'recaptcha-v3-during';

  readonly foreground: boolean = true;

  resolve(
    resolveConfig?: CaptchaActionResolveConfig,
  ): Promise<CaptchaTokenAndType> {
    return new Promise((resolve, reject) => {
      const captchaHelper = CaptchaHelper.getInstance();

      const target = this.showModalAndGet();

      captchaHelper.loadRecaptchaV3(this.action, {
        sitekey: this.siteKey,
        duringClass: CaptchaActionRecaptchaV3.duringClass,
        callback: (token) => {
          target.close();

          resolveConfig?.beforeNext?.();

          resolve({
            token,
            type: CaptchaType.RecaptchaV3,
          });
        },
        failCallback: (err) => {
          target.close();

          reject(err);
        },
      });
    });
  }

  private showModalAndGet(): {
    close: () => void,
  } {
    const wrapper = document.createElement('div');

    wrapper.id = CaptchaAction.recaptchaV3Wrapper;

    wrapper.innerHTML = `
      <div style="position: fixed; top: 0; left: 0; right: 0; bottom: 0;background-color: #0000007d; overflow-x: hidden; overflow-y: auto; z-index: 1072;">
      </div>
    `;

    document.body.appendChild(wrapper);

    return {
      close: () => document.body.removeChild(wrapper),
    };
  }
}

class CaptchaActionTurnstile extends CaptchaAction {
  constructor(params: {
    siteKey: string,
    action: string,
  }) {
    super();

    this.siteKey = params?.siteKey;

    this.action = params?.action;
  }

  private readonly siteKey: string;

  private readonly action: string;

  static readonly renderId = 'turnstile-v0-rendering';

  readonly foreground: boolean = true;

  resolve(
    resolveConfig?: CaptchaActionResolveConfig,
  ): Promise<CaptchaTokenAndType> {
    return new Promise((resolve, reject) => {
      const captchaHelper = CaptchaHelper.getInstance();

      const target = this.showModalAndGet();

      captchaHelper.loadTurnstileV0(`#${target.captchaElement.id}`, {
        sitekey: this.siteKey,
        action: this.action,
        callback: (token) => {
          target.close();

          resolveConfig?.beforeNext?.();

          resolve({
            token,
            type: CaptchaType.TurnstileV0,
          });
        },
        failCallback: (err) => {
          target.close();

          reject(err);
        },
      });
    });
  }

  private showModalAndGet(): {
    captchaElement: HTMLElement,
    close: () => void,
  } {
    const wrapper = document.createElement('div');

    wrapper.id = CaptchaAction.turnstileV0Wrapper;

    wrapper.innerHTML = `
      <div style="position: fixed; top: 0; left: 0; right: 0; bottom: 0;background-color: #0000007d; overflow-x: hidden; overflow-y: auto; z-index: 1072;">
        <div id="${CaptchaActionTurnstile.renderId}" style="position: fixed; top: 33%; left: 50%; margin-top: -30px; margin-left: -150px;"></div>
      </div>
    `;

    document.body.appendChild(wrapper);

    return {
      captchaElement: document.getElementById(CaptchaActionTurnstile.renderId)!,
      close: () => document.body.removeChild(wrapper),
    };
  }
}

class CaptchaActionSvgCaptcha extends CaptchaAction {
  constructor(params: {
    type: CaptchaType.SvgCaptchaText | CaptchaType.SvgCaptchaMath,
  }) {
    super();

    this.type = params.type;
  }

  static readonly renderId = 'svg-captcha-rendering';

  readonly type: CaptchaType;

  readonly foreground: boolean = true;

  resolve(
    resolveConfig?: CaptchaActionResolveConfig,
  ): Promise<CaptchaTokenAndType> {
    return new Promise(async (resolve, reject) => {
      const target = await this.showModalAndGet(resolveConfig?.svgData);

      target.close();

      resolveConfig?.beforeNext?.();

      resolve({
        token: target.token,
        type: this.type,
      });
    });
  }

  private async showModalAndGet(svgData?: string): Promise<{
    captchaElement: HTMLElement,
    token: string,
    close: () => void,
  }> {
    return new Promise((resolve, _) => {
      const type = this.type;

      const wrapper = document.createElement('div');

      const inputId = 'svgCaptchaInputId';

      const buttonId = 'svgCaptchaButtonId';

      const messageId = 'svgCaptchaMessageId';

      const title = type === CaptchaType.SvgCaptchaMath
        ? 'Please enter the calculation result as a number.'
        : 'Please enter the characters exactly as they appear above.';

      wrapper.id = CaptchaAction.svgCaptchaWrapper;

      wrapper.innerHTML = `
        <div style="position: fixed; top: 0; left: 0; right: 0; bottom: 0;background-color: #0000007d; overflow-x: hidden; overflow-y: auto; z-index: 1072;">
          <div id="${CaptchaActionSvgCaptcha.renderId}" style="position: fixed; top: 33%; left: 50%; margin-top: -30px; margin-left: -150px; background-color: #ffffff; padding: 30px; width: 300px; box-sizing: border-box;">
            <div style="text-align: center;">
              ${svgData}
            </div>
            <div style="margin-top: 5px; color: #404040; font-size: 14px; line-height: 20px;">
              ${title}
            </div>
            <div style="margin-top: 10px; display: flex; justify-content: space-between">
              <input type="text" id="${inputId}" style="font-size: 14px; line-height: 20px; outline: none; width: 160px;">
              <button id="${buttonId}" style="width: 65px;">GO</button>
            </div>
            <div id="${messageId}" style="color: red; margin-top: 5px;"></div>
          </div>
        </div>
      `;

      document.body.appendChild(wrapper);

      const button = document.getElementById(buttonId)! as HTMLButtonElement;

      const input = document.getElementById(inputId)! as HTMLInputElement;

      const message = document.getElementById(messageId)! as HTMLDivElement;

      input.focus();

      input.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
          button.click();
        }
      });

      button.addEventListener('click', (event) => {
        const token = (document.getElementById(inputId)! as HTMLInputElement).value;

        if (!token?.trim?.()?.length) {
          message.innerText = "Please enter a value.";

          return;
        }

        resolve({
          captchaElement: document.getElementById(CaptchaActionSvgCaptcha.renderId)!,
          token: token.trim(),
          close: () => document.body.removeChild(wrapper),
        });
      });
    });
  }
}

class CaptchaActionPassword extends CaptchaAction {
  constructor(params: {
    type: CaptchaType.PasswordV1,
  }) {
    super();

    this.type = params.type;
  }

  static readonly renderId = 'password-captcha-rendering';

  readonly type: CaptchaType;

  readonly foreground: boolean = true;

  resolve(
    resolveConfig?: CaptchaActionResolveConfig,
  ): Promise<CaptchaTokenAndType> {
    return new Promise(async (resolve, reject) => {
      const target = await this.showModalAndGet();

      target.close();

      resolveConfig?.beforeNext?.();

      resolve({
        token: target.token,
        type: this.type,
      });
    });
  }

  private async showModalAndGet(): Promise<{
    captchaElement: HTMLElement,
    token: string,
    close: () => void,
  }> {
    return new Promise((resolve, _) => {
      const wrapper = document.createElement('div');

      const inputId = 'passwordCaptchaInputId';

      const buttonId = 'passwordCaptchaButtonId';

      const messageId = 'passwordCaptchaMessageId';

      const title = 'Please enter your password.';

      wrapper.id = CaptchaAction.passwordCaptchaWrapper;

      wrapper.innerHTML = `
        <div style="position: fixed; top: 0; left: 0; right: 0; bottom: 0;background-color: #0000007d; overflow-x: hidden; overflow-y: auto; z-index: 1072;">
          <div id="${CaptchaActionPassword.renderId}" style="position: fixed; top: 33%; left: 50%; margin-top: -30px; margin-left: -150px; background-color: #ffffff; padding: 30px; width: 300px; box-sizing: border-box;">
            <div style="margin-top: 5px; color: #404040; font-size: 14px; line-height: 20px;">
              ${title}
            </div>
            <div style="margin-top: 10px; display: flex; justify-content: space-between">
              <input type="password" id="${inputId}" style="font-size: 14px; line-height: 20px; outline: none; width: 160px;">
              <button id="${buttonId}" style="width: 65px;">GO</button>
            </div>
            <div id="${messageId}" style="color: red; margin-top: 5px;"></div>
          </div>
        </div>
      `;

      document.body.appendChild(wrapper);

      const button = document.getElementById(buttonId)! as HTMLButtonElement;

      const input = document.getElementById(inputId)! as HTMLInputElement;

      const message = document.getElementById(messageId)! as HTMLDivElement;

      input.focus();

      input.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
          button.click();
        }
      });

      button.addEventListener('click', (event) => {
        const token = (document.getElementById(inputId)! as HTMLInputElement).value;

        if (!token?.trim?.()?.length) {
          message.innerText = "Please enter a value.";

          return;
        }

        resolve({
          captchaElement: document.getElementById(CaptchaActionPassword.renderId)!,
          token: token.trim(),
          close: () => document.body.removeChild(wrapper),
        });
      });
    });
  }
}

class CaptchaActionFakeCaptchaV1 extends CaptchaAction {
  constructor(params: {
    type: CaptchaType.FakeCaptchaSuccessV1 | CaptchaType.FakeCaptchaFailV1,
  }) {
    super();

    this.type = params.type;
  }

  static readonly renderId = 'fakeCaptcha-v1-rendering';

  readonly foreground: boolean = true;

  readonly type: CaptchaType;

  resolve(
    resolveConfig?: CaptchaActionResolveConfig,
  ): Promise<CaptchaTokenAndType> {
    return new Promise(async (resolve, reject) => {
      const target = await this.showModalAndGet();

      setTimeout(() => {
        target.close();
        
        if (this.type === CaptchaType.FakeCaptchaSuccessV1) {
          resolveConfig?.beforeNext?.();

          resolve({
            token: 'FAKE_TOKEN',
            type: this.type,
          });
        } else {
          reject('FakeCaptcha Failed.');
        }
      }, 1500);
    });
  }

  private async showModalAndGet(): Promise<{
    captchaElement: HTMLElement,
    close: () => void,
  }> {
    const promiseResult = new Promise<{
      captchaElement: HTMLElement
      close: () => void,
    }>((resolve, _) => {
      const wrapper = document.createElement('div');

      const splitType = CaptchaType.splitTypeVersion(this.type);
  
      const success = splitType.type.endsWith('Success');
  
      const displayText = success ? 'SUCCESS' : 'FAIL';
  
      const fontColor = success ? 'green' : 'red';

      const checkboxId = `${this.type}_checkbox`;

      const resultSpanId = `${this.type}_result`;

      const resultElement = `<span style="color: ${fontColor};">${displayText}</span>`;
  
      wrapper.innerHTML = `
        <div style="position: fixed; top: 0; left: 0; right: 0; bottom: 0;background-color: #0000007d; overflow-x: hidden; overflow-y: auto; z-index: 1072;">
          <div id="${CaptchaActionFakeCaptchaV1.renderId}" style="position: fixed; top: 33%; left: 50%; margin-top: -30px; margin-left: -150px; padding: 20px; width: 300px; height: 60px; border-radius: 6px; box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; font-size: 20px; background-color: #ffffff;">
            Click checkbox : <span id="${resultSpanId}"><input id="${checkboxId}" type="checkbox" style="transform: scale(1.5); margin-left: 10px;"></span>
          </div>
        </div>
      `;
  
      document.body.appendChild(wrapper);

      const checkbox = document.getElementById(checkboxId) as HTMLInputElement;

      checkbox.addEventListener('change', () => {
        const resultSpan = document.getElementById(resultSpanId)!;

        checkbox.disabled = checkbox.checked;

        resultSpan.innerHTML = resultElement;

        resolve({
          captchaElement: document.getElementById(CaptchaActionFakeCaptchaV1.renderId)!,
          close: () => document.body.removeChild(wrapper),
        });
      });
    });

    return promiseResult;
  }
}

class CaptchaActionNone extends CaptchaAction {
  readonly foreground: boolean = false;

  resolve(): Promise<CaptchaTokenAndType> {
    return Promise.resolve({
      token: '',
      type: CaptchaType.None,
    });
  }
}

class CaptchaService {
  constructor(
    protected helperService: ServiceHelperService,
    protected httpClient: HttpClient,
    protected verifyUrl: string,
  ) {
    this.initStyle();

    this.initUnloadAllWhenRouterChanged();
  }

  nextWithCaptcha(
    req: HttpRequest<unknown>, 
    next: HttpHandlerFn,
  ): Observable<HttpEvent<unknown>> {    
    const path = this.getPathFromRequest(req);

    // Inside this function it calls the captchaVerify, loadOffer API. 
    // Infinite recursive calls should be avoided.
    if (path === this.verifyUrl) {
      return next(req);
    }

    let captchaId = '';

    return of(1).pipe(
      concatMap((_) => {
        return next(this.makeRequestWithCaptchaId(req, captchaId));
      }),
      retry({
        delay: (errorResponse, retryCount) => {
          LogUtils.debug(
            `CaptchaService.nextWithCaptcha Retry : ${retryCount}`, 
            errorResponse,
            errorResponse?.error?.captcha?.step,
          );

          if (retryCount >= 10) {
            throw new MaxRetryCaptchaError();
          }

          if (!this.isRetryCaptcha(errorResponse)) {
            throw errorResponse;
          }

          captchaId = errorResponse.error.captcha.id;

          return from(
            this.makeAction({ 
              type: errorResponse.error.captcha.type, 
              captchaId, 
              step: errorResponse.error.captcha.step, 
              action: errorResponse?.error?.captcha?.action ?? 'NO_DESC_IN_RULE',
              svgData: errorResponse?.error?.captcha?.svgData,
            }).catch(e => {
              if (e instanceof UnsurpportedBrowserError) {
                throw e;
              }
            })
          ).pipe(
            switchMap(() => timer(700)),
          );
        },
      }),
      catchError((e) => {
        if (e instanceof UnsurpportedBrowserError) {
          this.showMessageModal(e.message);
        } else if (e instanceof MaxRetryCaptchaError) {
          this.showMessageModal(e.message);
        } else if (this.isCaptchaFailed(e)) {
          this.showMessageModal(this.getCaptchaFailedMessage());
        } else if (this.isForceRefresh(e)) {
          window.location.reload();
        }

        return throwError(() => e);
      }),
    );
  }

  getSiteKey(type: CaptchaType): string | null {
    return CaptchaCommonHelper.getSiteKey({
      type,
      uxComposite: this.helperService.uxcService.uxComposite,
    });
  }

  private getCaptchaFailedMessage(): string {
    let message = '';

    try {
      message = this.helperService.uxcService.uxComposite.getUxcomp('comp.brand.captcha.message.failed');
    } catch (e) {}

    if (!message) {
      message = 'Captcha failed. Access to this feature is denied.';
    }

    return message;
  }

  private isRetryCaptcha(errorResponse: any) {
    return errorResponse?.status === 412
      && !!errorResponse?.error?.captcha?.type
      && !!errorResponse?.error?.captcha?.step;
  }

  private isCaptchaFailed(errorResponse: any) {
    return errorResponse?.status === 401
      && errorResponse?.error?.captchaFailed === true;
  }

  private isForceRefresh(errorResponse: any) {
    return errorResponse?.status === 426;
  }

  private makeRequestWithCaptchaId(
    req: HttpRequest<unknown>, 
    captchaId: string,
  ): HttpRequest<unknown> {
    return req.clone({
      headers: req.headers.set(
        CaptchaCommonHelper.captchaGuardIdHeader,
        captchaId,
      ),
    });
  }

  private async makeAction(params: {
    type: CaptchaType,
    captchaId: string,
    step: string,
    action: string,
    svgData?: string,
  }): Promise<any> {
    const action = CaptchaAction.fromCaptchaType(
      params.type, 
      { 
        service: this,
        action: params.action,
      },
    );

    let beforeNext: (() => void) | undefined;

    if (action.foreground) {
      this.helperService.progressorService.fakeProgressors?.current?.setPause?.(true);

      beforeNext = () => {
        this.helperService.progressorService.fakeProgressors?.current?.setPause?.(false);
      };
    }

    const resolvedToken = await action.resolve({ 
      beforeNext, 
      svgData: params?.svgData,
    });

    if (resolvedToken.type === CaptchaType.None) {
      return true;
    }

    const split = CaptchaType.splitTypeVersion(resolvedToken.type);

    const queryParams = { 
      preFailed: resolvedToken.type === CaptchaType.RecaptchaV2, 
      token: resolvedToken.token,
      page: CaptchaCommonHelper.captchaGuardPage,
      product: split.type,
      version: split.version,
      captchaId: params?.captchaId,
      step: params?.step,
    };

    const queryString = Object
      .keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join('&');

    const verified = await firstValueFrom(
      this.httpClient.get(`${this.verifyUrl}?${queryString}`),
    );

    if (verified?.['status'] !== 'success') {
      LogUtils.warn('Captcha Interceptor Verification Failed.', verified);

      return Promise.reject('captcha failed');
    }

    return true;
  }

  private initStyle() {
    const style = document.createElement('style');

    style.innerHTML = `
      .grecaptcha-badge {visibility: hidden;}
      .grecaptcha-badge.${CaptchaActionRecaptchaV3.duringClass} {
        visibility: visible;
        position: fixed;
        top: 33%;
        left: 50%;
        margin-top: -30px;
        margin-left: -128px;
        z-index: 9999;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
        -moz-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
        -webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
      }
      #${CaptchaActionRecaptchaV2.renderId} {
        > div {
          margin: 0 auto;
        }
      }
      #${CaptchaActionTurnstile.renderId} {
        > iframe {
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          -moz-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          -webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        }
      }
    `;
  
    document.head.appendChild(style);
  }

  private initUnloadAllWhenRouterChanged() {
    this.helperService.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(_ => {
        CaptchaHelper.getInstance().unloadAll();

        CaptchaAction.removeAllElements();

        if (this.helperService.spinnerService.isSpinning()) {
          this.helperService.spinnerService.unspin();

          // If the user goes back during an API call, 
          // spin continues to operate, disrupting the user's UI.
          LogUtils.warn('CaptchaService.initUnloadAllWhenRouterChanged unspin');
        }
      });
  }

  private getPathFromRequest(req: HttpRequest<unknown>) {
    try {
      const url = new URL(req?.url, window.location.origin);

      const path = url.pathname.replace(/^\/api/, 'api');

      return path;
    } catch (e) {
      LogUtils.error('getPathFromRequest Error', e);

      return req?.url ?? '';
    }
  }

  private showMessageModal(message: string) {
    if (!message) {
      return;
    }

    let wrapper;

    try {
      wrapper = document.createElement('div');

      wrapper.innerHTML = `
        <div style="position: fixed; top: 0; left: 0; right: 0; bottom: 0;background-color: #0000007d; overflow-x: hidden; overflow-y: auto; z-index: 1072;">
          <div id="captchaActionMessageModal" style="position: fixed; top: 33%; left: 50%; margin-top: -30px; margin-left: -150px; padding: 20px; width: 300px; border-radius: 6px; box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; font-size: 16px; background-color: #ffffff;">
            <div>${message}</div>
            <button style="padding: 5px; margin-left: auto; margin-right: auto; display: block; margin-top: 20px; width: 80px; border-radius: 17px; background-color: grey; border-color: transparent; color: #ffffff; height: 34px;">Close</button>
          </div>
        </div>
      `;
  
      const button = wrapper.getElementsByTagName('button')?.[0];
  
      button.onclick = () => {
        document.body.removeChild(wrapper);
      };
  
      document.body.appendChild(wrapper);
    } catch (e) {
      LogUtils.error('CaptchaService.showMessageModal Error.', e);

      try {
        document.body.removeChild(wrapper);
      } catch (e) {
        LogUtils.warn('CaptchaService.showMessageModal remove fail.', e);
      }
    }
  }
}

@Injectable()
export class ClientCaptchaService extends CaptchaService {
  constructor(
    protected helperService: ServiceHelperService,
    protected httpClient: HttpClient,
  ) {
    super(
      helperService,
      httpClient,
      serverPaths.captchaVerify,
    );
  }
}

@Injectable()
export class AdminCaptchaService extends CaptchaService {
  constructor(
    protected helperService: ServiceHelperService,
    protected httpClient: HttpClient,
  ) {
    super(
      helperService,
      httpClient,
      serverPaths.captchaVerifyAdmin,
    );
  }
}

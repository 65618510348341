<mat-card class="summary">
    <mat-card-title>Click Field to Edit</mat-card-title>
    <mat-card-content>
      <div class="row">
        <div class="label">Customer ID</div>
        <div class="form">
          <span class="value">{{ user._id }}&nbsp;<a class="detail-link" (click)="detailLink()">CDP</a></span>
        </div>
      </div>
      <div class="row">
        <div class="label">Credit Cards</div>
        <div class="form column">
          <p class="value" *ngFor="let commerceToken of user.tempClient.commerceTokens | reverse; let i = index ">
            <span *ngIf="i==0">Credit Card: {{getCardInfo(commerceToken)}}<br></span>
            <span *ngIf="i!==0">Old Credit Card: {{getCardInfo(commerceToken)}}<br></span>
            Name on card: {{commerceToken.billingAddress.firstName}} {{commerceToken.billingAddress.lastName}}<br>
            Address: {{getAddress(commerceToken)}}<br>
            Expiration: {{commerceToken.expiration.month}}/{{commerceToken.expiration.year % 100}}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="label">Customer Name</div>
        <div class="form">
          <ng-container *ngIf="!editSummary">
            <span class="value">{{user.draft.firstName}} {{user.draft.lastName}}</span>
            <mat-icon (click)="editSummary = !editSummary" class="edit">edit</mat-icon>
          </ng-container>
          <div *ngIf="editSummary">
            <div>
              <mat-form-field appearance="outline" floatLabel="always" class="w-100">
                <mat-label>First name</mat-label>
                <input matInput type="text" [(ngModel)]="user.draft.firstName">
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline" floatLabel="always" class="w-100">
                <mat-label>Last name</mat-label>
                <input matInput type="text" [(ngModel)]="user.draft.lastName">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="label">Email Address</div>
        <div class="form">
          <ng-container *ngIf="!editSummary">
            <span class="value">{{ user.draft.email }}</span>
            <mat-icon (click)="editSummary = !editSummary" class="edit">edit</mat-icon>
          </ng-container>
          <ng-container *ngIf="editSummary">
            <mat-form-field appearance="outline" floatLabel="always" class="w-100">
              <mat-label>Email</mat-label>
              <input matInput type="text" [(ngModel)]="user.draft.email">
            </mat-form-field>
          </ng-container>
        </div>
      </div>
      <div class="row">
        <div class="label">Phone Number</div>
        <div class="form">
          <ng-container *ngIf="!editSummary">
            <span *ngIf="user.draft.phone" class="value">{{user.draft.phone}}</span>
            <mat-icon (click)="editSummary = !editSummary" class="edit">edit</mat-icon>
          </ng-container>
          <ng-container *ngIf="editSummary">
            <mat-form-field appearance="outline" floatLabel="always" class="w-100">
              <mat-label>Phone number</mat-label>
              <input matInput type="text" maxlength="14" [(ngModel)]="user.draft.phone" [value]="user.draft.phone"
                (input)="formatInput($event.target.value)">
            </mat-form-field>
          </ng-container>
        </div>
      </div>
      <div class="row">
        <div class="label">Member Since</div>
        <div class="form">
          <ng-container>
            <span class="value" [ngClass]="{enlarged: editSummary}">{{ (( user.createdTimestamp | formatTimestamp ) |
              date:timeFormat:custom.timezone) }}</span>
          </ng-container>
        </div>
      </div>
      <div class="row" *ngIf="adminFlag">
        <div class="label">Brand ID</div>
        <div class="form">
          <ng-container *ngIf="!editSummary">
            <span class="value">{{ user.draft.brandId }}</span>
            <mat-icon (click)="editSummary = !editSummary" class="edit">edit</mat-icon>
          </ng-container>
          <ng-container *ngIf="editSummary">
            <mat-form-field appearance="outline" floatLabel="always" class="w-100">
              <mat-label>Brand ID</mat-label>
              <input matInput type="text" [(ngModel)]="user.draft.brandId">
            </mat-form-field>
          </ng-container>
        </div>
      </div>
      <div class="row" *ngIf="adminFlag">
        <div class="label">UXC</div>
        <div class="form">
          <ng-container *ngIf="!editSummary">
            <span class="value">{{ user.draft.uxcId }}</span>
            <mat-icon (click)="editSummary = !editSummary" class="edit">edit</mat-icon>
          </ng-container>
          <ng-container *ngIf="editSummary">
            <mat-form-field appearance="outline" floatLabel="always" class="w-100">
              <mat-label>UXC ID</mat-label>
              <input matInput type="text" [(ngModel)]="user.draft.uxcId">
            </mat-form-field>
          </ng-container>
        </div>
      </div>
      <div class="row" *ngIf="adminFlag">
        <div class="label">UXL</div>
        <div class="form">
          <ng-container *ngIf="!editSummary">
            <span class="value">{{ user.draft.uxlId }}</span>
            <mat-icon (click)="editSummary = !editSummary" class="edit">edit</mat-icon>
          </ng-container>
          <ng-container *ngIf="editSummary">
            <mat-form-field appearance="outline" floatLabel="always" class="w-100">
              <mat-label>UXL ID</mat-label>
              <input matInput type="text" [(ngModel)]="user.draft.uxlId">
            </mat-form-field>
          </ng-container>
        </div>
      </div>
      <div class="row">
        <div class="label">IP Address</div>
        <div class="form">
          <span class="value">{{ commerceToken?.ipAddress }}</span>
        </div>
      </div>
      <div class="row">
        <div class="label">Device</div>
        <div class="form">
          <span class="value">{{ user.connectionInfo?.device !== 'phone' ? 'Desktop' : 'Mobile' }}</span>
        </div>
      </div>
      <div class="row">
        <div class="label">Status</div>
        <div class="form">
          <span class="value">{{ user.status }}</span>
          <button (click)="
                user.draft.status = user.status === 'active' ? 'banned' : 'active'; 
                updateUser(user, (user.status === 'active' ? 'Ban' : 'Active') + ' this user. (' + user.firstName + ' ' + user.lastName + ')')
              ">
            {{ user.status === 'active' ? 'Ban' : 'Active' }}
          </button>
        </div>
      </div>
      <div class="row">
        <div class="label">Sub Status</div>
        <div class="form">
          <span class="value">{{ user.subStatus }}</span>
        </div>
      </div>
      <div class="row">
        <div class="label">IdProtection Product</div>
        <div class="form">
          <span class="value">{{ useIdProtection ? 'Used' : 'Not Used' }}</span>
        </div>
      </div>
      <div class="row">
        <div class="label">pMaxx Customer Status</div>
        <div class="form">
          <span class="value">{{ pMaxxCustomerStatus ? 'Active' : 'Inactive' }}</span>
        </div>
      </div>
      <div class="row" *ngIf="editSummary">
        <button mat-raised-button color="primary" class="mr-2" [disabled]="!user.isDiffDraft()"
          (click)="updateUser(user)">Save</button>
        <button mat-stroked-button color="primary" (click)="editSummary = !editSummary">Cancel</button>
      </div>
    </mat-card-content>
  </mat-card>
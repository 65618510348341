import {Injectable} from "@angular/core";
import {User} from "../../common/models/user/user";
import {parameterdUtils} from "../utils/parameterUtils";
import {objectUtils} from "../../common/utils/objectUtils";
import { StorageService } from "./storage.service";

@Injectable()
export class ReferService {
  STORAGE_NAME = 'refer';

  public storage: any = {};

  constructor(private storageService: StorageService) {
  }
/**
 * gets all the params from parameterUtils
 * adds refer utm ckmc if either exits to params if refer exists in localStorage
 * adds and stores refer in storage
 */
  processRefer() {
    let params = parameterdUtils.getAllParams();
    let refer = this.getRefer();
    Object.keys(params).forEach((key) => {
      if (key.startsWith("utm_")) {
        refer[key.substr(4)] = params[key];
      } else if (key === 'ckmc' || key === 'ckmsc') {
        refer[key] = params[key];
      }
    });

    this.storage = refer;
    this.store();
  }
/**
 * gets refer value frpm the localstorage 
 * @returns the storage with refer value
 */
  getRefer() {
    if (this.isEmpty()) {
      this.storage = JSON.parse(this.storageService.getItem(this.STORAGE_NAME));
      //this.storage = JSON.parse(localStorage.getItem(this.STORAGE_NAME));
      if (this.isEmpty()) {
        this.storage = {};
      }
    }
    return this.storage;
  }
/**
 * gets all the params from parameterUtils
 * adds refer utm ckmc if either exits to params if refer exists in localStorage
 * concatinates the refer key params with &
 * adds and stores refer in storage
 * * @returns the concatinated refer string
 */
  getReferString() {
    let referString = "";
    let refer = this.getRefer();
    if (refer && objectUtils.isObject(refer)) {
      Object.keys(refer).forEach((key) => {

        if (referString) {
          referString += "&";
        }
        if (key === 'ckmc' || key === 'ckmsc') {
          referString += "" + key + "=" + refer[key];
        } else {
          referString += "utm_" + key + "=" + refer[key];
        }
      });
    }
    return referString;
  }

/**
 * 
 * @param user user values for the current user
 * stores the user and userid in the storage
 */
  setReferUser(user: User) {
    if (user && user._id) {
      this.storage.userId = user._id;
      this.store();
    }
  }

 // returns true if there is no value in the storage 
 
  isEmpty() {
    return !this.storage || Object.keys(this.storage).length === 0;
  }
// stores the item in the localStorage
  store() {
    this.storageService.set(this.STORAGE_NAME, this.storage);
    //localStorage.setItem(this.STORAGE_NAME, JSON.stringify(this.storage));
  }

}

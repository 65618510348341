<div class="tracking">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <span>
          Tracking
        </span>
        <div>
          <a class="mr-2" mat-stroked-button color="primary" [routerLink]="['/'+adminPaths.trackingV1]">Tracking v1</a>
          <button mat-icon-button color="primary" (click)="init()">
            <i class="material-icons">cached</i>
          </button>
        </div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline" floatLabel="always">
            <input matInput type="text" (keydown)="keyDownEvent($event)" placeholder="startTimestamp"
              [(ngModel)]="input.startTimestamp"/>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline" floatLabel="always">
            <input matInput type="text" (keydown)="keyDownEvent($event)" placeholder="endTimestamp"
              [(ngModel)]="input.endTimestamp" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline" floatLabel="always">
            <input matInput type="text" (keydown)="keyDownEvent($event)" placeholder="User ID Or Session Hash"
              [(ngModel)]="input.user" />
          </mat-form-field>
        </div>
        <div class="col action">
          <button mat-raised-button color="primary" (click)="search(input)">Search</button>
        </div>
      </div>
      <div class="spacer"></div>
      <div>
        <table mat-table [dataSource]="trackings" multiTemplateDataRows>
          <ng-container matColumnDef="expander">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button color="primary" (click)="toggleRowExpand(element)">
                <i class="material-icons">add_box</i>
              </button>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let element"> {{element.createdTimestamp | toDateTime}} </td>
          </ng-container>
  
          <ng-container matColumnDef="uid">
            <th mat-header-cell *matHeaderCellDef class="id"> User Id </th>
            <td mat-cell *matCellDef="let element"> {{element.userId}} </td>
          </ng-container>

          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef class="id"> Location </th>
            <td mat-cell *matCellDef="let element"> {{element.param.location}} </td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef class="type"> Type(provider) </th>
            <td mat-cell *matCellDef="let element">
              {{element.param.type}}
              @if (element.param.provider) {
                <br>
                ({{element.param.provider}})
              }
            </td>
          </ng-container>
  
          <ng-container matColumnDef="uxc">
            <th mat-header-cell *matHeaderCellDef class="id"> UXC(2) </th>
            <td mat-cell *matCellDef="let element">
              @if (element.uxcId) {
                {{element.uxcId}}
              }
              @if (element.uxcId2) {
                <br>
                ({{element.uxcId2}})
              }
            </td>
          </ng-container>
  
          <ng-container matColumnDef="uxl">
            <th mat-header-cell *matHeaderCellDef class="id"> UXL(2) </th>
            <td mat-cell *matCellDef="let element">
              @if (element.uxlId) {
                {{element.uxlId}}
              }
              @if (element.uxlId2) {
                <br>
                ({{element.uxlId2}})
              }
            </td>
          </ng-container>
  
          <ng-container matColumnDef="session">
            <th mat-header-cell *matHeaderCellDef> Session(2) </th>
            <td mat-cell *matCellDef="let element">
              @if (element.sessionHash) {
                {{element.sessionHash}}
              }
              @if (element.sessionHash2) {
                <br>
                ({{element.sessionHash2}})
              }
            </td>
          </ng-container>
  
          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
              <div class="example-element-detail" [@detailExpand]="isExpanded(element)">
                <br>
                <p><code>{{getRaw(element)}}</code></p>
              </div>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row">
          </tr>
          <tr mat-row *matRowDef="let element; columns: ['expandedDetail']" [@detailExpand]="isExpanded(element)" class="example-detail-row"></tr>
        </table>
        <button mat-raised-button color="primary" (click)="search(input, true)" [disabled]="noMore">LoadMore</button>
      </div>
    </mat-card-content>
  </mat-card>
</div>

import { Component, Input } from "@angular/core";

@Component({
    selector: 'uxHistory',
    templateUrl: 'uxHistory.component.html',
    standalone: false
})

export class UxHistoryComponent {
  @Input() uxHistory = [];
  @Input() id: string;
  @Input() type: string = '';
  @Input() jsonBeautifier: boolean = false;

  showBefore: boolean = false;

  ngOnInit() {
    this.uxHistory = this.uxHistory.filter(item => item[0].referenceId && item[0].referenceId == this.id)
  }

  isAdded(type, item, itemIdx, id) {
    if (!id) {
      return false;
    }

    const length = item?.filter?.(e => !!e)?.length ?? 0;

    return length > itemIdx + 1 && !item[itemIdx + 1]?.reference?.[type]?.includes?.(id);
  }

  isRemoved(type, item, itemIdx, id) {
    if (!id) {
      return false;
    }

    return 0 <= itemIdx - 1 && !item[itemIdx - 1]?.reference?.[type]?.includes?.(id);
  }
}

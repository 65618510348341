import {AfterViewInit, Component, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import {JsonService} from '../../clientCommon/services/json.service';
import {ActivatedRoute} from '@angular/router';
import {SpinnerService} from '../../clientCommon/services/spinner.service';
import {ResponseEvent} from '../../common/event/responseEvent';
import {User} from '../../common/models/user/user';
import {serverPaths, timeSheetPaths} from '../../common/helpers/pathHelpers';
import {LogUtils} from '../../common/utils/logUtils';
import { BaseDirective } from 'src/clientCommon/directives/BaseDirective';
import { ServiceHelperService } from 'src/clientCommon/services/serviceHelper.service';
import {TimeSheet} from '../../common/models/timesheet';
import { TimesheetService } from '../services/timesheet.service';
import { MatDialog } from '@angular/material/dialog';
import {AddTimesheetDialog} from "./timesheet/addTimesheetModal.component";
import moment from 'moment';
import { permissionUtils } from '../../common/utils/permissionUtils';

interface TimesheetDetail {
  date: number;
  timeIn: number;
  timeOut: number;
  activeHours: number;
  notes: string[];
  ids: string[];
  tempClient: any;
  timesheets: TimeSheet[],
}

@Component({
    templateUrl: './timeSheet.component.html',
    styleUrls: ['./timeSheet.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: false
})

export class TimeSheetComponent extends BaseDirective implements OnInit, AfterViewInit {
  userId = '';
  user: User;
  timesheets: TimesheetDetail[] = [];
  timesheetColumns = ['status','edit','delete','date','timeIn','timeOut','activeHours','notes'];
  timesheetGroupedByDate: Record<number, TimesheetDetail[]> = {};
  expandedElement;
  expandedList: TimesheetDetail[] = [];
  startDate = new Date();
  editData = {
    date: new Date(),
    timeIn: '',
    timeOut: '',
    note: '',
  }

  private readUrl = '/api/manage/admin/user/read';

  constructor(private jsonService: JsonService,
              private spinnerService: SpinnerService,
              private route: ActivatedRoute,
              private snackBar: MatSnackBar,
              public serviceHelperService: ServiceHelperService,
              public timesheetService: TimesheetService,
              public dialog: MatDialog
              ) {
    super(serviceHelperService, route);
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.userId = params.userId;
      let dat = new Date();
      dat.setDate(dat.getDate() - (dat.getDay()||7) );
      this.startDate = new Date(dat);
      super.baseInit().then(() => this.init());
    });
  }

  ngAfterViewInit() {}

  init() {
    this.spinnerService.spin();
    this.findUser().then(() => {
      return this.getWeeklyRecords();
    }).finally(() => {
      this.spinnerService.unspin();
      this.timesheetService.getDateTime()
    })
  }

  getWeeklyRecords() {
    let dat = new Date(this.startDate);
    const startDate = dat.toString().slice(4,15);
    dat = new Date(this.startDate);
    dat.setDate(dat.getDate() + 6);
    const endDate = new Date(dat).toString().slice(4,15);
    return this.jsonService.json(serverPaths.timeSheetGetRecordsByUser, {
      startDate,
      endDate,
      userId: this.userId
    }).then((responseEvent: ResponseEvent) => {
      this.timesheets = [];
      this.timesheetGroupedByDate = {};
      let timesheets = responseEvent.getDocs() as TimeSheet[];
      if (!timesheets.length) {
        return;
      }
      if (timesheets[0].type === TimeSheet.TYPE.clock_out) {
        timesheets = timesheets.slice(1);
      }
      if (timesheets[timesheets.length - 1].type == TimeSheet.TYPE.clock_in) {
        timesheets = timesheets.slice(0, timesheets.length - 1);
      }
      for (let i = 0 ; i < timesheets.length ; i += 2) {
        const notes = [];
        if (timesheets[i].note) {
          notes.push(timesheets[i].note);
        }
        const timesheetDetail: TimesheetDetail = {
          activeHours: timesheets[i + 1].timestamp - timesheets[i].timestamp,
          date: new Date(this.timesheetService.getDateTimeString(new Date(timesheets[i].timestamp))).setHours(0,0,0,0),
          notes: notes,
          timeIn: new Date(this.timesheetService.getDateTimeString(new Date(timesheets[i].timestamp))).getTime(),
          timeOut: new Date(this.timesheetService.getDateTimeString(new Date(timesheets[i + 1].timestamp))).getTime(),
          ids: [timesheets[i]._id, timesheets[i+1]._id],
          tempClient: {},
          timesheets: [timesheets[i], timesheets[i+1]]
        }
        if (!this.timesheetGroupedByDate[timesheetDetail.date]?.length) {
          this.timesheetGroupedByDate[timesheetDetail.date] = [timesheetDetail];
        } else {
          this.timesheetGroupedByDate[timesheetDetail.date].push(timesheetDetail);
        }
        this.timesheets = this.getTimesheetGroups();
      }
    }).catch((e) => {
      LogUtils.error(e);
    })
  }

  getTimesheetGroups() {
    const results: TimesheetDetail[] = [];
    Object.keys(this.timesheetGroupedByDate).forEach((key) => {
      let timeIn, timeOut, date, activeHours = 0, notes = [];
      this.timesheetGroupedByDate[+key].forEach((record, index) => {
        if (index === 0) {
          timeIn = record.timeIn;
          date = +key;
        }
        if (index === this.timesheetGroupedByDate[+key].length - 1) {
          timeOut = record.timeOut;
        }
        activeHours += record.activeHours;
        notes.push(...record.notes);
      });
      results.push({
        timeIn,
        timeOut,
        date,
        activeHours,
        notes,
        ids: [],
        tempClient: {},
        timesheets: []
      });
    })
    return results;
  }

  findUser() {
    return this.jsonService.json(serverPaths.timeSheetGetUsers, {}).then((responseEvent: ResponseEvent) => {
      const users = responseEvent.getDocs();
      this.user = users.find((u) => u._id === this.userId);
    }).catch((e) => {
      LogUtils.error(e);
    });
  }

  rangeFilter = (aDate: Date): boolean => {
    if (!aDate) {
      return false;
    }
    const date = aDate.getDay();
    return date === 0;
  }

  handleChangeStartDate(value) {
    this.startDate = new Date(value);
    this.getWeeklyRecords();
  }

  formatDate(timestamp: number) {
    const dates = [
      'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'
    ]
    const convertedDate = new Date(moment(timestamp).utcOffset('-0400').unix() * 1000);
    return `${dates[convertedDate.getDay()]} ${this.timesheetService.convertFullDigit(convertedDate.getMonth() + 1)}/${this.timesheetService.convertFullDigit(convertedDate.getDate())}`;
  }

  getHoursMinuteFromTimestamp(timestamp: number) {
    const date = new Date(moment(timestamp).utcOffset('-0400').unix() * 1000);
    return `${this.timesheetService.convertFullDigit(date.getHours())}:${this.timesheetService.convertFullDigit(date.getMinutes())}`
  }

  convertTimestampToHoursMinute(timestamp: number) {
    let seconds = Math.floor(timestamp / 1000);
    let minutes = Math.floor(seconds / 60);
    seconds %= 60;
    let hours = Math.floor(minutes / 60);
    minutes %= 60;
    return `${hours}h:${minutes}m:${seconds}s`;
  }

  deleteTimesheetsByDate(date: number) {
    const ids: string[] = [];
    this.timesheetGroupedByDate[date].forEach((detail) => ids.push(...detail.ids));
    return this.deleteRecords(ids);
  }

  deleteTimesheet(timesheet: TimesheetDetail) {
    return this.deleteRecords(timesheet.ids);
  }

  deleteRecords(ids: string[]) {
    this.serviceHelperService.spinnerService.spin();
    return Promise.resolve(true).then(() => {
      const user = this.serviceHelperService.authenticationService.getUser();
      if (permissionUtils.hasPermissionPath(user, "/" + serverPaths.manageCsrTimesheetDelete) || user.roles.includes(User.ROLES.admin)) {
        return;
      }
      return Promise.reject('This action is for management use only.');
    }).then(() => {
      return this.jsonService.json(serverPaths.manageCsrTimesheetDelete, {
        timesheetIds: ids
      }).then(() => {
        return this.getWeeklyRecords().catch((e) => {
          LogUtils.error(e);
        });
      }).catch((e) => {
        this.snackBar.open('Failed to delete records.', 'Failed', {duration: 2000});
        LogUtils.error(e);
      })
    }).catch((e) => {
      this.snackBar.open(e, 'Auth', {duration: 2000});
    }).finally(() => {
      this.serviceHelperService.spinnerService.unspin();
    })
  }

  toggleEdit(timesheet: TimesheetDetail) {
    const user = this.serviceHelperService.authenticationService.getUser();
    if (!permissionUtils.hasPermissionPath(user, "/" + serverPaths.manageCsrTimesheetUpdate) && !user.roles.includes(User.ROLES.admin)) {
      if (user._id !== this.user._id) {
        this.snackBar.open('This action is for management use only.', 'Failed');
        return;
      }
    }
    if (timesheet.tempClient.editing) {
      if (permissionUtils.hasPermissionPath(user, "/" + serverPaths.manageCsrTimesheetUpdate) || user.roles.includes(User.ROLES.admin)) {
        this.updateTimesheets(timesheet).then(() => {
          timesheet.tempClient.editing = false;
        }).catch((e) => {
          LogUtils.error(false);
          this.snackBar.open(e.reason, 'Failed', {duration: 2000});
        });
      } else {
        this.updateTimesheetNote(timesheet).then(() => {
          timesheet.tempClient.editing = false;
        }).catch((e) => {
          LogUtils.error(e);
          this.snackBar.open('Failed to update timesheet note.', 'Failed', {duration: 2000});
        });
      }
    } else {
      const newDate = new Date(new Date(timesheet.timeIn).setHours(0,0,0,0));
      const startHours = new Date(timesheet.timeIn).getHours();
      const startMinutes = new Date(timesheet.timeIn).getMinutes();
      const endHours = new Date(timesheet.timeOut).getHours();
      const endMinutes = new Date(timesheet.timeOut).getMinutes();
      this.editData = {
        date: newDate,
        note: timesheet.timesheets[0].note,
        timeIn: `${startHours}:${startMinutes}`,
        timeOut: `${endHours}:${endMinutes}`,
      }
      timesheet.tempClient.editing = true;
    }
  }

  isValidEditData() {
    if (!this.editData.date) {
      return false;
    }
    if (this.editData.timeOut?.split(":")?.length !== 2) {
      return false;
    }
    if (this.editData.timeIn?.split(":")?.length !== 2) {
      return false;
    }
    const startHours = +this.editData.timeIn.split(":")[0];
    const startMinutes = +this.editData.timeIn.split(":")[1];
    const endHours = +this.editData.timeOut.split(":")[0];
    const endMinutes = +this.editData.timeOut.split(":")[1];
    if (startHours > endHours) {
      return false;
    } else if (startHours === endHours) {
      if (startMinutes >= endMinutes) {
        return false;
      }
    }
    return true;
  }

  getTimestampFromDateAndHour(date: Date, hour: string) {
    const time = hour.split(":");
    if (time.length !== 2) {
      return -1;
    }
    const h = time[0];
    const m = time[1];
    return date.setHours(+h, +m, 0, 0);
  }

  updateTimesheets(timesheetDetail: TimesheetDetail) {
    if (!this.isValidEditData()) {
      return Promise.reject(false)
    }
    const timesheets = timesheetDetail.timesheets;
    timesheets[0].draft.note = this.editData.note;
    timesheets[1].draft.note = this.editData.note;
    timesheets[0].draft.timestamp = this.getTimestampFromDateAndHour(this.editData.date, this.editData.timeIn);
    timesheets[1].draft.timestamp = this.getTimestampFromDateAndHour(this.editData.date, this.editData.timeOut);
    return this.jsonService.json(serverPaths.manageCsrTimesheetUpdate, {
      timesheets
    }).then(() => {
      return this.getWeeklyRecords().catch((e) => {
        LogUtils.error(e);
      });
    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

  updateTimesheetNote(timesheetDetail: TimesheetDetail) {
    if (!this.isValidEditData()) {
      return Promise.reject(false)
    }
    const timesheets = timesheetDetail.timesheets;
    timesheets[0].draft.note = this.editData.note;
    timesheets[1].draft.note = this.editData.note;
    return this.jsonService.json(serverPaths.timeSheetUpdateNote, {
      timesheets
    }).then(() => {
      return this.getWeeklyRecords().catch((e) => {
        LogUtils.error(e);
      });
    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(false);
    });
  }

  formatTime(value) {
    let formatted = "";
    if (value) {
      value = value.replace(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/g, "");
    }
    return formatted;
  }

  onExpandRow(entry: TimesheetDetail) {
    const index = this.expandedList.findIndex((e) => e.ids === entry.ids)
    if (index > -1) {
      this.expandedList.splice(index, 1);
    } else {
      this.expandedList.push(entry);
    }
  }

  isRowExpanded(entry: TimesheetDetail) {
    return this.expandedList.findIndex((e) => e.ids === entry.ids) > -1;
  }

  addTimesheet() {
    const user = this.serviceHelperService.authenticationService.getUser();
    if (!permissionUtils.hasPermissionPath(user, "/" + serverPaths.manageCsrTimesheetCreate) && !user.roles.includes(User.ROLES.admin)) {
      this.snackBar.open('This action is for management use only.', 'Failed');
      return;
    }

    const dialogRef = this.dialog.open(AddTimesheetDialog);
    dialogRef.afterClosed().subscribe((value) => {
      if (!value) {
        return;
      }
      const timesheets: TimeSheet[] = value;
      timesheets.forEach((t, i) => {
        t.draft.ownerId = this.userId;
        if (i === 0) {
          t.draft.type = TimeSheet.TYPE.clock_in;
        } else {
          t.draft.type = TimeSheet.TYPE.clock_out;
        }
      })
      return this.jsonService.json(serverPaths.manageCsrTimesheetCreate, {
        timesheets
      }).then(() => {
        this.snackBar.open('Timesheet Records created.', 'Success');
        return this.getWeeklyRecords().catch((e) => {
          LogUtils.error(e);
        });
      }).catch((e) => {
        LogUtils.error(e);
        this.snackBar.open('Failed to create timesheet records.', 'Failed');
        return Promise.reject(false);
      });
    })
  }

  getLastNote(notes: string[]) {
    return notes.filter((n) => !!n).reverse()[0];
  }

  canEditNote() {
    const user = this.serviceHelperService.authenticationService.getUser();
    return permissionUtils.hasPermissionPath(user, "/" + serverPaths.manageCsrTimesheetUpdate) || user.roles.includes(User.ROLES.admin);
  }
}

import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SpinnerService} from '../../clientCommon/services/spinner.service';
import {Content} from '../../common/models/content';
import {LogUtils} from '../../common/utils/logUtils';
import {StorageService} from '../../clientCommon/services/storage.service';
import {CrudHelperSearchOptions, CrudHelperService} from '../services/crudHelper.service';
import {ModelBase} from "../../common/models/modelBase";
import {collectionClassHelper} from "../../common/decorators/database/collectionClass";
import { DeleteConfirmDialog } from '.';

@Component({
    templateUrl: 'contentMgmt.component.html',
    styleUrls: ['./contentMgmt.component.scss'],
    standalone: false
})
export class ContentMgmtComponent implements OnInit {

  newContent = new Content();
  alertDuration = 5000;
  searchOptions: CrudHelperSearchOptions = {
    key: 'adminContentSearchOptionFiltesr',
    filters: {
      contents: {
        className: collectionClassHelper.getCollectionName(Content),
        fields: ['_id', 'name'],
        value: '',
      },
    },
  };

  addFlag = false;
  results: { contents?: Content[] } = {};
  fullContentFlag = false;

  constructor(private crudHelperService: CrudHelperService,
              private storageService: StorageService,
              private spinnerService: SpinnerService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    const filters = this.storageService.get(this.searchOptions.key);
    if (filters) {
      this.searchOptions.filters = filters;
    }
  }

  processContentOptionsEvent($event, content: Content) {
    content.tempClient.optionsString = $event.target.value;
    try {
      content.options = JSON.parse($event.target.value);
    } catch (e) {
      LogUtils.info(e.message);
    }
  }

  addContent() {
    this.crudHelperService.addDefault(this.newContent, this.alertDuration, this.searchOptions, this.results).then(() => {
      this.newContent = new Content();
    }).catch((e) => {
      LogUtils.error(e);
    });
  }

  search(): Promise<any> {
    return this.crudHelperService.search(this.searchOptions, this.results).then(() => {
      // nothing
    }).catch((e) => {
      LogUtils.error(e);
    });

  }

  keyDownEvent(event) {
    return this.crudHelperService.keyDownEvent(event, this.searchOptions, this.results);
  }

  copyId(obj) {
    return this.crudHelperService.copyId(obj, this.alertDuration);
  }

  copyName(obj) {
    return this.crudHelperService.copyName(obj, this.alertDuration);
  }

  toggleStatus(object: ModelBase<any>) {
    if (object.draft.status === ModelBase.STATUS.active) {
      object.draft.status = ModelBase.STATUS.removed;
    } else {
      object.draft.status = ModelBase.STATUS.active;
    }
  }

  update(obj) {
    return this.crudHelperService.update(obj, this.alertDuration, this.searchOptions, this.results);
  }
}

import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JsonService } from '../../clientCommon/services/json.service';
import { User } from '../../common/models/user/user';
import { ResponseEvent } from '../../common/event/responseEvent';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelBase } from '../../common/models/modelBase';
import { CommerceOfferRuleKeyDetail, CommerceOrder } from '../../common/models/commerce/commerceOrder';
import { CommerceSchedule } from '../../common/models/commerce/commerceSchedule';
import { commerceUrls } from '../../common/models/commerce/commerceEvent';
import { SpinnerService } from '../../clientCommon/services/spinner.service';
import { ArrayUtils } from '../../common/utils/arrayUtils';
import {CommercePrice, SequenceOptions} from '../../common/models/commerce/commercePrice';
import { CommerceTransaction } from '../../common/models/commerce/commerceTransaction';
import { CommercePayment } from '../../common/models/commerce/commercePayment';
import { adminPaths, serverPaths } from '../../common/helpers/pathHelpers';
import { CommerceToken } from '../../common/models/commerce/commerceToken';
import { isNumber } from 'util';
import { collectionClassHelper } from '../../common/decorators/database/collectionClass';
import { UxComposite } from '../../common/models/ux/uxComposite';
import { UxHelper } from '../../clientCommon/helper/uxHelper';
import { CommerceService } from '../../clientCommon/services/commerce.service';
import { CreditCardInputHelper } from '../../clientCommon/helper/creditCardInputHelper';
import { Note } from '../../common/models/note';
import { AdminAuthGuard, CsrAuthGuard } from '../guards';
import { objectUtils } from '../../common/utils/objectUtils';
import { custom } from '../custom/custom.class';
import { LogUtils } from '../../common/utils/logUtils';
import { MatDialog } from '@angular/material/dialog';
import { configUtils } from '../utils/ConfigUtils';
import {
  AddCallDialog,
  AddCommerceOfferDialog,
  AddEmailDialog,
  AddNoteDialog,
  BillingInfoDialog,
  ChargebackDialog,
  ConfirmationDialog,
  RefundPaymentDialog,
  ViewNoteDialog
} from './customer/dialog.component';
import { ServiceHelperService } from 'src/clientCommon/services/serviceHelper.service';
import { EmailService } from 'src/clientCommon/services/email.service';
import { phoneUtils } from '../../common/utils/phoneUtils';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BaseDirective } from 'src/clientCommon/directives/BaseDirective';
import { CommerceOffer, CommerceOfferDetail } from '../../common/models/commerce/commerceOffer';
import { commerceUtils } from '../../common/utils/commerceUtils';
import { CommerceContent } from '../../common/models/commerce/commerceContent';
import moment from "moment";
import { lastValueFrom } from 'rxjs';
import { pathHelpers } from 'src/common/helpers';
import { IdProtectionHelper } from 'src/clientCommon/helper/idProtectionHelper';
import { idProtectionProviderTypes } from 'src/common/custom/idProtection/idProtectionKeys';

interface CommerceOrderUpdate {
  updaterId: string | null;
  retry: number;
  sequence: number;
  status: string;
  subStatus: string;
  timestamp: number;
}

@Component({
    templateUrl: 'customer.component.html',
    styleUrls: ['customer.component.scss'],
    standalone: false
})
export class CustomerComponent extends BaseDirective implements OnInit {
  paymentTypes = CommercePayment.TYPE;
  serverPaths = serverPaths;
  userId: string;
  user: User;
  uxHelper: UxHelper = new UxHelper();
  customerUxComposite: UxComposite;
  adminUxComposite: UxComposite;
  commerceOrders: CommerceOrder[] = [];
  commerceSchedules: CommerceSchedule[] = [];
  commerceToken: CommerceToken;
  commercePayments: CommercePayment[] = [];
  notes: Note[] = [];
  minDate = new Date();
  maxDate = new Date(new Date().getTime() + 31536000000); // 1 Year from now
  periodUnits;
  customerUserInfo: { [userId: string]: User } = {};
  mailTrackings: any = {};
  commerceOrderMailTrackings = {};
  custom = custom;
  timeFormat = 'yyyy-MM-dd hh:mm:ss aa';
  adminFlag = false;
  adminPaths = adminPaths;
  baseCommerceOrder: any = {};
  savedReports: CommerceContent[] = [];
  historyNoteInfo: { id: string, isShow: boolean, history: any }[] = [];

  hash;
  creditCardInputHelper = new CreditCardInputHelper();

  newNote: Note;
  alertDuration = 5000;

  trackingDisplay: any = {};
  advanced = false;
  systemUsername = 'System';
  initDone = false;
  brands = [];

  
  paymentColumns = ['expander', 'action', 'offerName', 'status', 'authorized', 'total', 'refunded', 'orderId'];
  communicationColumns = ['type', 'dateTime', 'from', 'to', 'message', 'action'];
  scheduleColumns = ['type', 'id', 'updater', 'amount', 'scheduleDate', 'action'];
  commerceUserActivityDataSource: Array<any> = [];
  editSummary = false;
  statusOptions = Object.keys(ModelBase.STATUS);
  detailedSchedules = false;
  cancellableIndex = -1;
  cancelledIndex = -1;

  expandedOrderIds = [];
  expandedPaymentIds = [];
  expandedOrderDebugIds = [];
  communicationDebugIds = [];

  agentPhoneNumber = '';
  agentAddress = '';
  companyName = '';
  noteData: any;
  paymentUrl = pathHelpers.adminPaths.commercePayment;
  orderUrl = pathHelpers.adminPaths.commerceOrder;

  offerRulesKey = "comp.csr.downsell.offer.rules";
  commerceOfferOptions: Record<string, {
    id: string,
    downsell: boolean
  }> = {};
  commerceOffers: CommerceOffer[] = [];
  searchInfo = [];
  PMAX_PROVIDER_TYPES = {
    customerEnrollment: idProtectionProviderTypes.customerEnrollment,
  };
  pMaxxCustomerStatus: boolean = false;

  constructor(private jsonService: JsonService,
    private spinnerService: SpinnerService,
    private adminAuthGuard: AdminAuthGuard,
    private csrAuthGuard: CsrAuthGuard,
    private commerceService: CommerceService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private router: Router,
    private emailService: EmailService,
    private bottomSheet: MatBottomSheet,
    serviceHelperService: ServiceHelperService,
    activatedRoute: ActivatedRoute,
  ) {
    super(serviceHelperService, activatedRoute);
    this.uxHelper.prefix = ``;
    this.periodUnits = ArrayUtils.toArray(CommercePrice.UNIT);
  }

  ngOnInit() {
    return super.baseInit().then(() => {
      this.adminUxComposite = this.uxComposite;
    }).then(() => {
      return this.route.params.subscribe((params) => {
        this.userId = params.userId;
      });
    }).then(() => {
      return this.init(this.userId);
    });
  }

  init(userId) {
    this.adminFlag = this.adminAuthGuard.hasAdminRole();
    this.spinnerService.spin();

    this.newNote = new Note();
    this.hash = this.commerceService.createHash();
    return this.jsonService.json(commerceUrls.csrFindCustomer + '/' + userId, {}).then((responseEvent: ResponseEvent) => {
      this.commerceOrders = [];
      this.commerceSchedules = [];
      this.notes = [];
      this.customerUxComposite = null;
      this.savedReports = [];
      this.pMaxxCustomerStatus = responseEvent.data?.pMaxxCustomerStatus === true;

      const docs = responseEvent.getDocs();
      LogUtils.info(docs);
      docs.forEach(async (obj: ModelBase<any>) => {
        if (obj instanceof User) {
          this.user = obj;
        } else if (obj instanceof CommerceOrder) {
          if (obj.commerceOffer?.nonMemberOnly === true) {
            this.baseCommerceOrder = obj;
          }
          if (!obj.isTrivialOrder()) {
            obj.toggle('show');
          }
          if (obj.commerce3ds) {
            delete obj.commerce3ds.draft;
            delete obj.commerce3ds.appliedCommercePayments;
          }
          this.addProcessCounts(obj);
          this.commerceOrders.push(obj);
          obj.getCommercePayments().forEach((payment) => {
            this.commercePayments.push(payment);
          })
        } else if (obj instanceof CommerceSchedule) {
          this.commerceSchedules.push(obj);
        } else if (obj instanceof UxComposite) {
          await this.processUxComposite(obj);
        } else if (obj instanceof CommerceToken) {
          this.commerceToken = obj;
        } else if (obj instanceof Note) {
          this.notes.push(obj);
        } else if (obj instanceof CommerceContent) {
          this.savedReports.push(obj);
        } else {
          LogUtils.error('Wrong doc', obj, obj instanceof UxComposite);
        }

        let arr = this.notes.map(el => el.type)
      });

      if (this.customerUxComposite && this.commerceOrders && this.commerceOrders.length) {
        this.brands = configUtils.getBrands(this.customerUxComposite);
        this.agentAddress = this.customerUxComposite.getUxcomp('comp.brand.address.twoline');
        this.agentPhoneNumber = this.customerUxComposite.getUxcomp('comp.brand.customer.phone');
        this.companyName = this.customerUxComposite.getUxcomp('comp.brand.company.name');

        this.commerceOrders.forEach((commerceOrder) => {
          this.processCommerceOrder(this.customerUxComposite, commerceOrder);
        });

        this.creditCardInputHelper.setDummyAddress(this.customerUxComposite);
      }

      this.cancellableIndex = this.commerceOrders.findIndex(commerceOrder => commerceOrder.isCancellable());
      this.cancelledIndex = this.commerceOrders.findIndex(commerceOrder => commerceOrder.isCancelled());

      if (responseEvent.data.searchInfo) {
        this.searchInfo = responseEvent.data.searchInfo;
      }

      this.commerceOrders.sort((a, b) => {
        return b.createdTimestamp - a.createdTimestamp;
      });
      this.notes.sort((a, b) => {
        return b.createdTimestamp - a.createdTimestamp;
      });

      if (responseEvent.data.userInfo) {
        Object.keys(responseEvent.data.userInfo).forEach((key) => {
          this.customerUserInfo[key] = new User(responseEvent.data.userInfo[key]);
        });

      }
      this.mailTrackings = responseEvent.data.mailTrackings;
      this.commerceOrderMailTrackings = this.getCommerceOrderTrackings(this.mailTrackings);
      Object.keys(this.commerceOrderMailTrackings).forEach(key => this.commerceUserActivityDataSource.push(...this.commerceOrderMailTrackings[key]));
      this.commerceUserActivityDataSource = this.commerceUserActivityDataSource.sort((a, b) => a.timestamp > b.timeStamp ? -1 : 1);
    }).then(() => {
      const commerceOfferIds = this.customerUxComposite.getUxcomp("comp.csr.downsell.offers.rules.ids");
      if (!commerceOfferIds?.length) {
        return Promise.reject('CommerceOffers are not available.');
      }
      return this.serviceHelperService.commerceService.findOffers(commerceOfferIds).then((commerceOffers) => {
        this.commerceOffers = commerceOffers;
      }).catch((e) => {
        LogUtils.error("AddonComponent:Finding offer error", e);
      });
      
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      this.initDone = true;
      this.spinnerService.unspin();
    });
  }

  goPaymentDetail(commercePayment: CommercePayment) {
    //const csrPath = configUtils.getCsrPathFromVersion(this.uxComposite);
    this.router.navigate([pathHelpers.adminPaths.commercePayment + '/' + commercePayment._id]);
  }

  reflectCommerceScheduleDate(date: Date, commerceSchedule: CommerceSchedule) {
    commerceSchedule.draft.dueTimestamp = date.getTime();
  }


  isValidUpdateCommerceSchedule(commerceSchedule: CommerceSchedule) {
    const dueDate = new Date(commerceSchedule.dueTimestamp);
    const draftDueDate = new Date(commerceSchedule.draft.dueTimestamp);

    if (!isNumber(commerceSchedule.draft.scheduleData.amount) || !isNumber(commerceSchedule.draft.dueTimestamp)) {
      return false;
    }

    return (commerceSchedule.scheduleData.amount !== commerceSchedule.draft.scheduleData.amount) ||
      (!(
        (dueDate.getFullYear() === draftDueDate.getFullYear()) &&
        (dueDate.getMonth() === draftDueDate.getMonth()) &&
        (dueDate.getDate() === draftDueDate.getDate())
      ));
  }

  async updateUser(user: User, confirmMsg?: string) {
    if (confirmMsg) {
      const confirmed = await this.openConfirmationDialog(confirmMsg);

      if (!confirmed) {
        return;
      }
    }

    if (user.draft.phone) {
      user.draft.phone = user.draft.phone.replace(/(?:(?:^[^0-9]*1*)|(?:[^0-9]))/g, "");
    }
    const obj = user.getSaveSet();
    obj._id = user._id;
    const input = { users: [obj] };
    this.emailService.checkEmailAddress(user.draft.email).then((valid) => {
      if (valid) {
        this.spinnerService.spin();
        this.jsonService.json(serverPaths.manageCsrUpdateCustomer, input).then((responseEvent: ResponseEvent) => {
          this.init(this.userId);
        }).then(() => {
          this.editSummary = false;
        }).catch((e) => {
          LogUtils.error(e);
          this.spinnerService.unspin();
          if (e?.responseCode === 409) {
            this.snackBar.open(`This email address has been already registered with ${this.customerUxComposite.brandName}. Please enter a different email address`, 'Error', { duration: this.alertDuration });
          }
        });
      } else {
        this.snackBar.open('Invalid Email', 'Error', { duration: this.alertDuration });
      }
    }).catch(() => {
      this.snackBar.open('Invalid Email', 'Error', { duration: this.alertDuration });
    });
  }

  updateCommerce(commerceObj: ModelBase<any>) {
    const obj = commerceObj.getSaveSet();
    obj._id = commerceObj._id;
    const input: any = {};
    if (commerceObj instanceof CommerceSchedule) {
      input.commerceSchedules = [obj];
    } else if (commerceObj instanceof CommerceOrder) {
      input.commerceOrders = [obj];
    } else if (commerceObj instanceof CommerceToken) {
      input.commerceTokens = [obj];
    } else {
      LogUtils.error('wrong obj', commerceObj);
      return;
    }

    this.spinnerService.spin();
    this.jsonService.json(commerceUrls.csrUpdate, input).then((responseEvent: ResponseEvent) => {
      this.init(this.userId);
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
    });
  }


  updateCommerceSchedule(commerceSchedule: CommerceSchedule) {
    commerceSchedule.draft.scheduleData.amount = Math.abs(commerceSchedule.draft.scheduleData.amount);
    if (commerceSchedule.draft.scheduleData.type === CommercePayment.TYPE.refund) {
      commerceSchedule.draft.scheduleData.amount *= -1;
    }
    this.updateCommerce(commerceSchedule);
  }

  updateCommerceOrder(commerceOrder: CommerceOrder) {
    this.updateCommerce(commerceOrder);
  }

  convertAdjustAmount(amount) {
    return -1 * Math.abs(amount);
  }

  isAdjustable(commercePayment: CommercePayment, amount) {
    return amount && commercePayment.isAdjustable({ code: commercePayment.price.code, amount: this.convertAdjustAmount(amount) });
  }

  isVoidable(commerceOrder: CommerceOrder, commercePayment: CommercePayment): boolean {
    return commercePayment.isVoidable() && !commerceOrder.isCapturedAuthorize(commercePayment);
  }

  refund(commerceOrder: CommerceOrder, commerceTransaction: CommerceTransaction, commercePayment: CommercePayment, amount) {
    const message = `Refund of $${amount}?`;
    if (confirm(message)) {
      return this.adjustPayment(CommercePayment.TYPE.refund, commerceOrder, commerceTransaction, commercePayment, amount);
    }
  }

  void(commerceOrder: CommerceOrder, commerceTransaction: CommerceTransaction, commercePayment: CommercePayment) {
    const message = `Full refund of $${commercePayment.calculateRemainingValue().amount}?`;
    if (confirm(message)) {
      let type = CommercePayment.TYPE.void;
      if (commercePayment.adjustment && commercePayment.adjustment.amount < 0) {
        type = CommercePayment.TYPE.refund;
      }
      return this.adjustPayment(type, commerceOrder, commerceTransaction, commercePayment, commercePayment.calculateRemainingValue().amount);
    }
  }

  adjustPayment(type: string, commerceOrder: CommerceOrder, commerceTransaction: CommerceTransaction, commercePayment: CommercePayment, amount) {
    const input: any = {};
    input.adjustment = { code: commercePayment.price.code, amount: this.convertAdjustAmount(amount) };
    input.type = type;
    input.commerceOrderId = commerceOrder._id;
    input.commercePaymentId = commercePayment._id;
    input.commerceOrderRevisionId = commerceOrder.currentModelRevisionId;
    input.commercePaymentRevisionId = commercePayment.currentModelRevisionId;
    this.spinnerService.spin();
    this.jsonService.json(commerceUrls.csrAdjustment, input).then((responseEvent: ResponseEvent) => {
    }).catch((e) => {
      LogUtils.error(e);
      window.alert('Refund/Void error');
    }).then(() => {
      return this.cancelChildren(commerceOrder, true);
    }).then(() => {
      this.init(this.userId);
    });
  }

  codeOutput(obj) {
    return JSON.stringify(obj, null, 4);
  }

  getClasses(obj) {
    const def = collectionClassHelper.getCollectionName(obj);
    return `${def} ${obj.status} ${obj.subStatus}`;
  }

  getPaymentClasses(obj) {
    const def = collectionClassHelper.getCollectionName(obj);
    if (obj.type === 'dispute') {
      return `${def} ${obj.type}`;
    } else {
      return `${def} ${obj.status}`;
    }
  }

  isUpdatableCreditCard() {
    return !!this.customerUxComposite.get(this.commerceService.getUpdateCreditCardOfferRuleUxcompKey());
  }

  updateCreditCard() {
    this.spinnerService.spin();
    return this.commerceService.updateCreditCard(this.hash, this.user, this.customerUxComposite, this.creditCardInputHelper).catch((e) => {
      LogUtils.error(e);
    }).then((result) => {
      this.init(this.userId);
    });
  }

  addNote() {
    this.newNote.draft.referenceCollection = collectionClassHelper.getCollectionName(User);
    this.newNote.draft.referenceId = this.userId;
    const input = { notes: [this.newNote] };
    this.spinnerService.spin();
    this.jsonService.json(serverPaths.manageCsrSaveNotes, input).then((responseEvent: ResponseEvent) => {
      this.snackBar.open('Note', 'Add', { duration: this.alertDuration });
      this.init(this.userId);
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => { });
  }

  clearPassword(user) {
    if (user) {
      const input = { userId: user._id };
      this.spinnerService.spin();
      this.jsonService.json(serverPaths.manageCsrClearPassword, input).then((responseEvent: ResponseEvent) => {
        this.snackBar.open('Done', 'Clear Password', { duration: this.alertDuration });
      }).catch((e) => {
        LogUtils.error(e);
      }).then(() => {
      });
    }
  }

  cancelAll() {
    if (this.cancellableIndex > -1) {
      this.spinnerService.spin();
      this.commerceService.request(serverPaths.manageCsrOrderCancel, {
        userId: this.user._id,
        orderIds: this.commerceOrders.map(order => order._id)
      }).then((responseEvent: ResponseEvent) => {
        LogUtils.debug(responseEvent);
      }).catch((e) => {
        LogUtils.error(e);
      }).then(() => {
        return this.init(this.userId);
      });
    }
  }

  cancel(commerceOrder: CommerceOrder, recursive = true) {
    if (commerceOrder.isCancellable()) {
      this.spinnerService.spin();
      this.commerceService.request(serverPaths.manageCsrOrderCancel, {
        userId: this.user._id,
        orderIds: [commerceOrder._id]
      }).then((responseEvent: ResponseEvent) => {
        LogUtils.debug(responseEvent);
      }).catch((e) => {
        LogUtils.error(e);
      }).then(() => {
        return this.cancelChildren(commerceOrder, recursive);
      }).then(() => {
        return this.init(this.userId);
      });
    }
  }

  cancelChildren(commerceOrder: CommerceOrder, recursive = true) {
    return Promise.resolve().then(() => {
      const promises = [];
      if (recursive) {
        this.commerceOrders.forEach((childCommerceOrder) => {
          if (childCommerceOrder.parentId === commerceOrder._id) {
            promises.push(this.cancel(childCommerceOrder, recursive));
          }
        });
      }
      return Promise.all(promises);
    });
  }

  uncancelAll() {
    if (this.cancelledIndex > -1) {
      this.spinnerService.spin();
      this.commerceService.request(serverPaths.manageCsrOrderUncancel, {
        userId: this.user._id,
        orderIds: this.commerceOrders.map(order => order._id)
      }).then((responseEvent: ResponseEvent) => {
        LogUtils.debug(responseEvent);
        // do nothing
      }).catch((e) => {
        LogUtils.error(e);
      }).then(() => {
        return this.init(this.userId);
      });
    }
  }

  uncancel(commerceOrder: CommerceOrder) {
    if (commerceOrder.isCancelled()) {
      this.spinnerService.spin();
      this.commerceService.request(serverPaths.manageCsrOrderUncancel, {
        userId: this.user._id,
        orderIds: [commerceOrder._id]
      }).then((responseEvent: ResponseEvent) => {
        LogUtils.debug(responseEvent);
        // do nothing
      }).catch((e) => {
        LogUtils.error(e);
      }).then(() => {
        return this.init(this.userId);
      });
    }
  }

  isModifiable(commerceOrder: CommerceOrder) {
    let flag = true;

    if (!(commerceOrder.isCancelled() || commerceOrder.isCancellable())) {
      flag = false;
    }

    if (flag) {
      let schedule: CommerceSchedule;
      this.commerceSchedules.some((commerceSchedule) => {
        if (commerceSchedule.commerceOrderId === commerceOrder._id) {
          schedule = commerceSchedule;
          return true;
        }
      });

      if (schedule) {
        flag = !schedule.isExpire();
      }
    }

    return flag;
  }

  isProtectedCommercePayment(commerceOrder: CommerceOrder, commercePayment: CommercePayment) {
    if (commerceOrder.commerce3ds && commerceOrder.commerce3ds.appliedCommercePaymentIds.indexOf(commercePayment._id) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  getCommerceOrderTrackings(mailTracking) {
    const commerceOrderMailTrackings = {};
    if (objectUtils.isObject(mailTracking)) {
      Object.keys(mailTracking).forEach((key) => {
        if (mailTracking[key].hooks &&
          mailTracking[key].hooks[0] &&
          mailTracking[key].hooks[0].param &&
          mailTracking[key].hooks[0].param.collections &&
          mailTracking[key].hooks[0].param.collections.commerceOrders &&
          mailTracking[key].hooks[0].param.collections.commerceOrders.length) {
          mailTracking[key].hooks[0].param.collections.commerceOrders.forEach((commerceOrderId) => {
            const actions = [];
            Object.keys(mailTracking[key].actions).forEach((actionsKey) => {
              mailTracking[key].actions[actionsKey].forEach((action) => {
                actions.push({
                  action: actionsKey,
                  timestamp: action.timestamp,
                  keyName: mailTracking[key].keyName,
                });
              });
            });
            commerceOrderMailTrackings[commerceOrderId] = actions;
          });
        }
      });
    }
    return commerceOrderMailTrackings;
  }

  processUxComposite(uxComposite: UxComposite) {
    this.customerUxComposite = uxComposite;
    this.trackingDisplay = uxComposite.get('comp.admin.csr.customer.tracking.display');
    const commerceOfferIds = this.customerUxComposite.getUxcomp("comp.csr.downsell.offers.rules.ids");
    return Promise.resolve(true).then(() => {
      if (commerceOfferIds?.length) {
        return this.serviceHelperService.commerceService.findOffers(commerceOfferIds).then((commerceOffers) => {
          this.commerceOffers = commerceOffers;
        }).catch((e) => {
          LogUtils.error("AddonComponent:Finding offer error", e);
        });
      }
      return;
    })
  }

  formatTimestamp(timestamp) {
    if (!timestamp) {
      timestamp = 0;
    }
    return moment(timestamp).tz('America/New_York').format("YYYY-MM-DD HH:mm:ss")
  }

  processCommerceOrder(uxComposite: UxComposite, commerceOrder: CommerceOrder) {
    if (commerceOrder) {
      if (commerceOrder.commerceOfferRuleKey) {
        const rulesExplained = [];
        Object.keys(commerceOrder.commerceOfferRuleKey).forEach((key) => {
          const rule = commerceOrder.commerceOfferRuleKey[key];
          let value = '';
          if (key.startsWith('email')) {
            const emailKey = 'comp.email.campaign.' + rule;
            Object.keys(uxComposite.ids).forEach((id) => {
              if (id.startsWith(emailKey + '.')) {
                if (value) {
                  value += '|';
                }
                value += uxComposite.ids[id];
              }
            });
            value = emailKey + ' ' + value + ' ';
          } else {
            value = uxComposite.get(rule);
          }

          rulesExplained.push({
            type: key,
            key: rule,
            rule: value,
          });
        });
        commerceOrder.tempClient.commerceOfferRuleExplained = rulesExplained;
      }

      const commercePayments = commerceOrder.getCommercePayments();
      if (commercePayments && commercePayments.length && this.commerceSchedules && this.commerceSchedules.length) {
        commercePayments.forEach((commercePayment) => {
          this.commerceSchedules.forEach((commerceSchedule) => {
            if (commerceSchedule.scheduleData.failedCommercePaymentId === commercePayment._id ||
              commerceSchedule.scheduleData.parentCommercePaymentId === commercePayment._id) {
              let reason = 'parent';
              if (commerceSchedule.scheduleData.failedCommercePaymentId === commercePayment._id) {
                reason = 'failed';
              }
              commercePayment.tempClient.delayRefund = {
                reason: reason,
                commerceScheduleId: commerceSchedule._id,
                amount: Math.abs(commerceSchedule.scheduleData.amount),
                dueTimestamp: commerceSchedule.dueTimestamp,
              };
            }
          });
        });
      }
    }
  }

  addProcessCounts(commerceOrder: CommerceOrder) {
    if (commerceOrder && commerceOrder.tempClientSecured && commerceOrder.tempClientSecured.processes) {
      commerceOrder.tempClientSecured.processCounts = [];
      Object.keys(commerceOrder.tempClientSecured.processes).forEach((productKey) => {
        const process = commerceOrder.tempClientSecured.processes[productKey];
        const processInfo = {
          productKey: productKey,
          quantity: process.quantity,
          process: process.process,
        };
        commerceOrder.tempClientSecured.processCounts.push(processInfo);
        LogUtils.info(commerceOrder._id, commerceOrder.commerceOffer.name, commerceOrder.commerceOffer.description, processInfo);
      });
    }
  }

  // getCardInfo(commerceToken) {
  //   let ccNumber = '';
  //   if (commerceToken) {
  //     const bin = commerceToken.bin;
  //     const last4 = commerceToken.last4;
  //     const length = commerceToken?.length - last4?.length - bin?.length;
  //     if (length) {
  //       ccNumber = bin;
  //       ccNumber += new Array(length).fill('*').join('');
  //       ccNumber += last4;
  //     }
  //   }

  //   return ccNumber;
  // }

  openAddNoteModal() {
    const addNoteModalRef = this.dialog.open(AddNoteDialog, {
      width: '50%',
      hasBackdrop: true,
    });

    addNoteModalRef.afterClosed().subscribe(result => {
      if (result) {
        this.newNote.draft.note = result;
        this.addNote();
      }
    });
  }

  openViewNoteModal(note: Note) {
  
    const actionRecommenderTitle = note.data.actionRecommends?.descriptions.toString().replaceAll('-', ' ').replaceAll(',', ' - ').replaceAll('instruct', ' ');
    const mood = note.data.actionRecommends?.mood == "N"? "Normal": "Determined";


    const viewNoteModalRef = this.dialog.open(ViewNoteDialog, {
      width: '50%',
      data: {
        note: `
        <strong>Type:</strong> ${note.type || 'Note'}<br><br>
        ${note.type?`<strong>Mood:</strong> ${mood}<br>`: ''}
        ${note.type? `<strong>Action Recommender Description:</strong><br/> ${actionRecommenderTitle || 'N/A'}`: ''}
        ${(note?.note)?note.note: ''}
        `,
        noteId:note?._id,
      }
      
    });

    viewNoteModalRef.afterClosed().subscribe((value) => {
      if (value?.save) {
        this.handleUpdateNote(value?.note,note?._id);
      }
    });
  }

  appendToEmailBody(note: Note) {
    this.noteData = note.note;
    const element = document.getElementById('email-worksheet') as HTMLElement;
    element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  openAddEmailModal() {
    const addEmailDialogRef = this.dialog.open(AddEmailDialog, {
      width: '50%'
    });

    addEmailDialogRef.afterClosed().subscribe(() => {
    });
  }

  openAddCallModal() {
    const addCallDialogRef = this.dialog.open(AddCallDialog, {
      width: '50%'
    });

    addCallDialogRef.afterClosed().subscribe(() => {
    });
  }

  openBillingInfoModal() {
    const billingInfoDialogRef = this.dialog.open(BillingInfoDialog, {
      width: '50%',
      data: {
        commerceToken: this.commerceToken,
        customerUxComposite: this.customerUxComposite,
        uxComposite: this.uxComposite,
      }
    });

    billingInfoDialogRef.afterClosed().subscribe((creditCardInputHelper: CreditCardInputHelper) => {
      this.creditCardInputHelper = creditCardInputHelper;
      if (this.creditCardInputHelper.isValidBillingInfo())
        this.updateCreditCard()
          .then(() => {
            this.snackBar.open('Billing Info Updated Successfully', 'Success', { duration: this.alertDuration })
          }).catch((error) => {
            this.snackBar.open(error.message, 'Failed', { duration: this.alertDuration })
          })
    });
  }

  openRefundPaymentModal(order: CommerceOrder, payment: CommercePayment, type, event: any) {
    event.stopPropagation();
    let transaction = {};
    order.commerceTransactionCollections.forEach(transactionCollection => {
      transactionCollection.commerceTransactions.forEach(commerceTransaction => {
        const id = commerceTransaction.commercePayments.findIndex(commercePayment => commercePayment._id === payment._id);
        if (id > -1) transaction = commerceTransaction;
      })
    })
    const refundPaymentModfalRef = this.dialog.open(RefundPaymentDialog, {
      width: '50%',
      data: {
        type: type,
        order,
        payment,
        transaction,
        userInfo: this.customerUserInfo,
      }
    });

    refundPaymentModfalRef.afterClosed().subscribe((data) => {
      if (data.type === 'refund') {
        this.refund(data.order, data.transaction, data.payment, data.amount);
      } else {
        this.void(data.order, data.transaction, data.payment);
      }
    });
  }

  /**
   * chargeback process
   * 
   * @param order 
   * @param payment 
   * @param type 
   * @param event 
   */
  openChargebackModal(order: CommerceOrder, payment: CommercePayment, type, event: any) {

    this.spinnerService.spin();

    return this.jsonService.json(commerceUrls.csrChargeback + '/' + this.userId+'/'+payment._id, {}).then((responseEvent: ResponseEvent) => {
      const docs = responseEvent.getSingleDoc();
      return docs;
    }).then((docs) => {
      const charbackDialgoReturn = this.dialog.open(ChargebackDialog, {
        width: '70%',
        height: '70%',
        data: {
          payment: payment._id,
          outputData: docs.output
        }
      });      

      charbackDialgoReturn.afterClosed().subscribe((data) => {
        if (data == true) {
          // send email
          return this.jsonService.json(commerceUrls.csrChargebackEmail + '/' + this.userId+'/'+payment._id, {}).then((responseEvent: ResponseEvent) => {
            const docs = responseEvent.getSingleDoc();
            alert("The email has been resend.");
            return docs;
          }).then((docs) => {        
          }).catch((e) => {
            alert('Mail Send error!!!');
            LogUtils.error(e);
          })
        }
      });

    }).catch((e) => {
      if (e.status == 'fail') {
          alert('The data does not exist. \nThe data prior to applying the Chargeback application cannot be viewed!')
      }    
      //LogUtils.error(e);
    }).then(() => {
      this.initDone = true;
      this.spinnerService.unspin();
    });

  }

  filterCommerceSchedules() {
    if (this.detailedSchedules) {
      return this.commerceSchedules;
    } else {
      return this.commerceSchedules.filter(schedule => {
        const order = this.commerceOrders.find(order => order._id === schedule.commerceOrderId);
        return !order.isTrivialOrder();
      })
    }
  }

  calculateRefundAmount(order: CommerceOrder) {
    let sum = 0;
    order.getCommercePayments().forEach((payment) => {
      if (payment.status === ModelBase.STATUS.fulfilled 
        && payment.price.amount < 0
        && !order.isAuthorizeVoid(payment)
      ) {
        sum += payment.price.amount;
      }
    })
    return sum === 0 ? null : `-${-sum}`;
  }

  calculateAuthorizeAmount(order: CommerceOrder) {
    let sum = 0;
    
    order.getCommercePayments().forEach((payment) => {
      if (payment.status === ModelBase.STATUS.fulfilled 
        && this.isAuthorizeColumn(order, payment)
      ) {
        sum += payment.price.amount;
      }
    });
    
    return sum;
  }

  getChildPayments(order: CommerceOrder) {
    return order.commerceTransactionCollections
  }

  renderStatus(status: string) {
    if (status === ModelBase.STATUS.fulfilled) return 'PASS';
    return ''
  }

  formatInput($event) {
    this.user.draft.phone = phoneUtils.formatPhoneNumber($event);
  }

  toggleExpand(ids: string[], obj) {
    if (ids.includes(obj._id)) {
      const index = ids.findIndex(id => obj._id === id);
      ids.splice(index, 1);
    } else {
      ids.push(obj._id);
    }
  }

  isExpanded(ids: string[], obj) {
    return ids.includes(obj._id)
  }

  toggleExpandAll() {
    if (this.isAllExpanded()) {
      this.expandedOrderIds = [];
      this.expandedPaymentIds = [];
    } else {
      this.commerceOrders.forEach(order => {
        this.expandedOrderIds.push(order._id);
      });
    }
  }

  isAllExpanded() {
    return this.expandedOrderIds.length > 0 || this.expandedPaymentIds.length > 0;
  }

  findOrderPayment(order: CommerceOrder) {
    return order.getCommercePayments().find((p) => !p.parentId)
  }

  // getAddress(commerceToken) {
  //   const { street1, state, city, zip } = commerceToken.billingAddress;
  //   return `${street1} ${city} ${state} ${zip}`;
  // }

  handleEditNote(note: Note) {
    note.tempClient.edit = true;
  }

  handleUpdateNote(note: Note,id:string) {
    const input = { note,id };
    this.spinnerService.spin();
    this.jsonService.json(serverPaths.manageCsrUpdateNote, input).then((responseEvent: ResponseEvent) => {
      this.snackBar.open('Note', 'Update', { duration: this.alertDuration });
      this.init(this.userId);
      this.spinnerService.unspin();
    }).catch((e) => {
      LogUtils.error(e);
      this.spinnerService.unspin();
    }).then(() => { });
  }

  isShowHistory(note: Note) {
    const id = note._id;
    let historyNote = this.historyNoteInfo.find(historyData => historyData.id === id);

    if (historyNote != null) {
      return historyNote.isShow;
    }else {
      return false;
    }
  }

  showHistory(note: Note) {
    const id = note._id;
    let historyNote = this.historyNoteInfo.find(historyData => historyData.id === id);

    if (historyNote != null) {
      return historyNote.history;
    }else {
      return "";
    }    
  }

  isHistoryNote(note: Note) {
    const id = note._id;
    let index = -1;
    let historyNote = this.historyNoteInfo.find(historyData => historyData.id === id);
    if (historyNote != null) {
      index = this.historyNoteInfo.indexOf(historyNote);
      if (historyNote.isShow)  {
        historyNote.isShow = false;
        this.historyNoteInfo[index] = historyNote;
        return;
      }else {
        historyNote.isShow = true;
        this.historyNoteInfo[index] = historyNote;
      }      
    } 

    const input = { id };
    this.spinnerService.spin();
    this.jsonService.json(serverPaths.manageCsrHistoryNote, input).then((responseEvent: ResponseEvent) => {
      this.snackBar.open('Note', 'History', { duration: this.alertDuration });
      const docs = responseEvent.getDocs();

      let htmlTagData = '';
      docs.forEach((doc) => {
        let data = '';
        if (doc.reference?.changedTimestamp) {
          data = this.formatTimestamp(doc.reference.changedTimestamp);
        }else {
          data = this.formatTimestamp(doc.reference.createdTimestamp);
        }
        htmlTagData += doc.reference.note + '['+data+'] &nbsp;<hr> <br/>';
      });

      if (historyNote == null) {
        this.historyNoteInfo.push(
          {
            id: id, 
            isShow: true,
            history: htmlTagData
          }
        );
      }else {
        historyNote.history = htmlTagData;
        this.historyNoteInfo[index] = historyNote;
      }

      this.spinnerService.unspin();
    }).catch((e) => {
      LogUtils.error(e);
      this.spinnerService.unspin();
    }).then(() => { });    
  }

  ellipseNote(note: string) {
    if (!note) { return '' }
    if (note.length > 80) {
      return note.slice(0, 80).concat(' ...');
    }
    return note;
  }

  showAddOfferModal() {
    if (!this.commerceOffers.length) {
      this.snackBar.open('No commerce offers available', 'Error', { duration: 2000 });
      return;
    }

    const addOfferModalRef = this.dialog.open(AddCommerceOfferDialog, {
      width: '50%',
      data: {
        commerceOffers: this.commerceOffers,
        brandName: this.customerUxComposite.brandName,
      }
    });

    addOfferModalRef.afterClosed().subscribe((data) => {
      if (data?.save) {
        // Run sales API
        this.serviceHelperService.spinnerService.spin();
        this.downsell(data.commerceOfferId).then(() => {
          this.serviceHelperService.spinnerService.unspin();
          this.init(this.userId);
        }).catch(() => {
          this.serviceHelperService.spinnerService.unspin();
        });
      }
    });
  }

  createIdProtectionContentInfo() {
    return IdProtectionHelper.createIdProtectionContentInfo(this.customerUxComposite);
  }

  downsell(commerceOfferId) {
    let offerRules = this.customerUxComposite.getUxcomp(this.offerRulesKey);
    let offerRuleKeyDetails: CommerceOfferRuleKeyDetail[] = [];
    let offerDetails: CommerceOfferDetail[] = [];

    if (offerRules && objectUtils.isObject(offerRules)) {
      Object.keys(offerRules).forEach((key) => {
        offerRuleKeyDetails.push({ key: key, commerceOfferIds: [commerceOfferId] });
      });
    }

    const sequenceOptions: SequenceOptions = {
      thinMatch: false,
      thinMatchNoResults: false,
    };
    offerDetails = commerceUtils.getCommerceOfferDetails(
      this.commerceOffers.filter(commerceOffer => commerceOffer._id === commerceOfferId),
      sequenceOptions,
    );

    const updaterId = this.serviceHelperService.authenticationService.getUserId();

    let contentInfos = [];
    const idProtectionContentInfos: any = this.createIdProtectionContentInfo()
    if (idProtectionContentInfos) {
      contentInfos.push(idProtectionContentInfos)
    }

    return this.serviceHelperService.commerceService.nonOptionSaleByUser(this.hash, this.user,
      this.customerUxComposite,
      this.offerRulesKey,
      offerRuleKeyDetails,
      {
        commerceOfferIds: [commerceOfferId],
        commerceOfferDetails: offerDetails,
        updaterId,
        contentInfos,
        csr: true,
      }).catch((e) => {
        LogUtils.error(e);
      });
  }

  

  formatSubStatus(update: CommerceOrderUpdate, updates: CommerceOrderUpdate[], index?) {
    if (update.subStatus === CommerceOrder.SUB_STATUS.none && update.status == 'Active' && (index <= 1)) {
      return 'Active';
    }

    const cancelledUpdates = updates.filter((u) => !!u.updaterId && u.timestamp < update.timestamp);
    if (update.subStatus === CommerceOrder.SUB_STATUS.none && !!cancelledUpdates.find((u) => u.subStatus === CommerceOrder.SUB_STATUS.cancelled)) {
      return 'reactivated';
    }
    return this.formatBaseSubStatus(update.status, update.subStatus);
  }

  formatBaseSubStatus(status: string, subStatus: string) {
    if (CommerceOrder.STATUS_MAPPING[`${status}|${subStatus}`]) {
      return CommerceOrder.STATUS_MAPPING[`${status}|${subStatus}`];
    } else if (subStatus === CommerceOrder.SUB_STATUS.none) {
      return 'active';
    } else {
      return subStatus;
    }
  }

  checkOrder(update: CommerceOrderUpdate, order: CommerceOrder) {
    if (update.status === CommerceOrder.STATUS.inProgress) {
      return false;
    }
    if (update.status === order.status && update.subStatus === order.subStatus && update.updaterId === order.updaterId && update.timestamp > order.changedTimestamp) {
      return false;
    }
    return true;
  }

  getUpdates(order: CommerceOrder) {
    const filtered = order.tempClient.updates.sort((a, b) => a.timestamp - b.timestamp).filter((update: CommerceOrderUpdate) => update.status !== CommerceOrder.STATUS.inProgress);
    return filtered;
  }

  formatStatus(data) {  
    return data?.status || "";
  }

  getMMDD(timestamp) {
    let date = this.formatTimestamp(timestamp);
    let d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth() + 1;
    let ddmm = `${month > 9 ? month : '0' + month}${day > 9 ? day : '0' + day}`;
    return ddmm;
  }

  isAuthorizeColumn(commerceOrder, commercePayment) {
    if (commercePayment?.type === CommercePayment.TYPE.authorize) {
      return true;
    } else if (commerceOrder.isAuthorizeVoid(commercePayment)) {
      return true;
    } else {
      return false;
    }
  }

  private openConfirmationDialog(message: string): Promise<boolean> {
    let dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: false
    });

    dialogRef.componentInstance.confirmMessage = message;

    return lastValueFrom(dialogRef.afterClosed());
  }
}

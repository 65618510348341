<div class="customer" *ngIf="initDone">
  <div class="customer-side">
    <mat-card>
      <div (click)="init(userId)" class="brand-bg">
        <img [src]="'/assets/logos/' + commerceOrders[0].brandId + '.png'" alt="brand" style="height: 60px;">
      </div>
      <h2 class="customer-side-title" (click)="init(userId)">{{ customerUxComposite?.brandDomain }}</h2>
      <p class="customer-side-info">{{companyName}}</p>
      <p class="customer-side-info" [innerHTML]="agentAddress"></p>
      <p class="customer-side-info">{{agentPhoneNumber}}</p>
      <p class="customer-side-subtitle">Customer summary</p>
      <div class="d-flex">
        <div class="ml-auto">
          <a [routerLink]="['/'+adminPaths.csrCustomerV1]+'/'+userId" mat-stroked-button color="primary"
            class="mr-2">V1</a>
          <a [routerLink]="['/'+adminPaths.csrCustomerV2]+'/'+userId" mat-stroked-button color="primary"
            class="mr-2">V2</a>
          <button mat-stroked-button color="primary" (click)="openBillingInfoModal()">Billing Info</button>
        </div>
      </div>

      <ng-container *ngIf="adminUxComposite">
        <app-csr-customer-summary [from]="customerUxComposite?.getUxcomp('comp.brand.customer.email')" [user]="user"
          [uxComposite]="adminUxComposite" [baseCommerceOrder]="baseCommerceOrder"
          [commerceToken]="commerceToken"
          [router]="router"
          [agentAddress]="customerUxComposite.getUxcomp('comp.brand.address.twoline')"
          [agentPhoneNumber]="customerUxComposite.getUxcomp('comp.brand.customer.phone')"
          [warningMessage]="adminUxComposite.getUxcomp('comp.brand.customer.warning.message')"
          [brandName]="user.draft.brandId" [editSummary]="editSummary" [adminFlag]="adminFlag"
          (notifyUpdateUser)="updateUser($event.user, $event.confirmMsg);"
          [commerceOrders]="commerceOrders"
          [pMaxxCustomerStatus]="pMaxxCustomerStatus"
        >
        </app-csr-customer-summary>
      </ng-container>

      <ng-container *ngIf="adminUxComposite">
        <app-csr-customer-activity [from]="customerUxComposite?.getUxcomp('comp.brand.customer.email')" [user]="user"
          [uxComposite]="adminUxComposite" [baseCommerceOrder]="baseCommerceOrder"
          [customerUxComposite]="customerUxComposite"
          [commerceToken]="commerceToken"
          [commerceUserActivityDataSource]="commerceUserActivityDataSource"
          [savedReports]="savedReports"
          [searchInfo]="searchInfo"
          [trackingDisplay]="trackingDisplay"
          [agentAddress]="customerUxComposite.getUxcomp('comp.brand.address.twoline')"
          [agentPhoneNumber]="customerUxComposite.getUxcomp('comp.brand.customer.phone')"
          [warningMessage]="adminUxComposite.getUxcomp('comp.brand.customer.warning.message')"
          [brandName]="user.draft.brandId" [editSummary]="editSummary" [adminFlag]="adminFlag"
        >
        </app-csr-customer-activity>
      </ng-container>
      
    </mat-card>
  </div>
  <div class="customer-content">
    <mat-card>
      <div class="d-flex customer-content-communications-actions">
        <p class="customer-content-subtitle mb0">Customer Communications</p>
        <div class="ml-20">
          <!-- <button mat-raised-button color="primary" (click)="openAddCallModal()">Call</button>
          <button mat-raised-button color="primary" (click)="openAddEmailModal()">Send Email</button> -->
          <button mat-raised-button color="primary" (click)="openAddNoteModal()">Add Note</button>
        </div>
      </div>
      <mat-card class="communications">
        <mat-card-content>
          <div class="table-responsive custom-accordion">
            <div class="acc-header d-flex flex-row head plr">
              <span class="acc_th">Type</span>
              <span class="acc_th_md">Time/Date</span>
              <span class="acc_th"> From </span>
              <span class="acc_th">To</span>
              <span class="acc_th_lg">Message</span>
              <span class="acc_th ml-auto w-15">Action</span>
            </div>

            <cdk-accordion class="custom-accordion" style="padding-left: 15px; padding-right: 15px;">
              <cdk-accordion-item *ngFor="let element of notes; let index = index;" #accordionItem="cdkAccordionItem" #historyItem="cdkAccordionItem"
                class="example-accordion-item" role="button" tabindex="0" [attr.id]="'accordion-header-' + index"
                [attr.aria-expanded]="accordionItem.expanded" [attr.aria-controls]="'accordion-body-' + index">
                <!-- <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
                  {{ item.title }}
                  <span class="example-accordion-item-description">
                    Click to {{ accordionItem.expanded ? 'close' : 'open' }}
                  </span>
                </div> -->
                <div class="acc-header d-flex flex-row plr border_bottom">
                  <!-- Type Field -->
                  <span class="acc_th"> {{element.type || 'Note'}}</span>
                  <!-- Time Field -->
                  <span class="acc_td_md">{{formatTimestamp(element.createdTimestamp) |
                    date:timeFormat:custom.timezone}}</span>
                  <!-- From Field -->
                  <span class="acc_th"> {{customerUserInfo[element.updaterId]?.getFullName()}}</span>
                  <!-- To Field -->
                  <div class="acc_th">
                    <span *ngIf="!element.type"></span>
                    <span *ngIf="element.type == 'Post-Office'">{{user.draft.firstName}}</span>
                    <span *ngIf="element.type == 'Email'">{{user.email || "N/A"}}</span>
                    <span *ngIf="element.type == 'Call'">{{user.phone || "N/A"}}</span>
                  </div>
                  <!-- Message Field -->
                  <span class="acc_th_lg" [innerHTML]="ellipseNote(element.note)"></span>
                  <!-- Action Field -->
                  <span class="acc_th ml-auto w-15">
                    <button mat-raised-button color="primary" (click)="openViewNoteModal(element)">Edit</button>
                    <button mat-icon-button color="primary" (click)="accordionItem.toggle();">
                      <ng-container *ngIf="!accordionItem.expanded"><i class="material-icons">add_box</i>
                      </ng-container>
                      <ng-container *ngIf="accordionItem.expanded"><i class="material-icons">remove</i>
                      </ng-container>
                    </button>
                    <button mat-raised-button color="primary" (click)="appendToEmailBody(element)">Reply</button>
                    <button mat-raised-button color="primary" (click)="isHistoryNote(element)">History</button>
                  </span>
                </div>
                <div class="acc_body bg-lightest-gray" role="region"
                  [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index"
                  [attr.aria-labelledby]="'accordion-header-' + index" [innerHTML]="element.note">
                </div>
                <ng-container *ngIf="isShowHistory(element)">
                  <div class="acc_body bg-lightest-gray" role="region" [innerHTML]="showHistory(element)"></div>
                </ng-container>
              </cdk-accordion-item>
            </cdk-accordion>


          </div>
        </mat-card-content>
      </mat-card>


      <div class="d-flex customer-content-transactions-actions">
        <p class="customer-content-subtitle mb0">Customer Transactions</p>
        <div class="ml-20">
          <button mat-raised-button color="primary" *ngIf="cancellableIndex > -1" class="mb-1"
            (click)="cancelAll()">Cancel All</button>
          <button mat-raised-button color="primary" *ngIf="cancellableIndex === -1 && cancelledIndex > -1" class="mb-1"
            (click)="uncancelAll()">Uncancel All</button>
          <button *ngIf="commerceOffers?.length" mat-raised-button color="primary" class="mb-1 ml-20"
            (click)="showAddOfferModal()">Add Offer</button>
        </div>
      </div>
      <mat-card class="transactions">
        <mat-card-content>
          <div class="table-responsive">
            <table mat-table [dataSource]="commerceOrders" class="w-100" multiTemplateDataRows>
              <ng-container matColumnDef="expander">
                <th mat-header-cell *matHeaderCellDef>
                  <button mat-icon-button color="primary" (click)="toggleExpandAll()">
                    <ng-container *ngIf="!isAllExpanded()"><i class="material-icons">add_box</i></ng-container>
                    <ng-container *ngIf="isAllExpanded()"><i class="material-icons">remove</i></ng-container>
                  </button>
                </th>
                <td mat-cell *matCellDef="let commerceOrder">
                  <button mat-icon-button color="primary" (click)="toggleExpand(expandedOrderIds, commerceOrder)">
                    <ng-container *ngIf="!isExpanded(expandedOrderIds, commerceOrder)"><i
                        class="material-icons">add_box</i>
                    </ng-container>
                    <ng-container *ngIf="isExpanded(expandedOrderIds, commerceOrder)"><i
                        class="material-icons">remove</i>
                    </ng-container>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="orderId">
                <th mat-header-cell *matHeaderCellDef class="id"> Id </th>
                <td mat-cell *matCellDef="let commerceOrder" class="id">
                  <strong>
                    <span 
                      (click)="toggleExpand(expandedOrderDebugIds, commerceOrder)">{{commerceOrder._id}}</span>&nbsp;
                      &nbsp;<a class="detail-link" href="admin/{{orderUrl}}/{{commerceOrder._id}}" target="_blank">O</a>
                  </strong>                  
                </td>
              </ng-container>

              <ng-container matColumnDef="offerName">
                <th mat-header-cell *matHeaderCellDef class="id"> Offer Name </th>
                <td mat-cell *matCellDef="let commerceOrder" class="id">
                  <strong>{{commerceOrder.commerceOffer.name}}</strong>
                </td>
              </ng-container>

              <ng-container matColumnDef="dateTime">
                <th mat-header-cell *matHeaderCellDef class="id"> Capture Date </th>
                <td mat-cell *matCellDef="let commerceOrder" class="id">
                  <strong></strong>
                </td>
              </ng-container>

              <ng-container matColumnDef="authorized">
                <th mat-header-cell *matHeaderCellDef class="amount"> Authorized </th>
                <td mat-cell *matCellDef="let commerceOrder" class="amount">
                  <strong>{{ calculateAuthorizeAmount(commerceOrder) | number : '1.2-2' }}</strong>
                </td>
              </ng-container>

              <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef class="amount"> Collected </th>
                <td mat-cell *matCellDef="let commerceOrder" class="amount">
                  <strong>${{ commerceOrder.calculateValue().amount | number : '1.2-2' }}</strong>
                </td>
              </ng-container>

              <ng-container matColumnDef="refunded">
                <th mat-header-cell *matHeaderCellDef class="id"> Refunded </th>
                <td mat-cell *matCellDef="let commerceOrder" class="id">
                  <strong>{{ calculateRefundAmount(commerceOrder) | number : '1.2-2' }}</strong>
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Sub-status | Date </th>
                <td mat-cell *matCellDef="let commerceOrder">
                  <strong>
                    {{formatTimestamp(commerceOrder.orderTimestamp) | date:timeFormat:custom.timezone}}&nbsp;
                    {{formatStatus(commerceOrder)}}<br>
                    <ng-container *ngFor="let update of getUpdates(commerceOrder)" class="commerceOrderUpdate">
                      <ng-container
                        *ngIf="checkOrder(update, commerceOrder) && customerUserInfo[update.updaterId]?.getFullName()">
                        <ng-container *ngIf="commercePayment?.type != 'dispute'">
                        {{formatTimestamp(update.timestamp) | date:timeFormat: custom.timezone}}&nbsp;
                        {{formatSubStatus(update, commerceOrder.tempClient.updates)}}&nbsp;
                        {{customerUserInfo[update.updaterId]?.getFullName()}} | {{getMMDD(update.timestamp)}}
                        </ng-container>
                        <ng-container *ngIf="commercePayment?.type === 'dispute'">
                         <span clsss="dispute-text"> {{formatTimestamp(update.timestamp) | date:timeFormat: custom.timezone}}&nbsp;
                        {{formatSubStatus(update, commerceOrder.tempClient.updates)}}&nbsp;
                        {{customerUserInfo[update.updaterId]?.getFullName()}} | {{getMMDD(update.timestamp)}}
                        </span>
                        </ng-container>
                        <br>
                      </ng-container>
                    </ng-container>
                  </strong>
                </td>
              </ng-container>

              <ng-container matColumnDef="action" class="action">
                <th mat-header-cell *matHeaderCellDef class="action"> Action </th>
                <td mat-cell *matCellDef="let commerceOrder">
                  <ng-container *ngIf="isModifiable(commerceOrder)">
                    <button mat-raised-button color="primary" class="w-100 mb-1" *ngIf="commerceOrder.isCancellable()"
                      (click)="cancel(commerceOrder)">Cancel</button>
                    <button mat-raised-button color="primary" class="w-100 mb-1" *ngIf="commerceOrder.isCancelled()"
                      (click)="uncancel(commerceOrder)">Reactivate</button>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let commerceOrder" colspan="9"
                  [class.inactive]="!isExpanded(expandedOrderIds, commerceOrder)" class="expanded-summary">
                  @if (isExpanded(expandedOrderIds, commerceOrder)) {
                    <div class="payment-detail">
                      <table mat-table [dataSource]="commerceOrder.getCommercePayments()" class="w-100 suborder"
                        multiTemplateDataRows>
                        <ng-container matColumnDef="expander">
                          <th mat-header-cell *matHeaderCellDef></th>
                          <td mat-cell *matCellDef="let commercePayment">
                            <button mat-icon-button color="primary" style="visibility: hidden;">
                              <i class="material-icons">add_box</i>
                            </button>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="orderId">
                          <th mat-header-cell *matHeaderCellDef class="id"> Id </th>
                          <td mat-cell *matCellDef="let commercePayment" class="id">
                            <ng-container *ngIf="commercePayment?.type != 'dispute'">
                            <span
                              (click)="toggleExpand(expandedPaymentIds, commercePayment)">{{commercePayment._id}}</span>
                            </ng-container>
                            <ng-container *ngIf="commercePayment?.type === 'dispute'">
                              <span class="dispute-text"
                                (click)="toggleExpand(expandedPaymentIds, commercePayment)">{{commercePayment._id}}</span>
                            </ng-container>

                            &nbsp;<a class="detail-link" href="admin/{{paymentUrl}}/{{commercePayment._id}}" target="_blank">P</a>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="offerName">
                          <th mat-header-cell *matHeaderCellDef class="id"> Offer Name </th>
                          <td mat-cell *matCellDef="let commercePayment" class="id">
                            <ng-container *ngIf="commercePayment?.type != 'dispute'">
                              {{commerceOrder.commerceOffer.name}}<br>
                              ({{ commercePayment?.paymentInfo?.gateway }}/{{ commercePayment?.paymentInfo?.merchantProcessorId }})
                            </ng-container>
                            <ng-container *ngIf="commercePayment?.type === 'dispute'">
                            <span class="dispute-text">
                              {{commerceOrder.commerceOffer.name}}<br>
                              ({{ commercePayment?.paymentInfo?.gateway }}/{{ commercePayment?.paymentInfo?.merchantProcessorId }})
                            </span> 
                            </ng-container>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="dateTime">
                          <th mat-header-cell *matHeaderCellDef class="id"> Offer Date </th>
                          <td mat-cell *matCellDef="let commercePayment" class="id">
                            <span class="dispute-text">{{formatTimestamp(commercePayment.paymentTimestamp) |
                              date:timeFormat:custom.timezone}}</span><br />
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="authorized">
                          <th mat-header-cell *matHeaderCellDef class="amount"> Authorized </th>
                          <td mat-cell *matCellDef="let commercePayment" class="amount">
                            @if (isAuthorizeColumn(commerceOrder, commercePayment)) {
                              @if (commercePayment.price.amount > 0) {
                                {{ '$' + (commercePayment.price.amount | number : '1.2-2') }}
                              }
                              @else if (commercePayment.price.amount < 0) {
                                {{ '-$' +-commercePayment.price.amount }}
                              }
                              @else {
                                {{ '' }}
                              }
                            }
                            @else {
                              {{ '' }}
                            }
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="total">
                          <th mat-header-cell *matHeaderCellDef class="amount"> Billed </th>
                          <td mat-cell *matCellDef="let commercePayment" class="amount">
                            @if (commercePayment?.type != 'dispute' && commercePayment?.type != 'authorize') {
                              {{ (commercePayment.price.amount > 0 ? '$' + (commercePayment.price.amount | number : '1.2-2')  : '') }}
                            }
                            @else {
                              {{ '' }}
                            }
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="refunded">
                          <th mat-header-cell *matHeaderCellDef class="id"> Refunded </th>
                          <td mat-cell *matCellDef="let commercePayment" class="id">
                            <ng-container *ngIf="commercePayment?.type != 'dispute'">
                              @if (commercePayment.price.amount < 0 && !commerceOrder.isAuthorizeVoid(commercePayment)) {
                                {{ '-$' +-commercePayment.price.amount }}
                              }
                              @else {
                                {{ '' }}
                              }
                            </ng-container>
                            <ng-container *ngIf="commercePayment?.type === 'dispute'">
                              <span class="dispute-text">Dispute Request</span>
                              <p><span class="dispute-text dispute-bold">{{commercePayment.parentId}}</span></p>
                            </ng-container>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                          <th mat-header-cell *matHeaderCellDef> Status </th>
                          <td mat-cell *matCellDef="let commercePayment">
                            <ng-container *ngIf="commercePayment?.type != 'dispute'">
                            <span>{{formatTimestamp(commercePayment.paymentTimestamp) |
                              date:timeFormat:custom.timezone}}</span><br />
                            </ng-container>
                            <ng-container *ngIf="commercePayment?.type === 'dispute'">
                              <span class="dispute-text">{{formatTimestamp(commercePayment.paymentTimestamp) |
                                date:timeFormat:custom.timezone}}</span><br />
                            </ng-container>  
                            <ng-container *ngIf="commercePayment.status === 'fulfilled'">        
                              <ng-container *ngIf="commercePayment?.type != 'dispute'">
                              {{commercePayment.status}}
                              {{customerUserInfo[commercePayment.updaterId]?.getFullName()}}
                              </ng-container>                    
                              <ng-container *ngIf="commercePayment?.type != 'dispute'">
                                <span class="dispute-text">
                                  {{commercePayment.status}}
                              {{customerUserInfo[commercePayment.updaterId]?.getFullName()}}
                                </span>
                              </ng-container>
                            </ng-container>
                            <ng-container *ngIf="commercePayment.status !== 'fulfilled'">
                              {{ commercePayment?.paymentInfo?.params?.responsetext }}
                              <br />
                              {{ commercePayment?.paymentInfo?.params?.processor_id }}
                            </ng-container>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="action" class="action">
                          <th mat-header-cell *matHeaderCellDef class="action"> Action </th>
                          <td mat-cell *matCellDef="let commercePayment">
                            @if (commercePayment.isAdjustable()) {
                              @if (commercePayment?.type !== 'authorize') {
                                <button mat-raised-button color="primary" class="w-100 mb-1"
                                  (click)="openRefundPaymentModal(commerceOrder, commercePayment, 'refund', $event)">
                                  Refund
                                </button>
                              }

                              @if (isVoidable(commerceOrder, commercePayment)) {
                                <button mat-raised-button color="primary" class="w-100 mb-1"
                                  (click)="openRefundPaymentModal(commerceOrder, commercePayment, 'void', $event)">
                                  Void
                                </button>
                              }
                            }

                            @if (commercePayment?.type == 'dispute') {
                              <button mat-raised-button color="primary" class="w-100 mb-1"
                              *ngIf="commercePayment?.type == 'dispute'"
                              (click)="openChargebackModal(commerceOrder, commercePayment, 'chargeback', $event)">Represent</button>  
                            }
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail">
                          <td mat-cell *matCellDef="let commercePayment" colspan="9"
                            [class.inactive]="!isExpanded(expandedPaymentIds, commercePayment)" class="expanded-summary">
                            @if (isExpanded(expandedPaymentIds, commercePayment)) {
                              <div class="payment-detail">
                                <app-transaction-detail-v3 [commerceOrder]="commerceOrder" [commercePayment]="commercePayment"
                                  [userInfo]="customerUserInfo" (updateCommerceOrder)="updateCommerceOrder($event)"
                                  [uxComposite]="customerUxComposite" [isOrder]="false">
                                </app-transaction-detail-v3>
                              </div> 
                            }
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="paymentColumns"></tr>
                        <tr mat-row *matRowDef="let commercePayment; columns: paymentColumns;" class="payment-row"
                          [ngClass]="getPaymentClasses(commercePayment)"
                          [class.payment-expanded-row]="isExpanded(expandedPaymentIds, commercePayment)"></tr>
                        <tr mat-row *matRowDef="let commercePayment; columns: ['expandedDetail']"
                          class="payment-detail-row {{isExpanded(expandedPaymentIds, commercePayment) ? 'expanded' : ''}}">
                        </tr>
                      </table>
                    </div>
                  }
                </td>
              </ng-container>

              <ng-container matColumnDef="expandedDebug">
                <td mat-cell *matCellDef="let commerceOrder" colspan="9"
                  [class.inactive]="!isExpanded(expandedOrderDebugIds, commerceOrder)" class="expanded-summary">
                  @if (isExpanded(expandedOrderDebugIds, commerceOrder)) {
                    <div class="payment-detail">
                      <app-transaction-detail-v3 [commerceOrder]="commerceOrder"
                        [commercePayment]="findOrderPayment(commerceOrder)" [userInfo]="customerUserInfo"
                        (updateCommerceOrder)="updateCommerceOrder($event)" [uxComposite]="customerUxComposite"
                        [isOrder]="true">
                      </app-transaction-detail-v3>
                    </div>
                  }
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="paymentColumns"></tr>
              <tr mat-row *matRowDef="let commerceOrder; columns: paymentColumns;" class="payment-row"
                [ngClass]="getClasses(commerceOrder)"
                [class.payment-expanded-row]="isExpanded(expandedOrderIds, commerceOrder)"></tr>
              <tr mat-row *matRowDef="let commerceOrder; columns: ['expandedDebug']"
                class="payment-detail-row {{isExpanded(expandedOrderDebugIds, commerceOrder) ? 'expanded' : ''}}"></tr>
              <tr mat-row *matRowDef="let commerceOrder; columns: ['expandedDetail']"
                class="payment-detail-row {{isExpanded(expandedOrderIds, commerceOrder) ? 'expanded' : ''}}"></tr>
            </table>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card class="common-mat-card">
        <ng-container *ngIf="adminUxComposite">
          <app-csr-action-recommender [from]="customerUxComposite?.getUxcomp('comp.brand.customer.email')" [user]="user"
            [uxComposite]="adminUxComposite" [baseCommerceOrder]="baseCommerceOrder"
            [agentAddress]="customerUxComposite.getUxcomp('comp.brand.address.twoline')"
            [agentPhoneNumber]="customerUxComposite.getUxcomp('comp.brand.customer.phone')"
            [warningMessage]="adminUxComposite.getUxcomp('comp.brand.customer.warning.message')"
            [brandName]="user.draft.brandId" [noteData]="noteData" (onSuccess)="init(userId);">
          </app-csr-action-recommender>
        </ng-container>
      </mat-card>

      <mat-card class="common-mat-card">
        <div class="d-flex customer-content-schedules-actions">
          <p class="customer-content-subtitle mb0">Billing Schedules</p>
          <div class="ml-auto">
            <button mat-stroked-button color="primary" (click)="detailedSchedules = true" *ngIf="!detailedSchedules">
              <mat-icon>unfold_more</mat-icon> More Details
            </button>
            <button mat-stroked-button color="primary" (click)="detailedSchedules = false" *ngIf="detailedSchedules">
              <mat-icon>unfold_less</mat-icon> Fewer Details
            </button>
          </div>
        </div>
      </mat-card>
      <mat-card class="schedules">
        <mat-card-content>
          <div class="table-responsive">
            <table mat-table [dataSource]="filterCommerceSchedules()" class="w-100">

              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef class="saleType"> Type </th>
                <td mat-cell *matCellDef="let element" class="saleType">
                  {{element?.scheduleData?.type === paymentTypes.refund ? "Refund" : "Sale"}}</td>
              </ng-container>

              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef class="id"> ID / Order ID </th>
                <td mat-cell *matCellDef="let element" class="id">{{element._id}}<br />{{element.commerceOrderId}}</td>
              </ng-container>

              <ng-container matColumnDef="updater">
                <th mat-header-cell *matHeaderCellDef> Updater </th>
                <td mat-cell *matCellDef="let element">{{customerUserInfo[element.updaterId]?.getFullName()}}</td>
              </ng-container>

              <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef class="amount"> Amount </th>
                <td mat-cell *matCellDef="let element" class="amount">
                  <mat-form-field class="w-100 amount" appearance="outline" floatLabel="always">
                    <mat-label>Amount</mat-label>
                    <input matInput type="number" [placeholder]="'$'+element.scheduleData.amount"
                      [(ngModel)]="element.draft.scheduleData.amount">
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="scheduleDate">
                <th mat-header-cell *matHeaderCellDef> Schedule Date </th>
                <td mat-cell *matCellDef="let element">
                  <mat-form-field appearance="outline" class="w-100" floatLabel="always">
                    <mat-label>Schedule Date</mat-label>
                    <input matInput [matDatepicker]="picker"
                      [placeholder]="(formatTimestamp(element.dueTimestamp) | date:timeFormat:custom.timezone) + ' ' +custom.timezone"
                      [min]="minDate" [max]="maxDate" 
                      [selected]="formatTimestamp(element.draft.dueTimestamp)"
                      (dateChange)="reflectCommerceScheduleDate($event.value,element)">
                    <mat-datepicker-toggle matSuffix [for]="picker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="action"> Action </th>
                <td mat-cell *matCellDef="let element" class="action">
                  <button mat-raised-button color="primary" [disabled]="!isValidUpdateCommerceSchedule(element)"
                    (click)="updateCommerceSchedule(element)">Update</button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="scheduleColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: scheduleColumns;"></tr>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-card>
  </div>
</div>

import { Pipe, PipeTransform } from "@angular/core";
import diff from 'simple-text-diff';
import { LogUtils } from "src/common/utils/logUtils";

@Pipe({
    name: 'diffStringPipe',
    pure: true,
    standalone: false
})
export class DiffStringPipe implements PipeTransform {
  private lastValue: string;

  private lastPrevious: string;

  private lastShowBefore: boolean;

  private effectiveValue: string = null;

  transform(value: string, previous: string, ignore: boolean, defaultValue: string = '', showBefore: boolean = false) {
    if (ignore) {
      return !!value ? this.escapeHtmlTags(value) : defaultValue;
    }

    if (value === this.lastValue && previous === this.lastPrevious && showBefore === this.lastShowBefore) {
      return this.chooseReturnValue(value, defaultValue);
    }

    this.lastValue = value;
    this.lastPrevious = previous;
    this.lastShowBefore = showBefore;

    try {
      const safeValue = typeof value === 'string' && !!value
        ? value
        : defaultValue;

      const safePrevious = typeof previous === 'string' && !!value
        ? previous
        : defaultValue;

      this.effectiveValue = safeValue === safePrevious
        ? this.escapeHtmlTags(safeValue)
        : this.escapeHtmlTags(
          showBefore 
            ? diff.diffPatch(safePrevious, safeValue).before
            : diff.diffPatch(safePrevious, safeValue).after
        );
    } catch (e) {
      LogUtils.error('DiffStringPipe.transform error', e);

      this.effectiveValue = null;
    }

    return this.chooseReturnValue(value, defaultValue);
  }

  private chooseReturnValue(value: string, defaultValue: string) {
    return this.effectiveValue === null 
      ? !!value 
        ? this.escapeHtmlTags(value)
        : defaultValue
      : this.effectiveValue;
  }

  private escapeHtmlTags(html: string): string {
    // Replace all tags except <ins> and <del>
    return html.replace(/(<(?!del|\/del|ins|\/ins))|((?<!del|ins)>)/gi, (tag) => {
      return tag.replace(/</g, "&lt;").replace(/>/g, "&gt;");
    });
  }
}
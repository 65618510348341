import { Pipe, PipeTransform } from '@angular/core';
import moment from "moment";

@Pipe({
    name: 'formatTimestamp',
    standalone: false
})
export class DateFormatPipe implements PipeTransform {

  transform(timestamp: number): string {
    if (!timestamp) {
      timestamp = 0;
    }
    return moment(timestamp).tz('America/New_York').format("YYYY-MM-DD HH:mm:ss")
  }
}
import { Component, OnInit } from '@angular/core';
import { ServiceHelperService } from 'src/clientCommon/services/serviceHelper.service';
import { UxConfig } from '../../../common/models/ux/uxConfig';
import { UxLayout } from '../../../common/models/ux/uxLayout';
import { UxComponentCollection } from '../../../common/models/ux/uxComponentCollection';
import { UxComponent } from '../../../common/models/ux/uxComponent';
import { serverPaths } from 'src/common/helpers/pathHelpers';
import { MatDialog } from '@angular/material/dialog';
import { viewUxHistorySearchDialog } from '../customer/dialog.component';

@Component({
    selector: 'app-ux-history',
    templateUrl: './ux-history.component.html',
    styleUrls: ['./ux-history.component.scss'],
    standalone: false
})
export class UxHistoryComponent implements OnInit {

  results: {
    uxConfigs: UxConfig[]
    uxLayouts: UxLayout[]
    uxComponentCollections: UxComponentCollection[]
    uxComponents: UxComponent[]
  } = {
      uxConfigs: [],
      uxLayouts: [],
      uxComponentCollections: [],
      uxComponents: [],
    };

  tabs = {
    0: () => { },
    1: () => { },
    2: () => { },
    3: () => { }
  }

  tableData: any[] = [];
  currentStatus = 'uxcomp';
  uxc: any = {};
  allRecords: any = [];
  pagination: any = {};
  uxcDownloadIds: string[] = [];
  filter: any

  constructor(private serviceHelperService: ServiceHelperService, public dialog: MatDialog) {
    this.pagination = {
      totalPages: 1,
      currentPage: 1,
      totalRecords: 0,
      perPage: 25,
      hasNext: false,
      hasPrev: false
    }
    this.tabs[0] = this.getUxCompHistoryRecords;
  }

  ngOnInit(): void {
    this.getUxCompRecords(); return;
  }
  getUxCompHistoryRecords() {
    this.pagination = { ...this.pagination, currentPage: 1, totalPages: 1 };
    this.currentStatus = 'uxcomp'; 
    this.getUxCompRecords(); return;
  }
  getUxccHistoryRecords() {
    this.pagination = { ...this.pagination, currentPage: 1, totalPages: 1 };
    this.currentStatus = 'uxcc'; 
    this.getUxccRecords(); return;
  }
  getUxlHistoryRecords() {
    this.pagination = { ...this.pagination, currentPage: 1, totalPages: 1 };
    this.currentStatus = 'uxl'; 
    this.getUxlRecords(); return;
  }
  getUxcHistoryRecords() {
    this.pagination = { ...this.pagination, currentPage: 1, totalPages: 1 };
    this.currentStatus = 'uxc'; 
    this.getUxcRecords(); return;
  }

  getUxCompRecords() {
    this.serviceHelperService.jsonService.json(serverPaths.uxCompData, { param: {filter:this.filter, status: this.currentStatus, pagination: this.pagination } })
      .then((results: any) => {
        this.pagination = results.data;
        this.allRecords = results.docs;
      });
  }

  getUxccRecords() {
    this.serviceHelperService.jsonService.json(serverPaths.uxccData, { param: {filter:this.filter, status: this.currentStatus, pagination: this.pagination } })
      .then((results: any) => {
        this.pagination = results.data;
        this.allRecords = results.docs;
      })
  }

  getUxlRecords() {
    this.serviceHelperService.jsonService.json(serverPaths.uxlData, { param: {filter:this.filter, status: this.currentStatus, pagination: this.pagination } })
      .then((results: any) => {
        this.pagination = results.data;
        this.allRecords = results.docs;
      })
  }

  getUxcRecords() {
    this.serviceHelperService.jsonService.json(serverPaths.uxcData, { param: {filter:this.filter, status: this.currentStatus, pagination: this.pagination } })
      .then((results: any) => {
        this.pagination = results.data;
        this.allRecords = results.docs;
      })
  }

  searchModal() {
    const viewNoteModalRef = this.dialog.open(viewUxHistorySearchDialog, {
      width: '35%',
      data: {
        note: ''
      }
    });
    this.getData();
  }

  getData() {
    this.serviceHelperService.uxHistorySearchFilter$.subscribe((res: any) => {
      this.filter = {
        dateFilter: {
        }
      }
      if (res && (res.startdate || res.enddate)) {

        if (res.startdate) {
          this.filter.dateFilter["startDate"] = res.startdate
        }
        if (res.enddate) {
          this.filter.dateFilter["endDate"] = res.enddate
        }

      }
      if (this.currentStatus == 'uxcomp') {
        this.serviceHelperService.jsonService.json(serverPaths.uxCompData, { param: { filter: this.filter, status: this.currentStatus, pagination: this.pagination } })
          .then((results: any) => {
            this.pagination = results.data;
            this.allRecords = results.docs;
          });
      }
      else if (this.currentStatus == 'uxcc') {
        this.serviceHelperService.jsonService.json(serverPaths.uxccData, { param: { filter: this.filter, status: this.currentStatus, pagination: this.pagination } })
          .then((results: any) => {
            this.pagination = results.data;
            this.allRecords = results.docs;
          });
      }
      else if (this.currentStatus == 'uxl') {
        this.serviceHelperService.jsonService.json(serverPaths.uxlData, { param: { filter: this.filter, status: this.currentStatus, pagination: this.pagination } })
          .then((results: any) => {
            this.pagination = results.data;
            this.allRecords = results.docs;
          });
      }
      else if (this.currentStatus == 'uxc') {
        this.serviceHelperService.jsonService.json(serverPaths.uxcData, { param: { filter: this.filter, status: this.currentStatus, pagination: this.pagination } })
          .then((results: any) => {
            this.pagination = results.data;
            this.allRecords = results.docs;
          });
      }
    })
  }

  clearFilter() {
    this.filter = {
      dateFilter: {
      }
    }
    if (this.currentStatus == 'uxcomp') {
      this.serviceHelperService.jsonService.json(serverPaths.uxCompData, { param: { filter: this.filter, status: this.currentStatus, pagination: this.pagination } })
        .then((results: any) => {
          this.pagination = results.data;
          this.allRecords = results.docs;
        });
    }
    else if (this.currentStatus == 'uxcc') {
      this.serviceHelperService.jsonService.json(serverPaths.uxccData, { param: { filter: this.filter, status: this.currentStatus, pagination: this.pagination } })
        .then((results: any) => {
          this.pagination = results.data;
          this.allRecords = results.docs;
        });
    }
    else if (this.currentStatus == 'uxl') {
      this.serviceHelperService.jsonService.json(serverPaths.uxlData, { param: { filter: this.filter, status: this.currentStatus, pagination: this.pagination } })
        .then((results: any) => {
          this.pagination = results.data;
          this.allRecords = results.docs;
        });
    }
    else if (this.currentStatus == 'uxc') {
      this.serviceHelperService.jsonService.json(serverPaths.uxcData, { param: { filter: this.filter, status: this.currentStatus, pagination: this.pagination } })
        .then((results: any) => {
          this.pagination = results.data;
          this.allRecords = results.docs;
        });
    }

  }

  onNextPage() {
    if (!this.pagination.hasNext) return;
    this.pagination.currentPage++;

    if (this.currentStatus == 'uxcomp') {
      this.getUxCompRecords();
    }
    else if (this.currentStatus == 'uxcc') {
      this.getUxccRecords();
    }
    else if (this.currentStatus == 'uxl') {
      this.getUxlRecords();
    }
    else if (this.currentStatus == 'uxc') {
      this.getUxcRecords();
    }
  }

  onSelectChange(event) {
    this.pagination = { ...this.pagination, currentPage: 1, totalPages: 1, perPage: event.perPage };
    if (this.currentStatus == 'uxcomp') {
      this.getUxCompRecords();
    }
    else if (this.currentStatus == 'uxcc') {
      this.getUxccRecords();
    }
    else if (this.currentStatus == 'uxl') {
      this.getUxlRecords();
    }
    else if (this.currentStatus == 'uxc') {
      this.getUxcRecords();
    }
  }

  onPrevPage() {
    if (!this.pagination.hasPrev) return;
    this.pagination.currentPage--;
    if (this.currentStatus == 'uxcomp') {
      this.getUxCompRecords();
    }
    else if (this.currentStatus == 'uxcc') {
      this.getUxccRecords();
    }
    else if (this.currentStatus == 'uxl') {
      this.getUxlRecords();
    }
    else if (this.currentStatus == 'uxc') {
      this.getUxcRecords();
    }
  }

  test(event) {
    switch (event.index) {
      case 0:
        this.getUxCompHistoryRecords();
        break;
      case 1:
        this.getUxccHistoryRecords();
        break;
      case 2:
        this.getUxlHistoryRecords();
        break;
      case 3:
        this.getUxcHistoryRecords();
        break;
    }
  }
}

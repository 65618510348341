import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {JsonService} from '../../clientCommon/services/json.service';
import {User} from '../../common/models/user/user';
import {ResponseEvent} from '../../common/event/responseEvent';
import {objectUtils} from '../../common/utils/objectUtils';
import {ActivatedRoute, Router} from '@angular/router';
import {SpinnerService} from '../../clientCommon/services/spinner.service';
import {adminPaths, serverPaths} from '../../common/helpers/pathHelpers';
import {inputUtils} from '../../clientCommon/utils/inputUtils';
import {LogUtils} from '../../common/utils/logUtils';
import {CsrAuthGuard} from '../guards';
import {BaseDirective} from '../../clientCommon/directives/BaseDirective';
import {ServiceHelperService} from '../../clientCommon/services/serviceHelper.service';
import {configUtils} from '../utils/ConfigUtils';
import {ModelBase} from '../../common/models/modelBase';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, map,} from 'rxjs/operators';
import {CommerceOrder} from '../../common/models/commerce/commerceOrder';
import {customerSearchUtils, CustomerSearchUtils} from '../utils/CustomerSearchUtils';
import {AddressUtils, AddressField} from '../../common/utils/addressUtils';
import {Location} from "@angular/common"
import { CommercePayment } from 'src/common/models/commerce/commercePayment';

@Component({
    templateUrl: './orders.component.html',
    styleUrls: ['orders.component.scss'],
    standalone: false
})

export class OrdersComponent extends BaseDirective implements OnInit {
  adminPaths = adminPaths;
  addressField: AddressField = AddressUtils.getAddressField("US");
  states = [];

  commerceOrders: CommerceOrder[] = [];
  filteredOrders: CommerceOrder[] = [];
  input: any = {
    //index: {},
    payment: {},
  };
  prevInput: any = {};
  more = false;
  brands = [];

  smartSearch = '';
  onSmartSearch = false;
  smartSearchCount = 0;
  returnedSmartSearchCount = 0;


  userColumns = [
    'brand', 'paymentId', 'amount', 'payerId', 'type', 'status', 'subStatus', 'commerceOfferId', 'commerceTokenId', 'timeStamp'
  ];

  findOrdersInput: any = {};

  @ViewChild('smartSearchInput', {static: true}) smartSearchInput: ElementRef;

  constructor(public serviceHelperService: ServiceHelperService,
              public activatedRoute: ActivatedRoute,
              private jsonService: JsonService,
              private csrAuthGuard: CsrAuthGuard,
              private route: ActivatedRoute,
              public snackBar: MatSnackBar,
              private spinnerService: SpinnerService,
              private router: Router,
              private location: Location) {
    super(serviceHelperService, activatedRoute);
  }

  ngOnInit() {
    return super.baseInit().then(() => {
      const allStateObj = {"code": "all", "name":"All States"};
      this.states = [allStateObj, ...this.addressField.getAdministrativeAreaOptionsArray()];
      fromEvent(this.smartSearchInput.nativeElement, 'keyup').pipe(
        map((event: any) => {
          return event.target.value;
        }),
        debounceTime(200),
        distinctUntilChanged(),
      ).subscribe((text: string) => {
        this.parseSmartSearchStringToQuery();
        if (this.findOrdersInput.flag) {
          this.searchSmart(this.findOrdersInput, false);
        } else {
          this.commerceOrders = [];
          this.filteredOrders = this.commerceOrders;
        }
      });
      return this.init();
    }).then(() => {
      this.initDone = true;
    });
  }

  init() {
    return Promise.resolve().then(() => {
      this.brands.push({
        id: "all",
        name: "All Brands",
        abb: "all",
      });
      this.brands.push(...configUtils.getBrands(this.uxComposite));
      this.populateSearch();
    }).then(() => {
      this.activatedRoute.queryParams.subscribe((params) => {
        if (params.brand) {
          this.input.user.brandId = params.brand;
        }
      });
    });
  }

  populateSearch() {
    const fullPath = this.location.path(true);
    const params = fullPath.split("?smartsearch=");
    if (params.length > 1) {
      const query = params[1];
      this.smartSearch = decodeURIComponent(query);
      this.parseSmartSearchStringToQuery();
      if (this.findOrdersInput.flag) {
        this.searchSmart(this.findOrdersInput, false);
      } else {
        this.commerceOrders = [];
        this.filteredOrders = this.commerceOrders;
      }
    }
  }

  isValid() {
    let flag = false;

    if (this.input.order.type ||
      this.input.order.subtype ||
      this.input.order.amount ||
      this.input.order.brandId ||
      this.input.order.from || this.input.order.to ) {
      flag = true;
    }

    return flag;
  }

  search(input, aggregate?: boolean) {
    objectUtils.deleteEmptyProperties(input);
    this.prevInput = objectUtils.clone(input);
    this.spinnerService.spin();
    this.jsonService.json('/' + serverPaths.manageCsrFindOrders, input).then((responseEvent: ResponseEvent) => {
      const commerceOrders = responseEvent.getDocs();
      commerceOrders.sort((a, b) => {
        return b.createdTimestamp - a.createdTimestamp;
      });

      if (aggregate === true) {
        this.commerceOrders = this.commerceOrders.concat(commerceOrders);
      } else {
        this.commerceOrders = commerceOrders;
      }

      this.filteredOrders = this.commerceOrders;
      this.more = !!responseEvent.data.extraResult.total;
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  searchSmart(smartInput, aggregate?: boolean) {
    this.smartSearchCount++;
    const currentSmartSearchCount = this.smartSearchCount;
    this.onSmartSearch = true;
    this.prevInput = objectUtils.clone(smartInput);
    this.jsonService.json('/' + serverPaths.manageCsrFindOrdersSmart, smartInput).then((responseEvent: ResponseEvent) => {
      if (this.returnedSmartSearchCount <= currentSmartSearchCount) {
        this.returnedSmartSearchCount = currentSmartSearchCount;
        const commerceOrders = responseEvent.getDocs();
        commerceOrders.sort((a, b) => {
          return b.createdTimestamp - a.createdTimestamp;
        });

        if (aggregate === true) {
          this.commerceOrders = this.commerceOrders.concat(commerceOrders);
        } else {
          this.commerceOrders = commerceOrders;
        }
        this.filteredOrders = this.commerceOrders;
        // TODO
        this.filterOrders();
        this.more = !!responseEvent.data.extraResult.total;
      }
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      if (this.smartSearchCount === currentSmartSearchCount) {
        this.onSmartSearch = false;
      }
    });
  }

  getUserClass(user: User) {
    if (user?.tempClient?.commerceOrder?.status === ModelBase.STATUS.inactive) {
      return 'inactive';
    } else if (user?.tempClient?.commerceOrder?.status === ModelBase.STATUS.failed) {
      return 'failed';
    } else if (user?.tempClient?.commerceOrder?.subStatus === CommerceOrder.SUB_STATUS.cancelled) {
      return 'cancelled';
    } else if (user?.tempClient?.commerceOrder?.status === ModelBase.STATUS.active) {
      return 'active';
    } else {
      return 'else';
    }
  }

  keyDownEvent(event) {
    if (inputUtils.isEnterEvent(event)) {
      return this.search(this.input);
    }
  }

  keyDownSmartSearchEvent(event) {
    if (inputUtils.isEnterEvent(event)) {
        return this.search(this.input);
    }

    // if (inputUtils.isEnterEvent(event)) {
    //   if (this.commercePayments.length === 1) {
    //     this.router.navigate([adminPaths.commercePayment + '/' + this.commercePayments[0]._id]);
    //   }
    // }
  }

  loadMore() {
    if (!this.prevInput.page) {
      this.prevInput.page = 0;
    }

    this.prevInput.page++;
    if (this.prevInput?.flag) {
      return this.searchSmart(this.prevInput, true);
    } else {
      return this.search(this.prevInput, true);
    }
  }


  getBrandAbbr(brandId) {
    const brand = this.brands.find(brand => brand.id === brandId);
    if (brand) {
      return brand.abb;
    }
    return '';
  }

  getBrandName(brandId) {
    const brand = this.brands.find(brand => brand.id === brandId);
    if (brand) {
      return brand.name;
    }
    return '';
  }

  getBrandId(brandAbb) {
    const brand = this.brands.find(brand => brand.abb.toLowerCase() === brandAbb?.toLowerCase());
    return brand?.id;
  }


  getDate(timestamp) {
    if (timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleDateString();
    }
  }

  createRecursiveSearchQuery(commerceOrder: CommerceOrder) {
    const findCustomerInput = {
      flag: false,
      order: [{
        index: [],
      }],
      commerceToken: [{
        bin: [],
      }],
    };
    findCustomerInput.order[0].index.push('^type:' + commerceOrder.type);
    findCustomerInput.order[0].index.push('^substatus:' + commerceOrder.subStatus);
    findCustomerInput.order[0].index.push('^amount:' + commerceOrder.commerceOfferDetail.price.amount);
    findCustomerInput.order[0].index.push('^date:' + commerceOrder.createdTimestamp);

    this.spinnerService.spin();
    this.jsonService.json('/' + serverPaths.manageCsrFindOrdersSmart, findCustomerInput).then((responseEvent: ResponseEvent) => {
      const responsePayments = responseEvent.getDocs();
      const rPayments = responsePayments.filter((u) => !this.commerceOrders.find(ou => {
        return ou._id === u._id
      })).sort((a, b) => {
        return b.createdTimestamp - a.createdTimestamp;
      });
      rPayments.forEach((u) => {
        u.tempClient.recursive = true;
      })
      const paymentIndex = this.commerceOrders.findIndex((u) => u._id === commerceOrder._id);
      const modifiedPayments = this.commerceOrders;
      if (paymentIndex > -1) {
        modifiedPayments.splice(paymentIndex + 1, 0, ...rPayments);
      }
      this.commerceOrders = [...modifiedPayments];
      this.filteredOrders = this.commerceOrders;
      this.spinnerService.unspin();
    }).catch((e) => {
      LogUtils.debug(e);
      this.spinnerService.unspin();
    })
  }

  parseSmartSearchStringToQuery(search?: string) {
    let text = this.smartSearch;
   
    if (search) {
      text = search;
    }
    let queries = [];
    if (text) {
      text = text.replace(/  */g, ' ');
      queries = text.split(' ');
    }

    this.findOrdersInput = {
      flag: false,
      order: [],
    };

    queries.forEach(query => {
      if (query) {
        if (query.indexOf('|') > 0) {
          let split = query.split('|');

          if (split[1].length > 0) {
            if (split[0].toLowerCase() === 'id') {
                this.findOrdersInput.flag = true;
                this.findOrdersInput.order.push({id: [`^${split[1]}`]});
            }else if (split[0].toLowerCase() === 'type') {
                this.findOrdersInput.flag = true;
                this.findOrdersInput.order.push({type: [split[1]]});
            } else if(split[0].toLowerCase() === 'substatus') {
                this.findOrdersInput.flag = true;
                this.findOrdersInput.order.push({substatus: [split[1]]});
            }else if(split[0].toLowerCase() === 'amount') {
                this.findOrdersInput.flag = true;
                this.findOrdersInput.order.push({amount: [split[1]]});
            } else if(split[0].toLowerCase() === 'brandid') {
                this.findOrdersInput.flag = true;
                this.findOrdersInput.order.push({brandId: [`^${split[1]}`]});
            } else if(split[0].toLowerCase() === 'from') {
              this.findOrdersInput.flag = false;
              if (split[1].length == 13 || split[1].length == 19) {
                if (split[1].length == 13) {
                  if (this.isAllDigits(split[1])) {                    
                    this.findOrdersInput.order.push({from: [split[1]]});                   
                  }
                }else {
                  if (this.isValidDateTimeFormat(split[1])) {                    
                    this.findOrdersInput.order.push({from: [this.convertToTimestamp(split[1])]});                    
                  }
                }
              }
            } else if(split[0].toLowerCase() === 'to') {
              // 날짜 체크  : timestamp (1718134904631), datetime (2024-01-12_12:01:11)
              if (split[1].length == 13 || split[1].length == 19) {
                if (split[1].length == 13) {
                  if (this.isAllDigits(split[1])) {                    
                    this.findOrdersInput.order.push({to: [split[1]]});
                    if (this.findOrdersInput.order.some(value => value.hasOwnProperty('from'))) {
                      this.findOrdersInput.flag = true;
                    }else {
                      this.findOrdersInput.flag = false;
                    }
                  }else {
                    this.findOrdersInput.flag = false;
                  }
                }else {
                  if (this.isValidDateTimeFormat(split[1])) {                    
                    this.findOrdersInput.order.push({to: [this.convertToTimestamp(split[1])]});
                    if (this.findOrdersInput.order.some(value => value.hasOwnProperty('from'))) {
                      this.findOrdersInput.flag = true;
                    }else {
                      this.findOrdersInput.flag = false;
                    }
                  }else {
                    this.findOrdersInput.flag = false;
                  }
                }
              }else {
                this.findOrdersInput.flag = false;
              }
            }
          }           
        } 
      }
    });
  }

  convertToTimestamp(dateTimeStr: string): number {
    const formattedStr = dateTimeStr.replace('_', ' ');
    const dateObj = new Date(formattedStr);
    return dateObj.getTime();
  }

  isValidDateTimeFormat(input: string): boolean {
    const dateTimeRegex = /^\d{4}-\d{2}-\d{2}_\d{2}:\d{2}:\d{2}$/;
    return dateTimeRegex.test(input);
  }

  isAllDigits(input: string): boolean {
    const regex = /^\d+$/;
    return regex.test(input);
  }

  viewDetailPage(commerceOrder: CommerceOrder) {
    //if (!this.csrAuthGuard.canActivatePath(adminPaths.csrCustomer)) return;
    window.open(adminPaths.commerceOrder + '/' + commerceOrder._id);
  }

  filterOrders() {
    let results = this.commerceOrders;
    if (this.input.order.brandId) {
      if (this.input.order.brandId !== 'all') {
        results = results.filter(u => u.brandId === this.input.order.brandId);
      }
    }
    // if (this.input.user.state) {
    //   if (this.input.user.state !== 'all') {
    //     results = results.filter(u => {
    //       return !!u.tempClient.commerceTokens.find((token) => token.billingAddress.state === this.input.user.state);
    //     })
    //   }
    // }
    this.filteredOrders = results;
  }

  getStatus(order: CommerceOrder) {
    if (CommerceOrder.STATUS_MAPPING[`${order.status}|${order.subStatus}`]) {
      return CommerceOrder.STATUS_MAPPING[`${order.status}|${order.subStatus}`];
    } else if (order.subStatus === CommerceOrder.SUB_STATUS.none) {
      return 'active';
    } else {
      return order.subStatus;
    }
  }

  onSmartSearchChange(value) {
    this.smartSearch = value;
  }

  getClassStatus(status) {
    if (status === 'rejected') {
      return 'tg-bg-color';
    }else if(status === 'fulfilled') {
      return 'tg-green-color';
    }else {
      return 'tg-white-color';
    }
  }

  getClassOrderStatus(status) {
    if (status === 'inactive') {
      return 'tg-bg-color';
    }else if(status === 'active') {
      return 'tg-green-color';
    }else if(status === 'failed') {
      return 'tg-fail-color';
    }else {
      return 'tg-white-color';
    }
  }
}

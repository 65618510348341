import {Injectable} from "@angular/core";

@Injectable()
export class StorageService {
  storage: any = {};
  tempStorage: any = {};

  constructor() {
  }
  /**
   *
   * @param key a uxcomp key of pageCategory
   * storing the key to localstorage if no key already exists in storage
   */
  get(key) {
    if (!this.storage[key]) {
      this.storage[key] = JSON.parse(localStorage.getItem(key));
    }

    return this.storage[key];
  }
  getItem(key) {
    return localStorage.getItem(key);
  }
/**
 *
 * @param key a uxcomp key of pageCategory
 * @param value content value to set in localStorage
 * storing the key and value in the localstorage
 */
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }
  setItem(key, value) {
    localStorage.setItem(key, value);
  }

  removeItem(key) {
    localStorage.removeItem(key);
  }


  /**
   *
   * @param key a uxcomp key of pageCategory
   * returning the item with param key from localStorage
   */
  getSession(key) {
    return JSON.parse(sessionStorage.getItem(key));
  }
/**
 *
 * @param key a uxcomp of pageCategory
 * @param value content value to set in sessionStorage
 * setting the param key and value in sessionStorage
 */
  setSession(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
  
  setSessionItem(key, value) {
    sessionStorage.setItem(key, value);
  }

  removeSession(key) {
    sessionStorage.removeItem(key);
  }
/**
 *
 * @param key a uxcomp key of pageCategory
 * returning the value at current key from storage
 */
  getTemp(key) {
    return this.storage[key];
  }
/**
 *
 * @param key a uxcomp key of pageCategory
 * @param value content value to set at the current key
 * setting the value to current key in storage
 */
  setTemp(key, value) {
    this.storage[key] = value;
  }

}

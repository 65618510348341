import { idProtectionProductKeys, idProtectionProviderTypes } from "../../common/custom/idProtection/idProtectionKeys";
import { UxComposite } from "../../common/models/ux/uxComposite";


function createIdProtectionContentInfo(uxComposite: UxComposite) {
  /**  
   * if any idProtection products available add then add one of it in content info, so then-
   * on signup content processor for idProtection will be executed
   */
  let products = [];

  for (let key in idProtectionProductKeys) {
    const productIds = uxComposite.getUxcomp(`comp.member.${key}.product.ids`);

    if (productIds && Array.isArray(productIds) && productIds?.length > 0) {
      products.push(productIds[0]);
    }
  }

  if (products.length > 0) {
    return {
      productId: products[0],
      productKey: idProtectionProviderTypes.customerEnrollment,
      data: {},
    };
  }

  return null;
}

export const IdProtectionHelper = {
  createIdProtectionContentInfo,
};

<div class="action-recommender">
  <div class="action-recommender__panel">
    <h2 class="action-recommender__panel--title">Action Recommender</h2>
    <div class="action-recommender__panel--content">
      <strong class="action-recommender__panel--subtitle">General Instructions</strong>
      <div class="action-recommender__panel--subpanel">
        <ng-container *ngIf="!instructionTexts.length">No Instruction Yet</ng-container>
        <p *ngFor="let instructionText of instructionTexts" [innerHTML]="instructionText"></p>
      </div>
      <strong class="action-recommender__panel--subtitle">Instructions for Refund Request</strong>
      <div class="action-recommender__panel--subpanel">
        <span class="action-recommender__panel--subtext">{{ refundType ? refundType : 'No Refund Type Yet' }}</span>
        <div class="action-recommender__panel--refund">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Refund Amount</mat-label>
            <input matInput type="number" [ngModel]="refundAmount" (ngModelChange)="handleChangeRefundAmount($event)" placeholder="Refund Amount">
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-select [ngModel]="refundAmount" (ngModelChange)="handleChangeRefundAmount($event)">
              <mat-option [value]="scheduledAmount">1x scheduled</mat-option>
              <mat-option [value]="scheduledAmount * 2">2x scheduled</mat-option>
              <mat-option [value]="scheduledAmount * 3">3x scheduled</mat-option>
              <mat-option [value]="scheduledAmount * 4">4x scheduled</mat-option>
              <mat-option [value]="allAmount">All scheduled</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex">
        <div class="action-recommender__panel--mood">
          <h3>Mood</h3>
          <mat-radio-group aria-label="Mood" color="primary" [ngModel]="customerMood" (ngModelChange)="handleMoodChange($event)">
            <mat-radio-button value="N">Normal Mood</mat-radio-button>&nbsp;&nbsp;&nbsp;
            <mat-radio-button value="D">Determined</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="action-recommender__panel--type">
          <h3>Type</h3>
          <mat-radio-group aria-label="Type" color="primary"
            [ngModel]="customerType"
            (ngModelChange)="handleTypeChange($event)">
            <mat-radio-button value="C" [checked]='true'>Call</mat-radio-button>&nbsp;&nbsp;&nbsp;
            <mat-radio-button value="E">Email</mat-radio-button>&nbsp;&nbsp;&nbsp;
            <mat-radio-button value="PO">Post Office</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="action-recommender__panel--options">
        <ng-container *ngFor="let category of scenariosByCategory; let i = index;">
          <div class="action-recommender__panel--option" *ngIf="category.length" [class.bottom-line]="categoryNames.length > 3">
            <h3 class="action-recommender__panel--optiontitle">{{categoryNames[i]}}</h3>
            <div *ngFor="let scenario of category">
              <mat-checkbox [checked]="isChecked(scenario.generalInstructionName)" color="primary" [value]="scenario.generalInstructionName" (change)="handleUserAction($event)">{{scenario.description}}</mat-checkbox>
              <ng-container *ngIf="isChecked(scenario.generalInstructionName) && scenario.note">
                <input matInput type="text" [(ngModel)]="testimonials[scenario.generalInstructionName]" [placeholder]="scenario.description">
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="action-recommender__panel">
    <h2 class="action-recommender__panel--title" id="email-worksheet">
      Email Worksheet
      <span><button mat-raised-button color="primary" (click)="handleSaveEmails(true)" [disabled]="!canSave || !validEmails(false)">Save</button></span>
      <span *ngIf="outboundEmailEnabled"><button mat-raised-button color="primary" (click)="handleSendEmails(true)" [disabled]="!canSave || !validEmails(true)">Save & Send Email</button></span>
      <span *ngIf="isShowMessage" class="warning-message" [innerHTML]="warningMessage"></span>
    </h2>
    <div class="action-recommender__panel--content">
      <div class="action-recommender__panel--email" *ngIf="outboundEmailEnabled">
        <div>
          <div class="action-recommender__panel--header">
            <span class="label">From:</span>
            <mat-form-field appearance="outline" floatLabel="always" class="w-100">
              <mat-label>From</mat-label>
              <input matInput type="email" [(ngModel)]="from" placeholder="From">
            </mat-form-field>
          </div>
          <div class="action-recommender__panel--header">
            <span class="label">To:</span>
            <mat-form-field appearance="outline" floatLabel="always" class="w-100">
              <mat-label>To</mat-label>
              <input matInput type="email" [(ngModel)]="to" placeholder="To">
            </mat-form-field>
          </div>
          <div class="action-recommender__panel--header">
            <span class="label">Subject:</span>
            <mat-form-field appearance="outline" floatLabel="always" class="w-100">
              <mat-label>Subject</mat-label>
              <input matInput type="text" [(ngModel)]="subject" placeholder="Subject">
            </mat-form-field>
          </div>
        </div>
        <editor [init]="{
            height: 500,
            menubar: true,
            plugins: 'advlist autolink lists link image charmap preview anchor searchreplace visualblocks code insertdatetime media table help wordcount',
            toolbar:
              'undo redo | formatselect | image bold italic | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent paste link | help'
          }" 
          [(ngModel)]="baseTemplate"></editor>
      </div>
      <div *ngIf="!outboundEmailEnabled" class="action-recommender__panel--email" [innerHTML]="baseTemplate"></div>
      <!-- <p *ngFor="let email of emailList" class="action-recommender__panel--email" [innerHTML]="email"></p> -->
    </div>
  </div>
</div>

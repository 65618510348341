import {Injectable} from '@angular/core';
import {CrudService} from '../../clientCommon/services';
import {ModelBase} from '../../common/models/modelBase';
import {ResponseEvent} from '../../common/event/responseEvent';
import {LogUtils} from '../../common/utils/logUtils';
import {SpinnerService} from '../../clientCommon/services/spinner.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {objectUtils} from '../../common/utils/objectUtils';
import {StorageService} from '../../clientCommon/services/storage.service';
import {inputUtils} from '../../clientCommon/utils/inputUtils';
import {clipboardUtils} from '../../clientCommon/utils/clipboardUtils';


export class CrudHelperSearchOptions {
  key = 'crudHelperSearchOptions ';
  filters: {
    [filter: string]: {
      className,
      fields: string[],
      value: string,
      additionalFields?: {
        [key: string]: any,
      },
    }
  } = {};
  sort?: any;
  removed?: boolean | null;
}

@Injectable()
export class CrudHelperService {


  constructor(private crudService: CrudService,
              private spinnerService: SpinnerService,
              private storageService: StorageService,
              private snackBar: MatSnackBar) {
  }

  add(obj: ModelBase<any>, alertDuration: number, searchOptions: CrudHelperSearchOptions, results) {
    this.spinnerService.spin();
    return this.crudService.create(CrudService.URL.admin, obj).then((response: ResponseEvent) => {
      this.snackBar.open('Adding done. ' + obj.draft.name, 'Add', {duration: alertDuration});
      return this.search(searchOptions, results).then(() => {
        this.snackBar.open(response.data[0], 'ID', {duration: alertDuration});
      }).then(() => {
        return response.data[0];
      });
    }).catch((e) => {
      LogUtils.error(e);
      this.snackBar.open('Error adding ' + obj.draft.name, 'Error', {duration: alertDuration});
      this.spinnerService.unspin();
      return Promise.reject(e);
    }).then((id) => {
      this.spinnerService.unspin();
      return id;
    });
  }

  addDefault(obj: ModelBase<any>, alertDuration: number, searchOptions: CrudHelperSearchOptions, results) {
    if (obj.draft.name) {
      obj.draft.status = ModelBase.STATUS.active;
      return this.add(obj, alertDuration, searchOptions, results).then(() => {
        // do nothing
      });
    } else {
      this.snackBar.open('Error adding ' + obj.draft.name, 'Error', {duration: alertDuration});
    }

  }

  search(searchOptions: CrudHelperSearchOptions, results): Promise<any> {
    this.storageService.set(searchOptions.key, searchOptions.filters);

    const promises = [];
    const baseSelectQuery: any = {
      status: searchOptions?.removed === true 
        ? ModelBase.STATUS.removed
        : ModelBase.STATUS.active,
    };
    let options: {objMeta: any, sort?: any} = { objMeta: { maxDepth: 0 } };
    if (searchOptions.sort) {
      options.sort = searchOptions.sort;
    }
    this.spinnerService.spin();

    if (searchOptions.filters) {
      Object.keys(searchOptions.filters).forEach((key) => {
        const filter = searchOptions.filters[key];
        const className = filter.className;
        results[className] = [];
        if (className && filter.value) {
          filter.fields?.forEach((field) => {
            if (filter.value || filter.additionalFields) {
              const selectQuery = objectUtils.clone(baseSelectQuery);

              if (filter.value) {
                selectQuery[field] = `/${filter.value}/i`;
              }

              if (filter.additionalFields) {
                for (const key in filter.additionalFields) {
                  if (filter.additionalFields[key] !== undefined 
                    && filter.additionalFields[key] !== null
                  ) {
                    selectQuery[key] = filter.additionalFields[key];
                  }
                }
              }

              promises.push(this.crudService.read(CrudService.URL.admin, className, selectQuery, options).then((result) => {
                result?.forEach((doc) => {
                  if (doc.draft.options) {
                    doc.draft.tempClient.optionsString = JSON.stringify(doc.draft.options);
                  }
                });
                if (result) {
                  results[className] = results[className].concat(result);
                }
              }));
            }
          });
        }
      });
    }

    return Promise.all(promises).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      // Dedupe
      Object.keys(results)?.forEach((key) => {
        results[key] = results[key].filter((item, index) => {
          return index === results[key].findIndex((item2) => {
            return item._id === item2._id;
          });
        });
      });
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  searchAndHistory(searchOptions: CrudHelperSearchOptions, results, historys): Promise<any> {
    this.storageService.set(searchOptions.key, searchOptions.filters);

    const promises = [];
    const baseSelectQuery: any = {status: ModelBase.STATUS.active};
    let options: {objMeta: any, sort?: any} = { objMeta: { maxDepth: 0 } };
    if (searchOptions.sort) {
      options.sort = searchOptions.sort;
    }
    this.spinnerService.spin();

    if (searchOptions.filters) {
      Object.keys(searchOptions.filters).forEach((key) => {
        const filter = searchOptions.filters[key];
        const className = filter.className;
        results[className] = [];
        if (className && filter.value) {
          filter.fields?.forEach((field) => {
            if (filter.value || filter.additionalFields) {
              const selectQuery = objectUtils.clone(baseSelectQuery);

              if (filter.value) {
                selectQuery[field] = `/${filter.value}/i`;
              }

              if (filter.additionalFields) {
                for (const key in filter.additionalFields) {
                  if (filter.additionalFields[key] !== undefined 
                    && filter.additionalFields[key] !== null
                  ) {
                    selectQuery[key] = filter.additionalFields[key];
                  }
                }
              }

              promises.push(this.crudService.read(CrudService.URL.admin, className, selectQuery, options).then((result) => {
                result?.forEach((doc) => {
                  if (doc.draft.options) {
                    doc.draft.tempClient.optionsString = JSON.stringify(doc.draft.options);
                  }
                });
                if (result) {
                  results[className] = results[className].concat(result);
                }
              }));
            }
          });
        }
      });
    }

    return Promise.all(promises).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      // Dedupe
      Object.keys(results)?.forEach((key) => {
        results[key] = results[key].filter((item, index) => {
          return index === results[key].findIndex((item2) => {
            return item._id === item2._id;
          });
        });
      });
    }).then(() => {
      this.spinnerService.unspin();
    });
  }


  keyDownEvent(event, searchOptions: CrudHelperSearchOptions, results) {
    if (inputUtils.isEnterEvent(event)) {
      return this.search(searchOptions, results);
    }
  }

  copyId(obj, alertDuration: number) {
    if (obj) {
      this.copyTextToClipboard(obj._id, alertDuration);
    }
  }

  copyName(obj, alertDuration: number) {
    if (obj) {
      this.copyTextToClipboard(obj.name, alertDuration);
    }
  }

  copyTextToClipboard(text, alertDuration: number) {
    if (clipboardUtils.copyTextToClipboard(text)) {
      this.snackBar.open(`${text}`, 'Copy', {duration: alertDuration});
    } else {
      this.snackBar.open(`${text}`, 'Fail', {duration: alertDuration});
    }
  }

  update(obj: ModelBase<any>, alertDuration, searchOptions: CrudHelperSearchOptions, results) {
    this.spinnerService.spin();
    return this.crudService.updateDoc(CrudService.URL.admin, [obj]).then((result) => {
      this.snackBar.open('updating done. ' + obj.draft.name, 'Update', {duration: alertDuration});
    }).then(() => {
      return this.search(searchOptions, results);
    }).catch((e) => {
      this.snackBar.open('Error updating ' + obj.draft.name, 'Error', {duration: alertDuration});
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

}

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'jsonBeautifierPipe',
    pure: true,
    standalone: false
})
export class JsonBeautifierPipe implements PipeTransform {
  private lastValue: string;

  private effectiveValue: string = null;

  transform(value: string, apply: boolean) {
    if (!apply) {
      return value;
    }

    if (this.lastValue === value) {
      return this.effectiveValue === null
        ? value
        : this.effectiveValue;
    }

    this.lastValue = value;

    try {
      this.effectiveValue = JSON.stringify(JSON.parse(value), null, 4);
    } catch (e) {
      this.effectiveValue = null;
    }

    return this.effectiveValue === null
      ? value
      : this.effectiveValue;
  }
}
import { Component, OnInit } from '@angular/core';
import { CrudService } from '../../clientCommon/services/crud.service';
import { LogUtils } from '../../common/utils/logUtils';
import { SpinnerService } from '../../clientCommon/services/spinner.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StorageService } from '../../clientCommon/services/storage.service';
import { inputUtils } from '../../clientCommon/utils/inputUtils';
import { ResponseEvent } from '../../common/event/responseEvent';
import { JsonService } from '../../clientCommon/services';
import { CrudHelperService } from '../services/crudHelper.service';
import {  MatDialog } from '@angular/material/dialog';
import { adminPaths } from '../../common/helpers/pathHelpers';
import { ModelRevision } from 'src/common/models/modelRevision';

@Component({
    templateUrl: 'uxcHistory.component.html',
    styleUrls: ['./uxcHistory.component.scss'],
    standalone: false
})

export class UxcHistoryComponent implements OnInit {
  uxHistory: ModelRevision[] = [];
  fromDate = '';
  toDate = '';

  uxDetailTypes = ['uxLayouts', 'uxComponentCollections', 'uxComponents'];

  searchOptions = {
    key: 'uxcHistorySearch',
    filters: {
      isUXC: true,
      isUXL: null,
      isUXCC: null,
      isUXComp: null,
      field: ['_id', 'name'],
      value: '',
    },
  };

  pageSize = 1000;
  currentPage = 0;
  fetchSize = 0;
  isLoadMore = false;

  constructor(
    private crudService: CrudService,
    private crudHelperService: CrudHelperService,
    private snackBar: MatSnackBar,
    private storageService: StorageService,
    private jsonService: JsonService,
    private spinnerService: SpinnerService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    const filters = this.storageService.get(this.searchOptions.key);
    if (filters) {
      this.searchOptions.filters = filters;
    }
  }

  
  search() { 
    this.uxHistory = [];
    const promises = [];

    this.isLoadMore = false;
    this.currentPage = 0;

    // const fetchData = (params, page, url) => {
    //   return this.jsonService.json(url, { ...params, page: page, pageSize: this.pageSize }).then((responseEvent: ResponseEvent) => {
    //     this.uxHistory.push(responseEvent.data);
    
    //     if (responseEvent.data.length === this.pageSize) {
    //       // 더 많은 데이터가 있는 경우 다음 페이지를 가져옵니다.
    //       return fetchData(params, page + 1, url);
    //     }
    //   });
    // };


    if (this.searchOptions.filters) {      
      const filters = this.searchOptions.filters;

      if (filters.value) {
        const value = filters.value;
        this.uxHistory = [];

        var params = {
            fromDt: this.fromDate,
            toDt: this.toDate,
            value: filters.value,
            isUXC: filters.isUXC,
            isUXL: filters.isUXL,
            isUXCC: filters.isUXCC,
            isUXComp: filters.isUXComp,
        }
        const url = `/api/manage/admin/findHistory`;
        this.spinnerService.spin();
        this.jsonService.json(url, { ...params, page: this.currentPage, pageSize: this.pageSize }).then((responseEvent: ResponseEvent) => {
          this.uxHistory = [...this.uxHistory, ...responseEvent.data];
          //this.uxHistory.push(responseEvent.data);
          this.fetchSize = responseEvent.data.length;
          if (this.fetchSize == this.pageSize) {
            this.isLoadMore = true;
            this.currentPage = this.currentPage + 1;
          }else {
            this.isLoadMore = false;
            this.currentPage = 0;
          }
        }).finally(() => {
          this.spinnerService.unspin();
        });
      }
    }
  }

  loadMoreSearch() {  
    if (this.searchOptions.filters) {      
      const filters = this.searchOptions.filters;

      if (filters.value) {
        const value = filters.value;

        var params = {
            fromDt: this.fromDate,
            toDt: this.toDate,
            value: filters.value,
            isUXC: filters.isUXC,
            isUXL: filters.isUXL,
            isUXCC: filters.isUXCC,
            isUXComp: filters.isUXComp,
        }
        const url = `/api/manage/admin/findHistory`;
        this.spinnerService.spin();
        this.jsonService.json(url, { ...params, page: this.currentPage, pageSize: this.pageSize }).then((responseEvent: ResponseEvent) => {          
          this.uxHistory = [...this.uxHistory, ...responseEvent.data];

          this.fetchSize = responseEvent.data.length;
          if (this.fetchSize == this.pageSize) {
            this.isLoadMore = true;
            this.currentPage = this.currentPage + 1;
          }else {
            this.isLoadMore = false;
            this.currentPage = 0;
          }
        }).finally(() => {
          this.spinnerService.unspin();
        });      
      }
    }    
  }  

  loadMore() {
    if (this.isLoadMore) this.loadMoreSearch();
  }
  
  keyDownEvent(event) {
    if (inputUtils.isEnterEvent(event)) {
      return this.search();
    }
  }

  checkModelChange(state, type) {
    switch (state) {
      case true:        
        this.searchOptions.filters[type] = null;
        break;
      case false:
        this.searchOptions.filters[type] = null;
          break;
      default:
        this.searchOptions.filters[type] = true;
        break;
    }
  }
}

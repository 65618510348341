import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import {JsonService} from '../../clientCommon/services/json.service';
import {ResponseEvent} from '../../common/event/responseEvent';
import {ActivatedRoute} from '@angular/router';
import {SpinnerService} from '../../clientCommon/services/spinner.service';
import {adminPaths, serverPaths} from '../../common/helpers/pathHelpers';
import {collectionClassHelper} from '../../common/decorators/database/collectionClass';
import {Note} from '../../common/models/note';
import {LogUtils} from '../../common/utils/logUtils';
import { MatTableDataSource } from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { ServiceHelperService } from 'src/clientCommon/services/serviceHelper.service';
import { BaseDirective } from 'src/clientCommon/directives/BaseDirective';
import { configUtils } from '../utils/ConfigUtils';
import { objectUtils } from 'src/common/utils/objectUtils';
import { inputUtils } from 'src/clientCommon/utils/inputUtils';

@Component({
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: false
})

export class NotesComponent extends BaseDirective implements OnInit, AfterViewInit {
  notes = new MatTableDataSource<Note>([]);
  adminPaths = adminPaths;

  columnsToDisplay = ['expander', 'date', 'id', 'updaterId', 'referenceCollection', 'categories', 'note'];

  input: any = {
    referenceCollection: 'users'
  };
  prevInput: any = {};
  more = false;
  brands = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private jsonService: JsonService,
              private spinnerService: SpinnerService,
              private route: ActivatedRoute,
              public serviceHelperService: ServiceHelperService,
              private snackBar: MatSnackBar) {
    super(serviceHelperService, route);
  }

  ngOnInit() {
    super.baseInit().then(() => this.init());
  }

  ngAfterViewInit() {
    this.notes.paginator = this.paginator;
  }

  init() {
    this.spinnerService.spin();
    this.brands = configUtils.getBrands(this.uxComposite);
    this.jsonService.json(serverPaths.manageCsrNotesView, {}).then((responseEvent: ResponseEvent) => {
      
      this.notes.data = responseEvent.getDocs();
      this.notes.data.sort((a, b) => {
        return b.createdTimestamp - a.createdTimestamp;
      });
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  getClasses(obj) {
    let def = collectionClassHelper.getCollectionName(obj);
    return `${def} ${obj.status} ${obj.subStatus}`;
  }

  getRaw(obj) {
    const param = objectUtils.clone(obj);
    return JSON.stringify(param, null, 4);
  }

  keyDownEvent(event) {
    if (inputUtils.isEnterEvent(event)) {
      return this.search(this.input);
    }
  }

  search(input, aggregate?: boolean) {
    objectUtils.deleteEmptyProperties(input);
    this.prevInput = objectUtils.clone(input);
    this.spinnerService.spin();
    this.jsonService.json(serverPaths.manageCsrNotesView, input).then((responseEvent: ResponseEvent) => {
      
      this.notes.data = responseEvent.getDocs();
      this.notes.data.sort((a, b) => {
        return b.createdTimestamp - a.createdTimestamp;
      });
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });

  }

  isValid() {
    return this.input;
  }
}

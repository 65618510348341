import {Component, OnInit} from '@angular/core';
import {CrudService} from '../../clientCommon/services/crud.service';
import {CommerceStock} from '../../common/models/commerce/commerceStock';
import {CommerceProduct, CommerceProductDetail} from '../../common/models/commerce/commerceProduct';
import {CommerceOffer} from '../../common/models/commerce/commerceOffer';
import {ModelBase} from '../../common/models/modelBase';
import {LogUtils} from '../../common/utils/logUtils';
import {collectionClassHelper} from '../../common/decorators/database/collectionClass';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CommercePrice} from '../../common/models/commerce/commercePrice';
import {ArrayUtils} from '../../common/utils/arrayUtils';
import {SpinnerService} from '../../clientCommon/services/spinner.service';
import {clipboardUtils} from '../../clientCommon/utils/clipboardUtils';
import {inputUtils} from '../../clientCommon/utils/inputUtils';
import {StorageService} from '../../clientCommon/services/storage.service';
import {UxComposite} from '../../common/models/ux/uxComposite';
import {UxcService} from '../../clientCommon/services';
import {objectUtils} from "../../common/utils/objectUtils";

@Component({
    templateUrl: './productFullMgmtV1.component.html',
    standalone: false
})

export class ProductFullMgmtComponentV1 implements OnInit {
  commercePriceOptions = CommercePrice.PRICE_OPTION;
  uxComposite: UxComposite;
  newCommerceStock: CommerceStock = new CommerceStock();
  newCommerceProduct: CommerceProduct;
  newCommerceOffer: CommerceOffer;

  commerceStocks: CommerceStock[] = [];
  commerceProducts: CommerceProduct[] = [];
  commerceOffers: CommerceOffer[] = [];

  searchFilterKey = 'adminProductSearchFilter';
  searchFilter = {stockName: '', productName: '', offerName: ''};

  periodUnits;
  alertDuration = 5000;

  constructor(private crudService: CrudService,
              private snackBar: MatSnackBar,
              private uxcService: UxcService,
              private storageService: StorageService,
              private spinnerService: SpinnerService) {
    this.periodUnits = ArrayUtils.toArray(CommercePrice.UNIT);
  }

  ngOnInit() {
    const searchFilter = this.storageService.get(this.searchFilterKey);
    if (searchFilter) {
      this.searchFilter = searchFilter;
    }

    return Promise.resolve().then(() => {
      return this.uxcService.getUxComposite();
    }).then((uxComposite: UxComposite) => {
      this.uxComposite = uxComposite;
      this.setNewCommerceProduct();
      this.setNewCommerceOffer();
    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

  setNewCommerceProduct() {
    this.newCommerceProduct = new CommerceProduct();
    this.newCommerceProduct.draft.commerceStockDetails = [{commerceStockId: '', quantity: 100, period: {quantity: 1, unit: 'm'}}];
  }

  setNewCommerceOffer() {
    this.newCommerceOffer = new CommerceOffer();
    this.newCommerceOffer.draft.payeeId = this.uxComposite.payeeId;
    this.newCommerceOffer.draft.commercePrice.addStandardTrial();
    this.newCommerceOffer.draft.commercePrice.addStandardRecurring();
    this.newCommerceOffer.draft.provides = [CommerceOffer.PROVIDES.membership];
    this.newCommerceOffer.draft.tempClient.optionsString = JSON.stringify(this.newCommerceOffer.draft.options ? this.newCommerceOffer.draft.options : {});
  }

  findCommerceProducts(ids) {
    let idsString = '';
    ids.forEach((id) => {
      if (idsString.length) {
        idsString += '|';
      }
      idsString += id;
    });

    idsString = '/^' + idsString + '$/';
    const selectQuery = {status: ModelBase.STATUS.active, _id: idsString};
    return this.crudService.read(CrudService.URL.admin, collectionClassHelper.getCollectionName(CommerceProduct), selectQuery).then((result) => {
      return result;
    }).catch((e) => {
      LogUtils.error(e);
    });
  }

  findCommerceProductDetails(commerceProductIds): Promise<CommerceProductDetail[]> {
    const commerceProductDetails = [];
    return this.findCommerceProducts(commerceProductIds).then((commerceProducts: CommerceProduct[]) => {
      commerceProductIds.forEach((commerceProductId) => {
        commerceProducts.some((commerceProduct) => {
          if (commerceProduct._id === commerceProductId) {
            commerceProductDetails.push({
              commerceProductId,
              commerceStockDetails: commerceProduct.commerceStockDetails,
            });
            return true;
          }
        });

      });
      return commerceProductDetails;
    });
  }


  add(obj: ModelBase<any>, ext1?) {
    return Promise.resolve().then(() => {
      this.spinnerService.spin();
    }).then(() => {
      if (!obj.draft.name) {
        return Promise.reject(false);
      }
    }).then(() => {
      obj.draft.status = ModelBase.STATUS.active;
      if (obj instanceof CommerceStock) {
        return this.crudService.create(CrudService.URL.admin, obj).then(() => {
          this.newCommerceStock = new CommerceStock();
        });
      } else if (obj instanceof CommerceProduct) {
        this.newCommerceProduct.draft.commerceStockIds = [ext1];
        this.newCommerceProduct.draft.commerceStockDetails[0].commerceStockId = ext1;
        return this.crudService.create(CrudService.URL.admin, obj).then(() => {
          this.setNewCommerceProduct();
        });
      } else if (obj instanceof CommerceOffer) {
        this.newCommerceOffer.draft.provides = [CommerceOffer.PROVIDES.membership];
        // this.newCommerceOffer.draft.commerceProductDetails = [{commerceProductId: ext1, quantity: 1}];
        this.newCommerceOffer.draft.commerceProductDetails = [];
        return this.findCommerceProductDetails(this.newCommerceOffer.draft.commerceProductIds).then((commerceProductDetails) => {
          this.newCommerceOffer.draft.commerceProductDetails = commerceProductDetails;
          return this.crudService.create(CrudService.URL.admin, obj);
        }).then(() => {
          this.setNewCommerceOffer();
        });
      } else {
        return Promise.reject(false);
      }
    }).then(() => {
      this.snackBar.open('adding done.' + obj.draft.name, 'Add', {duration: this.alertDuration});
    }).then(() => {
      return this.search();
    }).catch((e) => {
      LogUtils.error(e);
      this.snackBar.open('ERROR adding ' + obj.draft.name, 'Add', {duration: this.alertDuration});
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  search(): Promise<any> {
    this.storageService.set(this.searchFilterKey, this.searchFilter);

    this.commerceStocks = [];
    this.commerceProducts = [];
    this.commerceOffers = [];
    const promises = [];
    const baseSelectQuery: any = {status: ModelBase.STATUS.active, name: ''};
    if (this.searchFilter.stockName) {
      const selectQuery = objectUtils.clone(baseSelectQuery);
      selectQuery.name = `/${this.searchFilter.stockName}/i`;
      promises.push(this.crudService.read(CrudService.URL.admin, collectionClassHelper.getCollectionName(CommerceStock), selectQuery));
    }
    if (this.searchFilter.productName) {
      const selectQuery = objectUtils.clone(baseSelectQuery);
      selectQuery.name = `/${this.searchFilter.productName}/i`;
      promises.push(this.crudService.read(CrudService.URL.admin, collectionClassHelper.getCollectionName(CommerceProduct), selectQuery));
    }
    if (this.searchFilter.offerName) {
      const selectQuery = objectUtils.clone(baseSelectQuery);
      selectQuery.name = `/${this.searchFilter.offerName}/i`;
      promises.push(this.crudService.read(CrudService.URL.admin, collectionClassHelper.getCollectionName(CommerceOffer), selectQuery));
    }

    this.spinnerService.spin();
    return Promise.all(promises).then((results) => {
      this.commerceStocks = [];
      results.forEach((result) => {
        if (result.length > 0) {
          if (result[0] instanceof CommerceStock) {
            this.commerceStocks = result;
          } else if (result[0] instanceof CommerceProduct) {
            result.forEach((commerceProduct) => {
              commerceProduct.draft.commerceStockDetails.forEach((commerceStockDetail) => {
                if (!commerceStockDetail.period) {
                  commerceStockDetail.period = {};
                }

              });
            });
            this.commerceProducts = result;
          } else if (result[0] instanceof CommerceOffer) {
            this.commerceOffers = result;
            if (this.commerceOffers) {
              this.commerceOffers.forEach((commerceOffer) => {
                this.fixCommercrPrice(commerceOffer.commercePrice);
                this.fixCommercrPrice(commerceOffer.draft.commercePrice);
                commerceOffer.draft.tempClient.optionsString = JSON.stringify(commerceOffer.draft.options ? commerceOffer.draft.options : {});
              });
            }
          }
        }
      });
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  update(obj: ModelBase<any>) {
    return Promise.resolve().then(() => {
      this.spinnerService.spin();
    }).then(() => {
      if (obj instanceof CommerceStock) {
        // no special treat
      } else if (obj instanceof CommerceProduct) {
        obj.draft.commerceStockDetails[0].commerceStockId = obj.draft.commerceStockIds[0];
      } else if (obj instanceof CommerceOffer) {
        obj.draft.payeeId = this.uxComposite.payeeId;
        obj.draft.provides = [CommerceOffer.PROVIDES.membership];
        return this.findCommerceProductDetails(obj.draft.commerceProductIds).then((commerceProductDetails) => {
          obj.draft.commerceProductDetails = commerceProductDetails;
        });
      }
    }).then(() => {
      return this.crudService.updateDoc(CrudService.URL.admin, [obj]);
    }).then(() => {
      this.snackBar.open('updating done. ' + obj.draft.name, 'Update', {duration: this.alertDuration});
      return this.search();
    }).catch((e) => {
      this.snackBar.open('ERROR updating ' + obj.draft.name, 'Error', {duration: this.alertDuration});
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  copyId(obj) {
    if (obj) {
      this.copyTextToClipboard(obj._id);
    }
  }

  copyName(obj) {
    if (obj) {
      this.copyTextToClipboard(obj.name);
    }
  }

  copyTextToClipboard(text) {
    if (clipboardUtils.copyTextToClipboard(text)) {
      this.snackBar.open(`${text}`, 'Copy', {duration: this.alertDuration});
    } else {
      this.snackBar.open(`${text}`, 'Fail', {duration: this.alertDuration});
    }
  }

  addItemToArray(array: any[], form) {
    if (form.value) {
      array.push(form.value);
      form.value = null;
    }
  }

  removeItemInArray(array: any[], index) {
    array.splice(index, 1);
  }

  addPrice(commerceOffer: CommerceOffer) {
    commerceOffer.commercePrice.addStandardRecurring();
  }

  keyDownEvent(event) {
    if (inputUtils.isEnterEvent(event)) {
      return this.search();
    }
  }

  fixCommercrPrice(commercePrice: CommercePrice) {
    if (commercePrice && commercePrice.prices && commercePrice.prices.length) {
      commercePrice.prices.forEach((price) => {
        if (!price.options) {
          price.options = {};
        }
      });
    }
  }

  processCommerceOfferOptionsEvent($event, commerceOffer) {
    commerceOffer.tempClient.optionsString = $event.target.value;
    try {
      commerceOffer.options = JSON.parse($event.target.value);
    } catch (e) {
      LogUtils.info(e.message);
    }
  }

  isJsonParsable(value) {
    try {
      const parsedValue = JSON.parse(value);
      return objectUtils.isObject(parsedValue);
    } catch (e) {
      return false;
    }
  }

  isValid(doc: CommerceOffer) {
    if (doc instanceof CommerceOffer) {
      return doc.draft.name &&
        doc.draft.commercePrice &&
        doc.draft.commercePrice.isValid() &&
        doc.isDiffDraft() &&
        doc.draft.commerceProductIds.length > 0 &&
        this.isJsonParsable(doc.draft.tempClient.optionsString);
    }
  }
}

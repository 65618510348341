import {Injectable} from "@angular/core";
import {JsonService} from "./json.service";
import {RequestEvent} from "../../common/event/requestEvent";
import {ResponseEvent} from "../../common/event/responseEvent";
import {LogUtils} from "../../common/utils/logUtils";
import {UserInputService} from "./userInput.service";
import {AuthenticationService} from "./authentication.service";
import {UxComposite} from "../../common/models/ux/uxComposite";
import {serverPaths} from "../../common/helpers/pathHelpers";
import {UserInfoService} from "./userInfo.service";
import {PixelService} from "./pixel.service";
import {TrackingService} from "./tracking.service";
import {timeUtils} from "../../common/utils/timeUtils";
import {InputTrackingService} from "./InputTracking.service";
import {urlUtils} from "../utils/urlUtils";
import {Subject} from "rxjs";
import { StorageService } from "./storage.service";

@Injectable()
export class UxcService {

  private requestPromise = null;

  public uxComposite: UxComposite = null;
  afterUxInit = new Subject();

  constructor(private jsonService: JsonService,
              private authenticationService: AuthenticationService,
              private pixelService: PixelService,
              private trackingService: TrackingService,
              private userInfoService: UserInfoService,
              private userInputService: UserInputService,
              private inputTrackingService: InputTrackingService,
              private storageService: StorageService,
  ) {
  }

  /**
   *
   * @param uxComposite ucomposite for the current ux component
   * set the uxcomposite to current uxcomposite if any uxcomposite exists
   * logs the current dev server if the server is devserver
   * sets the current uxc id to uxcompositeconfig id
   * sets the uxlid to uxcomposite uxLaoutId
   * sets the userinput from userInputService to uxComposite codes
   * sets the userinfo from userinfoservice to uxComposite codes
   * sets the value of inputtracking service to uxComposite code
   * sets the user from authentication service to uxComposite code
   * sets the device from uxComposite to uxComposite code
   * pushes the new uxc if any ux exists in userinfo
   * sets this uxComposite using pixelService
   * sets this uxcomposite using trackingService
   */
  public setUxComposite(uxComposite: UxComposite) {
    if (uxComposite) {
      this.uxComposite = uxComposite;
      if (uxComposite.isDevServer()) {
        LogUtils.setDev();
      }
      this.setUxcId(uxComposite.uxConfigId);
      this.setUxlId(uxComposite.uxLayoutId);

      this.uxComposite.setCode("userInput", this.userInputService.getUserInput());
      this.uxComposite.setCode("userInfo", this.userInfoService.getUserInfo());
      this.uxComposite.setCode("inputTracking", this.inputTrackingService.getValue());
      this.uxComposite.setCode("user", this.authenticationService.getUser());
      this.uxComposite.setCode("device", uxComposite.device);

      this.pushNewUxIfNeeded();

      this.pixelService.setUxComposite(uxComposite);
      this.trackingService.setUxComposite(uxComposite);
      this.afterUxInit.next({uxComposite: uxComposite, status: true});
    }
  }

  /**
   *
   * @param status the current status for the uxc
   * tracks and reports the current uxcstatus with type postUxc using trackingService
   */
  private track(status: boolean) {
    this.trackingService.report({type: "postUxc", result: status,});
    this.pixelService.fire("comp.postUxc.pixel", "");
    if (navigator?.['doNotTrack'] === '1') {
      this.trackingService.report({type: "postUxcUoom", result: status,});
    }
  }

  /**
   *
   * @param param values passed to the param from uxcomposite
   * gets the user from authentication service and sets it to the user
   * sets userId to param if user useruxcId and uxLId exists
   * gets the current uxcid and sets it to param uxcid if no paramuxcid exists
   * creates a new promise and sets it to promise
   * gets timeStamp and sets it to hash
   * gets serverpath from uxcComposite and sets it to url
   * creates a new request Event
   * sets the current date and time to param clientTomestamp
   * sets the current params to requestEvent param
   * sends the current url and requestEvent as json using the jsonService
   * @returns
   */
  public findUxComposite(param): Promise<any> {
    if (!this.requestPromise) {
      let user = this.authenticationService.getUser();
      if (user && user.uxcId && user.uxlId) {
        param = {userId: user._id};
      } else if (!param.uxcId) {
        param.uxcId = this.getUxcId();
      } 

      let promise = new Promise<any>((fulfill, reject) => {
        try {
          let hash = Math.round(timeUtils.getTimestamp() / 1000 / 3600);
          let url = serverPaths.uxcFindComposite + "?" + hash;
          let requestEvent = new RequestEvent();
          param.clientTimestamp = new Date().getTime();
          requestEvent.param = param;

          this.jsonService.json(url, requestEvent).then((responseEvent: ResponseEvent) => {
            if (responseEvent.isSuccess()) {
              let uxComposite = responseEvent.getSingleDoc();
              this.setUxComposite(uxComposite);
              this.track(true);
              LogUtils.debug(uxComposite);
              fulfill(uxComposite);
            } else {
              this.track(false);
              reject(responseEvent);
            }
          }).catch((error) => {
            LogUtils.error(error);
            reject(error);
          });
        } catch (e) {
          LogUtils.error(e);
          reject(e);
        }
      });
      this.requestPromise = promise.then((uxComposite: UxComposite) => {
        return this.redirectIfNeeded(uxComposite).then(() => {
          return uxComposite;
        })
      }).catch((e) => {
        LogUtils.error(e);
        return Promise.reject(e);
      });
    }

    return this.requestPromise;
  }

  public getOrFindUxComposite(param): Promise<any> {
    return new Promise<any>((fulfill, reject) => {
      try {
        if (this.uxComposite) {
          fulfill(this.uxComposite);
        } else {
          if (!param.uxcId && this.getUxcId()) {
            param.uxcId = this.getUxcId();
            param.uxlId = this.getUxlId();
          } else if (this.getUxlId() && this.getUxcId() == param.uxcId) {
            param.uxlId = this.getUxlId();
          }
          this.findUxComposite(param).then(fulfill).catch(reject);
        }
      } catch (e) {
        LogUtils.error(e);
        reject(e);
      }
    });
  }

  public getLoadOrFindUxComposite(param): Promise<any> {
    return new Promise<any>((fulfill, reject) => {
      try {
        if (this.uxComposite) {
          fulfill(this.uxComposite);
        } else {
          if (!param.uxcId && this.getUxcId()) {
            param.uxcId = this.getUxcId();
            param.uxlId = this.getUxlId();
          } else if (this.getUxlId() && this.getUxcId() == param.uxcId) {
            param.uxlId = this.getUxlId();
          }
          
          this.findUxComposite(param).then(fulfill).catch(reject);

        }
      } catch (e) {
        LogUtils.error(e);
        reject(e);
      }
    });
  }

  public cascadeUxComposite(uxlId): Promise<any> {
    var param = {cascade: true, uxlId: uxlId};
    this.requestPromise = null;
    return this.findUxComposite(param);
  }

  public getUxComposite(): Promise<any> {
    var param = {type: "", uxcId: ""};
    return this.getOrFindUxComposite(param);
  }

  private setUxcId(uxcId) {
    this.storageService.setItem('uxcId', uxcId);
    //localStorage.setItem('uxcId', uxcId);
  }

  private getUxcId() {
    return this.storageService.getItem('uxcId');
    //return localStorage.getItem('uxcId');
  }

  public setUxlId(uxlId) {
    this.storageService.setItem('uxlId', uxlId);
    //localStorage.setItem('uxlId', uxlId);
  }

  private getUxlId() {
    return this.storageService.getItem('uxlId');
    //return localStorage.getItem('uxlId');
  }

  /**
   * assigns the userinfo from userinfoservice to userinfo
   * sets the timestamp uxcid and uxlid from uxcomposite to value if userinfo has no previous ux
   * pushes the value into the userinfo from userinfoService
   * stores this userinfo using the userinfoservice
   */
  private pushNewUxIfNeeded() {
    var userInfo = this.userInfoService.userInfo;
    if ((!userInfo.prevUx) || userInfo.prevUx.length === 0 ||
      userInfo.prevUx[userInfo.prevUx.length - 1].uxcId !== this.getUxcId() ||
      userInfo.prevUx[userInfo.prevUx.length - 1].uxlId !== this.getUxlId()) {
      let value = {timestamp: this.uxComposite.requestTimestamp, uxcId: this.getUxcId(), uxlId: this.getUxlId()};
      this.userInfoService.userInfo.prevUx.push(value);
      this.userInfoService.storeUserInfo();
    }
  }

  /**
   *
   * @param uxComposite value of the current uxComposite
   * checls the refer and config uxcomposite setting and redirects if needed
   * @returns timeout after redirecting
   */
  redirectIfNeeded(uxComposite: UxComposite) {
    // gets the redirect comp and checks for redirection in uxconfig layout on searched url params
    return Promise.resolve().then(() => {
      // value = [{
      //   url: "some url",
      //   uxc: "uxcId",
      // }]
      if (window?.location?.pathname?.startsWith('/member/loginLink/')) {
        return;
      } else if (uxComposite) {
        let circularRedirection = false;
        let value = uxComposite.get("comp.redirect");
        if (value && value[0] && value[0].url) {
          let url = value[0].url;
          let uxc = value[0].uxc;
          let keyIndex = {
            uxc: -1,
            referUxc: -1,
            referUxcs: -1,
          };
          // gets the current window location
          let search = window.location.search;
          let searchSplit = [];
          // splits the searched url after every &
          if (search) {
            search = search.substr(1);
            searchSplit = search.split("&");

            searchSplit.forEach((split, index) => {
              let keyValue = split.split("=");
              if (keyValue[0] === 'uxc') {
                keyIndex.uxc = index;
              } else if (keyValue[0] === 'utm_refer_uxc') {
                keyIndex.referUxc = index;
              } else if (keyValue[0] === 'utm_refer_uxcs') {
                keyIndex.referUxcs = index;
              }
            });
          }
          // checks the splitted searched url in uxc and sets the circularredirection to true
          let uxcValue = "uxc=" + uxc;
          if (keyIndex.uxc === -1) {
            searchSplit.push(uxcValue);
          } else {
            if (searchSplit[keyIndex.uxc] === ("uxc=" + uxc)) {
              circularRedirection = true;
            }
            searchSplit[keyIndex.uxc] = uxcValue;
          }
          // pushes the referuxc to splitted url if referUxc does not exist
          let referUxcValue = "utm_refer_uxc=" + uxComposite.uxConfigId;
          if (keyIndex.referUxc === -1) {
            searchSplit.push(referUxcValue);
          } else {
            if (searchSplit[keyIndex.referUxc].match(uxc)) {
              circularRedirection = true;
            }
            searchSplit[keyIndex.referUxc] = referUxcValue;
          }

          if (keyIndex.referUxcs === -1) {
            let referUxcsValue = "utm_refer_uxcs=" + uxComposite.uxConfigId;
            searchSplit.push(referUxcsValue);
          } else {
            if (searchSplit[keyIndex.referUxcs].match(uxc)) {
              circularRedirection = true;
            }

            let referUxcsValue = searchSplit[keyIndex.referUxcs] + "_" + uxComposite.uxConfigId;
            searchSplit[keyIndex.referUxcs] = referUxcsValue;
          }
          // logs Circular Reduction error if circular Reduction exists
          let finalUrl = url + "?" + searchSplit.join("&");
          if (circularRedirection) {
            LogUtils.error("CircularRedirection", uxc);
          } else {
            try {
              let hostname = window.location.hostname;
              if (hostname.indexOf("dev") > -1) {
                const hostParts = hostname.split(".");
                const targetHost = hostParts.slice(0, hostParts.indexOf("www")).join(".");

                if (finalUrl.indexOf("dev") == -1) {
                
                    const url = new URL(finalUrl);
                    const hostParts = url.hostname.split(".");
                    const updatedHost = `${targetHost}.${hostParts.slice(hostParts.indexOf("www")).join(".")}`;
                    url.hostname = updatedHost;
                    finalUrl = url.toString();               
                }
              }
            }catch(e) {}

            window.location.href = finalUrl;
            if (urlUtils.isAbsoluteUrl(finalUrl)) {
              return timeUtils.timeout(60 * 1000);
            }
          }
        }
      }
    });
  }

  getBrandId() {
    return this.uxComposite.brandId;
  }
}

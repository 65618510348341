import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { adminPaths } from 'src/common/helpers/pathHelpers';
import { User } from 'src/common/models/user/user';
import { UxComponent } from 'src/common/models/ux/uxComponent';
import { IPagination } from '../../notes-management/notes-management.component';

@Component({
    selector: 'app-ux-history-table',
    templateUrl: './ux-history-table.component.html',
    styleUrls: ['./ux-history-table.component.scss'],
    standalone: false
})
export class UxHistoryTableComponent implements OnInit {

  public searchFilter: any = '';
  users: User[] = [];
  keywords = "";
  expandable = { isExpanded: false, id: '' }
  filteredOptions: Observable<any[]>;
  myControl = new UntypedFormControl();

  @Input() pagination: IPagination;
  @Input() tableData: UxComponent[] = [];
  @Input() uxc: any = {};
  @Input() val = "close";
  searchSubject = new Subject();
  @Output() onExpand = new EventEmitter();
  @Output() onRefresh = new EventEmitter();


  constructor() {
    this.pagination = {
      totalPages: 1,
      currentPage: 1,
      totalRecords: 0,
      perPage: 50,
      hasNext: false,
      hasPrev: false
    }
   }

  ngOnInit(): void {
  }

  _onRefresh(id) { this.onRefresh.emit(id) }


  viewDetail(email) {
    if (!email) return;
    window.open(adminPaths.csrCustomers + '?smartsearch=email:' + email);
  }

}

import {Injectable} from "@angular/core";
import {LogUtils} from "../../common/utils/logUtils";
import {ResponseEvent} from "../../common/event/responseEvent";
import {CookieService} from "./cookie.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {RequestEvent} from "../../common/event/requestEvent";
import {DomSanitizer} from "@angular/platform-browser";
import {commerceUtils} from "../../common/utils/commerceUtils";
import {customPathChanger} from "../utils/customPathChanger";
import { StorageService } from "./storage.service";

@Injectable()
export class JsonService {
  tokenName = "jsonToken";

  /**
   *
   * @param httpClient to call the HTTP rest API methods
   * @param cookieService to set or access the cookies
   * @param domSanitizer to prevent XSS attack
   */
  constructor(private httpClient: HttpClient,
              private cookieService: CookieService,
              private storageService: StorageService,
              private domSanitizer: DomSanitizer) {
  }

  hasToken(): boolean {
    return !!this.getToken();
  }

  /**
   *
   * @returns sessionFlag from local storage against tokenName
   */
  getSessionFlag(): boolean {
    return !this.storageService.getItem(this.tokenName);

    //return !localStorage.getItem(this.tokenName);
  }

  /**
   * This clears/remove/cookie the data from local and session storage against tokenName
   */
  public clearToken() {
    this.storageService.removeItem(this.tokenName);
    this.storageService.removeSession(this.tokenName);
    //localStorage.removeItem(this.tokenName);
    //sessionStorage.removeItem(this.tokenName);
    this.cookieService.deleteCookie(this.tokenName);
  }

  /**
   *
   * @param token to be stored in storage services
   * @param sessionFlag boolean, if true it store the token in cookie otherwise localStorage
   */
  public setToken(token, sessionFlag: boolean) {
    this.clearToken();
    if (sessionFlag) {
      this.cookieService.setCookie(this.tokenName, token, 0, '/');
    } else {
      this.storageService.setItem(this.tokenName, token);
      //localStorage.setItem(this.tokenName, token);
    }
  }

  // get token from the cookie or local storage
  public getToken() {
    let token = this.cookieService.getCookie(this.tokenName);
    if (!token) {
      //token = localStorage.getItem(this.tokenName);
      token = this.storageService.getItem(this.tokenName);
    }

    return token;
  }

  /**
   * Call to REST API against url and request data
   * @param url of an API to be called
   * @param message this include requestEvent, request params to be provided to the API
   * @param options may have hedear options like jsonToken
   * @returns resolved promise with the response with data or rejected promise with false or error
   */
  private call(url, message, options) {
    return Promise.resolve().then(() => {
      return new Promise((fulfill, reject) => {
        try {
          // stringify the request data
          const json = JSON.stringify(message);
          if (!options) {
            options = {};
          }

          // setting the header options i.e token, contentType
          if (!options.headers) {
            const headerInfo = {
              'Content-Type': 'application/json',
            };
            options.headers = new HttpHeaders(headerInfo);
          }

          let token = this.getToken();
          if ((!options.headers.has("jsontoken")) && token) {
            options.headers = options.headers.append('jsontoken', token);
          }
          if(message?.param?.responseType == "blob") {
            options.responseType = "blob"
          }
          url = customPathChanger.getNewAPIPath(url);

          // call to REST API and setting up the responses
          this.httpClient.post(url, json, options).subscribe({
            next: (result) => {
              if(message?.param?.responseType == "blob") {
                fulfill(result)
              }
              fulfill(new ResponseEvent(result));
            }, 
            error: (error) => {
              let responseEvent;
              try {
                responseEvent = new ResponseEvent(error);
                if (responseEvent.errorMessage && responseEvent.errorMessage.error && responseEvent.errorMessage.error.status) {
                  responseEvent.errorMessage.error = undefined;
                }
              } catch (e) {
                LogUtils.error(e, error);
              }
              reject(responseEvent);
            },
          });
        } catch (e) {
          LogUtils.error("JsonService", e);
          let responseEvent = new ResponseEvent();
          responseEvent.errorMessage = e;
          reject(responseEvent);
        }
      });
    });
  }

  
  /**
   * Call to REST API against url and request json data
   * @param url of an API to be called
   * @param message this include requestEvent, request params to be provided to the API
   * @returns resolved promise with the response with data or rejected promise with false or error
   */
  public json(url, message) {
    return Promise.resolve().then(() => {
      return this.call(url, message, null);
    });
  }

  // This is incomplete. need to save blob data.
  public download(url, message, options) {
    if (!options) {
      options = {};
    }
    if (!options.responseType) {
      options.responseType = 'blob';
    }
    return Promise.resolve().then(() => {
      return this.call(url, message, options);
    }).then((data: any) => {
      const blob = new Blob([data], {type: 'application/octet-stream'});
    });
  }


    /**
   * Call to REST API against url and param data
   * @param url of an API to be called
   * @param param this include the request param data
   * @returns resolved promise with the response with data or rejected promise with false or error
   */
  public request(url, param): Promise<any> {
    let requestEvent = new RequestEvent();
    requestEvent.param = param;

    return this.json(url, requestEvent).catch((error) => {
      if (commerceUtils.isNotAcceptableRequestError(error)) {
        LogUtils.debug(error);
      } else {
        LogUtils.error(error);
      }
      return Promise.reject(error);
    });
  }
}

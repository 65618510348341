import { ViewAttachmentDialog } from '../../customer/dialog.component';
import { LogUtils } from '../../../../common/utils/logUtils';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ServiceHelperService } from 'src/clientCommon/services/serviceHelper.service';
import { adminPaths, serverPaths } from 'src/common/helpers/pathHelpers';
import { EmailRecord } from 'src/common/models/emailRecord';
import { User } from 'src/common/models/user/user';
import { MatDialog } from '@angular/material/dialog';
import { CommerceContent } from '../../../../common/models/commerce/commerceContent';
import { PageEvent } from '@angular/material/paginator';
import { UxComposite } from 'src/common/models/ux/uxComposite';
import { commerceUrls } from '../../../../common/models/commerce/commerceEvent';
import { ResponseEvent } from '../../../../common/event/responseEvent';
import { ModelBase } from 'src/common/models/modelBase';
import { CsrAuthGuard } from 'src/app/guards';
import { MatSnackBar } from '@angular/material/snack-bar';
import { peopleSearchProductKeys } from 'src/common/custom/peopleSearch/peopleSearchProductKeys';

@Component({
    selector: 'app-factFinders-table',
    templateUrl: './factFinders-table.component.html',
    styleUrls: ['./factFinders-table.component.scss'],
    standalone: false
})
export class CSRFactFindersTableComponent implements OnInit {

  constructor(
    public dialog: MatDialog, 
    private snackBar: MatSnackBar,
    private serviceHelperService: ServiceHelperService, 
    private csrAuthGuard: CsrAuthGuard,
  ) {


    this.searchSubject.pipe(
      debounceTime(200),
      distinctUntilChanged())
      .subscribe((value) => {
        this.fetchCsrs();
      });
    this.findFFRequests();

  }

  files = [];
  ffAllRequest = [];
  tempData = [];
  keywords = "";
  emailData;
  emailBodyData;
  commerceContentId: string;
  selectCheck: string = "";
  isChecked: boolean = false;
  showSelectedValue: boolean = false;
  holdTempValue: string = "";
  openDateSold: boolean = false;
  showPopover: boolean = false;
  show: boolean = false;
  selected: string = ""
  factFinderCommerceContents: CommerceContent[]
  getCSRPersons: {name: string, id: string}[] = [];
  attachmentCounts: number[] = [];
  attachmentsList: string | number[] = [];
  checkSelectValue: boolean = false;
  showSelectedName: string = "";
  expandable = { isExpanded: false, id: '' }
  cdpLink: string = "";
  selectedOwner = null;
  searchedCsrs = [];
  searchSubject = new Subject();
  myControl = new UntypedFormControl();
  selectedRecord = {
    isEditCdp: false,
    isEditOwner: false,
    isHintClicked: false,
    idOwner: '',
    idCdp: '',
  };
  public arrLength;
  uxComposite: UxComposite;


  PROVIDER_TYPES = {
    factFindersRequest: "PrivacyMaxxFactFindersRequest",
    ffrUser: "PrivacyMaxxFFRUser",
  }

  // @Input() pagination: IPagination;
  @Input() tableData: EmailRecord[] = [];
  @Input() thList = [];
  @Input() csr: any = {};
  @Input() val = 'close';
  @Input() onDemandEmailData = {};
  @Input() currentStatus: string;

  @Output() onClose = new EventEmitter();
  @Output() onExpand = new EventEmitter();
  @Output() onSearch = new EventEmitter();
  @Output() onRefresh = new EventEmitter();
  @Output() onUpdateStatus = new EventEmitter();
  @Output() onAssigned = new EventEmitter();
  @Output() onCdpSaved = new EventEmitter();
  @Output() onDemandEmail = new EventEmitter();

  assigedFrom = null;
  assigedCustomerFrom = null;

  changeCustomerAssignee(record: { obj: { _id: string, customer: { _id: string } } }) {
    this.selectedRecord.isHintClicked = false;
    this.selectedRecord.isEditCdp = true;
    this.assigedCustomerFrom = record.obj?.customer?._id;
    this.selectedRecord.idCdp = record?.obj?._id;
    this.cdpLink = '';
  }

  getEmailOnDemand(emailId: string) {
    this.onDemandEmail.emit({ emailRecordId: emailId })
    setTimeout(() => {
      this.emailData = this.onDemandEmailData['body']?.replaceAll("=\r\n", '');
      this.emailBodyData = this.emailData?.replaceAll('3D', '');
    }, 400);
  }

  ngOnInit(): void {
    this.fetchCsrs();
    this.findFFRequests();

    this.openDateSold = false;
  }

  selectOption(event) {
    this.selectedOwner = event.option.value._id;
  }
  downloadViewPDFReport(re) {
    this.serviceHelperService.spinnerService.spin();
    const factFinderCommerceContent: CommerceContent = this.factFinderCommerceContents?.find(cc => cc?._id === re?.referenceId)
    const commerceContentId = factFinderCommerceContent?.content?.reportCommerceContentId;
    if (!factFinderCommerceContent?.ownerId || !commerceContentId) {
      this.serviceHelperService.spinnerService.unspin();
      return;
    }
    this.serviceHelperService.jsonService.json(commerceUrls.csrFindCustomer + '/' + factFinderCommerceContent?.ownerId, {})
      .then(async (responseEvent: ResponseEvent) => {
        const docs = responseEvent.getDocs();
        let peopleSearchCommerceContent: CommerceContent;

        docs.forEach((obj: ModelBase<any>) => {
          if (obj instanceof CommerceContent && obj._id === commerceContentId) {
            peopleSearchCommerceContent = obj;
          }
          if (obj instanceof UxComposite) {
            this.uxComposite = obj
          }
        });
        if (peopleSearchCommerceContent) {
          window.open(this.serviceHelperService.peopleSearchService.getPdfDownloadLink(this.uxComposite, peopleSearchCommerceContent), `Download_${peopleSearchCommerceContent._id}`);
        }
        this.serviceHelperService.spinnerService.unspin();
      });
  }

  async fetchCsrs() {
    try {

      let res = await this.serviceHelperService
        .jsonService
        .json(serverPaths.manageCsrFindCsr, { param: { keywords: this.keywords } })
      for (let i = 0; i < res['docs'].length; i++) {
        if (res['docs'][i].obj.status === "active") {
          let fullName = res['docs'][i].obj.firstName + " " + res['docs'][i].obj.lastName;
          this.getCSRPersons.push({name: fullName, id: res['docs'][i].obj._id});
        }
      }
    }
    catch (err) {
      LogUtils.info(err);
    };
  }

  setUserBrand(user: User) {
    user.brandId = 'admin';
  }

  displayFn(csr: { obj: { firstName: string, lastName: string } }) {
    if (csr && csr.obj) {
      return csr.obj?.firstName + ' ' + csr.obj?.lastName;
    } else {
      return '';
    }
  }

  public selectHideShow(reportId: string): void {
    this.selectCheck = reportId;
    this.showSelectedValue = false;
    this.show = true;
    this.checkSelectValue = false;
  }

  async onChecked(id: string) {
    try {
      const check = <HTMLInputElement>document.getElementById("targetCheckBox");
      if (check.checked) {
        const attachedFiles = this.tempData.find(data => data?.referenceId === id)?.attachments?.length ?? 0;

        if (attachedFiles === 0) {
          this.snackBar.open(
            'Please upload attachments to complete the Fact Finders Task', 
            'Warning', 
            { duration: 5000 },
          );

          check.checked = false;
          
          return;
        }

        this.isChecked = !!id;
        this.currentStatus = ModelBase.STATUS.done;
        this.serviceHelperService.spinnerService.spin();
        this.markAsCompleted(id);
        return;
      }

      this.isChecked = !!id;
      this.currentStatus = ModelBase.STATUS.locked;
      this.serviceHelperService.spinnerService.spin();
      this.markAsInComplete(id);
    } catch (e) {
      LogUtils.error(e);
      this.serviceHelperService.spinnerService.unspin();
    }
  }

  async markAsCompleted(id: string) {
    let success = null;

    try {
      const selectQuery = { type: peopleSearchProductKeys.factFinder, status: this.currentStatus, id: id };
      const commerceContent: CommerceContent = await this.serviceHelperService.commerceService.updateFactFindersRequest(selectQuery);
      LogUtils.info(`commerceContent`, commerceContent);

      success = true;
    } catch (e) {
      success = false;

      LogUtils.error(e);
    } finally {
      if (success) {
        this.trackingCompleted(id, true);
      }
  
      this.serviceHelperService.spinnerService.unspin();
    }
  }

  async markAsInComplete(id) {
    let success = null;

    try {
      const selectQuery = { type: peopleSearchProductKeys.factFinder, status: this.currentStatus, id: id };
      const commerceContent: CommerceContent = await this.serviceHelperService.commerceService.updateFactFindersRequest(selectQuery);
      LogUtils.info(`commerceContent`, commerceContent);

      success = true;
    } catch (e) {
      success = false;

      LogUtils.error(e);
    } finally {
      if (success) {
        this.trackingCompleted(id, false);
      }

      this.serviceHelperService.spinnerService.unspin();
    }
  }

  async savePersonName(id: string) {
    try {
      if (this.selected.length > 0) {
        this.checkSelectValue = true;
      }
      this.currentStatus = ModelBase.STATUS.inProgress;
      this.serviceHelperService.spinnerService.spin();
      const selectQuery = { 
        type: peopleSearchProductKeys.factFinder, 
        ownerName: this.selected?.['name'], 
        ownerId: this.selected?.['id'],
        status: this.currentStatus, 
        id: id,
      };
      let commerceContent: CommerceContent = await this.serviceHelperService.commerceService.updateFactFindersRequest(selectQuery);
      LogUtils.info(`commerceContent`, commerceContent);
      setTimeout(() => {
        this.holdTempValue = this.selected;
        this.selected = "";
        this.showSelectedValue = true;
        this.show = false;
        this.serviceHelperService.spinnerService.unspin();
      }, 500);
    } catch (e) {
      LogUtils.error(e);
    }
  }



  async findFFRequests() {
    this.tempData = [];
    try {
      const selectQuery = { 
        type: peopleSearchProductKeys.factFinder, 
        status: this.currentStatus,
        managerId: this.serviceHelperService.authenticationService.getUserId(),
      };
      let res = await this.serviceHelperService.commerceService.getAllFFRequest(selectQuery);
      let comContents = [...res];
      let commerceContents: CommerceContent[] = comContents?.filter(cc => cc?.raws?.some(raw => raw?.meta?.type == peopleSearchProductKeys.factFinder));
      this.factFinderCommerceContents = comContents;

      commerceContents?.forEach((cc) => {
        const factFindersRequestRaws = cc?.raws?.find(raw => raw?.meta?.type == peopleSearchProductKeys.factFinder)
        this.tempData.push({ 
          ...factFindersRequestRaws, 
          customerId: cc?.ownerId,
          customerName: [cc?.owner?.firstName, cc?.owner?.lastName]
            .filter(e => e)
            .join(' '),
        });
        this.arrLength = this.tempData.slice(0, 5);
        const attachmentRaw = cc?.raws?.find(raw => raw?.meta?.type == "FFRequestAttachment");
        if (attachmentRaw) {
          this.tempData[this.tempData?.length - 1].attachments = attachmentRaw?.output?.attachements?.filter((f) => f.fileName.length > 0) || []
        }
      })
      this.tempData.forEach((result, i) => {
        if (result.attachments?.length > 0) {
          this.tempData[i].attachments = result.attachments.filter(res => res.fileName.length > 0);
        }
      })
    } catch (e) {
      LogUtils.error(e);
    }
  }

  onPageChange(event: PageEvent) {
    try {
      const startIndex = event.pageIndex * event.pageSize;
      let endIndex = startIndex + event.pageSize;
      //tempData array length
      if (endIndex > this.tempData?.length) {
        endIndex = this.tempData?.length;
      }
      //arrLength property for slice set
      this.arrLength = this.tempData.slice(startIndex, endIndex)
    } catch (e) {
      LogUtils.error(e);
    }
  }

  openAddAttachmentModal(id: string) {
    this.tempData.forEach((res) => {
      if (res.referenceId === id) {
        this.attachmentsList = res.attachments;
        this.commerceContentId = res.referenceId;
      }
    })

    const addAttachmentModalRef = this.dialog.open(ViewAttachmentDialog, {
      width: '50%',
      data: {
        file: this.files,
        id: id,
        attachments: this.attachmentsList,
        commerceContentId: this.commerceContentId
      },
      hasBackdrop: true,
    });

    addAttachmentModalRef.componentInstance.onDocumentSave.subscribe(async () => {
      await this.findFFRequests();
    });

    addAttachmentModalRef.afterClosed()
      .subscribe(result => {
        this.files.push(result?.file);
      });
  }

  _onCdpSave(id) {
    this.serviceHelperService.spinnerService.spin();
    setTimeout(() => {
      this.onCdpSaved.emit({ id, customerId: this.cdpLink.trim(), assigedCustomerFrom: this.assigedCustomerFrom });
      this.selectedRecord.idOwner = '';
      this.selectedRecord.isEditCdp = null;
      this.serviceHelperService.spinnerService.unspin();
    }, 500);
  }


  openCustomerDetail(customerId) {
    if (!customerId) {
      return;
    }

    if (!this.csrAuthGuard.canActivatePath(adminPaths.csrCustomer)) {
      return;
    }

    window.open(adminPaths.csrCustomer + '/' + customerId);
  }

  toggleHintClicked() {
    this.selectedRecord.isHintClicked = !this.selectedRecord.isHintClicked;
  }

  private trackingCompleted(id: string, completed: boolean) {
    if (!id) {
      return;
    }

    try {
      const factFinderCommerceContent: CommerceContent = this.factFinderCommerceContents?.find(e => e?._id === id);

      if (!factFinderCommerceContent) {
        throw new Error(`trackingCompleted - Not Found FactFinderCommerceContents : ID(${id})`);
      }

      this.serviceHelperService.trackingService.report({
        type: peopleSearchProductKeys.factFinder,
        action: completed ? "markedAsCompleted" : "markedAsInComplete",
        factFinderCommerceContentId: factFinderCommerceContent?._id,
        customerId: factFinderCommerceContent?.ownerId,
        override: {
          userId: factFinderCommerceContent?.ownerId,
          sessionHash2: factFinderCommerceContent?.data?.sessionId,
          uxcId2: factFinderCommerceContent?.data?.uxcId,
          uxlId2: factFinderCommerceContent?.data?.uxlId,
        },
      });
    } catch (e) {
      LogUtils.error(e);
    }
  }
}

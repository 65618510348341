import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'toDateObject',
    standalone: false
})
export class ToDateObjectPipe implements PipeTransform {
    transform(timestamp: number): Date {
        var date = new Date(timestamp);
        return date;
    }
}
import { LogUtils } from './../../../common/utils/logUtils';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServiceHelperService } from 'src/clientCommon/services/serviceHelper.service';
import { adminPaths, serverPaths } from 'src/common/helpers/pathHelpers';
import { EmailRecord } from 'src/common/models/emailRecord';
import { Note } from 'src/common/models/note';
import { ResponseEvent } from './../../../common/event/responseEvent';
import { MatDialog } from '@angular/material/dialog';
import { ViewSearchDialog } from '../customer/dialog.component';
@Component({
    selector: 'app-csr-email',
    templateUrl: './email.component.html',
    styleUrls: ['./email.component.scss'],
    standalone: false
})
export class CSREmailComponent implements OnInit {
  thList = [
    'Actions',
    'Owner',
    'CDP',
    'Name',
    'From', 'To', 'Subject', 'Date'
  ];
  tabs = {
    0: () => { },
    1: () => { },
    2: () => { },
    3: () => { }
  };
  currentStatus = 'active';
  tableData: any[] = [];
  csr: any = {};
  emailRecords: EmailRecord[] = [];
  closedRecords: EmailRecord[] = [];
  archivedRecords: EmailRecord[] = [];
  allRecords: EmailRecord[] = [];
  pagination: any = {};
  emailBody: any = {};
  nonMemberCDPID: any;

  constructor(private serviceHelperService: ServiceHelperService, private snackBar: MatSnackBar, public dialog: MatDialog) {
    this.csr = this.serviceHelperService.authenticationService.getUser();
    this.pagination = {
      totalPages: 1,
      currentPage: 1,
      totalRecords: 0,
      perPage: 25,
      hasNext: false,
      hasPrev: false
    };
    this.tabs[0] = this.getEmailRecords;
  }
  ngOnInit(): void {
    this.getRecords();
  }
  getEmailRecords() {
    this.currentStatus = 'active';
    this.pagination = { ...this.pagination, currentPage: 1, totalPages: 1 };
    this.getRecords(); return;
  }
  getRecords() {
    this.serviceHelperService.jsonService.json(serverPaths.emailsByStatus, { param: { status: this.currentStatus, pagination: this.pagination, filter: this.filter } })
      .then((results: any) => {
        this.pagination = results.data;
        this.allRecords = results.docs;
      });
      
  }
  getOneRecord(emailId) {
    return this.serviceHelperService.jsonService.json(serverPaths.emailById, { param: { emailId } })
      .then((results) => {
       return results
      })
  }

  getNonMemberCDPRecord(brandName) {
    return this.serviceHelperService.jsonService.json(serverPaths.nonMemberCDP, { param: { brandName } })
      .then((result: ResponseEvent) => {

       return result.docs[0].obj
      })
    }
  clearFilter(){
      this.pagination = { ...this.pagination, currentPage: 1, totalPages: 1 };
      this.filter = {
        rawFilter: {
        },
        csrFilter: {
        },
        customerFilter: {
        }
      }
      this.serviceHelperService.jsonService.json(serverPaths.emailsByStatus, { param: { filter: this.filter, status: this.currentStatus, pagination: this.pagination } })
        .then((results: any) => {
          this.pagination = results.data;
          this.allRecords = results.docs;
        });
  }
  filter = {
    rawFilter:{
    },
    csrFilter:{
    },
    customerFilter:{
    }
  }
  searchModal(){
    const viewNoteModalRef = this.dialog.open(ViewSearchDialog, {
      width: '50%',
      data: {
        note : ''
      }
    });
    this.serviceHelperService.emailFilter$.subscribe((res: any) => {

      this.clearFilter();

    if(res && res?.csrowner){
      const splitName = res.csrowner.split(' ')
      const firstName =  splitName[0]
      const lastName =  splitName[1]
      if(firstName){
        this.filter.csrFilter["firstName"] = firstName
      }
      if(lastName){
        this.filter.csrFilter["lastName"] = lastName
      }
    }
      if (res && (res?.name || res?.to)){
      const splitName = res.name.split(' ')
      const firstName =  splitName[0]
      const lastName =  splitName[1]
      if(firstName){
        this.filter.customerFilter["firstName"] = firstName
      }
      if(lastName){
        this.filter.customerFilter["lastName"] = lastName
      }
      if (res.to) {
        this.filter.customerFilter["to"] = res.to
      }
    }
    if(res && ( res?.from || res?.startdate || res?.enddate || res?.subject || res?.body)){
      if(res.from){
        this.filter.rawFilter["fromName"] = res.from
      }
      if(res.startdate){
        this.filter.rawFilter["startDate"] = res.startdate
      }
      if(res.enddate){
        this.filter.rawFilter["endDate"] = res.enddate
      }
      if(res.subject){
        this.filter.rawFilter["subject"] = res.subject
      }
      if(res.body){
        this.filter.rawFilter["body"] = res.body
      }
    }
    this.serviceHelperService.jsonService.json(serverPaths.emailsByStatus, { param: { filter: this.filter, status: this.currentStatus, pagination: this.pagination } })
      .then((results: any) => {
        this.pagination = results.data;
        this.allRecords = results.docs;
      });
    })
  }
  getClosedEmailRecords() {
    this.currentStatus = 'closed';
    this.pagination = { ...this.pagination, currentPage: 1, totalPages: 1 };
    this.getRecords(); return;
  }
  getArchivedEmailRecords() {
    this.currentStatus = 'archived';
    this.pagination = { ...this.pagination, currentPage: 1, totalPages: 1 };
    this.getRecords(); return;
  }
  getAllRecords() {
    this.currentStatus = 'all';
    this.pagination = { ...this.pagination, currentPage: 1, totalPages: 1 };
    this.getRecords(); return;
  }
  updateStatus(id, status) {
    let msg = '';
    if (status === 'closed') {
      msg = 'Closed';
    } else if (status === 'active') {
      msg = 'Reactivated';
    } else if (status === 'archived') {
      msg = 'Deleted';
    } else if (status === 'reopen') {
      msg = 'Reopened';
      status = 'active';
    }
    this.serviceHelperService.jsonService.json(serverPaths.emailsUpdateStatus, { param: { id, status } })
      .then((results: any) => {
        this.snackBar.open(msg + ' Successfully.', 'Success');
        this.ngOnInit();
      })
      .catch(err => {
        this.snackBar.open(err.error.reason, 'Failed')});
  }
  onAssigned(event) {
    if (!event.customerId) {
      this.snackBar.open('Please select a valid CSR', 'Failed', { duration: 2000 });
      return;
    }
    this.serviceHelperService.spinnerService.spin();
    if (!event.id) { return; }
    this.serviceHelperService.jsonService
      .json(serverPaths.emailsAssignCsr,
        {
          param:
          {
            isReAssign: event.isReAssign,
            id: event.id,
            csrId: event.isReAssign ? event.customerId : this.serviceHelperService.authenticationService.getUser()._id,
            assignedFrom: event.assignedFrom
          }
        })
      .then((results: any) => {
        this.ngOnInit();
        this.snackBar.open('CSR assigned successfully', 'Success', { duration: 2000 });
        if (!event.isReAssign) {
          const note = this.createNewNote(event.customerId, event.body, event.from, event.to, event.subject);
          return this.serviceHelperService.jsonService.json(serverPaths.manageCsrSaveNotes, { notes: [note] });
          // save note ==> then => open cdp
          // window.open(adminPaths.csrCustomer + '/' + event.customerId);
        } else {
          return Promise.resolve(true);
        }
      })
      .then((res) => {
        if (!event.isReAssign) {
          window.open(adminPaths.csrCustomer + '/' + event.customerId);
        }
        this.serviceHelperService.spinnerService.unspin();
      })
      .catch((err) => {
        LogUtils.info(err);
        this.snackBar.open(err.error.reason || 'Csr is already assigned', 'Failed', { duration: 2000 });
        if(event.isReAssign == false && event.customerId){
          setTimeout(() => {
            window.open(adminPaths.csrCustomer + '/' + event.customerId);
          }, 2000);
        }
        this.serviceHelperService.spinnerService.unspin();
      });
  }
  onCdpSave(event) {
    if (!event.customerId) {
      this.snackBar.open('Invalid CDP link', 'Failed', { duration: 2000 });
      return;
    }
    this.serviceHelperService.jsonService.json(serverPaths.emailsUpdateCDP, { param: { id: event.id, customerId: event.customerId, assigedCustomerFrom: event.assigedCustomerFrom } })
      .then((results: any) => {
        this.ngOnInit();
        this.snackBar.open('CDP link updated successfully', 'Success', { duration: 2000 });
      }).catch((err) => {
        LogUtils.info(err);
        this.snackBar.open(err.error.reason || 'Customer is already assigned', 'Failed', { duration: 2000 });
      });
  }
  onDemandEmails(event) {
    if (event.emailRecordId) {
      this.getOneRecord(event.emailRecordId).then(result => {
        this.emailBody = result;
        return this.emailBody;
      });
    }
    return this.emailBody;
  }

  onNonMemberCDPs(event){
    if (event.brandName) {
      this.getNonMemberCDPRecord(event.brandName).then(result => {
        this.nonMemberCDPID = result;
      });
    }
    return this.nonMemberCDPID;
  }

  createNewNote(customerId, body, from, to, subject, ) {
    const newNote = new Note();
    newNote.draft.referenceCollection = 'users';
    newNote.draft.referenceId = customerId;
    newNote.draft.type = 'email';
    newNote.draft.note = body;
    newNote.draft.from = from;
    newNote.draft.to = to;
    newNote.draft.subject = subject;
    return newNote;
  }
  onNextPage() {
    if (!this.pagination.hasNext) { return; }
    this.pagination.currentPage++;
    this.getRecords();
  }
  onSelectChange(event) {
    this.pagination = { ...this.pagination, currentPage: 1, totalPages: 1, perPage: event.perPage };
    this.getRecords();
  }
  onPrevPage() {
    if (!this.pagination.hasPrev) { return; }
    this.pagination.currentPage--;
    this.getRecords();
  }
  test(event) {
    switch (event.index) {
      case 0:
        this.getEmailRecords();
        break;
      case 1:
        this.getClosedEmailRecords();
        break;
      case 2:
        this.getArchivedEmailRecords();
        break;
      default:
        this.getAllRecords();
        break;
    }
  }
}
<style>
    mat-card {
      background: #ffffff;
      box-shadow: 0px 4px 25px rgba(201, 213, 216, 0.6);
      border-radius: 5px;
      margin-top: 48px;
      margin-left: -32px;
      margin-right: -32px;
    }

    mat-card-header {
      border-bottom: 2px solid #e3e8ff;
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 32px;
      padding-right: 32px;
    }

    mat-card-title {
      font-weight: 700;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    mat-card-content {
      padding: 8px 0;
    }
  .history-row {
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
  }
  .w-10 {
    width: 10%;
  }
  .w-20 {
    width: 20%;
  }
  .w-30 {
    width: 30%;
  }
  .w-40 {
    width: 40%;
  }
  .w-50 {
    width: 50%;
  }  

table, td, th {
  border: 1px solid #ddd;
  text-align: left;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  padding: 5px;
}
.mr-10 {
  margin-right: 10px;
}
</style>
<mat-card *ngIf="commerceSchedules" class="mat-elevation-z6">
  <mat-card-content>

      <ng-container *ngFor="let history of commerceSchedules">
            <div class="history-row">
                <table>
                    <tr>
                        
                        <td class="w-20">
                            <span>createdTimestamp : {{ history.createdTimestamp | date: "M/d/yy, h:mm a" || "N/A"}}</span> <br><br>
                            <span>changedTimestamp: {{ history.changedTimestamp | date: "M/d/yy, h:mm a" || "N/A"}}</span> <br><br>
                            <span>dueTimestamp: {{ history.dueTimestamp | date: "M/d/yy, h:mm a" || "N/A"}}</span> <br><br>
                            <span>commerceOrderId: {{ history.commerceOrderId || "N/A" }} </span> <br><br>
                        </td>

                        <td class="w-10">
                            <span>{{history.brandId}}</span>
                        </td>
                        <td class="w-10">
                            <span>{{history.status}}</span>
                        </td>
                        <td class="w-10">
                            <span>{{history.type}}</span>
                        </td>
                        <!-- <td class="w-30">
                            <span>{{codeOutput(history.data)}}</span>
                        </td> -->
                        <td class="w-50 code-column">
                            <span><code>{{codeOutput(history.scheduleData)}}</code></span>
                        </td>
                    </tr>
                </table>
            </div>
      </ng-container>
  </mat-card-content>
</mat-card>

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { JsonService } from "src/clientCommon/services";
import { SpinnerService } from "src/clientCommon/services/spinner.service";
import { ResponseEvent } from "src/common/event/responseEvent";
import { LogUtils } from "src/common/utils/logUtils";

@Component({
    templateUrl: 'uxRevisionHistoryPage.component.html',
    styleUrl: 'uxRevisionHistoryPage.component.scss',
    standalone: false
})
export class UxRevisionHistoryPageComponent implements OnInit {
  constructor(
    private jsonService: JsonService,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute,
  ) {}

  jsonBeautifier: boolean = false;
  selectedId;
  selectedType;
  uxHistory: Record<string, Array<any>> = { 
    uxConfigs: [], 
    uxLayouts: [], 
    uxComponents: [], 
    uxComponentCollections: [],
  };

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.selectedId = params.id;
      this.selectedType = params.type;

      this.findUxHistory(this.selectedId, this.selectedType);
    });
  }

  findUxHistory(id, referenceCollection) {
    const url = `/api/manage/admin/findHistory/${id}`;
    this.spinnerService.spin();
    return this.jsonService
      .json(url, {})
      .then((responseEvent: ResponseEvent) => {
        this.uxHistory = {
          [referenceCollection]: [responseEvent.data],
        };
      })
      .catch((e) => {
        LogUtils.error(e);
      })
      .then(() => {
        this.spinnerService.unspin();
      });
  }
}
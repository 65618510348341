<style>
  .w-100 {
    width: 100%;
  }

  .modal-title {
    background: #f44336;
    margin-left: -24px;
    margin-right: -24px;
    margin-top: -24px;
    padding: 8px 24px;
    color: white;
    font-weight: 600;
  }

  .content {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .mr-2 {
    margin-right: 12px;
  }
</style>

<h2 mat-dialog-title class="modal-title">Confirm {{ data.action }}</h2>
<mat-dialog-content class="mat-typography content">
  <h1>Are you sure to {{ data.action }} this item?</h1>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button color="warn" [mat-dialog-close]="true">Yes</button>
  <button mat-stroked-button color="primary" [mat-dialog-close]="false">No</button>
</mat-dialog-actions>
import {Injectable} from "@angular/core";
import {UxComposite} from "../../common/models/ux/uxComposite";
import {LogUtils} from "../../common/utils/logUtils";
import {User} from "../../common/models/user/user";
import {JsonService} from "./json.service";
import {RequestEvent} from "../../common/event/requestEvent";
import {ResponseEvent} from "../../common/event/responseEvent";
import {serverPaths, serverRouterPaths, trackingPaths} from "../../common/helpers/pathHelpers";
import {customPathChanger} from "../utils/customPathChanger";
import { UxHelper } from "../helper/uxHelper";

declare var ga: any;

@Injectable()
export class TrackingService {
  gaInitFlag = false;
  gtmInitFlag = false;
  uxComposite: UxComposite;

  constructor(private jsonService: JsonService) {
  }

  setUxComposite(uxComposite: UxComposite) {
    this.uxComposite = uxComposite;
    this.setTrackUx(uxComposite);
  }
/**
 * gets the uxcomposite brand.ga and sets it to id
 * if id exists sets the gaInitFlag to true
 * runs the googleanalytic script for Google analytics
 * catches and logs any erros that exist
 */
  initGA() {
    try {
      if (!this.gaInitFlag) {
        if (this.uxComposite) {
          let id = this.uxComposite.get("comp.brand.ga");
          if (id) {
            this.gaInitFlag = true;
            (function (i, s, o, g, r, a, m) {
              i['GoogleAnalyticsObject'] = r;
              i[r] = i[r] || function () {
                (i[r].q = i[r].q || []).push(arguments)
              }, i[r].l = 1 * <any>(new Date());
              a = s.createElement(o),
                m = s.getElementsByTagName(o)[0];
              a.async = 1;
              a.src = g;
              m.parentNode.insertBefore(a, m)
            })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

            ga("create", id, "auto");
          }
        }
      }
    } catch (e) {
      LogUtils.error(e);
    }
  }
/**
 * sets the uxcomposite brand.gtm to id if gtminit flag is false and uxcomposite exists
 * sets the gtmInitFlag to true if id exists
 * sets the evalcode to brand.gtm.code comp
 * runs the gtm Data layer script
 * catches and logs any erros that exist
 */
  initGTM() {
    try {
      if (!this.gtmInitFlag) {
        if (this.uxComposite) {
          let id = this.uxComposite.get("comp.brand.gtm");
          if (id) {
            this.gtmInitFlag = true;
            this.evalCode("comp.brand.gtm.code.pre");
            (function (w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({
                'gtm.start':
                  new Date().getTime(), event: 'gtm.js'
              });
              var f = d.getElementsByTagName(s)[0],
                j = <any>d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', id);
          }
        }
      }
    } catch (e) {
      LogUtils.error(e);
    }
  }
/**
 * 
 * @param uxComposite the value of the current uxComposite
 * runs the GA and GTm script
 * sets the partner.tracking.name from uxcompiste to partner
 * sets the dimensions to current uxConfigid,uxLayoutId and partner id with send event setup init as params if gainitflag exists
 * 
 */
  setTrackUx(uxComposite: UxComposite) {
    this.initGA();
    this.initGTM();

    let partner = this.uxComposite.get("comp.partner.tracking.name");
    if (this.gaInitFlag) {
      ga('send', 'event', 'setup', 'init', {
        'dimension1': uxComposite.uxConfigId,
        'dimension2': uxComposite.uxLayoutId,
        'dimension3': partner,
      });
    }
  }
/**
 * 
 * @param user the current user that is logged in
 * runs the GA script
 * sets the dimension 4 to user._id
 */
  setTrackUser(user: User) {
    this.initGA();
    if (this.gaInitFlag) {
      ga('send', 'event', 'user', 'login', {
        'dimension4': user._id,
      });
    }
  }
/**
 * 
 * @param key the current key of UxComposite
 * sets the key from uxComposite to code
 * sets the rval to code if uxComposite and code exists
 * catches and logs any errors 
 */
  evalCode(key) {
    try {
      if (this.uxComposite) {
        let code = this.uxComposite.get(key);
        // LogUtils.debug('EvalCode', key, code);
        if (code) {
          try {
            eval(code);
          } catch (e) {
            LogUtils.error(e);
          }
        }
      }
    } catch (e) {
      LogUtils.error(e);
    }

  }
/**
 * sets the code.event comp to evalCode
 */
  trackEvent() {
    this.evalCode("comp.tracking.code.event");
  }

  getPath() {
    let path = location.pathname;
    let oldPath = customPathChanger.getOldPathFromNewPath(path);
    if (oldPath) {
      path = oldPath;
    }
    return path;
  }

  /**
   * sets the current pathname from location to path
   * runs the GAscript
   * sends the path and pageview from ga if gaInitFlag exists
   * sets the path from pageview to param
   * reports the current param and tracks the Event
   *
   */
  trackPage() {
    let path = this.getPath();
    this.initGA();

    if (this.gaInitFlag) {
      ga('send', 'pageview', path);
    }

    let param = {pageview: path};
    this.report(param).then(() => {
      // Ignoring return
    });
    this.trackEvent();

  }
/**
 * 
 * @param param Current param being sent to the function
 * tracks the currentevent
 * sets a new Requestevent to requestEvent
 * sets the pathname from location to param.location
 * calls the jsonService and passes the trackingtrack from serverpaths and requestEvent
 * catches and logs any errors
 * 
 * @returns the json response from jsonService
 */
  report(param): Promise<any> {
    this.trackEvent();
    let requestEvent = new RequestEvent();
    requestEvent.param = param;
    param.location = this.getPath();

    LogUtils.debug(param);

    return this.jsonService.json(serverPaths.trackingTrack, requestEvent).then((responseEvent: ResponseEvent) => {
      if (!responseEvent.isSuccess()) {
        return Promise.reject(responseEvent);
      }
    }).catch((error) => {
      LogUtils.error(error);
      // Consumes catch
    });
  }

  privacyPolicyAgreeReport(options: {
    uxHelper: UxHelper,
    uxcompKeys: string[],
    piiStep?: string,
    email?: string,
    phone?: string,
  }) {
    try {
      const uxcompKeys = [
        'comp.sales.general.terms.headline',
        'comp.sales.general.terms.body',
        'comp.sales.general.privacy.headline',
        'comp.sales.general.privacy.body',
        ...options.uxcompKeys,
      ];

      const uxcomps = {};

      if (uxcompKeys.length) {
        uxcompKeys.forEach(key => {
          uxcomps[key] = {
            id: options.uxHelper.uxComposite.ids[key],
            revisionId: options.uxHelper.uxComposite.revisions[key],
          };
        });
      }

      const param = {
        page: options.uxHelper.page,
        pageType: options.uxHelper.pageType,
        action: 'agree',
        method: 'form',
        data: {
          timestamp: options.uxHelper.uxComposite.timestamp,
          uxcomps,
          piiStep: options?.piiStep,
          email: options?.email,
          phone: options?.phone,
        },
      };

      this.report(param);
    } catch (e) {
      LogUtils.error('TrackingService.privacyPolicyAgreeReport Error', e);
    }
  }

  privacyPolicyAgreeReportPii(options: {
    uxHelper: UxHelper,
    REPORTING_STEPS,
    piiStep: string,
    email?: string,
    phone?: string,
    billingCompKeys: string[],
  }) {
    try {
      const trackSteps = [
        options.REPORTING_STEPS['step2_out_email'],
        options.REPORTING_STEPS['step2_out_phone'],
        options.REPORTING_STEPS['step4_out_email'],
        options.REPORTING_STEPS['step4_out_phone'],
        options.REPORTING_STEPS['step6_out'],
      ];
  
      if (!trackSteps.includes(options.piiStep)) {
        return;
      }
  
      const uxcompKeys: string[] = [];
  
      let email;
      
      let phone;

      if (options.piiStep.startsWith('2')) {
        uxcompKeys.push(options.uxHelper.getUxcompKey('pii.step2.next.button.text'));
      } else if (options.piiStep.startsWith('4')) {
        uxcompKeys.push(options.uxHelper.getUxcompKey('pii.step4.next.button.text'));
      }
  
      if (options.piiStep.includes('email')) {
        uxcompKeys.push(options.uxHelper.getUxcompKey('pii.step.email.disclosure'));
        email = options?.email;
      } else if (options.piiStep.includes('phone')) {
        uxcompKeys.push(options.uxHelper.getUxcompKey('pii.step.phone.disclosure'));
        phone = options?.phone;
      } else {
        options.billingCompKeys.forEach(key => {
          uxcompKeys.push(key);
        });
      }
      
      this.privacyPolicyAgreeReport({
        uxHelper: options.uxHelper,
        uxcompKeys,
        piiStep: options.piiStep,
        email,
        phone,
      });
    } catch (e) {
      LogUtils.error('TrackingService.privacyPolicyAgreeReportPii Error', e);
    }
  }

/**
 * 
 * @param uxComposite the value in the currentUxcomposite
 * @param uxcompKey current key of the uxComposite
 * sets the current comp key from uxComposite to rulePreloaderPost
 * sets the destination from encodeURIComponent to destination
 * sets the path with tracking refelctFrame the current uxcompkey and destination to url
 * @returns the url
 */
  getTransferDomainUrl(uxComposite: UxComposite, uxcompKey) {
    let rulePreloaderPost = uxComposite.get(uxcompKey);
    let destination = encodeURIComponent(rulePreloaderPost.destination);
    let url = `/${serverRouterPaths.tracking}/${trackingPaths.reflectFrameA}?uxcompKey=${uxcompKey}&destination=${destination}`;
    return url;
  }
/**
 * 
 * @param uxComposite the value of the current Uxcomposite
 * @param uxcompKey the comp key of the current uxComposite
 * sets the compkey from uxComposite to rulePreloaderPost
 * @returns the rulePreloaderPost with its domain and destination
 */
  shouldTransfer(uxComposite: UxComposite, uxcompKey) {
    let rulePreloaderPost = uxComposite.get(uxcompKey);
    return rulePreloaderPost && rulePreloaderPost.domain && rulePreloaderPost.destination;
  }
/**
 * 
 * @param uxComposite the value of the current uxComposte 
 * @param uxcompKey the comp key of the current uxComposte 
 * gets the transferDomainUrl from current uxcomposite and uxcomp key and sets it to url
 * logs the transferDomain with the url
 * sets the url to current window location
 */
  transferDomain(uxComposite: UxComposite, uxcompKey) {
    let url = this.getTransferDomainUrl(uxComposite, uxcompKey);
    LogUtils.debug("transferDoamin", url);
    window.location.href = url;
  }
/**
 * 
 * @param requestParams the values in params being sent to this function
 * sets a new RequestEvent to requestEvent
 * sets the requestParams to requestEvent
 * @returns the response from jsonService by passing presaleTrackingPixel and the current requestEvent
 * @returns the docs from Requestevent
 * catches and logs any errors
 */
  public preSaleTrackingPixel(requestParams) {
    let requestEvent = new RequestEvent();
    requestEvent = requestParams;
    return this.jsonService.request(serverPaths.preSaleTrackingPixel, requestEvent).then((responseEvent: ResponseEvent) => {
      return responseEvent.getDocs();
    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }
}

import {UxComposite} from "../../common/models/ux/uxComposite";
import {UserInput} from "../../common/models/user/userInput";
import {UserInfo} from "../../common/models/user/userInfo";
import {clientPaths, serverPaths} from "../../common/helpers/pathHelpers";
import {objectUtils} from "../../common/utils/objectUtils";
import {LogUtils} from "../../common/utils/logUtils";
import {ServiceHelperService} from "../services/serviceHelper.service";
import {redirectHelper} from "../helper/redirectHelper";
import {behaviorHelper} from "../helper/behaviorHelper";
import {ActivatedRoute} from "@angular/router";
import {ElementRef} from "@angular/core";
import {customClientPaths, customServerPaths} from "../../common/custom/customPathHelpers";
import {CustomBaseDirective} from "./CustomBaseDirective";

/**
 * @fileoverview this have resuable properties and function can be accessed by its child components throughout the app i.e uxComposite, userInfo
 */
export class BaseDirective extends CustomBaseDirective {
  STORAGE_RANDOM = "random";
  console = console;
  inputElements: { [id: string]: ElementRef } = {};
  initDone = false;


  public static BRAND_TYPE = {
    peopleSearch: "peopleSearch",
    review: "review",
  };

  uxComposite: UxComposite;

  userInput: UserInput;
  userInfo: UserInfo;
  trackingInputs: any;


  clientPaths = clientPaths;
  customClientPaths = customClientPaths;
  serverPaths = serverPaths;
  customServerPaths = customServerPaths;
  protected destroyed = false;

  /**
 * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
 * @param activatedRoute provides access to information about a route associated with a component that is loaded in an outlet. *
 */
  constructor(public serviceHelperService: ServiceHelperService,
              public activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
  }

  onDestroy() {
    this.destroyed = true;
  }

  // will be called by child component (customBaseComponent) for intialization
  protected baseInit(): Promise<any> {
    return Promise.resolve().then(() => { // this set user input and info from the sessionStorage
      this.setRandomValueIfNeeded();
      this.userInput = this.serviceHelperService.userInputService.getUserInput();
      this.userInfo = this.serviceHelperService.userInfoService.getUserInfo();
      return this.serviceHelperService.uxcService.getUxComposite();
    }).then((uxComposite: UxComposite) => { // set's the route params in uxComposite
      this.uxComposite = uxComposite;
      return this.activatedRoute.params.subscribe((params) => {
        this.uxComposite.setCode("params", params);
      });
    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

  /**
   * @param obj javascript object
   * @returns stringified raw json object
   */
  getRaw(obj) {
    let cloned = objectUtils.clone(obj);
    return JSON.stringify(cloned, null, 4);
  }

  isBrandTypePeopleSearch() {
    return !this.isBrandTypeReview();
  }

  isBrandTypeReview() {
    return this.domainKey.match(/truthviewer/i);
  }

  /**
   * This will direct to the provided url, start the spinner and track the event
   * @param url path to be re-directed
   * @returns false
   */
  redirect(url) {
    redirectHelper.redirect(this.serviceHelperService, url).catch((e) => {
      LogUtils.error(e);
    });
    return false;
  }

  /**
   *
   * @param $event is an event object to get event info of the clicked html elment
   * @param option have optional params for url, propagation etc.
   */
  click($event, option?: { type?: string, target?: string, url?: string, extra?, promise?, newWindow?, propagation?: boolean }) {
    let promise = behaviorHelper.click(this.serviceHelperService, $event, option).catch((e) => {
      LogUtils.error(e);
    });

    if (option) {
      option.promise = promise;
    }

    if (option && (option.propagation === true)) {
      // does nothing
    } else {
      $event.stopPropagation();
    }

    return false;
  }

  /**
   *
   * @param obj from input change
   * @returns true only if obj is checkbox or radio
   */
  isCheckBoxOrRadio(obj) {
    if (obj === "checkbox" || this.isRadio(obj)) {
      return true;
    }

    return false;
  }

   /**
   *
   * @param obj from input change
   * @returns true only if obj is radio
   */
  isRadio(obj) {
    if (obj === "radio") {
      return true;
    }

    return false;
  }

  checkEmailAddress(value) {
    this.serviceHelperService.emailService.checkEmailAddress(value)
      .then((email)=> {
        if (typeof email?.data == 'string') {
          this.userInfo.email = email.data;
        }
      })
      .catch((e) =>{LogUtils.error(e)})
  }

  /**
   * this track the user event
   * @param $event is an event object to get event info of the clicked html elment
   */
  trackEvent($event, param?: any) {
      if (!param) {
        param = {};
      }
      if (param?.type === undefined) {
        param.type = $event?.type;
      }

      if (param?.id === undefined) {
        param.id = behaviorHelper?.getFromEvent($event, "id", true);
      }
      if (param?.ids === undefined) {
        param.ids = behaviorHelper?.getFromEvent($event, "id", false);
      }

      if (!param?.value) {
        param.value = $event?.target?.value;
      }
      if (param?.id === 'cardNumber') {
        param.value = '';
      }
    
      if ($event?.target?.type === 'email' && $event?.type === 'blur') this.checkEmailAddress(param.value);

      this.serviceHelperService.trackingService.report(param).catch((e) =>{LogUtils.error(e)})
  }

  trackPIISignupSteps(param?) {
    try {
      this.serviceHelperService.trackingService.report(param).catch((e) => {
        LogUtils.error(e);
      });
    } catch (e) {
      LogUtils.error(e);
    }
  }

  /**
   * @returns object having random values for general, binary and integer
   */
  setRandomValueIfNeeded() {
    let value = JSON.parse(this.serviceHelperService.storageService.getItem(this.STORAGE_RANDOM));
    //let value = JSON.parse(localStorage.getItem(this.STORAGE_RANDOM));
    if ((!value) || (!value.random)) {
      const random = Math.random();
      value = {
        random: random,
        integer: Math.round(random * 10000000000),
        binary: Math.round(random * 10000000000) % 2,
      };
      this.serviceHelperService.storageService.set(this.STORAGE_RANDOM, value);
      //localStorage.setItem(this.STORAGE_RANDOM, JSON.stringify(value));
    }

    return value;
  }
}

import {Component, OnInit} from '@angular/core';
import {CrudService} from '../../clientCommon/services/crud.service';
import {CommerceStock} from '../../common/models/commerce/commerceStock';
import {CommerceProduct, CommerceProductDetail} from '../../common/models/commerce/commerceProduct';
import {CommerceOffer} from '../../common/models/commerce/commerceOffer';
import {ModelBase} from '../../common/models/modelBase';
import {LogUtils} from '../../common/utils/logUtils';
import {collectionClassHelper} from '../../common/decorators/database/collectionClass';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CommercePrice} from '../../common/models/commerce/commercePrice';
import {ArrayUtils} from '../../common/utils/arrayUtils';
import {SpinnerService} from '../../clientCommon/services/spinner.service';
import {clipboardUtils} from '../../clientCommon/utils/clipboardUtils';
import {inputUtils} from '../../clientCommon/utils/inputUtils';
import {StorageService} from '../../clientCommon/services/storage.service';
import {UxComposite} from '../../common/models/ux/uxComposite';
import {UxcService} from '../../clientCommon/services';
import {objectUtils} from "../../common/utils/objectUtils";
import {adminPaths} from 'src/common/helpers/pathHelpers';
import { CrudHelperSearchOptions, CrudHelperService } from '../services/crudHelper.service';

@Component({
    templateUrl: './productFullMgmt.component.html',
    styleUrls: ['./productFullMgmt.component.scss'],
    standalone: false
})

export class ProductFullMgmtComponent implements OnInit {
  commercePriceOptions = CommercePrice.PRICE_OPTION;
  uxComposite: UxComposite;
  newCommerceStock: CommerceStock = new CommerceStock();
  newCommerceProduct: CommerceProduct;
  newCommerceOffer: CommerceOffer;

  commerceStocks: CommerceStock[] = [];
  commerceProducts: CommerceProduct[] = [];
  commerceOffers: CommerceOffer[] = [];

  searchFilterKey = 'adminProductSearchFilter';
  searchFilter = {stockName: '', productName: '', offerName: ''};

  periodUnits;
  alertDuration = 5000;

  addType = 'stock';

  adminPaths = adminPaths;

  priceRemovedStatus = {};
  draftProductIdRemovedStatus = [];
  draftPriceRemovedStatus = [];
  dragIndex = 0;

  results: {
    commerceStocks: CommerceStock[]
    commerceProducts: CommerceProduct[]
    commerceOffers: CommerceOffer[]
  } = {
    commerceOffers: [],
    commerceProducts: [],
    commerceStocks: [],
  };

  searchOptions: CrudHelperSearchOptions = {
    key: 'adminProductSearchOptionFilters',
    filters: {
      commerceStocks: {
        className: collectionClassHelper.getCollectionName(CommerceStock),
        fields: ['_id', 'name'],
        value: '',
      },
      commerceProducts: {
        className: collectionClassHelper.getCollectionName(CommerceProduct),
        fields: ['_id', 'name'],
        value: '',
      },
      commerceOffers: {
        className: collectionClassHelper.getCollectionName(CommerceOffer),
        fields: ['_id', 'name'],
        value: '',
      },
    },
  };

  constructor(private crudService: CrudService,
              private snackBar: MatSnackBar,
              private uxcService: UxcService,
              private crudHelperService: CrudHelperService,
              private storageService: StorageService,
              private spinnerService: SpinnerService) {
    this.periodUnits = ArrayUtils.toArray(CommercePrice.UNIT);
  }

  ngOnInit() {
    const filters = this.storageService.get(this.searchOptions.key);
    if (filters) {
      this.searchOptions.filters = filters;
    }
    const searchFilter = this.storageService.get(this.searchFilterKey);
    if (searchFilter) {
      this.searchFilter = searchFilter;
    }

    return Promise.resolve().then(() => {
      return this.uxcService.getUxComposite();
    }).then((uxComposite: UxComposite) => {
      this.uxComposite = uxComposite;
      this.setNewCommerceProduct();
      this.setNewCommerceOffer();
    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

  setNewCommerceProduct() {
    this.newCommerceProduct = new CommerceProduct();
    this.newCommerceProduct.draft.commerceStockDetails = [{commerceStockId: '', quantity: 100, period: {quantity: 1, unit: 'm'}}];
  }

  setNewCommerceOffer() {
    this.newCommerceOffer = new CommerceOffer();
    this.newCommerceOffer.draft.payeeId = this.uxComposite.payeeId;
    this.newCommerceOffer.draft.commercePrice.addStandardTrial();
    this.newCommerceOffer.draft.commercePrice.addStandardRecurring();
    this.newCommerceOffer.draft.provides = [CommerceOffer.PROVIDES.membership];
    this.newCommerceOffer.draft.tempClient.optionsString = JSON.stringify(this.newCommerceOffer.draft.options ? this.newCommerceOffer.draft.options : {});
  }

  findCommerceProducts(ids) {
    let idsString = '';
    ids.forEach((id) => {
      if (idsString.length) {
        idsString += '|';
      }
      idsString += id;
    });

    idsString = '/^' + idsString + '$/';
    const selectQuery = {status: ModelBase.STATUS.active, _id: idsString};
    return this.crudService.read(CrudService.URL.admin, collectionClassHelper.getCollectionName(CommerceProduct), selectQuery).then((result) => {
      return result;
    }).catch((e) => {
      LogUtils.error(e);
    });
  }

  findCommerceProductDetails(commerceProductIds): Promise<CommerceProductDetail[]> {
    const commerceProductDetails = [];
    return this.findCommerceProducts(commerceProductIds).then((commerceProducts: CommerceProduct[]) => {
      commerceProductIds.forEach((commerceProductId) => {
        commerceProducts.some((commerceProduct) => {
          if (commerceProduct._id === commerceProductId) {
            commerceProductDetails.push({
              commerceProductId,
              commerceStockDetails: commerceProduct.commerceStockDetails,
            });
            return true;
          }
        });

      });
      return commerceProductDetails;
    });
  }


  add(obj: ModelBase<any>, ext1?) {
    return Promise.resolve().then(() => {
      this.spinnerService.spin();
    }).then(() => {
      if (!obj.draft.name) {
        return Promise.reject(false);
      }
    }).then(() => {
      obj.draft.status = ModelBase.STATUS.active;
      if (obj instanceof CommerceStock) {
        return this.crudService.create(CrudService.URL.admin, obj).then(() => {
          this.newCommerceStock = new CommerceStock();
        });
      } else if (obj instanceof CommerceProduct) {
        this.newCommerceProduct.draft.commerceStockIds = [ext1];
        this.newCommerceProduct.draft.commerceStockDetails[0].commerceStockId = ext1;
        return this.crudService.create(CrudService.URL.admin, obj).then(() => {
          this.setNewCommerceProduct();
        });
      } else if (obj instanceof CommerceOffer) {
        this.newCommerceOffer.draft.commerceProductIds = this.newCommerceOffer.draft.commerceProductIds.filter(id => !this.draftProductIdRemovedStatus.includes(id));
        this.newCommerceOffer.draft.commercePrice.prices = this.newCommerceOffer.draft.commercePrice.prices.filter(price => !this.draftPriceRemovedStatus.includes(price));
        this.draftProductIdRemovedStatus = [];
        this.draftPriceRemovedStatus = [];
        this.newCommerceOffer.draft.provides = [CommerceOffer.PROVIDES.membership];
        // this.newCommerceOffer.draft.commerceProductDetails = [{commerceProductId: ext1, quantity: 1}];
        this.newCommerceOffer.draft.commerceProductDetails = [];
        return this.findCommerceProductDetails(this.newCommerceOffer.draft.commerceProductIds).then((commerceProductDetails) => {
          this.newCommerceOffer.draft.commerceProductDetails = commerceProductDetails;
          return this.crudService.create(CrudService.URL.admin, obj);
        }).then(() => {
          this.setNewCommerceOffer();
        });
      } else {
        return Promise.reject(false);
      }
    }).then(() => {
      this.snackBar.open('adding done.' + obj.draft.name, 'Add', {duration: this.alertDuration});
    }).then(() => {
      return this.search();
    }).catch((e) => {
      LogUtils.error(e);
      this.snackBar.open('ERROR adding ' + obj.draft.name, 'Add', {duration: this.alertDuration});
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  search(): Promise<any> {
    this.storageService.set(this.searchFilterKey, this.searchFilter);
    this.searchOptions.sort = [["_id", "asc"]];
    return this.crudHelperService.search(this.searchOptions, this.results).then(() => {

    }).catch((e) => {
      LogUtils.error(e);
    })
  }

  update(obj: ModelBase<any>) {
    return Promise.resolve().then(() => {
      this.spinnerService.spin();
    }).then(() => {
      if (obj instanceof CommerceStock) {
        // no special treat
      } else if (obj instanceof CommerceProduct) {
        obj.draft.commerceStockDetails[0].commerceStockId = obj.draft.commerceStockIds[0];
      } else if (obj instanceof CommerceOffer) {
        obj.draft.commercePrice.prices = obj.draft.commercePrice.prices.filter((price) => !this.priceRemovedStatus[obj._id].includes(price));
        delete this.priceRemovedStatus[obj.draft._id];
        obj.draft.payeeId = this.uxComposite.payeeId;
        obj.draft.provides = [CommerceOffer.PROVIDES.membership];
        return this.findCommerceProductDetails(obj.draft.commerceProductIds).then((commerceProductDetails) => {
          obj.draft.commerceProductDetails = commerceProductDetails;
        });
      }
    }).then(() => {
      return this.crudService.updateDoc(CrudService.URL.admin, [obj]);
    }).then(() => {
      this.snackBar.open('updating done. ' + obj.draft.name, 'Update', {duration: this.alertDuration});
      return this.search();
    }).catch((e) => {
      this.snackBar.open('ERROR updating ' + obj.draft.name, 'Error', {duration: this.alertDuration});
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  copyId(obj) {
    if (obj) {
      this.copyTextToClipboard(obj._id);
    }
  }

  copyName(obj) {
    if (obj) {
      this.copyTextToClipboard(obj.name);
    }
  }

  copyTextToClipboard(text) {
    if (clipboardUtils.copyTextToClipboard(text)) {
      this.snackBar.open(`${text}`, 'Copy', {duration: this.alertDuration});
    } else {
      this.snackBar.open(`${text}`, 'Fail', {duration: this.alertDuration});
    }
  }

  addItemToArray(array: any[], form) {
    if (form.value) {
      array.push(form.value);
      form.value = null;
    }
  }

  removeItemInArray(array: any[], index) {
    array.splice(index, 1);
  }

  addPrice(commerceOffer: CommerceOffer) {
    commerceOffer.commercePrice.addStandardRecurring();
  }

  keyDownEvent(event) {
    if (inputUtils.isEnterEvent(event)) {
      return this.search();
    }
  }

  fixCommercrPrice(commercePrice: CommercePrice) {
    if (commercePrice && commercePrice.prices && commercePrice.prices.length) {
      commercePrice.prices.forEach((price) => {
        if (!price.options) {
          price.options = {};
        }
      });
    }
  }

  processCommerceOfferOptionsEvent($event, commerceOffer) {
    commerceOffer.tempClient.optionsString = $event.target.value;
    try {
      commerceOffer.options = JSON.parse($event.target.value);
    } catch (e) {
      LogUtils.info(e.message);
    }
  }

  isJsonParsable(value) {
    try {
      const parsedValue = JSON.parse(value);
      return objectUtils.isObject(parsedValue);
    } catch (e) {
      return false;
    }
  }

  isValid(doc: CommerceOffer) {
    if (doc instanceof CommerceOffer) {
      return doc.draft.name &&
        doc.draft.commercePrice &&
        doc.draft.commercePrice.isValid() &&
        doc.isDiffDraft() &&
        doc.draft.commerceProductIds.length > 0 &&
        this.isJsonParsable(doc.draft.tempClient.optionsString);
    }
  }

  toggleEdit(obj: ModelBase<any>) {
    obj.tempClient.edit = !obj.tempClient.edit;
    if (obj.tempClient.edit) {
      if (obj instanceof CommerceOffer) {
        this.priceRemovedStatus[obj._id] = [];
      }
    } else {
      if (obj instanceof CommerceOffer) {
        delete this.priceRemovedStatus[obj._id];
      }
    }
  }

  removeTempFromArray(obj: ModelBase<any>, index, draft?: boolean) {
    if (draft) {
      if (obj instanceof CommerceOffer) {
        this.draftProductIdRemovedStatus.push(obj.draft.commerceProductIds[index]);
      } else if (obj instanceof CommercePrice) {
        this.draftPriceRemovedStatus.push(obj.prices[index]);
      }
    }
    else if (obj instanceof CommerceOffer) {
      this.priceRemovedStatus[obj._id].push(obj.draft.commercePrice.prices[index]);
    }
  }

  cancelRemoveTempFromArray(obj: ModelBase<any>, index, draft?: boolean) {
    if (draft) {
      if (obj instanceof CommerceOffer) {
        this.draftProductIdRemovedStatus = this.draftProductIdRemovedStatus.filter(price => price !== obj.draft.commerceProductIds[index]);
      } else if (obj instanceof CommercePrice) {
        this.draftPriceRemovedStatus = this.draftPriceRemovedStatus.filter(price => price !== obj.prices[index]);
      }
    }
    else if (obj instanceof CommerceOffer) {
      this.priceRemovedStatus[obj._id] = this.priceRemovedStatus[obj._id].filter(price => price !== obj.draft.commercePrice.prices[index]);
    }
  }

  isTempRemoved(obj: ModelBase<any>, index, draft?: boolean) {
    if (draft) {
      if (obj instanceof CommerceOffer) {
        return this.draftProductIdRemovedStatus.includes(obj.draft.commerceProductIds[index]);
      } else if (obj instanceof CommercePrice) {
        return this.draftPriceRemovedStatus.includes(obj.prices[index]);
      }
    }
    else if (obj instanceof CommerceOffer) {
      return this.priceRemovedStatus[obj._id].includes(obj.draft.commercePrice.prices[index]);
    }
  }

  isTempEdited(obj: ModelBase<any>, draft?: boolean) {
    if (draft) {
      if (obj instanceof CommerceOffer) {
        return this.draftProductIdRemovedStatus.length > 0;
      }
    }
    else if (obj instanceof CommerceOffer) {
      return this.priceRemovedStatus[obj._id].length > 0;
    }
  }

  isNewItem(arr: any[], id) {
    return !arr.includes(id);
  }

  dragStart(event, array: [], index) {
    this.dragIndex = index;
  }


  dragOver(event, array: [], index) {
    event.preventDefault();
  }

  drop(event, array: [], index) {
    if (index >= 0 && this.dragIndex >= 0) {
      const swap = array[this.dragIndex];
      array.splice(this.dragIndex, 1);
      array.splice(index, 0, swap);
    }
  }

  dragEnd(event, array: [], index) {
    this.dragIndex = -1;
  }

  up(event, array: [], index) {
    let changeIndex = index - 1;
    if (index >= 0 && changeIndex >= 0) {
      const swap = array[index];
      array.splice(index, 1);
      array.splice(changeIndex, 0, swap);
    }    
  }
  down(event, array: [], index) {
    let changeIndex = index + 1;    
    if (index >= 0 && changeIndex >= 0 && changeIndex < array.length) {
      const swap = array[index];
      array.splice(index, 1);
      array.splice(changeIndex, 0, swap);
    }    
  }  

  getUnitString(val) {
    if (val === 'd') return 'Day';
    if (val === 'm') return 'Month';
    if (val === 'y') return 'Year';
  }
}

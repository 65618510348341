import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, tap } from 'rxjs';
import { UxcBuildStatus } from './uxcFullMgmt.component';
import { LogUtils } from 'src/common/utils/logUtils';

interface BuildStatus {
  startTimestamp: number;
  endTimestamp: number;
  status: UxcBuildStatus;
}

interface DisplayStatus {
  startTimestamp: number;
  endTimestamp: number;
  status: string;
  uxcId: string;
}

interface UxcBuildModalData {
  statusSubject: BehaviorSubject<Record<string, BuildStatus>>;
}

@Component({
    selector: 'uxc-build-modal',
    templateUrl: 'uxcBuildModal.component.html',
    standalone: false
})
export class UxcBuildModalDialog implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UxcBuildModalData
  ) {}

  statusData: Array<DisplayStatus> = [];
  failedUxcs = '';
  curProgress = 0;
  totalCount = 0;
  successCount = 0;
  totalDuration = '0.00';
  finishedCount = 0;
  failedCount = 0;
  isStartTimer = false;
  timer: ReturnType<typeof setInterval>;

  ngOnInit() {
    this.data.statusSubject
      .asObservable()
      .pipe(tap(this.handleTimer.bind(this)))
      .subscribe(this.handleStatus.bind(this));
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  getDuration(data: DisplayStatus) {
    return ((data.endTimestamp - data.startTimestamp) / 1000).toFixed(2);
  }

  private handleStatus(status: Record<string, BuildStatus>) {
    this.statusData = Object.keys(status).map((k) => ({
      ...status[k],
      uxcId: k,
    }));

    let finished = true;
    
    let sum = 0;
    
    let finishedCount = 0;
    
    Object.keys(status).forEach((k) => {
      if (status[k].endTimestamp === 0) {
        finished = false;
      } else {
        sum += status[k].endTimestamp - status[k].startTimestamp;
        finishedCount += 1;
      }
    })
    
    if (finished === true) {
      this.failedUxcs = Object.keys(status).filter((k) => {
        return status[k].status === 'failed';
      }).join('|');
    }
    
    this.totalCount = Object.keys(status).length;
    
    this.successCount = Object.keys(status).filter((k) => status[k].status === 'success').length;
    
    this.failedCount = Object.keys(status).filter((k) => status[k].status === 'failed').length;
    
    this.curProgress = Math.ceil(finishedCount / this.totalCount * 100);
    
    this.totalDuration = (sum / 1000).toFixed(2);
    
    this.finishedCount = finishedCount;
  }

  private handleTimer(status: Record<string, BuildStatus>) {
    try {
      if (this.isStart(status)) {
        this.startTimer();
      } else if (this.isEnd(status)) {
        clearInterval(this.timer);
      }
    } catch (e) {
      LogUtils.warn('UxcBuildModalDialog.handleTimer Error', e);

      clearInterval(this.timer);
    }
  }

  private isStart(status: Record<string, BuildStatus>) {
    if (this.isStartTimer) {
      return false;
    }

    return this.mapValues(status)
      .filter(status => status !== 'building')
      .length === 0;
  }

  private isEnd(status: Record<string, BuildStatus>) {
    return this.mapValues(status)
      .filter(status => status === 'building')
      .length === 0;
  }

  private startTimer() {
    this.isStartTimer = true;

    this.timer = setInterval(() => {
      this.totalDuration = (+this.totalDuration + 0.01).toFixed(2);
    }, 10);
  }

  private mapValues(
    status: Record<string, BuildStatus>, 
    key: string = 'status',
  ) {
    return Object
      .values(status)
      .map(value => value[key]);
  }
}
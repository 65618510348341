import { Component, ViewChild, OnInit, OnDestroy } from "@angular/core";
import { AdminAuthGuard } from "./guards/admin.auth.guard";
import { CsrAuthGuard } from "./guards/csr.auth.guard";
import { SpinnerService } from "../clientCommon/services/spinner.service";
import { adminPaths } from "../common/helpers/pathHelpers";
import { MatSidenav } from '@angular/material/sidenav';
import { AuthenticationService } from "../clientCommon/services/authentication.service";
import { TimesheetService } from "./services/timesheet.service";
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { User } from "../common/models/user/user";
import { UxHelper } from "src/clientCommon/helper/uxHelper";
import { UxcService } from "src/clientCommon/services/uxc.service";
import { LogUtils } from "src/common/utils/logUtils";
import { UxComposite } from "src/common/models/ux/uxComposite";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: false
})

export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') sidenav: MatSidenav;

  private subscription: Subscription;
  private csrMessageSubscription: Subscription;
  adminPaths = adminPaths;
  isLoggedIn = false;
  isOpen=false;
  uxComposit: UxComposite;
  isInfoOverlayInit = false;
  info: any = "";
  uxhelper = new UxHelper();

  constructor(public spinnerService: SpinnerService,
    public adminAuthGuard: AdminAuthGuard,
    public csrAuthGuard: CsrAuthGuard,
    public authService: AuthenticationService,
    public timesheetService: TimesheetService,
    private router: Router,
    private uxcService: UxcService) {      
      this.loadUxc();
      this.uxcService.afterUxInit.subscribe((data:any) => {
        this.isInfoOverlayInit = true;
        this.uxComposit = data.uxComposite
        this.info = this.uxComposit.getUxcomp('comp.info.overlay');        
      })
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.sidenav.close();
        if (val.url === '/login') this.isLoggedIn = false;
        else this.isLoggedIn = true;
        if (this.isLoggedIn) {
          if (!this.timesheetService.lastCsrRecord || !this.timesheetService.lastRecord) {
            this.findTimesheet();
          }
        }
      }
    })
  }
  loadUxc() {
    let params = new URLSearchParams(window.location.search);
    let uxc = params.get('uxc');

    this.uxcService
    .getOrFindUxComposite({ type: "", uxcId: uxc })
    .then((uxComposite: UxComposite) => {
      // uxComposite.setCode("refer", this.referService.getRefer());
      // uxComposite.setCode("referString", this.referService.getReferString());
    }).catch((e) => {
      LogUtils.error(e);
    });
  }

  close() {
    this.sidenav.close();
  }

  ngOnInit() {
    this.findTimesheet();
    this.subscription = interval(1000).subscribe(() => {
      this.timesheetService.getCurrentTimestamp();
    })
    this.csrMessageSubscription = interval(10000).subscribe(() => {
      this.findTimesheet();
    })
  }

  findTimesheet() {
    if (this.authService.isLoggedIn()) {
      const user = this.authService.getUser();
      if (user.roles.includes(User.ROLES.csr)) {
        this.timesheetService.findLatestTimeSheet();
      }
      this.timesheetService.findLatestGlobalTimeSheet();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}



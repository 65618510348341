import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JsonService } from '../../clientCommon/services/json.service';
import { ActivatedRoute } from '@angular/router';
import { SpinnerService } from '../../clientCommon/services/spinner.service';
import { ResponseEvent } from '../../common/event/responseEvent';
import { User } from '../../common/models/user/user';
import { serverPaths, adminPaths } from '../../common/helpers/pathHelpers';
import { LogUtils } from '../../common/utils/logUtils';
import { BaseDirective } from 'src/clientCommon/directives/BaseDirective';
import { ServiceHelperService } from 'src/clientCommon/services/serviceHelper.service';
import { TimeSheet } from '../../common/models/timesheet';
import { CsrAuthGuard } from '../guards/csr.auth.guard';
import { permissionUtils } from '../../common/utils/permissionUtils';
import momenttz from 'moment-timezone';
interface TimeSheetSummary {
  ownerId: string;
  name: string;
  contact: string[];
  timeInToday: number;
  hoursToday: number;
  hoursYesterday: number;
  weeklyHours: number;
  status: string;
  notes: string[];
}

@Component({
    templateUrl: './timeSheetSummary.component.html',
    styleUrls: ['./timeSheetSummary.component.scss'],
    standalone: false
})

export class TimeSheetSummaryComponent extends BaseDirective implements OnInit, AfterViewInit {
  userId = '';
  users: User[] = [];
  timesheetRecords: TimeSheetSummary[] = [];
  timesheetRecordGroup: Record<string, TimeSheet[]> = {};
  timesheetColumns = ['name', 'contact', 'timeInToday', 'status', 'hoursToday', 'weeklyHours', 'notes'];
  totalHoursYesterday = 0;

  startDate = new Date();

  private readUrl = '/api/manage/admin/user/read';

  constructor(private jsonService: JsonService,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private csrAuthGuard: CsrAuthGuard,
    public serviceHelperService: ServiceHelperService,
  ) {
    super(serviceHelperService, route);
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.userId = params.userId;
      let dat = new Date();
      dat.setDate(dat.getDate() - (dat.getDay() || 7));
      this.startDate = new Date(dat);
      super.baseInit().then(() => this.init());
    });
  }

  ngAfterViewInit() { }

  getWeeklyRecords() {
    let dat = new Date(this.startDate);
    const startDate = dat.toString().slice(4, 15);
    dat = new Date(this.startDate);
    dat.setDate(dat.getDate() + 6);
    const endDate = new Date(dat).toString().slice(4, 15);
    this.spinnerService.spin();
    return this.jsonService.json(serverPaths.timeSheetGetWeeklyRecord, {
      startDate,
      endDate
    }).then((responseEvent: ResponseEvent) => {
      const timesheets = responseEvent.getDocs() as TimeSheet[];
      this.convertTimesheetsToGroup(timesheets);
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    })
  }

  init() {
    this.spinnerService.spin();
    this.findUser().then(() => {
      return this.getWeeklyRecords();
    }).finally(() => {
      this.spinnerService.unspin();
    })
  }

  findUser() {
    return this.jsonService.json(serverPaths.timeSheetGetUsers, {}).then((responseEvent: ResponseEvent) => {
      this.users = responseEvent.getDocs();
    }).catch((e) => {
      LogUtils.error(e);
    });
  }

  rangeFilter = (aDate: Date): boolean => {
    if (!aDate) {
      return false;
    }
    const date = aDate.getDay();
    return date === 0;
  }

  handleChangeStartDate(value) {
    this.startDate = new Date(value);
    this.getWeeklyRecords();
  }

  convertTimesheetsToGroup(timesheets: TimeSheet[]) {
    const group: Record<string, TimeSheet[]> = {};
    const timesheetGroup: TimeSheetSummary[] = [];
    timesheets.forEach((timesheet) => {
      if (group[timesheet.ownerId]) {
        group[timesheet.ownerId].push(timesheet);
      } else {
        group[timesheet.ownerId] = [timesheet];
      }
    });
    this.timesheetRecordGroup = group;
    Object.keys(group).forEach((userId) => {

      const startTimestamp = momenttz(new Date().setHours(0,0,0,0)).tz('America/New_York').unix() * 1000;
      const endTimestamp = startTimestamp + 1000 * 60 * 60 * 24 - 1;
      const yesterdayTimestamp = startTimestamp - 1000 * 60 * 60 * 24;
      let records = group[userId];
      let todayRecords = [];
      let weeklyHours = 0;
      let notes = [];
      let hoursToday = 0;
      let hoursYesterday = 0;
      todayRecords = records.filter((r) => r.timestamp > startTimestamp);
      let timeInToday = 0;
      if (todayRecords.length) {
        timeInToday = todayRecords[0].timestamp
      }
      const todayReverseRecords = records.filter((r) => r.timestamp > startTimestamp).reverse();
      const user = this.users.find((user) => user._id === userId);
      let status = '';
      if (todayReverseRecords.length) {
        status = todayReverseRecords[0]?.type === TimeSheet.TYPE.clock_in ? 'In' : 'Out';
      }
      if (records.length > 0) {
        if (records[0].type !== TimeSheet.TYPE.clock_in) {
          records = records.slice(1);
        }
        if (records.length > 0) {
          if (records[records.length - 1].type !== TimeSheet.TYPE.clock_out) {
            records = records.slice(0, records.length - 1);
          }
        }
        records.forEach((record, index) => {
          if (index % 2 === 1) {
            weeklyHours += (records[index].timestamp - records[index - 1].timestamp);
          }
          if (record.note) {
            notes.push(record.note);
          }
          if (record.timestamp >= startTimestamp && record.timestamp <= endTimestamp) {
            if (timeInToday === 0) {
              timeInToday = record.timestamp;
            }
            hoursToday += (record.type === TimeSheet.TYPE.clock_in ? -record.timestamp : record.timestamp);
          } else if (record.timestamp >= yesterdayTimestamp && record.timestamp < startTimestamp) {
            hoursYesterday += (record.type === TimeSheet.TYPE.clock_in ? -record.timestamp : record.timestamp);
          }
        });
      }
      timesheetGroup.push({
        ownerId: user?._id,
        weeklyHours,
        notes,
        name: `${user?.firstName} ${user?.lastName}`,
        contact: [`${user?.email}`],
        hoursToday,
        hoursYesterday,
        timeInToday,
        status
      });
    });
    this.timesheetRecords = timesheetGroup;
    this.timesheetRecords.filter((r) => {
      const user = this.users.find((u) => u._id === r.ownerId);
      if (!user) {
        return false;
      }
      return !permissionUtils.hasPermissionPath(user, "/" + serverPaths.manageCsrTimesheetUpdate);
    }).forEach((r) => {
      this.totalHoursYesterday += r.hoursYesterday
    });
  }

  sanitizeHours(value: number) {
    if (value === 0) {
      return '0h';
    }
    let seconds = Math.round(value / 1000);
    return (seconds / 3600).toFixed(2) + 'h';
  }

  sanitizeDate(value: number) {
    if (value === 0) {
      return '';
    }
    let startTimestamp = new Date().setHours(0, 0, 0, 0);
    const offset = value - startTimestamp;
    let seconds = Math.floor(offset / 1000);
    let minutes = Math.floor(seconds / 60);
    seconds %= 60;
    let hours = Math.floor(minutes / 60);
    minutes %= 60;
    return `${hours < 10 ? `0${hours}` : `${hours}`}:${minutes < 10 ? `0${minutes}` : `${minutes}`}`;
  }

  viewDetailPage(row) {
    if (!this.csrAuthGuard.canActivatePath(adminPaths.timesheet)) return;
    window.open(adminPaths.timesheet + '/' + row.ownerId);
  }

  checkInvalid(timestamp: number) {
    if (timestamp < 0) {
      return true;
    }
    if (timestamp > 1000 * 3600 * 12) {
      return true;
    }
    return false
  }
}

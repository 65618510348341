export var idProtectionProductKeys = {
    idDarkWebMonitoring: "idDarkWebMonitoring",
    idProtectionTheftRecovery: "idProtectionTheftRecovery",
    idProtectionLostWallet: "idProtectionLostWallet",
    idBreachScanReport: "idBreachScanReport",
    idProtectionFraudInsurance: "idProtectionFraudInsurance",
    idProtectionFraudInsurance1M: "idProtectionFraudInsurance1M"
};

export const idProtectionProviderTypes = {
    customerEnrollment: "PrivacyMaxxCustomerEnrollment",
    customerUnenrollment: "PrivacyMaxxCustomerUnenrollment",
    customerReenrollment: "PrivacyMaxxCustomerReEnrollment",
    customerFamilyProvisioning: "customerFamilyProvisioning",
    customerLostwalletProvisioning: "customerFamilyProvisioning",
    customerMonitoringGetAlerts: "CustomerMonitoringGetAlerts",
    dwbEmailMonitoringEnrollment: "DwbEmailMonitoringEnrollment",
    dwbEmailMonitoringCancelEnrollment: "DwbEmailMonitoringCancelEnrollment",
    dwbUpdateEmailMonitoringEnrollment: "DwbUpdateEmailMonitoringEnrollment",
    dwbEmailMonitoringGetFullResults: "DwbEmailMonitoringGetFullResults",
    dwbEmailMonitoringGetAlerts: "DwbEmailMonitoringGetAlerts"
} as const;
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { StorageService } from "./storage.service";

@Injectable()
export class LimitStorageService {

    constructor(
        private storageService: StorageService) {
    }

    isSearchLimited = new BehaviorSubject(this.searchLimited);

    set searchLimited(value: string) {
        this.isSearchLimited.next(value);
        this.storageService.setItem('isSearchLimited', value);
        //localStorage.setItem('isSearchLimited', value);
    }

    get searchLimited() {
        return this.storageService.getItem('isisSearchLimited') || 'false';
        //return localStorage.getItem('isSearchLimited') || 'false';
    }

}
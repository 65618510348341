import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { User } from 'src/common/models/user/user';
import { CommerceOrder } from 'src/common/models/commerce/commerceOrder';
import { UxComposite } from 'src/common/models/ux/uxComposite';
import { custom } from '../../custom/custom.class';
import { CommerceToken } from 'src/common/models/commerce/commerceToken';
import { configUtils } from 'src/app/utils/ConfigUtils';
import { Router } from '@angular/router';
import { idProtectionUtils } from 'src/common/custom/idProtection/idProtectionUtils';

@Component({
    selector: 'app-csr-customer-summary',
    templateUrl: './customer-summary.component.html',
    styleUrls: ['./customer-summary.component.scss'],
    standalone: false
})

export class CustomerSummaryComponent implements OnInit, OnChanges {
  @Input() user: User;
  @Input() baseCommerceOrder: CommerceOrder;
  @Input() commerceOrders: CommerceOrder[];
  @Input() uxComposite: UxComposite;
  @Input() customerUxComposite: UxComposite;
  @Input() commerceToken: CommerceToken;
  @Input() router: Router;
  @Input() agentAddress: any = "";
  @Input() agentPhoneNumber: any = "";
  @Input() brandName: string = "";
  @Input() noteData: any;
  @Input() editSummary: any = false;
  @Input() adminFlag: any = false;
  @Input() pMaxxCustomerStatus: any = false;
  @Output() notifyUpdateUser: EventEmitter<{user:User, confirmMsg?: string}> = new EventEmitter();

  timeFormat = 'yyyy-MM-dd hh:mm:ss aa';
  custom = custom;
  useIdProtection: boolean = false;

  ngOnInit() {
    this.updateUseIdProtection();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.commerceOrders.firstChange) {
      this.updateUseIdProtection();
    }
  }

  detailLink() {
    const csrPath = configUtils.getCsrPathFromVersion(this.uxComposite);
    this.router.navigate([csrPath + '/' + this.user._id]);
  }

  updateUser(user, message?: string) {
    this.notifyUpdateUser.emit({user, confirmMsg: message});
  }

  getAddress(commerceToken) {
    const { street1, state, city, zip } = commerceToken.billingAddress;
    return `${street1} ${city} ${state} ${zip}`;
  }
  
  getCardInfo(commerceToken) {
    let ccNumber = '';
    if (commerceToken) {
      const bin = commerceToken.bin;
      const last4 = commerceToken.last4;
      const length = commerceToken?.length - last4?.length - bin?.length;
      if (length) {
        ccNumber = bin;
        ccNumber += new Array(length).fill('*').join('');
        ccNumber += last4;
      }
    }

    return ccNumber;
  }

  private updateUseIdProtection() {
    if (!this.commerceOrders?.length) {
      this.useIdProtection = false;

      return;
    }

    this.useIdProtection = !!idProtectionUtils.getProductIdForCustomerEnrollment(this.commerceOrders);
  }
}  
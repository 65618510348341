import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {routing} from './app.routing';
import {AuthenticationService} from '../clientCommon/services/authentication.service';
import {AuthGuard} from './guards';
import {AlertService} from '../clientCommon/services/alert.service';

import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {EmailComponent, MenuItemComponent, ListItemComponent} from './email';
import {DeleteConfirmDialog, UxcFullMgmtComponent} from './uxc/uxcFullMgmt.component';
import {CrudService} from '../clientCommon/services/crud.service';
import {JsonService} from '../clientCommon/services/json.service';
import {APP_BASE_HREF} from '@angular/common';
import {ProductFullMgmtComponent} from './product/productFullMgmt.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatNativeDateModule, MatOptionModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {CdkColumnDef} from '@angular/cdk/table';
import {CsrAuthGuard} from './guards/csr.auth.guard';
import {AdminAuthGuard} from './guards/admin.auth.guard';
import {CSR_DECLARATIONS} from './csr/index';
import {ToDateTimePipe} from '../clientCommon/pipes/toDateTime.pipe';
import {SpinnerService} from '../clientCommon/services/spinner.service';
import {StorageService} from '../clientCommon/services/storage.service';
import {CommerceService} from '../clientCommon/services/commerce.service';
import {ReferService} from '../clientCommon/services/refer.service';
import {TrackingService} from '../clientCommon/services/tracking.service';
import {ToDateObjectPipe} from '../clientCommon/pipes/toDateObject.pipe';
import {USERS_DECLARATIONS} from './users/index';
import {UserInputService} from '../clientCommon/services/userInput.service';
import {UserInfoService} from '../clientCommon/services/userInfo.service';
import {UxcService} from '../clientCommon/services/uxc.service';
import {PixelService} from '../clientCommon/services/pixel.service';
import {PageService} from '../clientCommon/services/page.service';
import {EmailService} from '../clientCommon/services/email.service';
import {CookieService} from '../clientCommon/services/cookie.service';
import {ToArrayPipe} from '../clientCommon/pipes/toArray.pipe';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {ServiceHelperService} from '../clientCommon/services/serviceHelper.service';
import {ProgressorService} from '../clientCommon/services/ProgressorService';
import {ReversePipe} from '../clientCommon/pipes/reverse.pipe';
import {CustomServiceHelperService} from '../clientCommon/custom/services/customServiceHelper.service';
import {CUSTOM_DECLARATIONS, CUSTOM_IMPORTS, CUSTOM_PROVIDERS} from './custom/custom.module';
import {ContentMgmtComponent, UxcBuildModalDialog, UxcFullMgmtComponentV1, UxDetailComponent, UxHistoryComponent} from './uxc';
import {InputTrackingService} from '../clientCommon/services/InputTracking.service';
import {CrudHelperService} from './services/crudHelper.service';
import {ManageEmailService} from '../clientCommon/services/manageEmail.service';
import {ProductFullMgmtComponentV1} from './product/productFullMgmtV1.component';
import {EditorModule, TINYMCE_SCRIPT_SRC} from '@tinymce/tinymce-angular';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatRadioModule} from '@angular/material/radio';
import {TimesheetService} from './services/timesheet.service';
import { NotesManagementComponent } from './csr/notes-management/notes-management.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { ConfirmDialogComponent } from './csr/notes-management/confirm-dialog/confirm-dialog.component'
import { AcknowledgeDialogComponent } from './csr/notes-management/acknowledge-dialog/acknowledge-dialog.component';
import { PreviewDetailDialogComponent } from './csr/notes-management/preview-detail-dialog/preview-detail-dialog.component';
import { AdminCaptchaService, ReferralService } from 'src/clientCommon/services';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import { COMPONENT_DECLARATIONS, COMPONENT_EXPORTS } from './components';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { CommerceOfferService } from '../clientCommon/services/commerceOffer.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LimitStorageService } from 'src/clientCommon/services/limitStorage.service';
import { adminCaptchaInterceptor } from 'src/clientCommon/interceptors/captcha.interceptor';
import { ScriptLoaderService } from '../clientCommon/services/scriptLoader.service';
import { DateFormatPipe } from 'src/app/pipe/dateFormat.pipe';
import { UxRevisionHistoryComponent } from './uxc/uxRevisionHistory.component';
import { RemoveInfoService } from '../clientCommon/services/removeInfo.service';
import { ReplaceRegexPipe } from './pipe/replaceRegex.pipe';
import { DiffStringPipe } from './pipe/diffString.pipe';
import { JsonBeautifierPipe } from './pipe/jsonBeautifier.pipe';
import { UxRevisionHistoryPageComponent } from './uxc/uxRevisionHistoryPage.component';
export const appModuleDefiner = {
  declarations: [
    AppComponent,
    LoginComponent,
    UxcFullMgmtComponent,
    UxcFullMgmtComponentV1,
    UxDetailComponent,
    UxHistoryComponent,
    UxRevisionHistoryPageComponent,
    UxRevisionHistoryComponent,
    DeleteConfirmDialog,
    UxcBuildModalDialog,
    ProductFullMgmtComponent,
    ProductFullMgmtComponentV1,
    ContentMgmtComponent,
    EmailComponent,
    USERS_DECLARATIONS,
    CSR_DECLARATIONS,
    ToDateTimePipe,
    ToDateObjectPipe,
    ToArrayPipe,
    ReversePipe,
    MenuItemComponent,
    ListItemComponent,
    ...CUSTOM_DECLARATIONS,
    NotesManagementComponent,
    ConfirmDialogComponent,
    AcknowledgeDialogComponent,
    PreviewDetailDialogComponent,
    ...COMPONENT_DECLARATIONS,
    DateFormatPipe,
    ReplaceRegexPipe,
    DiffStringPipe,
    JsonBeautifierPipe,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    routing,
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatSidenavModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatListModule,
    MatSelectModule,
    MatSidenavModule,
    MatOptionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    EditorModule,
    MatProgressBarModule,
    MatBottomSheetModule,
    MatRadioModule,
    MatGridListModule,
    CdkAccordionModule,
    DragDropModule,
    ...CUSTOM_IMPORTS,
  ],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/admin'},
    {provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'},
    provideHttpClient(
      withInterceptors([adminCaptchaInterceptor]),
    ),
    CustomServiceHelperService,
    ServiceHelperService,
    AuthGuard,
    CsrAuthGuard,
    AdminAuthGuard,
    AlertService,
    AuthenticationService,
    CrudService,
    CrudHelperService,
    JsonService,
    SpinnerService,
    StorageService,
    LimitStorageService,
    CommerceService,
    CommerceOfferService,
    ReferService,
    TrackingService,
    UserInputService,
    UserInfoService,
    InputTrackingService,
    UxcService,
    PixelService,
    PageService,
    EmailService,
    ReferralService,
    CookieService,
    ProgressorService,
    ManageEmailService,
    CdkColumnDef,
    TimesheetService,
    AdminCaptchaService,
    RemoveInfoService,
    ScriptLoaderService,
    ...CUSTOM_PROVIDERS,
  ],
  bootstrap: [AppComponent]
};

@NgModule({
  declarations: appModuleDefiner.declarations,
  imports: appModuleDefiner.imports,
  providers: appModuleDefiner.providers,
  bootstrap: appModuleDefiner.bootstrap,
  exports: [ConfirmDialogComponent, AcknowledgeDialogComponent, ...COMPONENT_EXPORTS],
})
export class AppModule {
}

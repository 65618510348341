import {CollectionClass} from "../decorators/database/collectionClass";
import {ModelBase, ServerMeta} from "./modelBase";
import {IndexField} from "../decorators/database/indexField";
import {ServerField} from "../decorators/database/serverField";
import { LogUtils } from "../utils/logUtils";

@CollectionClass({name: "raws", revisionEnabled: false, draftEnabled: false})
export class Raw extends ModelBase<Raw> {

  @IndexField() referenceId: string;
  @IndexField() uniqueId: string;
  referenceCollection: string;

  meta;
  @ServerField input;
  @ServerField output;
  @ServerField indicatorOutput;
  extra;

  @ServerField serverMeta: ServerMeta = {};

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }

  getClientDoc(): Raw {
    let obj: Raw = super.getClientDoc();

    return obj;
  }


  getSecuredDoc(): Raw {
    let obj: Raw = super.getSecuredDoc();
    obj.referenceId = this.referenceId;
    obj.referenceCollection = this.referenceCollection;

    return obj;
  }

  getMetaProvider() {
    if (this.meta) {
      return this.meta.provider;
    }
  }

  setServerMetaForExistingTeaser(
    clonedRaw: Raw, 
    options: { draft: boolean } = { draft: true },
  ) {
    try {
      const clone = {
        cloned: !!clonedRaw,
      };
  
      if (!!clonedRaw) {
        clone['referenceId'] = clonedRaw?._id;
        clone['referenceCreatedTimestamp'] = clonedRaw?.createdTimestamp;
      }
  
      const obj = options.draft ? this.draft : this;
  
      obj.serverMeta['clone'] = clone;
    } catch (e) {
      LogUtils.error('Raw.setServerMetaForExistingTeaser Error', e);
    }
  }
}

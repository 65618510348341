import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {JsonService} from '../../clientCommon/services/json.service';
import {ResponseEvent} from '../../common/event/responseEvent';
import {ActivatedRoute} from '@angular/router';
import {SpinnerService} from '../../clientCommon/services/spinner.service';
import {serverPaths} from '../../common/helpers/pathHelpers';
import {collectionClassHelper} from '../../common/decorators/database/collectionClass';
import {ManagedMail} from '../../common/models/user/managedMail';
import {objectUtils} from '../../common/utils/objectUtils';
import {inputUtils} from '../../clientCommon/utils/inputUtils';
import {emailUtils} from '../../common/utils/emailUtils';
import {LogUtils} from '../../common/utils/logUtils';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { configUtils } from '../utils/ConfigUtils';
import { BaseDirective } from 'src/clientCommon/directives/BaseDirective';
import { ServiceHelperService } from 'src/clientCommon/services/serviceHelper.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
    templateUrl: './mailSent.component.html',
    styleUrls: ['./mailSent.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: false
})

export class MailSentComponent extends BaseDirective implements OnInit, AfterViewInit {
  managedMails = new MatTableDataSource<ManagedMail>([]);
  input: any = {};
  prevInput: any = {};
  more = false;
  brands = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  columnsToDisplay = ['expander', 'id', 'date', 'brand', 'email', 'status', 'subStatus', 'action'];

  constructor(private jsonService: JsonService,
              private spinnerService: SpinnerService,
              private route: ActivatedRoute,
              private snackBar: MatSnackBar,
              public serviceHelperService: ServiceHelperService,
              ) {
    super(serviceHelperService, route);
  }

  ngOnInit() {
    super.baseInit().then(() => this.init());
  }

  ngAfterViewInit() {
    this.managedMails.paginator = this.paginator;
  }

  init() {
    this.spinnerService.spin();
    this.brands = configUtils.getBrands(this.uxComposite);

    this.jsonService.json(serverPaths.manageCsrMailSent, {}).then((responseEvent: ResponseEvent) => {
      this.managedMails.data = responseEvent.getDocs();
      this.managedMails.data.sort((a, b) => {
        return b.createdTimestamp - a.createdTimestamp;
      });
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  getClasses(obj) {
    const def = collectionClassHelper.getCollectionName(obj);
    return `${def} ${obj.status} ${obj.subStatus}`;
  }

  getRaw(obj) {
    const cloned = objectUtils.clone(obj);
    delete (cloned.draft);
    return JSON.stringify(cloned, null, 4);
  }

  keyDownEvent(event) {
    if (inputUtils.isEnterEvent(event)) {
      return this.search(this.input);
    }
  }

  search(input, aggregate?: boolean) {
    objectUtils.deleteEmptyProperties(input);
    this.prevInput = objectUtils.clone(input);
    this.spinnerService.spin();
    this.jsonService.json(serverPaths.manageCsrMailSent, input).then((responseEvent: ResponseEvent) => {
      this.managedMails.data = responseEvent.getDocs();
      this.managedMails.data.sort((a, b) => {
        return b.createdTimestamp - a.createdTimestamp;
      });
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });

  }

  isValid() {
    return this.input && this.input.email;
  }

  unsubscribe(managedMail: ManagedMail) {
    this.spinnerService.spin();
    this.jsonService.json(serverPaths.manageCsrMailUnsubscribe, {email: managedMail.email, brandId: managedMail.brandId}).then((responseEvent: ResponseEvent) => {
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    }).then(() => {
      return this.search(this.input);
    });
  }
}

import {Injectable} from "@angular/core";
import {StorageAbstract} from "../../common/models/user/storageAbstract";
import {UserInput} from "../../common/models/user/userInput";
import {UserInfo} from "../../common/models/user/userInfo";
import {SearchedPerson} from "../../common/custom/models/peopleSearch/searchedPerson";
import { StorageService } from "./storage.service";

@Injectable()
export class InputTrackingService extends StorageAbstract {

  constructor(public storageService: StorageService) {
    super('inputTracking', storageService);
  }

  /**
   * This store the searched user input values in localStorage
   * @param userInput object have all the values that user provided for searching candidate
   */
  storeSearch(userInput: UserInput) {
    let value = this.getValue();
    value.search = {
      firstName: userInput.fname,
      middleName: userInput.mname,
      lastName: userInput.lname,
      city: userInput.city,
      state: userInput.state,
      minAge: userInput.minAge,
      maxAge: userInput.maxAge,
      email: userInput.maxAge,
    };
    this.storeValue();
  }

  /**
   * This store the searched user input values in localStorage
   * @param searchedPerson object have values for selected person from the search result page
   */
  storeTarget(searchedPerson: SearchedPerson) {

    let value = this.getValue();
    value.target = {
      extId: searchedPerson.extId,
      firstName: searchedPerson.fName,
      middleName: searchedPerson.mName,
      lastName: searchedPerson.lName,
      city: searchedPerson.addresses[0] ? searchedPerson.addresses[0].city : null,
      state: searchedPerson.addresses[0] ? searchedPerson.addresses[0].state : null,
      age: searchedPerson.age,
      phone: searchedPerson.phones[0]?.number
    };
    this.storeValue();
  }

  /**
   * This store the input values from SUP1 in localStorage
   * @param searchedPerson object have values user entered in first Sign Up step
   */
  storeUserInfo(userInfo: UserInfo) {
    let value = this.getValue();
    value.user = {
      firstName: userInfo.fname,
      lastName: userInfo.lname,
      email: userInfo.email,
      zip: userInfo.zip,
      phone: userInfo.phone
    };
    this.storeValue();
  }
}

<div class="ux-revision-history-page">
  <div class="actions">
    @if (selectedType === 'uxComponents') {
      <button mat-raised-button color="primary" (click)="jsonBeautifier = !jsonBeautifier">{{!jsonBeautifier ? 'Enable Json' : 'Disable Json'}}</button>
    }
  </div>
  <uxHistory 
    [uxHistory]="uxHistory[selectedType]" 
    [id]="selectedId" 
    [type]="selectedType"
    [jsonBeautifier]="jsonBeautifier"
  ></uxHistory>
</div>
import {Injectable} from '@angular/core';
import {JsonService} from './json.service';
import {RequestEvent} from '../../common/event/requestEvent';
import {ResponseEvent} from '../../common/event/responseEvent';
import {LogUtils} from '../../common/utils/logUtils';
import {serverPaths} from '../../common/helpers/pathHelpers';
import {emailUtils} from '../../common/utils/emailUtils';
import {ManagedMail} from '../../common/models/user/managedMail';
import {UxComposite} from '../../common/models/ux/uxComposite';

@Injectable()
export class EmailService {

  /**
   *
   * @param jsonService to having the servie method to call the API
   */
  constructor(private jsonService: JsonService) {
  }

  /**
   * This will call mail/add api from the SUP1 to store email along with the brand, and userInfo
   * @param brandId against which email will be stored
   * @param email entered by the user on SUP1
   * @param key this is usally is a string indicating user state i.e 'guest'
   * @param meta data may include user info(uxc, uxl, fname, lname...) and contentInfo(productId, productKey...)
   * @param extraInfo it may include extra info like optin status or optin URL
   * @param isSup 
   * @returns resolved promise with the response with data or rejected promise with false or error
   */
  public addEmail(brandId, email, key, meta, extraInfo, isSup:boolean = false): Promise<any> {
    if (emailUtils.validateEmail(email) && brandId) {
      let requestEvent = new RequestEvent();
      requestEvent.param = {brandId: brandId, email: email, key: key, meta: meta, extraInfo: extraInfo, isSup};
      return this.jsonService.json(serverPaths.mailAdd, requestEvent).then((responseEvent: ResponseEvent) => {
        return true;
      }).catch((responseEvent: ResponseEvent) => {
        LogUtils.error(responseEvent.rawResponse);
        return Promise.reject(false);
      });
    } else {
      LogUtils.error('Not valid email entry', brandId, email);
      return Promise.reject(false);
    }
  }

  /**
   * This call the commerce IsAvilable Id API to check if email is available against the brand id
   * @param email against which we want to check
   * @param brandId which we want to if available for provided email
   * @returns resolved promise with the response with data or rejected promise with false or error
   */
  public isAvailableId(email, brandId): Promise<any> {
    if (emailUtils.validateEmail(email)) {
      let requestEvent = new RequestEvent();
      requestEvent.param = {email: email, brandId: brandId};
      return this.jsonService.json(serverPaths.commerceActionIsAvailableId, requestEvent).then((responseEvent: ResponseEvent) => {
        return !!responseEvent.data.available;
      }).catch((responseEvent: ResponseEvent) => {
        LogUtils.error(responseEvent.rawResponse);
        return Promise.reject(false);
      });
    } else {
      return Promise.reject(false);
    }
  }

  /**
   * This first check the general validity of the email, then call the check mail API on server to check if email is legit
   * @param email which we want to check if valid or not
   * @returns resolved promise with the response with data or rejected promise with false or error
   */
  public checkEmailAddress(email): Promise<any> {
    if (emailUtils.validateEmail(email)) {
      let requestEvent = new RequestEvent();
      requestEvent.param = {email: email};
      return this.jsonService.json(serverPaths.mailCheck, requestEvent).catch((responseEvent: ResponseEvent) => {
        LogUtils.error(responseEvent.rawResponse);
        return responseEvent;
      }).then((responseEvent: ResponseEvent) => {
        if (responseEvent && responseEvent.isSuccess()) {
          return Promise.resolve(responseEvent);
        } else {
          return Promise.reject(false);
        }
      });
    } else {
      return Promise.reject(false);
    }
  }

  /**
   *
   * @param uxComposite current uxComposite in which we want to set managed mail data
   * @param email which we want to store in uxComposite
   * @param meta data may include user info(uxc, uxl, fname, lname...) and contentInfo(productId, productKey...)
   */
  public addMailToUxComposite(uxComposite: UxComposite, email, meta) {

    let mail = new ManagedMail();
    mail.email = email;
    mail.meta = meta;

    uxComposite.setCode('mail', mail);
  }

  public getEmails(perPage:number, page:number) {
    // get emails from backend to display on csr email page.
  }
}


import { LogUtils } from './../../../../common/utils/logUtils';
import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, pipe, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { JsonService } from 'src/clientCommon/services';
import { ServiceHelperService } from 'src/clientCommon/services/serviceHelper.service';
import { adminPaths, serverPaths } from 'src/common/helpers/pathHelpers';
import { EmailRecord } from 'src/common/models/emailRecord';
import { User } from 'src/common/models/user/user';
import { IPagination } from '../../notes-management/notes-management.component';

@Component({
    selector: 'app-email-table',
    templateUrl: './email-table.component.html',
    styleUrls: ['./email-table.component.scss'],
    standalone: false
})
export class CSREmailTableComponent implements OnInit {


  constructor(private jsonService: JsonService, private serviceHelperService: ServiceHelperService, private cd: ChangeDetectorRef) {
    this.pagination = {
      totalPages: 1,
      currentPage: 1,
      totalRecords: 0,
      perPage: 50,
      hasNext: false,
      hasPrev: false
    };
    this.searchSubject.pipe(
      debounceTime(200),
      distinctUntilChanged())
      .subscribe((value) => {
        this.fetchCsrs(value);
      });
  }

  private readUrl = '/api/manage/admin/user/read';
  public searchFilter: any = '';
  users: User[] = [];
  editData = {};
  keywords = "";
  emailData;
  public emailBody = new Subject<any>();
  nonMemberCDPData;
  emailBodyData;
  expandable = { isExpanded: false, id: '' }
  cdpLink: string = "";
  selectedOwner: any = null;
  searchedCsrs: any[] = [];
  searchSubject = new Subject();
  filteredOptions: Observable<any[]>;
  myControl = new UntypedFormControl();
  selectedRecord = {
    isEditCdp: false,
    isEditOwner: false,
    isHintClicked: false,
    idOwner: '',
    idCdp: '',
  };

  @Input() pagination: IPagination;
  @Input() tableData: EmailRecord[] = [];
  @Input() thList = [];
  @Input() csr: any = {};
  @Input() val = 'close';
  @Input() onDemandEmailData = {};
  @Input() onDemandNonMemberCDPData = {};

  @Output() onClose = new EventEmitter();
  @Output() onExpand = new EventEmitter();
  @Output() onSearch = new EventEmitter();
  @Output() onRefresh = new EventEmitter();
  @Output() onUpdateStatus = new EventEmitter();
  @Output() onAssigned = new EventEmitter();
  @Output() onCdpSaved = new EventEmitter();
  @Output() onDemandEmail = new EventEmitter();
  @Output() onNonMemberCDP = new EventEmitter();

  assigedFrom = null;
  assigedCustomerFrom = null;
  a;
  b;

  changeCustomerAssignee(record) {
    this.selectedRecord.isHintClicked = false;
    this.selectedRecord.isEditCdp = true;
    this.assigedCustomerFrom = record.obj?.customer?._id;
    this.selectedRecord.idCdp = record?.obj?._id;
    this.cdpLink = '';
  }

  getEmailOnDemand(emailId){
    this.onDemandEmail.emit({ emailRecordId: emailId})
    setTimeout(() => {
      this.emailData = this.onDemandEmailData['body']?.replaceAll("=\r\n", '');
      this.emailBodyData = this.emailData?.replaceAll('3D', '');
    }, 400);

  }

  getNonMemberCDP(brandEmail) {
    this.nonMemberCDPData = ''
    let splited = brandEmail.split('@')
    let brandName = splited[1].split('.')
    this.onNonMemberCDP.emit({ brandName: brandName[0] })
    setTimeout(() => {
      this.nonMemberCDPData = this.onDemandNonMemberCDPData;
    }, 100)
      
  }

  changeAssignee(record) {
    this.assigedFrom = record?.obj?.csr?._id;
    this.selectedRecord.idOwner = record?.obj?._id;
    this.selectedRecord.isEditOwner = true;
    this.keywords = '';
  }

  ngOnInit(): void {
    this.fetchCsrs(null);
  }

  selectOption(event) {
    this.selectedOwner = event.option.value.obj._id;
    // alert(this.selectedOwner);
  }

  fetchCsrs(value) {
    return this.serviceHelperService
      .jsonService
      .json(serverPaths.manageCsrFindCsr, { param: { keywords: this.keywords } })
      .then((res: any) => {
        const arr = res.docs;
        this.searchedCsrs = arr.filter(el => el.obj.status === 'active');
      })
      .catch(err => {
        LogUtils.info(err);
      });
  }

  setUserBrand(user: User) {
    user.brandId = 'admin';
  }

  show() {
    alert(
      this.selectedOwner
    );
  }

  displayFn(csr) {
    if (csr && csr.obj) {
      return csr.obj?.firstName + ' ' + csr.obj?.lastName;
    } else {
      return '';
    }
  }

  _onCdpSave(emailId,id) {
    this.getEmailOnDemand(emailId)
    this.serviceHelperService.spinnerService.spin();
    setTimeout(() => {
      this.onCdpSaved.emit({ id, customerId: this.cdpLink.trim(), assigedCustomerFrom: this.assigedCustomerFrom });
      this.selectedRecord.idOwner = '';
      this.selectedRecord.isEditCdp = null;
      this.serviceHelperService.spinnerService.unspin();
    }, 500);
  }

  _onUpdateStatus(id, status) { this.onUpdateStatus.emit({ id, status }); }
  _onClose(id) { this.onClose.emit(id); }
  _onAdd(id) { this.onExpand.emit(id); }
  _onSearch(id) { this.onSearch.emit(id); }
  _onRefresh(id) { this.onRefresh.emit(id); }

  _onAssignedTo(emailId,id, customerId, from, to, subject, body) {
    this.onAssigned.emit({ id, customerId, isReAssign: false, from, to, subject, body });     
  }

  _onReassign() {
    this.serviceHelperService.spinnerService.spin();
    setTimeout(() => {
      this.onAssigned.next({ id: this.selectedRecord.idOwner, customerId: this.selectedOwner, isReAssign: true, assignedFrom: this.assigedFrom });
      this.selectedRecord.idOwner = '';
      this.selectedRecord.isEditOwner = null;
      this.serviceHelperService.spinnerService.unspin();
    }, 500);
  }

  viewDetail(email) {
    if (!email) { window.open(adminPaths.csrCustomers) }
    window.open(adminPaths.csrCustomers + '?smartsearch=email:' + email);
  }

  toggleHintClicked() {
    this.selectedRecord.isHintClicked = !this.selectedRecord.isHintClicked;
  }

  redirectToCustomer(customerId) {
    if (customerId){
      window.open(adminPaths.csrCustomer + '/' + customerId);
    }
    else {return}
  }
}

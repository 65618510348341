import { Injectable } from "@angular/core";
import { JsonService } from "./json.service";
import { ResponseEvent } from "../../common/event/responseEvent";
import { LogUtils } from "../../common/utils/logUtils";
import { AuthenticationService } from "./authentication.service";
import { serverPaths, manageCsrPaths } from "../../common/helpers/pathHelpers";
import { CommerceOffer } from "../../common/models/commerce/commerceOffer";
import { CommerceSaleRequestEvent } from "../../common/models/commerce/commerceEvent";
import { User } from "../../common/models/user/user";
import { UserInfo } from "../../common/models/user/userInfo";
import { UxComposite } from "../../common/models/ux/uxComposite";
import { ReferService } from "./refer.service";
import { CreditCardInputHelper } from "../helper/creditCardInputHelper";
import { CommerceOfferRuleKeyDetail, CommerceOrder } from "../../common/models/commerce/commerceOrder";
import { objectUtils } from "../../common/utils/objectUtils";
import { Commerce3ds } from "../../common/models/commerce/commerce3ds";
import { Router } from "@angular/router";
import { isObject } from "util";
import { StorageService } from "./storage.service";
import { ModelBase } from "../../common/models/modelBase";
import { BankIdentificationNumber } from "../../common/models/bankIndentificationNumber";
import { commerceOrderHelper } from "../../common/helpers/commerceOrderHelper";
import { CommerceContent } from "../../common/models/commerce/commerceContent";
import { BehaviorSubject } from 'rxjs';
import {SequenceOptions} from "../../common/models/commerce/commercePrice";
import { HttpClient, HttpHeaders } from "@angular/common/http";
// import { responseEvent } from "@common/event";

@Injectable()
export class CommerceService {
  STORAGE_NAME = 'commerce';
  public storage;
  public storageSubject = new BehaviorSubject<any>({})

  /**
   *
   * @param jsonService to access localStorage for managing token etc.
   * @param referService to get the refer from the localStorage
   * @param cookieService to set or access the cookies
   * @param router to access the routes
   * @param authenticationService to get the userId
   */
  constructor(private jsonService: JsonService,
    private referService: ReferService,
    private storageService: StorageService,
    private router: Router,
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService) {
    this.getStorage();
  }

  /**
   * Request to API URL with a param provided
   * @param url of a API to be required
   * @param param params to be provided to the URL
   * @returns the resolved/rejected promise
   */
  public request(url, param): Promise<any> {
    return this.jsonService.request(url, param);
  }

  /**
   * set's the commerceOrderId inside pixelExtra property of the BaseComponent
   * @param base context of the Base component for which we want to set the pixelExtra property
   */
  setExtraToPixel(base) {
    return Promise.resolve().then(() => {
      try {
        return base.activatedRoute.params.subscribe((params) => {
          let commerceOrderId = params.commerceOrderId;
          base.commerceOrderId = commerceOrderId;
          base.setExtraPixel("commerceOrderId=" + commerceOrderId);
        });
      } catch (e) {
        LogUtils.error(e);
        return Promise.reject(e);
      }
    })
  }

  /**
   *
   * @returns the localStorage data stored against the key STORAGE_NAME
   */
  getStorage() {
    if (objectUtils.isEmpty(this.storage)) {
      this.storage = JSON.parse(this.storageService.getItem(this.STORAGE_NAME)); // JSON.parse(localStorage.getItem(this.STORAGE_NAME));
      if (objectUtils.isEmpty(this.storage)) {
        this.storage = {};
      }
    }
    return this.storage;
  }

  getStorageAsObservable() {
    this.storageSubject.next(this.getStorage());
    return this.storageSubject.asObservable()
  }

  /**
   *
   * @param storage data to be stored in the sessionStorage against the STORAGE_NAME key
   */
  setSessionStorage(storage) {
    this.storageService.setSession(this.STORAGE_NAME, storage);
  }

  updateStorage(storage) {
    this.storage = storage
    this.storageSubject.next(this.storage);
    this.storageService.set(this.STORAGE_NAME, this.storage);
    //localStorage.setItem(this.STORAGE_NAME, JSON.stringify(this.storage));
  }

  // save the data from the storage property in localStorage
  saveStorage() {
    this.storageService.set(this.STORAGE_NAME, this.storage);
    //localStorage.setItem(this.STORAGE_NAME, JSON.stringify(this.storage));
  }

  /**
 *
 * @returns the sessionStorage data stored against the key STORAGE_NAME
 */
  getSessionStorage() {
    let sessionStorage = this.storageService.getSession(this.STORAGE_NAME);
    if (!sessionStorage) {
      sessionStorage = {};
    }
    return sessionStorage;
  }

  // temporary store the commerceOrders in storage property
  setCommerceOrders(commerceOrders: CommerceOrder[]) {
    this.storageService.setTemp(this.STORAGE_NAME, commerceOrders);
  }

  // get the commerceOrder data from storage temporarily stored in storage property
  getCommerceOrders() {
    return this.storageService.getTemp(this.STORAGE_NAME);
  }

  /**
   * Find the commerce Order with commerceOrderId by sending the request to the commerce Order API
   * @param commerceOrderId of the commerceOrder for which data is required
   * @param maxDepth i.e 1,2 for finding document from mongodb collection
   * @returns resolved/rejected promise for the commerceOrder
   */
  findCommerceOrder(commerceOrderId, maxDepth = null) {
    return Promise.resolve().then(() => {
      let param = {
        userId: this.authenticationService.getUserId(),
        orderId: commerceOrderId,
        maxDepth: maxDepth,
      };
      return this.request(serverPaths.commerceCustomerFindOrder, param);
    }).then((response: ResponseEvent) => {
      let commerceOrders: CommerceOrder[] = response.getDocs();
      LogUtils.debug("CommerceService.findCommerceOrder", commerceOrders);
      return commerceOrders;
    });
  }


  reminderPopupViewUpdate(commerceOrderId) {
    return Promise.resolve().then(() => {
      let param = {
        userId: this.authenticationService.getUserId(),
        orderId: commerceOrderId,
      };
      return this.request(serverPaths.commerceCustomerReminderViewUpdate, param);
    }).then((response: ResponseEvent) => {
      return "OK";
    });
  }
  /**
   * Find the active commerce Orders using logged in userId in case of commerceOrders are not found using commerceOrderId
   * @param force boolean, if true we get active commerceOrders by loggedIn userID
   */
  findCommerceOrders(force: boolean = false) {
    return Promise.resolve().then(() => {
      if (force || this.getCommerceOrders() === undefined) {
        let param = { userId: this.authenticationService.getUserId(), status: ModelBase.STATUS.active };
        return this.request(serverPaths.commerceCustomerFindOrders, param).then((response: ResponseEvent) => {
          let commerceOrders = response.getDocs();
          this.setCommerceOrders(commerceOrders);
          LogUtils.debug("CommerceService.findCommerceOrders", commerceOrders);
        });
      }
    }).then(() => {
      return this.getCommerceOrders();
    });
  }

  /**
   * get upsell offers from commerce customer upsell offer API based on upsellRuleKey & userId
   * @param upsellRulesKey offer rule key got from uxComp rules
   */
  findUpsellOffers(upsellRulesKey) {
    return Promise.resolve().then(() => {
      // request params for commerce customer upsell offer API
      let param = { userId: this.authenticationService.getUserId(), upsellRulesKey: upsellRulesKey };
      return this.request(serverPaths.commerceCustomerFindUpsellOffers, param);
    }).then((response: ResponseEvent) => {
      // set the commerceOffers received response
      let commerceOffers: CommerceOffer[] = response.getDocs();

      // Here we are setting up an result object which have commerceOffer as value against each offer key
      let result: any = {};
      Object.keys(response.data.offers).forEach((key) => {
        result[key] = [];
        response.data.offers[key].forEach((commerceOfferId) => {
          commerceOffers.some((commerceOffer) => {
            if (commerceOffer._id === commerceOfferId) {
              result[key].push(commerceOffer);
              return true;
            }
          });
        });
      });

      LogUtils.debug("CommerceService.findUpsellOffers", response.data.offers, commerceOffers, result);
      return result;
    }).catch((e) => {
      LogUtils.error("CommerceService.findUpsellOffers", e);
      return Promise.reject(e);
    });
  }

  /**
   * This set's the signup content provided in param i.e ProductKey.. in the session Storage
   * @param param have content to be stored in session Storage
   */
  setSignupContent(param) {
    let storage = this.getSessionStorage();
    storage.signupContent = param;
    this.setSessionStorage(storage);
  }

  /**
   *
   * @returns signup content from the session Storage
   */
  getSignupContent() {
    let storage = this.getSessionStorage();
    return storage.signupContent
  }

  /**
   * this FN is to request commerce customer find content API against the provided request params
   * @param param :request params for the commerce customer find content API
   * @returns resolved promise with the response for content data or rejected promise with error data
   */
  public findContents(param): Promise<any> {
    if (!
      isObject(param)
    ) {
      param = {};
    }

    param.userId = this.authenticationService.getUserId();
    return this.request(serverPaths.commerceCustomerFindContents, param
    ).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

  /**
  * this FN is to request commerce customer past searches API against the provided request
  * @param param :request params for the commerce customer past searches API
  * @returns resolved promise with the response for content data or rejected promise with error data
  */
  public findPastSearches(param): Promise<any> {
    if (!
      isObject(param)
    ) {
      param = {};
    }

    param.userId = this.authenticationService.getUserId();
    return this.request(serverPaths.commerceCustomerFindPastSearches, param
    ).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

  /**
 * this FN is to request commerce customer find content API against the provided commerceContentId and userId
 * @param commerceContentId against which the commerce customer find content API will be requested
 * @returns resolved promise with the response for content data or rejected promise with error data
 */
  public findContent(commerceContentId): Promise<any> {
    return this.request(serverPaths.commerceCustomerFindContent, {
      userId: this.authenticationService.getUserId(),
      commerceContentId: commerceContentId,
    }).then((responseEvent: ResponseEvent) => {
      return responseEvent.getSingleDoc();

    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }
  /**
   * CommerceContentId is able to null
   * 
   * @param commerceContentId 
   * @returns 
   */
  public findContentDwb(commerceContentId): Promise<any> {
    return this.request(serverPaths.commerceCustomerFindContentDwb, {
      userId: this.authenticationService.getUserId(),
      commerceContentId: commerceContentId,
    }).then((responseEvent: ResponseEvent) => {
      return responseEvent.getSingleDoc();

    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

  public deleteCommerceContent(param): Promise<any> {
    param.userId = this.authenticationService.getUserId();
    return this.request(serverPaths.commerceCustomerDeleteCommerceContent, param
    ).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }
  public deleteSearchedAll(param): Promise<any> {
    param.userId = this.authenticationService.getUserId();
    return this.request(serverPaths.commerceCustomerDeleteAllCommerceContent, param
    ).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }
  /**
   * To change the relative, it request commerce Customer update relationship API
   * @param param include the object with related commerceCommerceContent id and relationship event
   * @returns resolved promise with the response for content data or rejected promise with error data
   */
  public updateRelationship(param): Promise<any> {
    param.userId = this.authenticationService.getUserId();
    return this.request(serverPaths.commerceCustomerUpdateRelationship, param
    ).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }
  //Adding this function to unlock Bonus data using the Bonus Upsell
  public unlockBonus(param): Promise<any> {
    param.userId = this.authenticationService.getUserId();
    return this.request(serverPaths.commerceCustomerUpdateRelationship, param
    ).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

  public updateIdiCount(param): Promise<any> {
    param.userId = this.authenticationService.getUserId();
    return this.request(serverPaths.commerceCustomerUpdateIdiCount, param)
    .catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

  /**
   * @returns newly created hash using data and random num
   */
  public createHash() {
    return (new Date().getTime()).toString() + (Math.random()).toString();
  }

  /**
   * THis login the user after the successful sale request
   * @param responseEvent from the successful sale request
   */
  public updateUserOnSession(responseEvent: ResponseEvent) {
    if (responseEvent && responseEvent instanceof ResponseEvent) {
      responseEvent.getDocs().forEach((doc) => {
        if (doc instanceof User) {
          this.authenticationService.loginAsUser(doc, true);
        }
      });
    }
  }

  /**
   * Requesting to commerce Sale API
   * @param requestParam for the sales API
   * @returns resolved promise with the response for content data or rejected promise with error data
   */
  public processSale(requestParam, options?): Promise<any> {
    let url = serverPaths.commerceActionSale;
    if (options?.csr === true) {
      url = serverPaths.manageCsrCommerceSale;
    }
    return this.request(url, requestParam).then((responseEvent: ResponseEvent) => {
      this.updateUserOnSession(responseEvent);
      return responseEvent;
    }).catch((e) => {
      this.updateUserOnSession(e);
      return Promise.reject(e);
    });
  }

  /**
   * Requesting to commerce Sale API by setting the request param using CommerceSaleRequestEvent model
   * @param requestParam for the sales API
   * @returns resolved promise with the response for content data or rejected promise with error data
   */
  public processNonOptionSale(requestParam, options?): Promise<any> {
    let request = new CommerceSaleRequestEvent(); // Using class to use TypeScript hint
    request.param = requestParam;
    return this.processSale(request.param, options);
  }

  /**
   * Requesting to commerce Sale API by setting the providing the different request params seperatly
   * @param hash generated for the user
   * @param user object includes the user info
   * @param uxComposite current uxComposite
   * @param upsellRulesKey offer rule key got from uxComp rules
   * @param offerRuleKeyDetails object have commerce Offer Id against each key
   * @param options optional params
   */
  public nonOptionSaleByUser(hash: string,
    user: User,
    uxComposite: UxComposite,
    offerRuleKey: string,
    offerRuleKeyDetails: CommerceOfferRuleKeyDetail[],
    options?): Promise<any> {

    let userInfo = new UserInfo();
    userInfo.id = user._id;
    let requestParam: any = {
      hash: hash,
      userInfo: userInfo,
      commerceOfferIds: [],
      commerceOfferDetails: [],
      refer: this.referService.getRefer(),
      uxcId: uxComposite.uxConfigId,
      uxlId: uxComposite.uxLayoutId,
      offerRuleKey: offerRuleKey,
      offerRuleKeyDetails: offerRuleKeyDetails,
    };

    if (options) {
      if (options.parentCommerceOrderId) {
        requestParam.parentCommerceOrderId = options.parentCommerceOrderId;
      }
      if (options.commerceOfferIds) {
        requestParam.commerceOfferIds = options.commerceOfferIds;
      }
      if (options.commerceOfferDetails) {
        requestParam.commerceOfferDetails = options.commerceOfferDetails;
      }
      if (options.updaterId) {
        requestParam.updaterId = options.updaterId;
      }
      if (options.commerceContentId) {
        requestParam.commerceContentId = options.commerceContentId;
      }
      if (options.upsellKey) {
        requestParam.upsellKey = options.upsellKey;
      }
      if (options.contentInfos) {
        requestParam.contentInfos = options.contentInfos;
      }
      if (options.downsell) {
        requestParam.downsell = options.downsell;
      }
    }

    return this.processNonOptionSale(requestParam, options);
  }

  // credit card uxComp offer rule key
  public getUpdateCreditCardOfferRuleUxcompKey() {
    return "comp.billing.creditcard.update.offers.rules";
  }

  /**
   *
   * @param hash generated for the user
   * @param user object includes the user info
   * @param uxComposite current uxComposite value
   * @param creditCardInputHelper include the required credit card properties & methods
   * @param token optional token param
   * @param offerRuleUxcompKey optional overriding offerRuleUxcompkey param
   */
  public updateCreditCard(hash, user: User, uxComposite: UxComposite, creditCardInputHelper: CreditCardInputHelper, token?, offerRuleUxcompKey?, cpcc?): Promise<any> {
    // set the rule key for uxComp
    let offerRuleKey = offerRuleUxcompKey ? offerRuleUxcompKey : this.getUpdateCreditCardOfferRuleUxcompKey();
    // get the rules from uxComp based on rule key
    let offerRules = uxComposite.get(offerRuleKey);
    // setting up the object having each key detail
    let offerRuleKeyDetails: { key:string }[] = [];
    const offerRuleKeyList = Object.keys(offerRules);
    if (cpcc?.productKey) {
      const found = offerRuleKeyList.find((key) => key === cpcc.productKey);
      if (found) {
        offerRuleKeyDetails.push({ key: found });
      } else {
        LogUtils.error(`NotFound ruleKey : ${cpcc.productKey} in ${offerRuleKey}`);
        offerRuleKeyDetails.push({ key: offerRuleKeyList[0] });
      }
    } else {
      offerRuleKeyList.forEach((key) => {
        offerRuleKeyDetails.push({ key: key });
      });
    }
    // setting the required user properties/info
    let userInfo = new UserInfo();
    userInfo.id = user._id;
    // setting the request params for the sales API
    let requestParam = {
      hash: hash,
      userInfo: userInfo,
      commerceOfferIds: [],
      commerceOfferDetails: [],
      refer: this.referService.getRefer(),
      uxcId: uxComposite.uxConfigId,
      uxlId: uxComposite.uxLayoutId,
      ccNumber: creditCardInputHelper.ccNumber,
      ccExpMonth: creditCardInputHelper.ccExpMonth,
      ccExpYear: creditCardInputHelper.ccExpYear,
      ccCvv: creditCardInputHelper.ccCvv,
      billingAddress: creditCardInputHelper.billingAddress,
      disablePreviousTokens: true,
      declineIfSameActiveToken: true,
      token: token,
      offerRuleKey: offerRuleKey,
      offerRuleKeyDetails: offerRuleKeyDetails,
      cpcc,
    };

    if (token) {
      requestParam.billingAddress.zip = token.billingZip;
      requestParam.ccExpMonth = token.expMonth;
      requestParam.ccExpYear = token.expYear;
    }

    return this.processNonOptionSale(requestParam);
  }

  /**
   * request to the commerce has Order API to find orders against the product uxComp Names & user Id
   * @param productUxcompNames array of product ux comp names
   * @returns resolved promise with the response for content data or rejected promise with error data
   */
  public hasOrder(productUxcompNames: string[]): Promise<any> {
    return this.request(serverPaths.commerceCustomerHasOrder, {
      userId: this.authenticationService.getUserId(),
      productUxcompNames: productUxcompNames,
    }).then((responseEvent: ResponseEvent) => {
      return responseEvent.getDocs();
    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

    /**
   * reqesting to verifcation API for secure 3d payments
   * @param requestParam merchant plugin info including gracePeriod, merchantId
   * @return resolved promise having true if verifcation is successful
   */
  public verify3ds(commerceOffers: CommerceOffer[], requestParam, sequenceOptions: SequenceOptions): Promise<any> {
    let param = objectUtils.clone(requestParam);
    let amount = 0;

    commerceOffers.forEach((commerceOffer) => {
      amount += commerceOffer.commercePrice.getTotalPrice(0, 0, sequenceOptions).amount;
      amount += commerceOffer.commercePrice.getTotalPrice(1, 0, sequenceOptions).amount;
    });

    param.amount = amount;

    return this.request(serverPaths.commerceMpi3dsVerify, param);
  }

  /**
   * This create the auth iframe for secure 3d payments
   * @param commerce3ds have required payment properties & methods
   */
  public create3dsAuthIframe(commerce3ds: Commerce3ds) {
    let id = `mpi-${commerce3ds._id}`;
    let iframe: any = document.createElement("iframe");
    iframe.setAttribute("id", id);
    iframe.setAttribute("class", "mpi-3ds-container");
    iframe.setAttribute("src", "about:blank");
    iframe.setAttribute("height", "1");
    iframe.setAttribute("width", "1");

    if (commerce3ds.hidden) {
      iframe.setAttribute("height", "1");
      iframe.setAttribute("width", "1");
    } else {
      iframe.setAttribute("height", "400");
      iframe.setAttribute("width", "480");
    }
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("scrolling", "NO");
    document.body.appendChild(iframe);

    let termUrl = this.getAbsolutePath(serverPaths.commerceMpi3dsProcess);
    let src = `
            <div style="width:0;height: 0;position:relative;overflow: hidden;">
                <form name="pares" action="${commerce3ds.acsUrl}" method="POST">
                    <input name="TermUrl" value="${termUrl}" />
                    <input name="PaReq" value="${commerce3ds.pareq}" />
                    <input name="MD" value="${commerce3ds._id}" />
                </form>
            </div>
        `;

    iframe.contentWindow.document.body.innerHTML = src;
    try {
      iframe.contentWindow.document.pares.submit();
      return iframe;
    } catch (e) {
      LogUtils.error(e);
      return false;
    }
  }

  // hide secure 3d payments iframe element
  public hide3dsIframe() {
    [].forEach.call(document.querySelectorAll('.mpi-3ds-container'), function (el) {
      try {
        el.style.height = '1';
        el.style.width = '1';
        el.style.display = 'none';
      } catch (e) {
        LogUtils.error("failed hiding 3ds iframe", e);
      }
    });
  }

  // Resolve the base url as the full absolute url subtract the relative url.
  public getAbsolutePath(path) {
    let currentAbsoluteUrl = window.location.href;
    let currentRelativeUrl = this.router.url;
    let index = currentAbsoluteUrl.indexOf(currentRelativeUrl);
    let baseUrl = currentAbsoluteUrl.substring(0, index);

    return baseUrl + '/' + path;
  }

  /**
   * @param productKeys array of string having product keys
   * @returns the keys array having uxComp names got agaisnt the product Keys
   */
  getProductUxcompNamesByProductKeys(productKeys: string[]) {
    let keys = [];
    productKeys.forEach((productKey) => {
      keys.push(commerceOrderHelper.getCommerceOrderProductKeyUxcompName(productKey));
    });

    return keys;
  }

  /**
  * @param productKeys array of string having product keys
  * @param uxComposite current uxComposite value
  * @returns the id's array having product id's got against the product Keys
  */
  getProductIdsByProductKeys(uxComposite: UxComposite, productKeys: string[]) {
    let names = this.getProductUxcompNamesByProductKeys(productKeys);
    let ids = [];
    names.forEach((name) => {
      let currentIds = uxComposite.getUxcomp(name);
      if (currentIds) {
        ids = ids.concat(uxComposite.getUxcomp(name))
      }
    });
    return ids;
  }


  /**
   * This check if commerce Order have products
   * @param uxComposite current uxComposite value
   * @param commerceOrders commerceOrder from which we will check if it have products
   * @param productKeys array of string having product keys
   * @returns boolean, true only if prouctIds were found in orders
   */
  public hasProductInOrder(uxComposite: UxComposite, commerceOrders: CommerceOrder[], productKeys: string[]) {
    let flag = false;
    // get the productids from product key & uxComposite
    let productIds = this.getProductIdsByProductKeys(uxComposite, productKeys);
    commerceOrders.some((commerceOrder) => {
      if (commerceOrder.isActive() && commerceOrder.commerceOfferDetail.hasProducts(productIds).length > 0) {
        flag = true;
        return true;
      }
    });

    return flag;
  }


  /**
   * this get's the bank identification number object having bank detail calling bankIdentificationNumberFind API
   * @param bin bank identification number
   * @returns resolved promise with the response for content data or rejected promise with error data
   */
  public findBin(bin): Promise<any> {
    return this.request(serverPaths.bankIdentificationNumberFind, {
      bin: bin,
    }).then((responseEvent: ResponseEvent) => {
      return new BankIdentificationNumber(responseEvent.data);
    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

  /**
   * Request to count updated content API providing the userID
   * @returns resolved promise with the response for content data or rejected promise with error data
   */
  public countContentUpdated(): Promise<any> {
    return this.request(serverPaths.commerceCustomerCountContentUpdated, {
      userId: this.authenticationService.getUserId(),
    }).then((responseEvent: ResponseEvent) => {
      return responseEvent.data.count;
    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

  /**
 * Request to processUpdateContent API providing the userID & commerceContentId
 * @param commerceContentId against which processUpdateContent API will be called
 * @returns resolved promise with the response for content data or rejected promise with error data
 */
  public processUpdateContent(commerceContentId): Promise<any> {
    return this.request(serverPaths.commerceCustomerProcessUpdateContent, {
      userId: this.authenticationService.getUserId(),
      commerceContentId: commerceContentId,
    }).then((responseEvent: ResponseEvent) => {
      return responseEvent.getSingleDoc();
    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

  public declineIdProtectionOfferBanner(commerceContentId): Promise<any> {
    return this.request(serverPaths.commerceCustomerDeclineIdProtectionOfferBanner, {
      userId: this.authenticationService.getUserId(),
      commerceContentId: commerceContentId,
    }).then((responseEvent: ResponseEvent) => {
      const commerceStorage = this.getStorage();
      this.updateStorage({
        ...commerceStorage,
        idClaimBannerRejected: true
      })
      return responseEvent.data;
    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

  public declineIdProtectionOfferPopup(commerceContentId): Promise<any> {
    return this.request(serverPaths.commerceCustomerDeclineIdProtectionOfferPopup, {
      userId: this.authenticationService.getUserId(),
      commerceContentId: commerceContentId,
    }).then((responseEvent: ResponseEvent) => {
      const commerceStorage = this.getStorage();
      this.updateStorage({
        ...commerceStorage,
        idClaimPopupRejected: true
      })
      return responseEvent.data;
    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }


  public markAlertAsRead(commerceContentId, alarmId): Promise<any> {
    return this.request(serverPaths.commerceCustomerMarkAlertAsRead, {
      userId: this.authenticationService.getUserId(),
      commerceContentId: commerceContentId,
      alarmId: alarmId,
    }).then((responseEvent: ResponseEvent) => {
      
      const commerceStorage = this.getStorage();
      let unreadAlerts = commerceStorage?.unreadAlerts || 0;

      if (unreadAlerts > 0) {
        unreadAlerts = unreadAlerts -1;
      }
      this.updateStorage({
        ...commerceStorage,
        unreadAlerts: unreadAlerts
      })
      return responseEvent.data;
    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

  public processBonusContent(commerceContentId, tempClient): Promise<any> {
    return this.request(serverPaths.commerceCustomerProcessUpdateContent, {
      userId: this.authenticationService.getUserId(),
      tempClient: tempClient,
      commerceContentId: commerceContentId,

    }).then((responseEvent: ResponseEvent) => {

      return responseEvent.getSingleDoc();
    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

  /**
   * This FN is to load offers against commerceOfferIds calling commerce load offer API
   * @param commerceOfferIds against which commerce load offer API will be called
   * @returns resolved promise with the response for content data or rejected promise with error data
   */
  public findOffers(commerceOfferIds: string[]): Promise<CommerceOffer[]> {
    return this.request(serverPaths.commerceActionLoadOffer, { ids: commerceOfferIds }).then((responseEvent: ResponseEvent) => {
      return responseEvent.getDocs();
    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

  /**
 * This FN is to load commerceOrders against commerceOrderIds calling FindContentsByOrders API
 * @param commerceOrderIds against which FindContentsByOrders API will be called
 * @returns resolved promise with the response with commerceContents or rejected promise with false or error
 */
  findCommerceContentsByCommerceOrderIds(commerceOrderIds: string[], options) {
    return Promise.resolve().then(() => {
      if (commerceOrderIds && commerceOrderIds.length) {
        let param = objectUtils.clone(options);
        param.userId = this.authenticationService.getUserId();
        param.commerceOrderIds = commerceOrderIds;
        param.status = ModelBase.STATUS.fulfilled;

        return this.request(serverPaths.commerceCustomerFindContentsByOrders, param).then((responseEvent: ResponseEvent) => {
          let commerceContents: CommerceContent[] = responseEvent.getDocs();
          LogUtils.debug("CommerceService.findCommerceContentsByCommerceOrderIds", commerceContents);
          return commerceContents;
        });
      } else {
        return Promise.reject(false);
      }
    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

  /**
   * This FN is to load customer repport against commerceContentId calling Customer View Report API
   * @param commerceContentId against which Customer View Report API will be called
   * @returns resolved promise with the response with data or rejected promise with false or error
   */
  public viewReport(commerceContentId): Promise<any> {
    return this.request(serverPaths.commerceCustomerViewReport, {
      userId: this.authenticationService.getUserId(),
      commerceContentId: commerceContentId,
    }).then((responseEvent: ResponseEvent) => {
      return responseEvent.data.lastViewedTimestamp
    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

  /**
  IF (flag to claim the profile is TRUE)
  - This service will call an API to provsion email monitoring and after its success
  - Then, it will call `/GetFullResultsFromEmail` API to retrive breach scan report
  - Then update the commerceContent with breach scan report data
  ELSE  (if flag to claim the profile is FALSE)
  - it will call an API to cancel email monitoring and after its success
 * @param id is commerceContent which will be used to update the commerceContent
 * @param flag a boolean, against which Update Me Report Flag API will be called
 * @param email to be monitored for id protection
 * @returns resolved promise with the response with data or rejected promise with false or error
 */
  public updateMeReportFlag(
    id: string,
    reportClaimedContentId: string,
    spyDwbCommerceContentId: string,
    flag: boolean,
    emails: string[],
    firstName: string,
    lastName: string
  ): Promise<any> {
      // requirement : not dwb regsiter, so not email check
      // if(!emails || emails?.length == 0) {
      //   LogUtils.error("No emails to be monitoted");
      //   return Promise.reject("No emails to be monitoted");
      // }
      let user: User = this.authenticationService.getUser();
      return this.request(serverPaths.commerceCustomerUpdateMeReportFlag, {
        userId: user._id,
        emails,
        id,
        flag,
        user,
        reportClaimedContentId,
        spyDwbCommerceContentId,
        firstName,
        lastName
      })
      .then((responseEvent: ResponseEvent) => {
        const commerceStorage = this.getStorage()
        this.updateStorage({
          ...commerceStorage,
          reportClaimedContentId: flag ? reportClaimedContentId : null,
        })
        return responseEvent.getResponse();
      })
      .catch((e) => {
        LogUtils.error(e);
        return Promise.reject(e);
      });
  }

    public monitorDwb(
      id: string,
      peopleSearchCommerceContentId: string,
      idProtectionCommerceContentId: string,
      flag: boolean,
      emails: string[],
      firstName: string,
      lastName: string
    ): Promise<any> {
        if(!emails || emails?.length == 0) {
          LogUtils.error("No emails to be monitoted");
          return Promise.reject("No emails to be monitoted");
        }
        let user: User = this.authenticationService.getUser();
        return this.request(serverPaths.commerceCustomerMonitorDwb, {
          userId: user._id,
          emails,
          id,
          flag,
          user,
          peopleSearchCommerceContentId,
          idProtectionCommerceContentId,
          firstName,
          lastName
        })
        .then((responseEvent: ResponseEvent) => {
          const commerceStorage = this.getStorage()
          const currentPsIds = commerceStorage?.monitoredPeopleSearchCommerceContentIds || []
          if(flag) {
            this.updateStorage({
              ...commerceStorage,
              monitoredPeopleSearchCommerceContentIds: Array.from(new Set([...currentPsIds, peopleSearchCommerceContentId]))
            })
          } else {
            this.updateStorage({
              ...commerceStorage,
              monitoredPeopleSearchCommerceContentIds: currentPsIds.filter(p => p !== peopleSearchCommerceContentId)
            })
          }
          return responseEvent.getResponse();
        })
        .catch((e) => {
          LogUtils.error(e);
          return Promise.reject(e);
        });
      }


/**
 * This service is to call an api which will Update an existing alert monitoring account for an email address
 * with additonal data i.e first name and last name
 * @param id is commerceContent which will be used to update the commerceContent
 * @param toAddEmails emails to be monitored for id protection
 * @param toUpdateEmails emails to be monitored for id protection
 * @param toCancelEmails emails to be monitored for id protection
 * @param firstName to be provided as a additional monitoring data
 * @param lastName to be provided as a additional monitoring data
 * @returns resolved promise with the response with data or rejected promise with false or error
 */
public updateEmailMonitoringForIdProtection(
  id: string,
  toAddEmails: string[],
  toUpdateEmails: string[],
  toCancelEmails: string[],
  firstName: string,
  lastName: string
): Promise<any> {
  return this.request(serverPaths.commerceCustomerUpdateEmailMonitoringForIdProtection, {
    toAddEmails,
    toUpdateEmails,
    toCancelEmails,
    id,
    firstName,
    lastName,
    userId: this.authenticationService.getUserId()
  })
    .then((responseEvent: ResponseEvent) => {
      return responseEvent.getResponse();
    })
    .catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
}



  /**
   * This service is to call an api which will Update an existing alert monitoring account for an email address
   * with additonal data i.e first name and last name
 * @param id is commerceContent which will be used to update the commerceContent
 * @param toAddEmails emails to be monitored for id protection
 * @param toUpdateEmails emails to be monitored for id protection
 * @param toCancelEmails emails to be monitored for id protection
 * @param firstName to be provided as a additional monitoring data
 * @param lastName to be provided as a additional monitoring data
 * @returns resolved promise with the response with data or rejected promise with false or error
 */
  public updateEmailMonitoring(
    id: string,
    toAddEmails: string[],
    toUpdateEmails: string[],
    toCancelEmails: string[],
    firstName: string,
    lastName: string
  ): Promise<any> {
    return this.request(serverPaths.commerceCustomerUpdateEmailMonitoring, {
      toAddEmails,
      toUpdateEmails,
      toCancelEmails,
      id,
      firstName,
      lastName,
      userId: this.authenticationService.getUserId()
    })
      .then((responseEvent: ResponseEvent) => {
        return responseEvent.getResponse();
      })
      .catch((e) => {
        LogUtils.error(e);
        return Promise.reject(e);
      });
  }



  /**
* This service is to call an api which to provision family information
* @param id is commerceContent which will be used to update the commerceContent
* @param relativeInfo relative firstname and last name to be provisioned
* @returns resolved promise with the response with data or rejected promise with false or error
*/
  public addFamilyProvisioning(
    id: string,
    email: string,
    relativeInfo: { name_first: string, name_last: string }[]
  ): Promise<any> {
    return this.request(serverPaths.commerceCustomerAddFamilyProvisioning, {
      relativeInfo,
      id,
      email,
      userId: this.authenticationService.getUserId()
    })
      .then((responseEvent: ResponseEvent) => {
        return responseEvent.getResponse();
      })
      .catch((e) => {
        LogUtils.error(e);
        return Promise.reject(e);
      });
  }
  public deleteFamilyProvisioning(
    id: string,
    email: string,    
    cancelInfo:  { name_first: string, name_last: string }[],
    relativeInfo: { name_first: string, name_last: string }[]
  ): Promise<any> {
    return this.request(serverPaths.commerceCustomerDeleteFamilyProvisioning, {
      relativeInfo,
      cancelInfo,
      id,
      email,
      userId: this.authenticationService.getUserId()
    })
      .then((responseEvent: ResponseEvent) => {
        return responseEvent.getResponse();
      })
      .catch((e) => {
        LogUtils.error(e);
        return Promise.reject(e);
      });
  }

  public addLostWalletProvisioning(
    id: string,
    email: string,
    lostWalletInfo: { record_id: string, record_type: string, subtype: string, data: string}[]
  ): Promise<any> {
    return this.request(serverPaths.commerceCustomerAddLostWalletProvisioning, {
      lostWalletInfo,
      id,
      email,
      userId: this.authenticationService.getUserId()
    })
      .then((responseEvent: ResponseEvent) => {
        return responseEvent.getResponse();
      })
      .catch((e) => {
        LogUtils.error(e);
        return Promise.reject(e);
      });
  }

  public deleteLostWalletProvisioning(
    id: string,
    email: string,    
    cancelInfo:  { record_id: string, record_type: string, subtype: string, data: string }[],
    lostWalletInfo: { record_id: string, record_type: string, subtype: string, data: string }[]
  ): Promise<any> {
    return this.request(serverPaths.commerceCustomerDeleteLostWalletProvisioning, {
      lostWalletInfo,
      cancelInfo,
      id,
      email,
      userId: this.authenticationService.getUserId()
    })
      .then((responseEvent: ResponseEvent) => {
        return responseEvent.getResponse();
      })
      .catch((e) => {
        LogUtils.error(e);
        return Promise.reject(e);
      });
  }


  public updateFactFindersRequest(
    result: any
  ): Promise<any> {
    const params = { result };

    return this.request(serverPaths.manageCsrUpdateFFRequest, {
      ...params,
      userId: this.authenticationService.getUserId(),

    })

      .then((responseEvent: ResponseEvent) => {
        return responseEvent.getResponse();
      })
      .catch((e) => {
        LogUtils.error(e);
        return Promise.reject(e);
      });
  }
  public addFactFindersRequest(
    result: any
  ): Promise<any> {
    const params = { result };

    return this.request(serverPaths.commerceCustomerAddFactFindersRequest, {
      ...params,
      userId: this.authenticationService.getUserId(),

    })
      .then((responseEvent: ResponseEvent) => {
        const data = responseEvent.getResponse()?.data;
        const user = data?.user;
        this.authenticationService.updateUser(user);
        return data?.commerceContent
      })
      .catch((e) => {
        LogUtils.error(e);
        return Promise.reject(e);
      });
  }
  public getFFRequestShowStatusById(param): Promise<any> {
    return this.request(serverPaths.commerceCustomerGetFactFindersRequestToShowStatus, {
      ...param,
      userId: this.authenticationService.getUserId(),
    })

      .then((responseEvent: ResponseEvent) => {
        return responseEvent.data;
      })
      .catch((e) => {
        LogUtils.error(e);
        return Promise.reject(e);
      });
  }
  public updateStatusForWithdrawRequest (param): Promise<any>  {
    return this.request(serverPaths.commerceCustomerGetFactFindersWithdraw, {
      ...param,
      userId: this.authenticationService.getUserId()
    })
    .then((responseEvent: ResponseEvent) => {
      const user =  this.authenticationService.getUser();
      this.authenticationService.updateUser(user);
      return responseEvent.data;
    })
    .catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }
  public findFFRequestUserById(param): Promise<any> {
    return this.request(serverPaths.commerceCustomerFindFactFindersRequestUserById, {
      ...param,
      userId: this.authenticationService.getUserId(),
    })

      .then((responseEvent: ResponseEvent) => {
        return responseEvent.data;
      })
      .catch((e) => {
        LogUtils.error(e);
        return Promise.reject(e);
      });
  }

  public addAttachment(fd: any) {
    return this.httpClient.post(serverPaths.manageCsrAddAttachment, fd)
  }

  public deleteAttachment(selectQuery: any) {
    return this.httpClient.post(serverPaths.manageCsrDeleteAttachment, selectQuery)
  }

  public downloadAttachment(selectQuery: any) {
    try {
      return this.httpClient.get(`${serverPaths.manageCsrDownloadAttachment}${selectQuery.attachmentId}`,
        { responseType: 'blob' },
      )
    } catch (e) {
      LogUtils.error(e);
      Promise.reject(e);
    }
  }

  public downloadClientAttachment(param: any) {
    try {
      return this.request(serverPaths.commerceCustomerdownladAttachment,
        {
          attachmentIds: param.attachmentIds,
          contentType: param.contentType,
          userId: this.authenticationService.getUserId(),
          responseType: 'blob'
        })
    } catch (e) {
      LogUtils.error(e);
      Promise.reject(e);
    }
  }

  public getFiles() {
    return this.httpClient.get(serverPaths.manageCsrGetAllAttachments);
  }

  public getAllFFRequest(params): Promise<any> {
    return this.request(serverPaths.manageCsrGetAllFFRequest, {
      ...params
    }).then((responseEvent: ResponseEvent) => {
        return responseEvent.data;
      })
      .catch((e) => {
        LogUtils.error(e);
        return Promise.reject(e);
      });
  }

  /**
 * Requesting to check rules. Dev#945
 * @param params any parameters used by checkRule
 * @returns rules based on uxcomp keys
 */
    public checkRules(params: any): Promise<any> {
    const url = serverPaths.commerceActionCheckRules;
    return this.request(url, params).then((responseEvent: ResponseEvent) => {
      return responseEvent;
    }).catch((e) => {
      return Promise.reject(e);
    });
  }
}




<style>
  mat-card {
    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(201, 213, 216, 0.6);
    border-radius: 5px;
    margin-top: 48px;
    margin-left: -32px;
    margin-right: -32px;
  }

  mat-card-header {
    border-bottom: 2px solid #e3e8ff;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 32px;
    padding-right: 32px;
  }

  mat-card-title {
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  mat-card-content {
    padding: 8px 0;
  }

  .history-row {
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
  }

  .w-20 {
    width: 20%;
  }

  .w-30 {
    width: 30%;
  }

  .w-40 {
    width: 40%;
  }

  table,
  td,
  th {
    border: 1px solid #ddd;
    text-align: left;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    padding: 15px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .added {
    background-color: #cff2db;
  }

  .removed {
    color: #7f7f7f;
  }

  .add-remove-info {
    color: #7f7f7f;
  }

  .history-item {
    display: block;
    padding: 5px;
  }

  .history-actions {
    display: flex;
    flex-direction: row-reverse;
  }
</style>
<mat-card *ngIf="uxHistory" class="mat-elevation-z6">
  <mat-card-content>
    <div class="history-actions">
      <button (click)="showBefore = !showBefore">
        Diff {{ showBefore ? 'Before' : 'Current' }}
      </button>
    </div>
    <div class="history-row">
      <table>
          <tr>
              <!-- For all UXs -->
              <th class="w-40"><span><strong>Date/ID/Name/Description/Type (if any)</strong></span></th>

              <!-- For UXConfigs -->
              @if (type === 'uxConfigs') {
                <th class="w-20"><span><strong>UXL Ids</strong></span></th>
                <th class="w-20"><span><strong>UXC-UXL Desc</strong></span></th>
                <th class="w-20"><span><strong>Split Type/Split Weight</strong></span></th>
              }

              <!-- For UXLayouts -->
              @if (type === 'uxLayouts') {
                <th><span><strong>UXCC Ids</strong></span></th>
              }

              <!-- For UXComponentCollections -->
              @if (type === 'uxComponentCollections') {
                <th class="w-30"><span><strong>UXCC</strong></span>
                <th class="w-30"><span><strong>UXComp Ids</strong></span>
              }

              <!-- For UXComponents -->
              @if (type === 'uxComponents') {
                <th class="w-20"><span><strong>ChangedBy</strong></span></th>
                <th><span><strong>Content</strong></span></th>                  
              }
          </tr>
      </table>
    </div>
    <ng-container *ngFor="let item of uxHistory">
      @for (history of item; track history._id; let itemIdx = $index) {
        <div class="history-row">
          <table>
            @if (type === 'uxConfigs') {
              @for (id of (history.reference.uxLayoutIds?.length ? history.reference.uxLayoutIds : [null]); track id; let layoutIdx = $index) {
                <tr>
                  @if (layoutIdx === 0) {
                    <td class="w-40" [rowSpan]="history.reference.uxLayoutIds.length">
                      <span>{{ history.createdTimestamp | date: "M/d/yy, h:mm:ss:SSS a" || "N/A"}} ({{ history.createdTimestamp }})</span> <br><br>
                      <span>{{ history?.referenceId || "N/A" }} </span> <br><br>
                      <span [innerHTML]="history?.reference?.name | diffStringPipe: item[itemIdx + 1]?.reference?.name: item.length === itemIdx + 1: 'N/A': showBefore"></span> <br><br>
                      <span [innerHTML]="history?.reference?.description | diffStringPipe: item[itemIdx + 1]?.reference?.description: item.length === itemIdx + 1: 'N/A': showBefore"></span> <br><br>
                      <span>{{ history?.reference?.status }} </span> <br><br>
                    </td>
                  }
            
                  <td class="w-20" 
                    [class.added]="isAdded('uxLayoutIds', item, itemIdx, id)"
                    [class.removed]="isRemoved('uxLayoutIds', item, itemIdx, id)"
                  >
                    <span>{{id}}</span>
                    @if (isAdded('uxLayoutIds', item, itemIdx, id) || isRemoved('uxLayoutIds', item, itemIdx, id)) {
                      <span class="add-remove-info">
                        (
                          @if (isAdded('uxLayoutIds', item, itemIdx, id)) {
                            +
                          }
                          @if (isRemoved('uxLayoutIds', item, itemIdx, id)) {
                            -
                          }
                        )
                      </span>
                    }
                  </td>
                  <td class="w-20"
                    [class.added]="isAdded('uxLayoutIds', item, itemIdx, id)"
                    [class.removed]="isRemoved('uxLayoutIds', item, itemIdx, id)"
                  >
                    <span [innerHTML]="history?.reference?.slotTypes?.[id]?.desc | diffStringPipe: item[itemIdx + 1]?.reference?.slotTypes?.[id]?.desc: item.length === itemIdx + 1: 'N/A': showBefore"></span>
                  </td>
                  <td class="w-20"
                    [class.added]="isAdded('uxLayoutIds', item, itemIdx, id)"
                    [class.removed]="isRemoved('uxLayoutIds', item, itemIdx, id)"
                  >
                    <span [innerHTML]="history?.reference?.slotTypes?.[id]?.slotType | diffStringPipe: item[itemIdx + 1]?.reference?.slotTypes?.[id]?.slotType: item.length === itemIdx + 1: '': showBefore"></span> / 
                    <span [innerHTML]="history?.reference?.slotTypes?.[id]?.weight | diffStringPipe: item[itemIdx + 1]?.reference?.slotTypes?.[id]?.weight: item.length === itemIdx + 1: 'N/A': showBefore"></span>
                  </td>
                </tr>
              }
            }
            @else {
              <tr>
                <!-- For all UXs -->
                <td class="w-40">
                  <span>{{ history.createdTimestamp | date: "M/d/yy, h:mm:ss:SSS a" || "N/A"}} ({{ history.createdTimestamp }})</span> <br><br>
                  <span>{{ history.referenceId || "N/A" }} </span> <br><br>
                  <span [innerHTML]="history?.reference?.name | diffStringPipe: item[itemIdx + 1]?.reference?.name: item.length === itemIdx + 1: 'N/A': showBefore"></span> <br><br>
                  <span [innerHTML]="history?.reference?.description | diffStringPipe: item[itemIdx + 1]?.reference?.description: item.length === itemIdx + 1: 'N/A': showBefore"></span> <br><br>
                  @if (type === 'uxComponents') {
                    <span [innerHTML]="history?.reference?.type | diffStringPipe: item[itemIdx + 1]?.reference?.type: item.length === itemIdx + 1: 'N/A': showBefore"></span> <br><br>
                  }
                  <span>{{ history?.reference?.status }} </span> <br><br>
                </td>
          
                @if (type === 'uxLayouts') {
                  <td>
                    @for (id of history.reference.uxComponentCollectionIds; track id; let index = $index) {
                      <span class="history-item"
                        [class.added]="isAdded('uxComponentCollectionIds', item, itemIdx, id)"
                        [class.removed]="isRemoved('uxComponentCollectionIds', item, itemIdx, id)"
                      >
                        {{id}}
                        @if (isAdded('uxComponentCollectionIds', item, itemIdx, id) || isRemoved('uxComponentCollectionIds', item, itemIdx, id)) {
                          <span class="add-remove-info">
                            (
                              @if (isAdded('uxComponentCollectionIds', item, itemIdx, id)) {
                                +
                              }
                              @if (isRemoved('uxComponentCollectionIds', item, itemIdx, id)) {
                                -
                              }
                            )
                          </span>
                        }
                      </span>
                    }
                  </td>
                }
          
                @if (type === 'uxComponentCollections') {
                  <td class="w-30">
                    @for (id of history.reference.childUxccIds; track id; let index = $index) {
                      <span class="history-item"
                        [class.added]="isAdded('childUxccIds', item, itemIdx, id)"
                        [class.removed]="isRemoved('childUxccIds', item, itemIdx, id)"
                      >
                        {{id}}
                        @if (isAdded('childUxccIds', item, itemIdx, id) || isRemoved('childUxccIds', item, itemIdx, id)) {
                          <span class="add-remove-info">
                            (
                              @if (isAdded('childUxccIds', item, itemIdx, id)) {
                                +
                              }
                              @if (isRemoved('childUxccIds', item, itemIdx, id)) {
                                -
                              }
                            )
                          </span>
                        }
                      </span>
                    }
                  </td>
                  <td class="w-30">
                    @for (id of history.reference.uxComponentIds; track id; let index = $index) {
                      <span class="history-item"
                        [class.added]="isAdded('uxComponentIds', item, itemIdx, id)"
                        [class.removed]="isRemoved('uxComponentIds', item, itemIdx, id)"
                      >
                        {{id}}
                        @if (isAdded('uxComponentIds', item, itemIdx, id) || isRemoved('uxComponentIds', item, itemIdx, id)) {
                          <span class="add-remove-info">
                            (
                              @if (isAdded('uxComponentIds', item, itemIdx, id)) {
                                +
                              }
                              @if (isRemoved('uxComponentIds', item, itemIdx, id)) {
                                -
                              }
                            )
                          </span>
                        }
                      </span>
                    }
                  </td>
                }
          
                @if (type === 'uxComponents') {
                  <td class="w-20">
                    <span [innerHTML]="history?.reference?.draft?.updaterName | diffStringPipe: item[itemIdx + 1]?.reference?.draft?.updaterName: item.length === itemIdx + 1: 'N/A': showBefore"></span>
                  </td>
                  <td>
                    <span 
                      [style.whiteSpace]="jsonBeautifier && history?.reference?.type === 'JSON' ? 'pre' : ''"
                      [innerHTML]="history?.reference?.content | jsonBeautifierPipe: jsonBeautifier && history?.reference?.type === 'JSON' | diffStringPipe: (item[itemIdx + 1]?.reference?.content | jsonBeautifierPipe: jsonBeautifier && item[itemIdx + 1]?.reference?.type === 'JSON'): item.length === itemIdx + 1: 'N/A': showBefore"
                    ></span>
                  </td>
                }
              </tr>
            }
          </table>
        </div>
      }
    </ng-container>
    <div class="history-actions">
      <button (click)="showBefore = !showBefore">
        Diff {{ showBefore ? 'Before' : 'Current' }}
      </button>
    </div>
  </mat-card-content>
</mat-card>

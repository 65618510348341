import { UxComposite } from "../models/ux/uxComposite";

export enum CaptchaType {
  RecaptchaV2 = 'recaptcha.v2',
  RecaptchaV3 = 'recaptcha.v3',
  TurnstileV0 = 'turnstile.v0',
  SvgCaptchaText = 'svgCaptcha.text',
  SvgCaptchaMath = 'svgCaptcha.math',
  PasswordV1 = 'password.v1',
  FakeCaptchaSuccessV1 = 'fakeCaptchaSuccess.v1',
  FakeCaptchaFailV1 = 'fakeCaptchaFail.v1',
  None = '',
}

export type CaptchaTokenAndType = {
  token: string,
  type: CaptchaType,
};

export type CaptchaTypeData = { type: CaptchaType, action: string };

export namespace CaptchaType {
  export function fromString(type: string): CaptchaType {
    switch (type) {
      case CaptchaType.RecaptchaV2:
        return CaptchaType.RecaptchaV2;
      case CaptchaType.RecaptchaV3:
        return CaptchaType.RecaptchaV3;
      case CaptchaType.TurnstileV0:
        return CaptchaType.TurnstileV0;
      case CaptchaType.SvgCaptchaText:
        return CaptchaType.SvgCaptchaText;
      case CaptchaType.SvgCaptchaMath:
        return CaptchaType.SvgCaptchaMath;
      case CaptchaType.PasswordV1:
        return CaptchaType.PasswordV1;
      case CaptchaType.FakeCaptchaSuccessV1:
        return CaptchaType.FakeCaptchaSuccessV1;
      case CaptchaType.FakeCaptchaFailV1:
        return CaptchaType.FakeCaptchaFailV1;
      default:
        return CaptchaType.None;
    }
  }

  export function fromNameAndVersion(name: string, version: string): CaptchaType {
    switch (`${name}.${version}`) {
      case CaptchaType.RecaptchaV2:
        return CaptchaType.RecaptchaV2;
      case CaptchaType.RecaptchaV3:
        return CaptchaType.RecaptchaV3;
      case CaptchaType.TurnstileV0:
        return CaptchaType.TurnstileV0;
      case CaptchaType.SvgCaptchaText:
        return CaptchaType.SvgCaptchaText;
      case CaptchaType.SvgCaptchaMath:
        return CaptchaType.SvgCaptchaMath;
      case CaptchaType.PasswordV1:
        return CaptchaType.PasswordV1;
      case CaptchaType.FakeCaptchaSuccessV1:
        return CaptchaType.FakeCaptchaSuccessV1;
      case CaptchaType.FakeCaptchaFailV1:
        return CaptchaType.FakeCaptchaFailV1;
      default:
        return CaptchaType.None;
    }
  }

  export function splitTypeVersion(type: CaptchaType): {
    type: string,
    version: string,
  } {
    switch (type) {
      case CaptchaType.RecaptchaV2:
        return { type: 'recaptcha', version: 'v2' };
      case CaptchaType.RecaptchaV3:
        return { type: 'recaptcha', version: 'v3' };
      case CaptchaType.TurnstileV0:
        return { type: 'turnstile', version: 'v0' };
      case CaptchaType.SvgCaptchaText:
        return { type: 'svgCaptcha', version: 'text' };
      case CaptchaType.SvgCaptchaMath:
        return { type: 'svgCaptcha', version: 'math' };
      case CaptchaType.PasswordV1:
        return { type: 'password', version: 'v1' };
      case CaptchaType.FakeCaptchaSuccessV1:
        return { type: 'fakeCaptchaSuccess', version: 'v1' };
      case CaptchaType.FakeCaptchaFailV1:
        return { type: 'fakeCaptchaFail', version: 'v1' };
      default:
        return { type: 'none', version: '' };
    }
  }
}

export class CaptchaCommonHelper {
  static readonly captchaGuardIdHeader = 'X-Captcha-Id';

  static readonly captchaGuardPage = 'captcha-guard-page';

  static getSiteKey(params: {
    type: CaptchaType,
    uxComposite: UxComposite,
  }) {
    switch (params.type) {
      case CaptchaType.RecaptchaV2:
        return params.uxComposite.getUxcomp('comp.recaptcha.key.v2');
      case CaptchaType.RecaptchaV3:
        return params.uxComposite.getUxcomp('comp.recaptcha.key.v3');
      case CaptchaType.TurnstileV0:
        return params.uxComposite.getUxcomp('comp.brand.captcha.turnstile.siteKey');
      default:
        return null;
    }
  }
}

<div class="management">
  <mat-card class="add-edit">
    <mat-card-header>
      <mat-card-title>
        <span>
          UX management
        </span>
        <div class="management-toolbar">
          <mat-slide-toggle [(ngModel)]="uxCompDownloadFlag" (change)="toggleCreateUpdate()" color="primary">
            <span class="management-toggle">{{ uxCompDownloadFlag ? 'Update Uxcomps' : 'New Uxcomps' }}</span>
          </mat-slide-toggle>
          <button mat-stroked-button color="primary" class="mr-2" (click)="createFromSearched()">
            Download Searched
          </button>
          <button mat-stroked-button color="primary" class="mr-2" (click)="download()">
            Download Created/Updated
          </button>
          <mat-checkbox color="primary" [checked]="isAllDownloadable()" (change)="toggleAllDownload()">
            <span class="management-toolbar__small">Download All</span>
          </mat-checkbox>
          <a mat-stroked-button color="primary" [routerLink]="['/'+adminPaths.uxcFullMgmtV1]">UXC Changelog</a>
        </div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="option">
        <div class="tooltip-label">
          <span>UX type</span>
          <i class="material-icons" matTooltip="Select uxc type you wanna add" matTooltipPosition="after">help</i>
        </div>
        <mat-button-toggle-group color="primary" name="newUxcType" [(ngModel)]="newUxcType">
          <mat-button-toggle value="uxconfig">UxConfig</mat-button-toggle>
          <mat-button-toggle value="uxlayout">UxLayout</mat-button-toggle>
          <mat-button-toggle value="uxcc">UxCC</mat-button-toggle>
          <mat-button-toggle value="uxcomp">UxComp</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="row" *ngIf="newUxcType === 'uxconfig'">
        <div class="col">
          <div class="tooltip-label">
            <span>Name</span>
            <i class="material-icons" matTooltip="Input new UXC name here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Name" [(ngModel)]="newUxc.draft.name">
          </mat-form-field>
        </div>
        <div class="col">
          <div class="tooltip-label">
            <span>Description</span>
            <i class="material-icons" matTooltip="Input new UXC description here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Description" [(ngModel)]="newUxc.draft.description">
          </mat-form-field>
        </div>
        <div class="col expanded">
          <div class="tooltip-label">
            <span>UXL Id</span>
            <i class="material-icons" matTooltip="Input new Child UXL id here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput #newUxcUxlId placeholder="UXL ID" (change)="addItemToArray(newUxc.draft.uxLayoutIds,newUxcUxlId)">
          </mat-form-field>
          <div *ngFor="let uxLayoutId of newUxc.draft.uxLayoutIds; let i = index" class="expanded-content" (dragover)="dragOver($event,newUxc.draft.uxLayoutIds,i)" (drop)="drop($event,newUxc.draft.uxLayoutIds,i)">
            <div class="input">
              <mat-form-field appearance="outline" class="id">
                <input matInput placeholder="UXL Id" [(ngModel)]="newUxc.draft.uxLayoutIds[i]">
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline" class="select">
                <mat-select placeholder="Type" [ngModel]="newUxc.draft.slotTypes[uxLayoutId]?.slotType" (ngModelChange)="newUxc.draft.setSlotType(uxLayoutId,'slotType', $event)">
                  <mat-option *ngFor="let slotType of slotTypes" [value]="slotType.key">{{slotType.value}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="input">
              <mat-form-field appearance="outline" class="id">
                <mat-label>Description</mat-label>
                <input matInput type="text" placeholder="Desc" [ngModel]="newUxc.draft.slotTypes[uxLayoutId]?.desc" (ngModelChange)="newUxc.draft.setSlotType(uxLayoutId,'desc', $event)">
              </mat-form-field>
            </div>
            <div class="input">
              <mat-form-field appearance="outline" *ngIf="isWeightedUxLayout(newUxc.draft,uxLayoutId)" class="id">
                <mat-label>Weight</mat-label>
                <input matInput type="text" placeholder="Weight" [ngModel]="newUxc.draft.slotTypes[uxLayoutId]?.weight" (ngModelChange)="newUxc.draft.setSlotType(uxLayoutId,'weight', $event)">
              </mat-form-field>
            </div>
            <div class="new-action">
              <button mat-icon-button (click)="removeItemInArray(newUxc.draft.uxLayoutIds,i)" color="primary">
                <i class="material-icons">delete</i>
              </button>
              <button mat-icon-button color="primary" [draggable]="true" (dragstart)="dragStart($event,newUxc.draft.uxLayoutIds,i)" (dragend)="dragEnd($event,newUxc.draft.uxLayoutIds,i)"><i class="material-icons">swap_vert</i></button>
            </div>
          </div>
        </div>
        <div class="col action">
          <button mat-raised-button color="primary" [disabled]="newUxc.isActive()" (click)="addUxc()">Add</button>
        </div>
      </div>
      <div class="row" *ngIf="newUxcType === 'uxlayout'">
        <div class="col">
          <div class="tooltip-label">
            <span>Name</span>
            <i class="material-icons" matTooltip="Input new UXL Name here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Name" [(ngModel)]="newUxl.draft.name">
          </mat-form-field>
        </div>
        <div class="col">
          <div class="tooltip-label">
            <span>Description</span>
            <i class="material-icons" matTooltip="Input new UXL description here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <input matInput placeholder="Name" [(ngModel)]="newUxl.draft.description">
          </mat-form-field>
        </div>
        <div class="col">
          <div class="tooltip-label">
            <span>UXCC Id</span>
            <i class="material-icons" matTooltip="Input child UXCC Id here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput #newUxlUxccId placeholder="UXCC" (change)="addItemToArray(newUxl.draft.uxComponentCollectionIds,newUxlUxccId)">
          </mat-form-field>
          <div *ngFor="let uxccId of newUxl.draft.uxComponentCollectionIds; let i = index " class="expanded-content" (dragover)="dragOver($event,newUxl.draft.uxComponentCollectionIds,i)" (drop)="drop($event,newUxl.draft.uxComponentCollectionIds,i)">
            <div class="input">
              <mat-form-field appearance="outline">
                <input matInput [(ngModel)]="newUxl.draft.uxComponentCollectionIds[i]">
              </mat-form-field>
            </div>
            <div class="new-action">
              <button mat-icon-button color="primary">
                <i class="material-icons" (click)="removeItemInArray(newUxl.draft.uxComponentCollectionIds,i)">delete</i>
              </button>
              <button mat-icon-button color="primary" [draggable]="true" (dragstart)="dragStart($event,newUxl.draft.uxComponentCollectionIds,i)" (dragend)="dragEnd($event,newUxl.draft.uxComponentCollectionIds,i)"><i class="material-icons">swap_vert</i></button>
            </div>
          </div>
        </div>
        <div class="col action">
          <button mat-raised-button color="primary" [disabled]="newUxl.isActive()" (click)="addUxl()">Add</button>
        </div>
      </div>
      <div class="row" *ngIf="newUxcType === 'uxcc'">
        <div class="col">
          <div class="tooltip-label">
            <span>Name</span>
            <i class="material-icons" matTooltip="Input new UXCC name here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Name" [(ngModel)]="newUxcc.draft.name">
          </mat-form-field>
        </div>
        <div class="col">
          <div class="tooltip-label">
            <span>Description</span>
            <i class="material-icons" matTooltip="Input new UXCC description here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Name" [(ngModel)]="newUxcc.draft.description">
          </mat-form-field>
        </div>
        <div class="col">
          <div class="tooltip-label">
            <span>UXCC Id</span>
            <i class="material-icons" matTooltip="Input new child UXComp Id here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput #newUxccUxccId placeholder="UXComponent ID" (change)="addItemToArray(newUxcc.draft.childUxccIds,newUxccUxccId)">
          </mat-form-field>
          <div *ngFor="let uxccId of newUxcc.draft.childUxccIds; let i = index " class="expanded-content" (dragover)="dragOver($event,newUxcc.draft.childUxccIds,i)" (drop)="drop($event,newUxcc.draft.childUxccIds,i)">
            <div class="input">
              <mat-form-field appearance="outline">
                <input matInput [(ngModel)]="newUxcc.draft.childUxccIds[i]">
              </mat-form-field>
            </div>
            <div class="new-action">
              <button mat-icon-button color="primary" (click)="removeItemInArray(newUxcc.draft.childUxccIds,i)">
                <i class="material-icons">delete</i>
              </button>
              <button mat-icon-button color="primary" [draggable]="true" (dragstart)="dragStart($event,newUxcc.draft.childUxccIds,i)" (dragend)="dragEnd($event,newUxcc.draft.childUxccIds,i)"><i class="material-icons">swap_vert</i></button>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="tooltip-label">
            <span>UXComp Id</span>
            <i class="material-icons" matTooltip="Input new child UXComp Id here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput #newUxccUxcompId placeholder="UXComponent ID" (change)="addItemToArray(newUxcc.draft.uxComponentIds,newUxccUxcompId)">
          </mat-form-field>
          <div *ngFor="let uxcompId of newUxcc.draft.uxComponentIds; let i = index " class="expanded-content" (dragover)="dragOver($event,newUxcc.draft.uxComponentIds,i)" (drop)="drop($event,newUxcc.draft.uxComponentIds,i)">
            <div class="input">
              <mat-form-field appearance="outline">
                <input matInput [(ngModel)]="newUxcc.draft.uxComponentIds[i]">
              </mat-form-field>
            </div>
            <div class="new-action">
              <button mat-icon-button color="primary">
                <i class="material-icons" (click)="removeItemInArray(newUxcc.draft.uxComponentIds,i)">delete</i>
              </button>
              <button mat-icon-button color="primary" [draggable]="true" (dragstart)="dragStart($event,newUxcc.draft.uxComponentIds,i)" (dragend)="dragEnd($event,newUxcc.draft.uxComponentIds,i)"><i class="material-icons">swap_vert</i></button>
            </div>
          </div>
        </div>
        <div class="col action">
          <button mat-raised-button color="primary" [disabled]="newUxcc.isActive()" (click)="addUxcc()">Add</button>
        </div>
      </div>
      <div class="row" *ngIf="newUxcType === 'uxcomp'">
        <div class="col">
          <div class="tooltip-label">
            <span>Name</span>
            <i class="material-icons" matTooltip="Input new UXComp name here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Name" [(ngModel)]="newUxComponent.draft.name">
          </mat-form-field>
        </div>
        <div class="col">
          <div class="tooltip-label">
            <span>Description</span>
            <i class="material-icons" matTooltip="Input new UXComp description here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Name" [(ngModel)]="newUxComponent.draft.description">
          </mat-form-field>
        </div>
        <div class="col">
          <div class="tooltip-label">
            <span>Type</span>
            <i class="material-icons" matTooltip="Input new UXComp type here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-select placeholder="Type" [(ngModel)]="newUxComponent.draft.type">
              <mat-option *ngFor="let compType of uxComponentTypes" [value]="compType.key">{{compType.value}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <div class="tooltip-label">
            <span>Content</span>
            <i class="material-icons" matTooltip="Input new UXComp content here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline" floatLabel="always">
            <textarea rows="6" #newUxlComponentContent matInput placeholder="Content" [(ngModel)]="newUxComponent.draft.content"></textarea>
          </mat-form-field>
        </div>
        <div class="col">
          <div class="tooltip-label">
            <span>Options</span>
            <i class="material-icons" matTooltip="Modify new UXComp options here." matTooltipPosition="after">help</i>
          </div>
          <mat-checkbox color="primary" [(ngModel)]="newUxComponent.draft.serverOnly">ServerOnly</mat-checkbox>
          <button mat-icon-button color="primary" *ngIf="!newUxComponent.getToggle('uncheck')" [class.opacity50]="!newUxComponent.draft.check(false)" (click)="newUxComponent.toggle('uncheck')">
            <i class="material-icons">check_circle_outline</i>
          </button>
          <button mat-icon-button color="primary" *ngIf="newUxComponent.getToggle('uncheck')" [class.opacity50]="!newUxComponent.draft.check(false)" (click)="newUxComponent.toggle('uncheck')">
            <i class="material-icons">account_circle</i>
          </button>
        </div>
        <div class="col action">
          <button mat-raised-button color="primary" [disabled]="newUxComponent.isActive()" (click)="addUxComponent()">Add</button>
        </div>
      </div>
      <div class="spacer"></div>
      <div class="row">
        <div class="col">
          <div class="tooltip-label">
            <span>UXC</span>
            <i class="material-icons" matTooltip="Input UXC name or id here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput (keydown)="keyDownEvent($event)" placeholder="UXC" [(ngModel)]="searchOptions.filters.uxConfigs.value">
          </mat-form-field>
        </div>
        <div class="col">
          <div class="tooltip-label">
            <span>UXL</span>
            <i class="material-icons" matTooltip="Input UXL name or id here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput (keydown)="keyDownEvent($event)" placeholder="UXL" [(ngModel)]="searchOptions.filters.uxLayouts.value">
          </mat-form-field>
        </div>
        <div class="col">
          <div class="tooltip-label">
            <span>UXCC</span>
            <i class="material-icons" matTooltip="Input UXCC name or id here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput (keydown)="keyDownEvent($event)" placeholder="UXCC" [(ngModel)]="searchOptions.filters.uxComponentCollections.value">
          </mat-form-field>
        </div>
        <div class="col">
          <div class="tooltip-label">
            <span>UXComp</span>
            <i class="material-icons" matTooltip="Input UXComp name or id here." matTooltipPosition="after">help</i>
            <input class="tristate-checkbox"
                   type="checkbox" 
                   id="uxcompServerOnly"
                   [indeterminate]="searchOptions.filters.uxComponents?.additionalFields?.serverOnly === false" 
                   [ngModel]="searchOptions.filters.uxComponents?.additionalFields?.serverOnly" 
                   (ngModelChange)="checkServerOnly(searchOptions.filters.uxComponents?.additionalFields?.serverOnly)">
            <label for="uxcompServerOnly">Server Only</label>
            <input class="checkbox"
                   type="checkbox" 
                   id="removedToggle"
                   [ngModel]="searchOptions?.removed" 
                   (ngModelChange)="checkRemoved(searchOptions.removed)">
            <label for="removedToggle">Removed</label>
          </div>
          <mat-form-field appearance="outline">
            <input matInput (keydown)="keyDownEvent($event)" placeholder="UXComp" [(ngModel)]="searchOptions.filters.uxComponents.value">
          </mat-form-field>
        </div>
        <div class="col action">
          <button mat-raised-button color="primary" (click)="search()">Search</button>
        </div>
      </div>
      @if (results.uxConfigs.length || results.uxLayouts.length || results.uxComponentCollections.length || results.uxComponents.length) {
        <div class="spacer">
          <button class="toggle-replace-btn" (click)="showReplaceSection = !showReplaceSection">
            @if (showReplaceSection) {
              Close Replace
            }
            @else {
              Open Replace
            }
          </button>
        </div>
        @if (showReplaceSection) {
          <div class="row">
            <div class="col">
              <div class="tooltip-label">
                <span>Pattern</span>
                <i class="material-icons" matTooltip="Enter a regular expression pattern for replace." matTooltipPosition="after">help</i>
              </div>
              <mat-form-field appearance="outline">
                <input matInput placeholder="Pattern" [ngModel]="updatePattern()" (ngModelChange)="updatePattern.set($event)">
              </mat-form-field>
            </div>
            <div class="col">
              <div class="tooltip-label">
                <span>Replacement</span>
                <i class="material-icons" matTooltip="Enter a value to change the value corresponding to the Pattern." matTooltipPosition="after">help</i>
              </div>
              <mat-form-field appearance="outline">
                <input matInput placeholder="Replacement" [ngModel]="updateReplacement()" (ngModelChange)="updateReplacement.set($event)">
              </mat-form-field>
            </div>
            <div class="col replace-regex-options">
              <div class="tooltip-label">
                <span>Options</span>
                <i class="material-icons" matTooltip="Enter options for Pattern." matTooltipPosition="after">help</i>
              </div>
              <mat-form-field appearance="outline">
                <input matInput placeholder="Options" [ngModel]="updateOptions()" (ngModelChange)="updateOptions.set($event)">
              </mat-form-field>
            </div>
            <div class="col action">
              <button mat-raised-button color="primary" (click)="previewUpdateFieldsByRegex()">Preview</button>
            </div>
            <div class="col action">
              <button mat-raised-button color="primary" (click)="clearPreviewUpdateFieldsByRegex()">Clear<br>Preview</button>
            </div>
            <div class="col action">
              <button mat-stroked-button color="primary" (click)="updateFieldsByRegex()">Update</button>
            </div>
          </div>
          <div class="row replace-regex-check-group">
            <span>
              <input id="replaceRegexNameCheck" type="checkbox" [ngModel]="updateNameCheck()" (ngModelChange)="updateNameCheck.set($event)"><label for="replaceRegexNameCheck">Name</label>
            </span>
            <span>
              <input id="replaceRegexDescCheck" type="checkbox" [ngModel]="updateDescCheck()" (ngModelChange)="updateDescCheck.set($event)"><label for="replaceRegexDescCheck">Desc</label>
            </span>
            <span>
              <input id="replaceRegexUXCUXLDescCheck" type="checkbox" [ngModel]="updateUxcUxlDescCheck()" (ngModelChange)="updateUxcUxlDescCheck.set($event)"><label for="replaceRegexUXCUXLDescCheck">UXC-UXL Desc</label>
            </span>
            <span>
              <input id="replaceRegexContentCheck" type="checkbox" [ngModel]="updateContentCheck()" (ngModelChange)="updateContentCheck.set($event)"><label for="replaceRegexContentCheck">Content</label>
            </span>
            <span style="margin-left: auto">
              <input id="replaceRegexShowDiff" type="checkbox" [ngModel]="updateShowDiff()" (ngModelChange)="updateShowDiff.set($event)"><label for="replaceRegexShowDiff">Show Diff</label>
            </span>
          </div>
        }
      }
    </mat-card-content>
  </mat-card>
</div>
<div class="searched">
  <ng-container *ngIf="results.uxConfigs.length">
    <div class="searched-title">
      <span>UXC</span>
      <div>
        @if (uxcBuildShowTimestamp) {
          <input class="uxc-build-timestamp-size" type="number" min="1" [(ngModel)]="uxcBuildTimestamp">
        }
        @else {
          <button mat-raised-button color="primary" class="mr-2" (click)="uxcBuildShowTimestamp = true">Timestamp</button>
        }
        <input class="uxc-build-parallel-size" type="number" min="1" max="36" [(ngModel)]="uxcBuildParallelSize">
        <button mat-raised-button color="primary" class="mr-2" (click)="buildUxComposites()">Build All</button>
        <button *ngIf="isOrphanCheckable()" mat-stroked-button color="primary" (click)="findOrphanUx()">Check Orphans</button>
      </div>
    </div>
    <div class="searched-content" *ngFor="let uxConfig of results.uxConfigs">
      <div class="row" *ngIf="uxConfig.tempClient.edit">
        <div class="info">
          <div class="info-section form">
            <label (click)="copyId(uxConfig)" class="copy">UXC</label>
            <span>
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>UXC</mat-label>
                <input matInput placeholder="ID" [disabled]="true" [(ngModel)]="uxConfig._id">
              </mat-form-field>
            </span>
          </div>
          <div class="info-section form">
            <label (click)="copyName(uxConfig)" class="copy">Name</label>
            <span>
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Name" [(ngModel)]="uxConfig.draft.name">
              </mat-form-field>
            </span>
          </div>
          <div class="info-section form">
            <label>Desc</label>
            <span>
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Desc</mat-label>
                <input matInput placeholder="Desc" [(ngModel)]="uxConfig.draft.description">
              </mat-form-field>
            </span>
          </div>
        </div>
        <div class="col">
          <div class="row mb-1">
            <div class="col id primary">UXL</div>
            <div class="col primary">UXL Name</div>
            <div class="col primary">UXC-UXL Desc</div>
            <div class="col primary">Split Type</div>
            <div class="col primary">Split Weight</div>
            <div class="col action"></div>
          </div>
          <div *ngFor="let uxLayoutId of uxConfig.draft.uxLayoutIds; let i = index" (dragover)="dragOver($event,uxConfig.draft.uxLayoutIds,i)" (drop)="drop($event,uxConfig.draft.uxLayoutIds,i)" class="row">
            <ng-container *ngIf="(!uxConfig.getUxLayoutById(uxLayoutId)) || uxConfig.getUxLayoutById(uxLayoutId)?.isActive()">
              <div class="col id">
                <mat-form-field appearance="outline">
                  <input matInput [disabled]="isTempRemoved(uxConfig,i)" [placeholder]="uxConfig.getUxLayoutById(uxLayoutId)?.name" [(ngModel)]="uxConfig.draft.uxLayoutIds[i]">
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field appearance="outline" [class.hidden]="isNewItem(uxConfig.uxLayoutIds, uxLayoutId)">
                  <input matInput type="text" [disabled]="true" placeholder="Name" [value]="uxConfig.getUxLayoutById(uxLayoutId)?.name">
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field appearance="outline">
                  <input matInput [disabled]="isTempRemoved(uxConfig,i)" type="text" placeholder="Desc" [ngModel]="uxConfig.draft.slotTypes[uxLayoutId]?.desc" (ngModelChange)="uxConfig.draft.setSlotType(uxLayoutId,'desc', $event)">
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-select [disabled]="isTempRemoved(uxConfig,i)" placeholder="Type" [ngModel]="uxConfig.draft.slotTypes[uxLayoutId]?.slotType" (ngModelChange)="uxConfig.draft.setSlotType(uxLayoutId,'slotType', $event)">
                    <mat-option *ngFor="let slotType of slotTypes" [value]="slotType.key">{{slotType.value}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field appearance="outline" *ngIf="isWeightedUxLayout(uxConfig.draft,uxLayoutId)">
                  <input [disabled]="isTempRemoved(uxConfig,i)" matInput type="text" placeholder="Weight" [ngModel]="uxConfig.draft.slotTypes[uxLayoutId]?.weight" (ngModelChange)="uxConfig.draft.setSlotType(uxLayoutId,'weight', $event)">
                </mat-form-field>
              </div>
              <div class="col action normal">
                <button *ngIf="!isTempRemoved(uxConfig,i)" mat-icon-button color="primary" (click)="removeTempFromArray(uxConfig,i)"><i class="material-icons">delete</i></button>
                <button *ngIf="isTempRemoved(uxConfig,i)" mat-icon-button color="primary" (click)="cancelRemoveTempFromArray(uxConfig,i)"><i class="material-icons">refresh</i></button>
                <button mat-icon-button color="primary" [draggable]="true" (dragstart)="dragStart($event,uxConfig.draft.uxLayoutIds,i)" (dragend)="dragEnd($event,uxConfig.draft.uxLayoutIds,i)"><i class="material-icons">swap_vert</i></button>
              </div>
            </ng-container>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline" floatLabel="always">
                <input matInput #newUxlId placeholder="UXL ID" (change)="addItemToArray(uxConfig.draft.uxLayoutIds,newUxlId)">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!uxConfig.tempClient.edit">
        <div class="info">
          <div class="info-section copy" (click)="copyId(uxConfig)"><label>UXC</label><span>{{uxConfig._id}}</span></div>
          <div class="info-section copy" (click)="copyName(uxConfig)"><label>Name</label><span [innerHTML]="uxConfig.name | replaceRegexPipe: updateInfo: updateTypes.name"></span></div>
          <div class="info-section"><label>Desc</label><span [innerHTML]="uxConfig.description | replaceRegexPipe: updateInfo: updateTypes.desc"></span></div>
          <div class="info-section"><label>Status</label><span>{{ uxConfig.status }}</span></div>
        </div>
        <div class="col">
          <div class="row mb-1">
            <div class="col id primary">UXL</div>
            <div class="col primary">UXL Name</div>
            <div class="col primary">UXC-UXL Desc</div>
            <div class="col primary">Split Type</div>
            <div class="col primary">Split Weight</div>
            <div class="col action"></div>
          </div>
          <ng-container *ngFor="let uxLayoutId of uxConfig.uxLayoutIds; let i = index">
            <div *ngIf="(!uxConfig.getUxLayoutById(uxLayoutId)) || uxConfig.getUxLayoutById(uxLayoutId)?.isActive()" class="row mb-1">
              <div class="col id copy" (click)="copyId(uxConfig.getUxLayoutById(uxLayoutId))">{{uxLayoutId}}</div>
              <div class="col copy" (click)="copyName(uxConfig.getUxLayoutById(uxLayoutId))">{{uxConfig.getUxLayoutById(uxLayoutId)?.name}}</div>
              <div class="col" [innerHTML]="uxConfig.draft.slotTypes[uxLayoutId]?.desc | replaceRegexPipe: updateInfo: updateTypes.uxcUxlDesc"></div>
              <div class="col">{{uxConfig.draft.slotTypes[uxLayoutId]?.slotType === 'default' ? 'default' : 'manual'}}</div>
              <div class="col">{{uxConfig.draft.slotTypes[uxLayoutId]?.weight}}</div>
              <div class="col action"></div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="row">
        <button *ngIf="!uxConfig.tempClient.edit" mat-raised-button color="primary" class="mr-2" (click)="toggleEdit(uxConfig)">Edit</button>
        <button *ngIf="uxConfig.tempClient.edit" mat-raised-button color="primary" class="mr-2" (click)="update(uxConfig)" [disabled]="!uxConfig.isDiffDraft() && !isTempEdited(uxConfig)">Save</button>
        <button *ngIf="uxConfig.tempClient.edit" mat-stroked-button color="primary" class="mr-2" (click)="toggleEdit(uxConfig)">Cancel</button>
        <button *ngIf="!uxConfig.tempClient.edit" mat-stroked-button color="primary" class="mr-2" (click)="runBuild(uxConfig._id, true)">Build</button>
        <button *ngIf="!uxConfig.tempClient.edit" mat-stroked-button color="primary" (click)="duplicate(uxConfig)" class="mr-2">Copy</button>
        <button *ngIf="!uxConfig.tempClient.edit" mat-raised-button color="warn" (click)="openDeleteToggleModal(uxConfig)" class="mr-2">
          {{ uxConfig.status === 'active' ? 'Delete' : 'Restore' }}
        </button>
        <button mat-stroked-button color="primary" (click)="findUxHistory(uxConfig._id, 'uxConfigs')" class="mr-2 gr-left">History</button>
        <button mat-stroked-button color="primary" (click)="openUxHistory(uxConfig._id, 'uxConfigs')" class="mr-2 gr-right">🔗</button>
      </div>
      <div *ngIf="hasHistory('uxConfigs', uxConfig._id)">
        <uxHistory [uxHistory]="uxHistory['uxConfigs']" [id]="uxConfig._id" type='uxConfigs'></uxHistory>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="results.uxLayouts.length">
    <div class="searched-title"><span>UXLayout</span></div>
    <div class="searched-content" *ngFor="let uxLayout of results.uxLayouts">
      <div class="row" *ngIf="uxLayout.tempClient.edit">
        <div class="info">
          <div class="info-section">
            <label class="copy" (click)="copyId(uxLayout)">UXL</label>
            <span>
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>UXL</mat-label>
                <input matInput type="text" disabled [(ngModel)]="uxLayout._id">
              </mat-form-field>
            </span>
          </div>
          <div class="info-section">
            <label class="copy" (click)="copyName(uxLayout)">Name</label>
            <span>
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Name</mat-label>
                <input matInput type="text" [(ngModel)]="uxLayout.draft.name">
              </mat-form-field>
            </span>
          </div>
          <div class="info-section">
            <label>Desc</label>
            <span>
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Desc</mat-label>
                <input matInput type="text" [(ngModel)]="uxLayout.draft.description">
              </mat-form-field>
            </span>
          </div>
        </div>
        <div class="col">
          <div class="row mb-1">
            <div class="col primary">UXCC</div>
            <div class="col primary">UXCC Name</div>
            <div class="col action"></div>
          </div>
          <div *ngFor="let uxccId of uxLayout.draft.uxComponentCollectionIds; let i = index " (dragover)="dragOver($event,uxLayout.draft.uxComponentCollectionIds,i)" (drop)="drop($event,uxLayout.draft.uxComponentCollectionIds,i)" class="row">
            <ng-container *ngIf="(!uxLayout.getUxCCById(uxccId)) || uxLayout.getUxCCById(uxccId)?.isActive()">
              <div class="col">
                <mat-form-field appearance="outline" floatLabel="always">
                  <mat-label>ID</mat-label>
                  <input [disabled]="isTempRemoved(uxLayout, i)" matInput [placeholder]="uxLayout.draft.uxComponentCollectionIds[i]" [(ngModel)]="uxLayout.draft.uxComponentCollectionIds[i]">
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field appearance="outline" floatLabel="always" [class.hidden]="isNewItem(uxLayout.uxComponentCollectionIds, uxccId)">
                  <mat-label>Name</mat-label>
                  <input [disabled]="isTempRemoved(uxLayout, i)" matInput [value]="uxLayout.getUxCCById(uxccId)?.name" disabled>
                </mat-form-field>
              </div>
              <div class="col action normal">
                <button *ngIf="!isTempRemoved(uxLayout, i)" mat-icon-button color="primary" (click)="removeTempFromArray(uxLayout,i)"><i class="material-icons">delete</i></button>
                <button *ngIf="isTempRemoved(uxLayout, i)" mat-icon-button color="primary" (click)="cancelRemoveTempFromArray(uxLayout,i)"><i class="material-icons">refresh</i></button>
                <button mat-icon-button color="primary" [draggable]="true" (dragstart)="dragStart($event,uxLayout.draft.uxComponentCollectionIds,i)" (dragend)="dragEnd($event,uxLayout.draft.uxComponentCollectionIds,i)"><i class="material-icons">swap_vert</i></button>
              </div>
            </ng-container>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>New UXCC</mat-label>
                <input matInput #newUxccId placeholder="UXCC ID" (change)="addItemToArray(uxLayout.draft.uxComponentCollectionIds,newUxccId)">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!uxLayout.tempClient.edit">
        <div class="info">
          <div class="info-section copy" (click)="copyId(uxLayout)"><label>UXL</label><span>{{uxLayout._id}}</span></div>
          <div class="info-section copy" (click)="copyName(uxLayout)"><label>Name</label><span [innerHTML]="uxLayout.draft.name | replaceRegexPipe: updateInfo: updateTypes.name"></span></div>
          <div class="info-section"><label>Desc</label><span [innerHTML]="uxLayout.draft.description | replaceRegexPipe: updateInfo: updateTypes.desc"></span></div>
          <div class="info-section"><label>Status</label><span>{{ uxLayout.status }}</span></div>
        </div>
        <div class="col">
          <div class="row mb-1">
            <div class="col id primary">UXCC</div>
            <div class="col primary">UXCC Name</div>
          </div>
          <ng-container *ngFor="let uxccId of uxLayout.uxComponentCollectionIds; let i = index ">
            <div *ngIf="(!uxLayout.getUxCCById(uxccId)) || uxLayout.getUxCCById(uxccId)?.isActive()" class="row mb-1">
              <div class="col id copy" (click)="copyId(uxLayout.getUxCCById(uxccId))">{{uxccId}}</div>
              <div class="col copy" (click)="copyName(uxLayout.getUxCCById(uxccId))">{{uxLayout.getUxCCById(uxccId)?.name}}</div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="row">
        <ng-container *ngIf="uxLayout.tempClient.edit">
          <button mat-raised-button color="primary" (click)="update(uxLayout)" class="mr-2" [disabled]="!uxLayout.isDiffDraft() && !isTempEdited(uxLayout)">Save</button>
          <button mat-stroked-button color="primary" (click)="toggleEdit(uxLayout)">Cancel</button>
        </ng-container>
        <ng-container *ngIf="!uxLayout.tempClient.edit">
          <button mat-raised-button color="primary" (click)="toggleEdit(uxLayout)" class="mr-2">Edit</button>
          <button mat-stroked-button color="primary" (click)="duplicate(uxLayout)" class="mr-2">Copy</button>
          <button mat-stroked-button color="primary" (click)="findUxDetail(uxLayout._id)" class="mr-2">Search</button>
          <button mat-raised-button color="warn" (click)="openDeleteToggleModal(uxLayout)" class="mr-2">
            {{ uxLayout.status === 'active' ? 'Delete' : 'Restore' }}
          </button>
          <button mat-stroked-button color="primary" (click)="findUxHistory(uxLayout._id, 'uxLayouts')" class="mr-2 gr-left">History</button>
          <button mat-stroked-button color="primary" (click)="openUxHistory(uxLayout._id, 'uxLayouts')" class="mr-2 gr-right">🔗</button>
        </ng-container>
      </div>
      <mat-card-content *ngIf="hasDetail(uxLayout)">
        <div class="uxDetail">
          <uxDetail [uxDetail]="uxLayout.tempClient.uxDetail"></uxDetail>
        </div>
      </mat-card-content>
      <div *ngIf="hasHistory('uxLayouts', uxLayout._id)">
        <uxHistory [uxHistory]="uxHistory['uxLayouts']" [id]="uxLayout._id" type='uxLayouts'></uxHistory>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="results.uxComponentCollections.length">
    <div class="searched-title"><span>UXCC</span></div>
    <div class="searched-content" *ngFor="let uxComponentCollection of results.uxComponentCollections">
      <div class="row" *ngIf="uxComponentCollection.tempClient.edit">
        <div class="info">
          <div class="info-section">
            <label class="copy" (click)="copyId(uxComponentCollection)">UXCC</label>
            <span>
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>UXCC</mat-label>
                <input matInput type="text" disabled [(ngModel)]="uxComponentCollection._id">
              </mat-form-field>
            </span>
          </div>
          <div class="info-section">
            <label class="copy" (click)="copyName(uxComponentCollection)">Name</label>
            <span>
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Name</mat-label>
                <input matInput type="text" [(ngModel)]="uxComponentCollection.draft.name">
              </mat-form-field>
            </span>
          </div>
          <div class="info-section">
            <label>Desc</label>
            <span>
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Desc</mat-label>
                <input matInput type="text" [(ngModel)]="uxComponentCollection.draft.description">
              </mat-form-field>
            </span>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <div class="col">
              <div class="row mb-1">
                <div class="col id primary">UXCC</div>
                <div class="col primary">UXCC Name</div>
                <div class="col action"></div>
              </div>
              <ng-container *ngFor="let uxccId of uxComponentCollection.draft.childUxccIds; let i = index ">
                <div class="row mb-1" *ngIf="(!uxComponentCollection.getChildUxCCById(uxccId)) || uxComponentCollection.getChildUxCCById(uxccId)?.isActive()"(dragover)="dragOver($event,uxComponentCollection.draft.childUxccIds,i)" (drop)="drop($event,uxComponentCollection.draft.childUxccIds,i)">
                  <div class="col id">
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-label>UXCC</mat-label>
                      <input [disabled]="isTempRemoved(uxComponentCollection, i, 'uxcc')" matInput [placeholder]="uxComponentCollection.getChildUxCCById(uxccId)?.name" [(ngModel)]="uxComponentCollection.draft.childUxccIds[i]">
                    </mat-form-field>
                  </div>
                  <div class="col">
                    <mat-form-field appearance="outline" floatLabel="always" [class.hidden]="isNewItem(uxComponentCollection.childUxccIds, uxccId)">
                      <mat-label>UXCC Name</mat-label>
                      <input disabled matInput [value]="uxComponentCollection.getChildUxCCById(uxccId)?.name">
                    </mat-form-field>
                  </div>
                  <div class="col action normal">
                    <button *ngIf="!isTempRemoved(uxComponentCollection, i, 'uxcc')" mat-icon-button color="primary" (click)="removeTempFromArray(uxComponentCollection,i,'uxcc')"><i class="material-icons">delete</i></button>
                    <button *ngIf="isTempRemoved(uxComponentCollection, i, 'uxcc')" mat-icon-button color="primary" (click)="cancelRemoveTempFromArray(uxComponentCollection,i,'uxcc')"><i class="material-icons">refresh</i></button>
                    <button mat-icon-button color="primary" [draggable]="true" (dragstart)="dragStart($event,uxComponentCollection.draft.childUxccIds,i)" (dragend)="dragEnd($event,uxComponentCollection.draft.childUxccIds,i)"><i class="material-icons">swap_vert</i></button>
                  </div>
                </div>
              </ng-container>
              <div class="row">
                <div class="col">
                  <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>New UXCC</mat-label>
                    <input matInput #newUxccId placeholder="UXCC ID" (change)="addItemToArray(uxComponentCollection.draft.childUxccIds,newUxccId)">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row mb-1">
                <div class="col id primary">UXComp</div>
                <div class="col primary">UXComp Name</div>
              </div>
              <ng-container *ngFor="let uxcompId of uxComponentCollection.draft.uxComponentIds; let i = index ">
                <div class="row mb-1" *ngIf="(!uxComponentCollection.getUxCompById(uxcompId)) || uxComponentCollection.getUxCompById(uxcompId)?.isActive()" (dragover)="dragOver($event,uxComponentCollection.draft.uxComponentIds,i)" (drop)="drop($event,uxComponentCollection.draft.uxComponentIds,i)">
                  <div class="col id">
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-label>UXComp</mat-label>
                      <input [disabled]="isTempRemoved(uxComponentCollection, i, 'uxcomp')" matInput [placeholder]="uxComponentCollection.getUxCompById(uxcompId)?.name" [(ngModel)]="uxComponentCollection.draft.uxComponentIds[i]">
                    </mat-form-field>
                  </div>
                  <div class="col">
                    <mat-form-field appearance="outline" floatLabel="always" [class.hidden]="isNewItem(uxComponentCollection.uxComponentIds, uxcompId)">
                      <mat-label>UXComp Name</mat-label>
                      <input matInput disabled [value]="uxComponentCollection.getUxCompById(uxcompId)?.name">
                    </mat-form-field>
                  </div>
                  <div class="col action normal">
                    <button *ngIf="!isTempRemoved(uxComponentCollection, i, 'uxcomp')" mat-icon-button color="primary" (click)="removeTempFromArray(uxComponentCollection,i,'uxcomp')"><i class="material-icons">delete</i></button>
                    <button *ngIf="isTempRemoved(uxComponentCollection, i, 'uxcomp')" mat-icon-button color="primary" (click)="cancelRemoveTempFromArray(uxComponentCollection,i,'uxcomp')"><i class="material-icons">refresh</i></button>
                    <button mat-icon-button color="primary" [draggable]="true" (dragstart)="dragStart($event,uxComponentCollection.draft.uxComponentIds,i)" (dragend)="dragEnd($event,uxComponentCollection.draft.uxComponentIds,i)"><i class="material-icons">swap_vert</i></button>
                  </div>
                </div>
              </ng-container>
              <div class="row">
                <div class="col">
                  <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>UXComp ID</mat-label>
                    <input matInput #newUxcompId placeholder="UXComp ID" (change)="addItemToArray(uxComponentCollection.draft.uxComponentIds,newUxcompId)">
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!uxComponentCollection.tempClient.edit">
        <div class="info">
          <div class="info-section copy" (click)="copyId(uxComponentCollection)"><label>UXCC</label><span>{{uxComponentCollection._id}}</span></div>
          <div class="info-section copy" (click)="copyName(uxComponentCollection)"><label>Name</label><span [innerHTML]="uxComponentCollection.draft.name | replaceRegexPipe: updateInfo: updateTypes.name"></span></div>
          <div class="info-section"><label>Desc</label><span [innerHTML]="uxComponentCollection.draft.description | replaceRegexPipe: updateInfo: updateTypes.desc"></span></div>
          <div class="info-section"><label>Status</label><span>{{ uxComponentCollection.status }}</span></div>
        </div>
        <div class="col">
          <div class="row">
            <div class="col">
              <div class="row mb-1">
                <div class="col primary id">UXCC</div>
                <div class="col primary">UXCC Name</div>
                <div class="col action"></div>
              </div>
            </div>
            <div class="col">
              <div class="row mb-1">
                <div class="col primary id">UXComp</div>
                <div class="col primary">UXComp Name</div>
                <div class="col action"></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <ng-container *ngFor="let uxccId of uxComponentCollection.draft.childUxccIds; let i = index ">
                <div class="row mb-1" *ngIf="(!uxComponentCollection.getChildUxCCById(uxccId)) || uxComponentCollection.getChildUxCCById(uxccId)?.isActive()">
                  <div class="col id copy" (click)="copyId(uxComponentCollection.getChildUxCCById(uxccId))">{{uxccId}}</div>
                  <div class="col copy" (click)="copyName(uxComponentCollection.getChildUxCCById(uxccId))">{{uxComponentCollection.getChildUxCCById(uxccId)?.name}}</div>
                </div>
              </ng-container>
            </div>
            <div class="col">
              <ng-container  *ngFor="let uxcompId of uxComponentCollection.draft.uxComponentIds; let i = index ">
                <div class="row mb-1" *ngIf="(!uxComponentCollection.getUxCompById(uxcompId)) || uxComponentCollection.getUxCompById(uxcompId)?.isActive()">
                  <div class="col id copy" (click)="copyId(uxComponentCollection.getUxCompById(uxcompId))">{{uxcompId}}</div>
                  <div class="col copy" (click)="copyName(uxComponentCollection.getUxCompById(uxcompId))">{{uxComponentCollection.getUxCompById(uxcompId)?.name}}</div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <ng-container *ngIf="!uxComponentCollection.tempClient.edit">
          <button mat-raised-button color="primary" (click)="toggleEdit(uxComponentCollection)" class="mr-2">Edit</button>
          <button mat-stroked-button color="primary" (click)="duplicate(uxComponentCollection)" class="mr-2">Copy</button>
          <button mat-stroked-button color="primary" (click)="findUxDetail(uxComponentCollection._id)" class="mr-2">Search</button>
          <button mat-raised-button color="warn" (click)="openDeleteToggleModal(uxComponentCollection)" class="mr-2">
            {{ uxComponentCollection.status === 'active' ? 'Delete' : 'Restore' }}
          </button>
          <button mat-stroked-button color="primary" (click)="findUxHistory(uxComponentCollection._id, 'uxComponentCollections')" class="mr-2 gr-left">History</button>
          <button mat-stroked-button color="primary" (click)="openUxHistory(uxComponentCollection._id, 'uxComponentCollections')" class="mr-2 gr-right">🔗</button>
          <mat-checkbox color="primary" [checked]="isDownloadable(uxComponentCollection)" (change)="toggleDownloadFlag(uxComponentCollection)">Download</mat-checkbox>
        </ng-container>
        <ng-container *ngIf="uxComponentCollection.tempClient.edit">
          <button mat-raised-button color="primary" [disabled]="!uxComponentCollection.isDiffDraft() && !isTempEdited(uxComponentCollection)" (click)="update(uxComponentCollection)" class="mr-2">Save</button>
          <button mat-stroked-button color="primary" (click)="toggleEdit(uxComponentCollection)">Cancel</button>
        </ng-container>
      </div>
      <mat-card-content *ngIf="hasDetail(uxComponentCollection)">
        <div class="uxDetail">
          <uxDetail [uxDetail]="uxComponentCollection.tempClient.uxDetail"></uxDetail>
        </div>
      </mat-card-content>
      <div *ngIf="hasHistory('uxComponentCollections', uxComponentCollection._id)">
        <uxHistory [uxHistory]="uxHistory['uxComponentCollections']" [id]="uxComponentCollection._id" type='uxComponentCollections'></uxHistory>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="results.uxComponents.length">
    <div class="searched-title">
      <span>UXComponent</span>
      <div>
        <button mat-raised-button color="primary" (click)="fullContentFlag = !fullContentFlag">{{!fullContentFlag ? 'Expand Content' : 'Collapse Content'}}</button>
        &nbsp;&nbsp;
        <button mat-raised-button color="primary" (click)="jsonBeautifier = !jsonBeautifier">{{!jsonBeautifier ? 'Enable Json' : 'Disable Json'}}</button>
      </div>
    </div>
    <div class="searched-content" *ngFor="let uxcomp of results.uxComponents">
      <div class="row" *ngIf="!uxcomp.tempClient.edit">
        <div class="col comp-detail">
          <div class="info-section copy" (click)="copyId(uxcomp)"><label>UXComp</label><span>{{uxcomp._id}}</span></div>
          <div class="info-section copy" (click)="copyName(uxcomp)"><label>Name</label><span [innerHTML]="uxcomp.name | replaceRegexPipe: updateInfo: updateTypes.name"></span></div>
          <div class="info-section"><label>Desc</label><span [innerHTML]="uxcomp.description | replaceRegexPipe: updateInfo: updateTypes.desc"></span></div>
          <div class="info-section"><label>Status</label><span>{{ uxcomp.status }}</span></div>
        </div>
        <div class="col comp-option">
          <div class="info-section long" *ngIf="uxcomp.serverOnly"><label>Server Only</label></div>
          <div class="info-section long"><label>Type</label><span>{{uxcomp.type}}</span></div>
          <div class="info-section long" *ngIf="isOrphan(uxcomp)"><label>Orphan</label></div>
        </div>
        <div class="col comp-content">
          @if (showUpdatePreview() && updateInfo.isEffective(updateTypes.content)) {
            <div class="content" 
                 [class.expanded]="fullContentFlag" 
                 [style.whiteSpace]="jsonBeautifier && uxcomp.type === 'JSON' ? 'pre' : ''"
                 [innerHTML]="uxcomp.content | jsonBeautifierPipe: jsonBeautifier && uxcomp.type === 'JSON' | replaceRegexPipe: updateInfo: updateTypes.content"
            ></div>
          }
          @else {
            <div class="content" 
                 [class.expanded]="fullContentFlag"
                 [style.whiteSpace]="jsonBeautifier && uxcomp.type === 'JSON' ? 'pre' : ''"
            >
              {{ uxcomp.content | jsonBeautifierPipe: jsonBeautifier && uxcomp.type === 'JSON' }}
            </div>
          }
        </div>
      </div>
      <div class="row" *ngIf="uxcomp.tempClient.edit">
        <div class="col comp-detail">
          <div class="info-section">
            <label>UXComp</label>
            <span>
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>UXComp</mat-label>
                <input matInput type="text" [disabled]="true" [(ngModel)]="uxcomp._id">
              </mat-form-field>
            </span>
          </div>
          <div class="info-section">
            <label>Name</label>
            <span>
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Name</mat-label>
                <input matInput type="text" [(ngModel)]="uxcomp.draft.name">
              </mat-form-field>
            </span>
          </div>
          <div class="info-section">
            <label>Desc</label>
            <span>
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Description</mat-label>
                <input matInput type="text" [(ngModel)]="uxcomp.draft.description">
              </mat-form-field>
            </span>
          </div>
        </div>
        <div class="col comp-option">
          <div class="info-section long">
            <label>Server Only</label>
            <span>
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Server Only</mat-label>
                <mat-select [(ngModel)]="uxcomp.draft.serverOnly">
                  <mat-option [value]="true">True</mat-option>
                  <mat-option [value]="false">False</mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </div>
          <div class="info-section long">
            <label>Type</label>
            <span>
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Type</mat-label>
                <mat-select [(ngModel)]="uxcomp.draft.type" [disabled]="!uxcomp.draft.isActive()">
                  <mat-option *ngFor="let compType of uxComponentTypes" [value]="compType.key">{{compType.value}}</mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </div>
        </div>
        <div class="col comp-content">
          <mat-form-field appearance="outline" floatLabel="always">
            <textarea rows="10" matInput [(ngModel)]="uxcomp.draft.content"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <ng-container *ngIf="uxcomp.tempClient.edit">
          <button mat-raised-button color="primary" (click)="update(uxcomp)" [disabled]="!uxcomp.isDiffDraft()" class="mr-2">Save</button>
          <button mat-stroked-button color="primary" (click)="toggleEdit(uxcomp)" class="mr-2">Cancel</button>
          <button mat-icon-button color="primary" *ngIf="!uxcomp.getToggle('uncheck')" (click)="uxcomp.toggle('uncheck')">
            <i class="material-icons" [class.opacity50]="!uxcomp.draft.check(false)">check_circle_outline</i>
          </button>
          <button mat-icon-button color="primary" *ngIf="uxcomp.getToggle('uncheck')" (click)="uxcomp.toggle('uncheck')">
            <i class="material-icons" [class.opacity50]="!uxcomp.draft.check(false)">account_circle</i>
          </button>
        </ng-container>
        <ng-container *ngIf="!uxcomp.tempClient.edit">
          <button mat-raised-button color="primary" (click)="toggleEdit(uxcomp)" class="mr-2">Edit</button>
          <button mat-stroked-button color="primary" (click)="duplicate(uxcomp)" class="mr-2">Copy</button>
          <button mat-stroked-button color="primary" (click)="findUxDetail(uxcomp._id)" class="mr-2">Search</button>
          <button mat-raised-button color="warn" (click)="openDeleteToggleModal(uxcomp)" class="mr-2">
            {{ uxcomp.status === 'active' ? 'Delete' : 'Restore' }}
          </button>
          <button mat-stroked-button color="primary" (click)="findUxHistory(uxcomp._id, 'uxComponents')" class="mr-2 gr-left">History</button>
          <button mat-stroked-button color="primary" (click)="openUxHistory(uxcomp._id, 'uxComponents')" class="mr-2 gr-right">🔗</button>
          <button mat-stroked-button color="primary" (click)="previewHtml(uxcomp.content)" class="mr-2">Preview</button>
          <mat-checkbox color="primary" [checked]="isDownloadable(uxcomp)" (change)="toggleDownloadFlag(uxcomp)">Download</mat-checkbox>
          <mat-slide-toggle class="ml-12" [checked]="uxcUpdateIds.includes(uxcomp._id) ? true : false"  (change)="toggleCreateUpdateUxcomp(toUpdate, uxcomp)" #toUpdate color="primary">
            <span class="management-toggle">{{ toUpdate.checked ? 'Update Uxcomp': 'New Uxcomp' }}</span>
          </mat-slide-toggle>
        </ng-container>
      </div>
      <div *ngIf="hasHistory('uxComponents', uxcomp._id)">
        <uxHistory [uxHistory]="uxHistory['uxComponents']" 
                   [id]="uxcomp._id" type='uxComponents'
                   [jsonBeautifier]="jsonBeautifier"
        ></uxHistory>
      </div>
      <div *ngIf="hasDetail(uxcomp)">
        <uxDetail [uxDetail]="uxcomp.tempClient.uxDetail"></uxDetail>
      </div>
    </div>
  </ng-container>
</div>

import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
    selector: 'app-list-item',
    templateUrl: './list-item.component.html',
    standalone: false
})
export class ListItemComponent implements OnInit {
  @Input() items: any[];
  @ViewChild('childList') public childList;

  @Output() onClickedList = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {
  }

  handleClick(name, index) {
    let updateName = name;
    if (index > -1)
      updateName = this.items[index].name + '|' + updateName;
    this.onClickedList.emit(updateName);
  }
}

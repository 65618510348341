import { Component, Input, ChangeDetectorRef } from "@angular/core";

@Component({
    selector: 'uxRevisionHistory',
    templateUrl: 'uxRevisionHistory.component.html',
    standalone: false
})

export class UxRevisionHistoryComponent {
    @Input() uxHistory = [];
    @Input() type: string = '';

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit() {  
      //this.uxHistory = this.uxHistory;
      this.cdr.detectChanges();
    }
}

import {RouterModule, Routes} from '@angular/router';
import {DefaultLoginComponent, LoginComponent} from './login/index';
import {AuthGuard, CsrAuthGuard} from './guards/index';
import {ContentMgmtComponent, UxcFullMgmtComponent, UxcFullMgmtComponentV1} from './uxc/index';
import {ProductFullMgmtComponent} from './product/productFullMgmt.component';
import {ProductFullMgmtComponentV1} from './product/productFullMgmtV1.component';
import {UserMgmtComponent} from './users/userMgmt.component';
import {UserMgmtComponentV1} from './users/userMgmtV1.component';
import {AdminAuthGuard} from './guards/admin.auth.guard';
import {CustomersComponent} from './csr/customers.component';
import {CustomerComponentV2} from './csr/customerv2.component';
import {adminPaths} from '../common/helpers/pathHelpers';
import {MailSentComponent} from './csr/mailSent.component';
import {TrackingComponent} from './csr/tracking.component';
import {PermissionsComponent} from './users/permissions.component';
import {PermissionsComponentV1} from './users/permissionsV1.component';
import {OptOutComponent} from './csr/optOut.component';
import { CustomerComponentV1 } from './csr/customerv1.component';
import {TrackingComponentV1} from './csr/trackingV1.component';
import {OptOutComponentV1} from './csr/optOutV1.component';
import {MailSentComponentV1} from './csr/mailSentV1.component';
import {UnsubscribeComponent} from './csr/unsubscribe.component';
import {TimeSheetComponent} from './csr/timeSheet.component';
import {TimeSheetSummaryComponent} from './csr/timeSheetSummary.component';
import { NotesManagementComponent } from './csr/notes-management/notes-management.component';
import { CSREmailComponent } from './csr/email';
import { UxHistoryComponent } from './csr/ux-history/ux-history.component';
import { CSRFactFindersComponent } from './csr/factFinders';
import { CustomerComponent } from './csr/customer.component';
import { PaymentComponent } from './csr/payment.component';
import { PaymentsComponent } from './csr/payments.component';
import { UxcHistoryComponent } from './uxc/uxcHistory.component';
import { OrdersComponent } from './csr/orders.component';
import { OrderComponent } from './csr/order.component';
import { ScheduleHistorysComponent } from './csr/scheduleHistorys.component';
import { UxRevisionHistoryPageComponent } from './uxc/uxRevisionHistoryPage.component';

const appRoutes: Routes = [
  {path: adminPaths.login, component: LoginComponent},
  {path: adminPaths.uxcFullMgmt, component: UxcFullMgmtComponent, canActivate: [AuthGuard, CsrAuthGuard]},
  {path: adminPaths.uxcHistory, component: UxcHistoryComponent, canActivate: [AuthGuard,  CsrAuthGuard]},
  {path: adminPaths.uxcRevisionHistory + '/:type/:id', component: UxRevisionHistoryPageComponent, canActivate: [AuthGuard,  CsrAuthGuard]},
  {path: adminPaths.scheduleHistory, component: ScheduleHistorysComponent, canActivate: [AuthGuard,  CsrAuthGuard]},
  
  {path: adminPaths.productFullMgmt, component: ProductFullMgmtComponent, canActivate: [AuthGuard, CsrAuthGuard]},
  {path: adminPaths.userMgmt, component: UserMgmtComponent, canActivate: [AuthGuard,  CsrAuthGuard]},
  {path: adminPaths.userMgmtV1, component: UserMgmtComponentV1, canActivate: [AuthGuard,  CsrAuthGuard]},
  {path: adminPaths.csrCustomers, component: CustomersComponent, canActivate: [AuthGuard, CsrAuthGuard]},
  {path: adminPaths.csrCustomer + '/:userId', component: CustomerComponent, canActivate: [AuthGuard, CsrAuthGuard]},
  {path: adminPaths.commercePayment, component: PaymentsComponent, canActivate: [AuthGuard, CsrAuthGuard]},
  {path: adminPaths.commercePayment + '/:commercePaymentId', component: PaymentComponent, canActivate: [AuthGuard, CsrAuthGuard]},
  {path: adminPaths.commerceOrder, component: OrdersComponent, canActivate: [AuthGuard, CsrAuthGuard]},
  {path: adminPaths.commerceOrder + '/:commerceOrderId', component: OrderComponent, canActivate: [AuthGuard, CsrAuthGuard]},
  
  {path: adminPaths.mailSent, component: MailSentComponent, canActivate: [AuthGuard, CsrAuthGuard]},
  {path: adminPaths.tracking, component: TrackingComponent, canActivate: [AuthGuard, CsrAuthGuard]},
  {path: adminPaths.optOutView, component: OptOutComponent, canActivate: [AuthGuard, CsrAuthGuard]},
  {path: adminPaths.factFinders, component: CSRFactFindersComponent, canActivate: [AuthGuard, CsrAuthGuard]},
  {path: adminPaths.permissions, component: PermissionsComponent, canActivate: [AuthGuard, AdminAuthGuard]},
  {path: adminPaths.contentMgmt, component: ContentMgmtComponent, canActivate: [AuthGuard, CsrAuthGuard]},
  // {path: adminPaths.emailMgmt, component: EmailComponent, canActivate: [AuthGuard, CsrAuthGuard]},
  {path: adminPaths.emailMgmt, component: CSREmailComponent, canActivate: [AuthGuard, CsrAuthGuard]},
  { path: adminPaths.uxHistoryMgmt, component: UxHistoryComponent, canActivate: [AuthGuard, CsrAuthGuard] },
  {path: adminPaths.csrCustomerV1 + '/:userId', component: CustomerComponentV1, canActivate: [AuthGuard, CsrAuthGuard]},
  {path: adminPaths.csrCustomerV2 + '/:userId', component: CustomerComponentV2, canActivate: [AuthGuard, CsrAuthGuard]},
  {path: adminPaths.uxcFullMgmtV1, component: UxcFullMgmtComponentV1, canActivate: [AuthGuard, CsrAuthGuard]},
  {path: adminPaths.productFullMgmtV1, component: ProductFullMgmtComponentV1, canActivate: [AuthGuard, CsrAuthGuard]},
  {path: adminPaths.permissionsV1, component: PermissionsComponentV1, canActivate: [AuthGuard, AdminAuthGuard]},
  {path: adminPaths.uxcFullMgmtV1, component: UxcFullMgmtComponentV1, canActivate: [AuthGuard, CsrAuthGuard]},
  {path: adminPaths.productFullMgmtV1, component: ProductFullMgmtComponentV1, canActivate: [AuthGuard, CsrAuthGuard]},
  {path: adminPaths.trackingV1, component: TrackingComponentV1, canActivate: [AuthGuard, CsrAuthGuard]},
  {path: adminPaths.optOutViewV1, component: OptOutComponentV1, canActivate: [AuthGuard, CsrAuthGuard]},
  {path: adminPaths.mainSentV1, component: MailSentComponentV1, canActivate: [AuthGuard, CsrAuthGuard]},
  // {path: adminPaths.notes, component: NotesComponent, canActivate: [AuthGuard, CsrAuthGuard]},
  // {path: adminPaths.notes, component: NotesComponent, canActivate: [AuthGuard, CsrAuthGuard]},
  {path: adminPaths.notes, component: NotesManagementComponent, canActivate: [AuthGuard, CsrAuthGuard]},
  {path: adminPaths.unsubscribe, component: UnsubscribeComponent, canActivate: [AuthGuard, CsrAuthGuard]},
  {path: adminPaths.timesheets, component: TimeSheetSummaryComponent, canActivate: [AuthGuard, CsrAuthGuard]},
  {path: adminPaths.timesheet + '/:userId', component: TimeSheetComponent, canActivate: [AuthGuard, CsrAuthGuard]},

  // otherwise redirect to home
  {path: '', component: DefaultLoginComponent},
  {path: '**', redirectTo: ''}
];

export const routing = RouterModule.forRoot(appRoutes);

import {Injectable} from "@angular/core";
import {UserInput} from "../../common/models/user/userInput";
import { StorageService } from "./storage.service";

@Injectable()
export class UserInputService {
  STORAGE_NAME = 'userInput';

  public userInput: UserInput;

  constructor(private storageService: StorageService) {
  }
 /**
   * getting the user info from local storage
   * if user info already exists returning the uxinfo in the original component
   */
  getUserInput(): UserInput {

    if (!this.userInput) {
      this.userInput = new UserInput(JSON.parse(this.storageService.getItem(this.STORAGE_NAME)));
      //this.userInput = new UserInput(JSON.parse(localStorage.getItem(this.STORAGE_NAME)));      
    }
    return this.userInput;
  }
/**
   * storing the userinfo in the localstorage
   */
  storeUserInput() {
    localStorage.setItem(this.STORAGE_NAME, JSON.stringify(this.userInput));
  }
  /**
   * clearing all the userinfo fields from userinfo
   * clearing the userinfo from localstorage
   */
  clear() {
    Object.keys(this.userInput).forEach((key) => {
      this.userInput[key] = undefined;
    });

    this.storageService.set(this.STORAGE_NAME, this.userInput);
    //localStorage.setItem(this.STORAGE_NAME, JSON.stringify(this.userInput));
  }
}
